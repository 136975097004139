import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Card, CardHeader, CardBody, Row, Col, Button, CardFooter, CardTitle, Label, Container, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Form, Field } from "react-final-form";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import { OnChange } from "react-final-form-listeners";
import ReactTable from "react-table";
import NotificationAlert from "react-notification-alert";
import { loginToken, updatePart, notifySigners, unloadNotify, getParamsUrl } from "reducers/SinglePurchase";

var API_LINK_URL = process.env.REACT_APP_API_LINK_URL;

class NotifyParts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfiaNotificationOpen: false,
      senderConfia: false,
      finishModal: false,
    };
  }

  async componentWillMount() {
    if (!this.props.company) {
      //Try to reconect using token
      await this.props.loginToken();
    }
  }
  componentDidMount() {
    this.props.getParamsUrl("type");
  }
  componentWillUnmount() {
    this.props.unloadNotify();
  }

  copyToClipboard = async (link) => {
    await navigator.clipboard.writeText(link);
    var options = {
      place: "tc",
      message: (
        <div>
          <div>URL copiado para área de transferência.</div>
        </div>
      ),
      type: "success",
      icon: "fa fa-check-circle",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleSendWhatsapp = (props) => {
    var name = props.name.toLowerCase().split(" ")[0];
    name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    const message = `Olá, ${name}! Você tem um documento para assinar com a Confi.\n\nAgora, você vai passar pelas etapas de identificação e biometria facial para assinar o seu documento. Clique neste link para começar: ${
      API_LINK_URL + "/?chave=" + props.identifier
    }\n\n*A Confia não é responsável pelo conteúdo do documento ou por eventuais negociações entre as partes.`;
    window.open(`https://wa.me/55${FieldNormalize.NUMBER(props.phone)}?text=${encodeURIComponent(message)}`, "_blank");
  };

  handleSelectCheckbox = (row, value, id) => {
    var part = row;

    if (id === 1) {
      part.notifySms = value == "true" ? true : false;
    } else if (id === 2) {
      part.notifyWhatsapp = value == "true" ? true : false;
    } else if (id === 3) {
      part.notifyEmail = value == "true" ? true : false;
    }

    this.props.updatePart(part);
  };

  render() {
    if (this.props.redirectToSingle) {
      return <Redirect to={"/singlePurchase"} />;
    }

    if (this.props.company && this.props.company.sendMessage) {
      var table = [
        {
          Header: "CPF",
          sortable: false,
          filterable: false,
          style: { "white-space": "unset" },
          accessor: "cpf",
          headerClassName: "headTable align-items-center",
        },
        {
          Header: "Nome",
          sortable: false,
          filterable: false,
          accessor: "name",
          headerClassName: "headTable align-items-center",
        },
        {
          Header: "Enviar link de assinatura",
          sortable: false,
          filterable: false,
          headerClassName: "text-center text-wrap headTable align-items-center",
          Cell: (row) => {
            return (
              <div className="actions-right">
                {row.original.phone && (
                  <>
                    <Button color="default" size="sm" title="Enviar link de assinatura por WhatsApp" className="btn-icon btn-link edit" onClick={() => this.handleSendWhatsapp(row.original)}>
                      <i className="fa fa-whatsapp" />
                    </Button>{" "}
                  </>
                )}
                <Button
                  color="default"
                  size="sm"
                  title="Copiar link de assinatura"
                  className="btn-icon btn-link edit"
                  onClick={() => this.copyToClipboard(API_LINK_URL + "/?chave=" + row.original.identifier)}
                >
                  <i className="fa fa-copy" />
                </Button>{" "}
              </div>
            );
          },
        },
        {
          Header: "Notificar pela Confia",
          sortable: false,
          filterable: false,
          minWidth: 200,
          headerClassName: "text-center text-wrap headTable align-items-center",
          Cell: (row) => {
            return (
              <Row>
                {row.original.phone && (
                  <>
                    <Col sm="4">
                      <Label check>
                        <Field name={"signPartNotifySms" + row.original.signPartId} component="input" type="checkbox" value="true" />
                        {" SMS"}
                        <OnChange name={"signPartNotifySms" + row.original.signPartId}>
                          {(value, previous) => {
                            this.handleSelectCheckbox(row.original, value, 1);
                          }}
                        </OnChange>
                      </Label>
                    </Col>
                    <Col sm="4">
                      <Label check>
                        <Field name={"signPartNotifyWhatsapp" + row.original.signPartId} component="input" type="checkbox" value="true" />
                        {" WHATSAPP"}
                        <OnChange name={"signPartNotifyWhatsapp" + row.original.signPartId}>
                          {(value, previous) => {
                            this.handleSelectCheckbox(row.original, value, 2);
                          }}
                        </OnChange>
                      </Label>
                    </Col>
                  </>
                )}
                {row.original.email && (
                  <Col sm="4">
                    <Label check>
                      <Field name={"signPartNotifyEmail" + row.original.signPartId} component="input" type="checkbox" value="true" />
                      {" EMAIL"}
                      <OnChange name={"signPartNotifyEmail" + row.original.signPartId}>
                        {(value, previous) => {
                          this.handleSelectCheckbox(row.original, value, 3);
                        }}
                      </OnChange>
                    </Label>
                  </Col>
                )}
                {!row.original.phone && !row.original.email && <Col>É necessário que os dados do assinante sejam colocados ao cadastrar o documento para podermos enviar a notificação.</Col>}
              </Row>
            );
          },
        },
      ];
    } else {
      var table = [
        {
          Header: "CPF",
          sortable: false,
          filterable: false,
          style: { "white-space": "unset" },
          accessor: "cpf",
          headerClassName: "headTable",
        },
        {
          Header: "Nome",
          sortable: false,
          filterable: false,
          accessor: "name",
          headerClassName: "headTable",
        },
        {
          Header: "Enviar link para assinates",
          sortable: false,
          filterable: false,
          headerClassName: "headTable",
          Cell: (row) => {
            return (
              <div className="text-center">
                {row.original.phone && (
                  <>
                    <Button color="default" size="sm" title="Enviar link de assinatura por WhatsApp" className="btn-icon btn-link edit" onClick={() => this.handleSendWhatsapp(row.original)}>
                      <i className="fa fa-whatsapp" />
                    </Button>{" "}
                  </>
                )}
                <Button
                  color="default"
                  size="sm"
                  title="Copiar link de assinatura"
                  className="btn-icon btn-link edit"
                  onClick={() => this.copyToClipboard(API_LINK_URL + "/?chave=" + row.original.identifier)}
                >
                  <i className="fa fa-copy" />
                </Button>{" "}
              </div>
            );
          },
        },
      ];
    }

    return (
      <>
        <div className="login-page">
          <NotificationAlert ref="notificationAlert" />
          <Container>
            <Row>
              <div className="col-md-2 ml-auto mr-auto mt-5">
                <img alt="..." src={require("stylesheets/img/logo/confia_site_branca.png")} />
              </div>
              <Col className="ml-auto mr-auto mt-3" lg="12" md="4">
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                    <form onSubmit={handleSubmit}>
                      <Row className="justify-content-center">
                        <Col md="8">
                          <Card className="card-tasks">
                            <CardHeader>
                              <Row className="border-bottom">
                                <Col>
                                  <h4 style={{ fontWeight: "700" }}>
                                    DOCUMENTO CADASTRADO <img style={{ paddingLeft: "10px", height: "40px", objectFit: "cover" }} alt="..." src={require("stylesheets/img/icones/Confia-09.png")} />
                                    <br /> COM SUCESSO !
                                  </h4>
                                </Col>
                                <Col />
                              </Row>
                              <Row className="mt-4">
                                <Col>
                                  <h6>ENVIAR LINK DE ASSINATURA</h6>
                                  <p style={{ fontSize: "16px" }}>Chegou a hora de enviar o link para quem vai assinar o documento.</p>
                                  <p className="mt-2" style={{ fontSize: "16px", fontWeight: "680" }}>
                                    Clique no ícone do WhatsApp e mande diretamente para o seu contato ou copie o link e envie por e-mail, por exemplo.
                                  </p>
                                  {this.props.company.sendMessage && (
                                    <>
                                      <p className="mt-2" style={{ fontSize: "16px", fontWeight: "680" }}>
                                        Você também pode enviar o link de assinatura pelos canais da Confia. Se essa for a sua opção, clique no botão cinza ao lado.
                                      </p>
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <Row className="justify-content-center">
                                <Col md="11">
                                  <ReactTable
                                    data={this.props.signParts}
                                    columns={table}
                                    showPagination={false}
                                    minRows={1}
                                    sortable={false}
                                    loadingText="Carregando..."
                                    noDataText="Nenhum assinate para o documento"
                                    className="-striped -highlight"
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                            <CardFooter>
                              <Row>
                                <Col>
                                  {this.props.company && this.props.company.sendMessage ? (
                                    <>
                                      <Col m="6" className="text-left" />
                                      <Col m="6" className="text-right">
                                        <Button
                                          className="btn btn-round"
                                          onClick={() => {
                                            this.props.notifySigners({
                                              parts: this.props.signParts,
                                              companyId: this.props.company.companyId,
                                            });
                                          }}
                                        >
                                          {"PRONTO"}
                                        </Button>
                                      </Col>
                                    </>
                                  ) : (
                                    <>
                                      <Col m="6" className="text-right">
                                        {this.props.typeUrl === "RTD" || this.props.typeUrl === "rtd" ? (
                                          <>
                                            <Button className="btn btn-round" onClick={() => this.setState({ finishModal: true })}>
                                              {"PRONTO"}
                                            </Button>
                                          </>
                                        ) : (
                                          <>
                                            <Link className="btn btn-round" to="/singlePurchase/documents">
                                              {"PRONTO"}
                                            </Link>
                                          </>
                                        )}
                                      </Col>
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </CardFooter>
                          </Card>

                          <Modal isOpen={this.state.senderConfia || this.state.finishModal} backdrop="static" size="md">
                            {this.state.senderConfia ? (
                              <>
                                {/*

                                <Row className="text-center mt-5  mb-2">
                                  <Col>
                                    <img style={{ height: "40px", objectFit: "cover" }} alt="..." src={require("stylesheets/img/icones/Confia-10.png")} />
                                    <h6>ENVIAR LINK PELA CONFIA</h6>
                                    <p>
                                      Para enviar o link de assinatura pelos canais da Confia, você paga <strong>R$ 1,50</strong> por assinante
                                    </p>
                                  </Col>
                                </Row>
                                <Row className="text-center">
                                  <Col>
                                    <Button style={{ backgroundColor: "#a8c8c8", paddingRight: "15px" }} className="btn btn-sm" onClick={() => this.setState({ senderConfia: false })}>
                                      {"VOLTAR"}
                                    </Button>{" "}
                                    <Button
                                      className="btn btn-sm"
                                      onClick={() => {
                                        this.setState({ senderConfia: false });
                                      }}
                                    >
                                      {"OK, CONFIRMAR"}
                                    </Button>
                                  </Col>
                                </Row>*/}
                              </>
                            ) : (
                              <>
                                <Row className="text-center mt-5  mb-2">
                                  <Col>
                                    <img style={{ height: "40px", objectFit: "cover" }} alt="..." src={require("stylesheets/img/icones/Confia-10.png")} />
                                    <h6>
                                      FALTA POUCO PARA CONCLUIR A ETAPA DE
                                      <br /> ASSINATURA DO DOCUMENTO.
                                    </h6>
                                    <p>
                                      Depois que todos assinarem
                                      <br /> a segunda etapa é com o cartório
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-center" md="2">
                                    <p className="number-li">1.</p>
                                  </Col>
                                  <Col md="7" lg="7">
                                    <p>Faça o upload do arquivos na Central RTDPJ; </p>
                                  </Col>
                                  <Col>
                                    {" "}
                                    <img style={{ height: "30px" }} alt="..." src={require("stylesheets/img/icones/li-1.png")} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-center" md="2">
                                    <p className="number-li">2.</p>
                                  </Col>
                                  <Col md="7" lg="7">
                                    <p>Você receberá um novo orçamento para registro no cartório selecionado;</p>
                                  </Col>
                                  <Col>
                                    {" "}
                                    <img style={{ height: "30px" }} alt="..." src={require("stylesheets/img/icones/li-2.png")} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-center" md="2">
                                    <p className="number-li">3.</p>
                                  </Col>
                                  <Col md="7" lg="7">
                                    <p>Depois de pagar o emolumento do cartório, o documento será registrado e enviado para você</p>
                                  </Col>
                                  <Col>
                                    {" "}
                                    <img style={{ height: "30px" }} alt="..." src={require("stylesheets/img/icones/li-3.png")} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-center" md="2">
                                    <p className="number-li">4.</p>
                                  </Col>
                                  <Col md="7" lg="7">
                                    <p>Para todas as etapas do cartório, você receberá alertas no seu e-mail.</p>
                                  </Col>
                                  <Col>
                                    {" "}
                                    <img style={{ height: "30px" }} alt="..." src={require("stylesheets/img/icones/li-4.png")} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-center">
                                    <Link className="btn btn-sm" to="/singlePurchase/documents?type=rtd">
                                      {"OK, ENTENDI"}
                                    </Link>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Modal>
                        </Col>
                      </Row>
                    </form>
                  )}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const onSubmit = async (values) => {
  //  await sleep(300)
};

const mapStateToProps = (state) => ({
  ...state.SinglePurchase,
});

function mapDispatchToProps(dispatch) {
  return {
    updatePart: (part) => dispatch(updatePart(part)),
    notifySigners: (props) => dispatch(notifySigners(props)),
    loginToken: () => dispatch(loginToken()),
    unloadNotify: () => dispatch(unloadNotify()),
    getParamsUrl: (params) => dispatch(getParamsUrl(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifyParts);
