import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { Card, CardHeader, CardBody, Row, Col, Button, CardFooter, Modal, Label, CardTitle } from "reactstrap";
import ReactWizard from "react-bootstrap-wizard";
import NotificationAlert from "react-notification-alert";

import TextField from "components/Fields/TextField";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import FieldValidation from "components/Fields/Validation/FieldValidation";

import { unload, load, removeUser, unlockUser, save } from "reducers/Users";
import { showAlert, closeAlert } from "reducers/Notification";

import ReactTable from "react-table";
import SelectField from "components/Fields/SelectField";
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalEdit: false,
      editUser: null,
    };
  }

  componentWillMount() {
    if (this.props.company) {
      this.props.load(this.props.company.companyId);
    }
  }

  componentDidMount() {
    if (this.props.alertNewDocument) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div style={{ fontWeight: "bold" }}>{this.props.alertMessageNewDocument}</div>
          </div>
        ),
        type: "success",
        icon: "fa fa-check-circle",
        autoDismiss: 0,
      };
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  componentWillUnmount() {
    this.props.unload();
  }

  handleClickRemoveUser = (userId) => (ev) => {
    const props = {
      userId,
      companyId: this.props.company.companyId,
    };

    console.log("handleClickRemove");
    if (ev) {
      ev.preventDefault();
    }
    Promise.resolve(this.props.removeUser(props))
      .then((response) => {
        console.log("RESPONSE->", response);
        this.props.load(this.props.company.companyId);
        this.props.closeAlert();
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>Usuário excluido com sucesso.</div>
            </div>
          ),
          type: "success",
          icon: "fa fa-check-circle",
          autoDismiss: 0,
        };
        this.refs.notificationAlert.notificationAlert(options);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleClickUnlockUser = (userId) => (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    Promise.resolve(this.props.unlockUser(userId))
      .then((response) => {
        console.log("RESPONSE->", response);
        this.props.load(this.props.company.companyId);
        this.props.closeAlert();
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>Usuário desbloqueado com sucesso.</div>
            </div>
          ),
          type: "success",
          icon: "fa fa-check-circle",
          autoDismiss: 0,
        };
        this.refs.notificationAlert.notificationAlert(options);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  toggleModalEdit = () => {
    this.setState({
      modalEdit: !this.state.modalEdit,
    });
  };

  handleSave = (values) => (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    const props = {
      userId: this.state.editUser.userId,
      companyId: this.props.company.companyId,
      values: {
        name: values.name,
        email: values.email,
        phone: values.phone,
        roleId: values.roleId.value,
      },
    };

    Promise.resolve(this.props.save(props))
      .then((response) => {
        console.log("RESPONSE->", response);
        this.props.load(this.props.company.companyId);
        this.toggleModalEdit();
        this.props.closeAlert();
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>Usuário alterado com sucesso.</div>
            </div>
          ),
          type: "success",
          icon: "fa fa-check-circle",
          autoDismiss: 5,
        };
        this.refs.notificationAlert.notificationAlert(options);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.modalEdit}
          toggle={this.toggleModalEdit}
          style={{
            maxWidth: "80vw",
            width: "90%",
            margin: "auto",
            paddingBottom: "5vh",
            paddingTop: "5vh",
          }}
        >
          <>
            {this.state.editUser && (
              <>
                <Form
                  onSubmit={onSubmit}
                  initialValues={this.state.editUser}
                  render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col className="text-center" md="12">
                          <Card className="card-tasks">
                            <CardHeader className="text-center">
                              <CardTitle tag="h6">Editar Usuário</CardTitle>
                            </CardHeader>

                            <CardBody>
                              <Row>
                                <Col>
                                  <Card>
                                    <CardBody>
                                      <Row>
                                        <Col className="mx-auto" md="6">
                                          <Field name="cpf" label="CPF" placeholder="CPF" component={TextField} required parse={FieldNormalize.CPF} validate={FieldValidation.required} disabled />
                                        </Col>
                                        <Col className="mx-auto" md="6">
                                          <Field name="name" label="Nome" placeholder="Nome" component={TextField} required validate={FieldValidation.required} />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col className="mx-auto" md="4">
                                          <Field
                                            name="phone"
                                            label="Telefone"
                                            placeholder="Telefone"
                                            component={TextField}
                                            type="tel"
                                            required
                                            parse={FieldNormalize.CELULAR}
                                            validate={FieldValidation.composeValidators(FieldValidation.required)}
                                          />
                                        </Col>
                                        <Col className="mx-auto" md="4">
                                          <Field
                                            name="email"
                                            label="E-mail"
                                            placeholder="E-mail"
                                            component={TextField}
                                            type="email"
                                            required
                                            validate={FieldValidation.composeValidators(FieldValidation.required, FieldValidation.email)}
                                          />
                                        </Col>
                                        <Col className="mx-auto" md="4">
                                          <Field
                                            name="roleId"
                                            label="Perfil"
                                            placeholder="Perfil"
                                            component={SelectField}
                                            required
                                            validate={FieldValidation.required}
                                            options={[
                                              { id: 1, desc: "Administrador" },
                                              { id: 2, desc: "Operador" },
                                              { id: 5, desc: "Supervisor" },
                                            ]}
                                            textField="desc"
                                            valueField="id"
                                          />
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>

                              <Row>
                                <Col m="6" className="text-left">
                                  <Button className="btn btn-round" onClick={this.toggleModalEdit}>
                                    {"CANCELAR"}
                                  </Button>
                                </Col>
                                <Col m="6" className="text-right">
                                  <Link
                                    className="btn btn-round"
                                    onClick={() =>
                                      this.props.showAlert({
                                        type: "warning",
                                        title: "Deseja realmente editar esse usuário?",
                                        content: "Esse usuário será alterado em todo o sistema.",
                                        showCancel: true,
                                        onCancel: this.props.closeAlert,
                                        confirmBtnBsStyle: "warning",
                                        cancelBtnBsStyle: "secondary",
                                        onConfirm: this.handleSave(values),
                                        confirmBtnText: "Confirmar",
                                        cancelBtnText: "Cancelar",
                                      })
                                    }
                                    disabled={
                                      values.email === this.state.editUser.email &&
                                      values.phone === this.state.editUser.phone &&
                                      values.name === this.state.editUser.name &&
                                      values.roleId === this.state.editUser.roleId
                                    }
                                  >
                                    {"CONFIRMAR"}
                                  </Link>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </form>
                  )}
                />
              </>
            )}
          </>
        </Modal>

        <div className="content">
          <NotificationAlert ref="notificationAlert" />

          <div style={{ position: "sticky", top: "0", zIndex: "100" }}>
            <Row>
              <Col m="4" className="text-left">
                <Link size="sm" className="btn btn-sm" to="/company/dashboard">
                  Cancelar
                </Link>
              </Col>
              <Col m="4" className="text-center">
                <h6 className="title" style={{ lineHeight: "44px", fontSize: ".85em" }}>
                  Usuários
                </h6>
              </Col>
              <Col m="4" className="text-right">
                <Link size="sm" className="btn btn-sm" to="/company/new-user">
                  Novo
                </Link>
              </Col>
            </Row>
          </div>

          <Row>
            <Col className="text-center" md="12">
              <Card className="card-tasks">
                <CardBody>
                  <ReactTable
                    data={this.props.users}
                    filterable
                    columns={[
                      {
                        Header: "Nome",
                        style: { whiteSpace: "unset" },
                        Cell: (row) => {
                          if (row.original.blocked) {
                            return <div style={{ color: "red" }}>{row.original.name}</div>;
                          } else {
                            return <div>{row.original.name}</div>;
                          }
                        },
                      },
                      {
                        Header: "CPF",
                        style: { whiteSpace: "unset" },
                        accessor: "cpf",
                      },
                      {
                        Header: "Perfil",
                        Cell: (row) => {
                          return <div>{row.original.roleId === 1 ? "Administrador" : row.original.roleId === 5 ? "Supervisor" : "Operador"}</div>;
                        },
                      },
                      {
                        Header: "Telefone",
                        style: { whiteSpace: "unset" },
                        accessor: "phone",
                      },
                      {
                        Header: "Email",
                        style: { whiteSpace: "unset" },
                        accessor: "email",
                      },
                      {
                        Header: "Ações",
                        sortable: false,
                        filterable: false,
                        Cell: (row) => {
                          return (
                            <div className="actions-right">
                              {!row.original.blocked || (
                                <Button
                                  color="default"
                                  size="sm"
                                  title="Desbloquear acesso do usuário"
                                  className="btn-icon btn-link edit"
                                  onClick={() =>
                                    this.props.showAlert({
                                      type: "danger",
                                      title: "Você realmente quer desbloquear esse usuário?",
                                      content: "Esse usuário poderá tentar acessar o sistema novamente.",
                                      showCancel: true,
                                      onCancel: this.props.closeAlert,
                                      confirmBtnBsStyle: "danger",
                                      cancelBtnBsStyle: "secondary",
                                      onConfirm: this.handleClickUnlockUser(row.original.userId),
                                      confirmBtnText: "Desbloquear",
                                      cancelBtnText: "Cancelar",
                                    })
                                  }
                                >
                                  <i className="fa fa-unlock" />
                                </Button>
                              )}
                              {this.props.user.userId !== row.original.userId && (
                                <>
                                  <Button
                                    color="default"
                                    size="sm"
                                    title="Editar usuário"
                                    className="btn-icon btn-link edit"
                                    onClick={() => {
                                      this.setState({ editUser: row.original });
                                      this.toggleModalEdit();
                                    }}
                                  >
                                    <i className="fa fa-edit" />
                                  </Button>{" "}
                                  <Button
                                    color="default"
                                    size="sm"
                                    title="Excluir usuário"
                                    className="btn-icon btn-link edit"
                                    onClick={() =>
                                      this.props.showAlert({
                                        type: "success",
                                        title: "Você realmente quer excluir esse usuário?",
                                        content: "Esse usuário será desvinculado da empresa e não poderá mais acessar o sistema.",
                                        showCancel: true,
                                        onCancel: this.props.closeAlert,
                                        confirmBtnBsStyle: "dark",
                                        cancelBtnBsStyle: "secondary",
                                        onConfirm: this.handleClickRemoveUser(row.original.userId),
                                        confirmBtnText: "Excluir",
                                        cancelBtnText: "Cancelar",
                                      })
                                    }
                                  >
                                    <i className="fa fa-close" />
                                  </Button>
                                </>
                              )}
                            </div>
                          );
                        },
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    showPageSizeOptions={false}
                    showPageJump={false}
                    previousText="ANTERIOR"
                    nextText="PRÓXIMO"
                    loadingText="Carregando..."
                    noDataText="Nenhum usuário encontrado"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                    className="-striped -highlight defaul-pagination"
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">{this.props.users ? this.props.users.length : 0} Usuários</div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const onSubmit = async (values) => {
  //  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2));
};

const mapStateToProps = (state) => ({
  ...state.Auth,
  ...state.Users,
});

function mapDispatchToProps(dispatch) {
  return {
    unload: () => dispatch(unload()),
    load: (companyId) => dispatch(load(companyId)),
    removeUser: (props) => dispatch(removeUser(props)),
    unlockUser: (props) => dispatch(unlockUser(props)),
    save: (props) => dispatch(save(props)),
    showAlert: (alertProps) => dispatch(showAlert(alertProps)),
    closeAlert: () => dispatch(closeAlert(0)),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
