const ADD_ALERT = 'confia/notification/ADD_ALERT';
const REMOVE_ALERT = 'confia/notification/REMOVE_ALERT';

const INITIAL_STATE = { alerts: [],};

export function showAlert(alertProps) {

  if (!alertProps.id) {
    alertProps.id = '' + Date.now() + Math.random() + Math.random();
  }

  return {
    type: ADD_ALERT,
    payload: alertProps
  };
}

export function closeAlert() {
  return {
    type: REMOVE_ALERT,
  };
}

export default function Notification(state = INITIAL_STATE, action) {
  switch (action.type) {

    case ADD_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, action.payload]
      };
    case REMOVE_ALERT:
      return INITIAL_STATE

    // initial state
    default:
      return state;
  }
}

export const getVisibleAlert = (state) => (state.alerts && state.alerts.length > 0) ? state.alerts[0] : null;
