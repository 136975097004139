import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  CardFooter,
  CardTitle,
  Table,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import ReactTable from "react-table";
import NotificationAlert from "react-notification-alert";
import { Form, Field } from "react-final-form";
import TextField from "components/Fields/TextField";
import FieldValidation from "components/Fields/Validation/FieldValidation";
import {
  getNotifications,
  exportNotifications,
} from "./../../reducers/ListNotifications";
import LoadingOverlay from "react-loading-overlay";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import MaskDate from "Utils/MaskDate";
import moment from "moment";
import fileDownload from "js-file-download";

class ListNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lista: [] };
  }

  search = async (values) => {
    const props = {
      companyId: this.props.company.companyId,
      values: {
        dateInit: MaskDate.TO_DATABASE_DATE(values.dateInit),
        dateEnd: MaskDate.TO_DATABASE_DATE(values.dateEnd),
        cpf: FieldNormalize.NUMBER(values.cpf),
      },
    };

    await this.props.getNotifications(props);
    this.setState({ lista: this.props.notifications });
  };

  handleSearch = async (values, form) => {
    if (!values.dateInit || !values.dateEnd) {
      form.submit();
    } else {
      await this.search(values);
    }
  };

  exportNotifications = async (values) => {
    const props = {
      companyId: this.props.company.companyId,
      values: {
        dateInit: MaskDate.TO_DATABASE_DATE(values.dateInit),
        dateEnd: MaskDate.TO_DATABASE_DATE(values.dateEnd),
      },
    };

    await this.props.exportNotifications(props);
    //this.setState({ lista: this.props.notifications });
  };

  handleExport = async (values, form) => {
    await this.exportNotifications(values);
    console.log("--------=========---------");
    console.log(this.props.file);
    const downloadLink = document.createElement("a");
    downloadLink.href = this.props.file;
    downloadLink.download = "relatorioAvisos.xlsx";
    downloadLink.click();
  };

  render() {
    return (
      <>
        <div className="content">
          <NotificationAlert ref="notificationAlert" />
          <LoadingOverlay
            active={this.props.loadingPage}
            spinner
            text="Processando..."
          >
            <Form
              onSubmit={onSubmit}
              initialValues={{
                dateInit: moment(new Date()).format("DD/MM/YYYY"),
                dateEnd: moment(new Date()).format("DD/MM/YYYY"),
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
                validating,
                valid,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div style={{ top: "0", zIndex: "100" }}>
                      <h6
                        className="title text-center"
                        style={{ lineHeight: "44px", fontSize: ".85em" }}
                      >
                        Avisos Extrajudiciais
                      </h6>
                    </div>

                    <Row>
                      <Col className="text-center" md="12">
                        <Card className="card-tasks">
                          <CardHeader className="text-center">
                            <CardTitle tag="h6">
                              Selecione o período a ser consultado
                            </CardTitle>
                          </CardHeader>

                          <CardBody>
                            <Row>
                              <Col md="3">
                                <Field
                                  name="dateInit"
                                  label="Data inicial"
                                  validate={FieldValidation.required}
                                  component={TextField}
                                  parse={FieldNormalize.DATE}
                                />
                              </Col>

                              <Col md="3">
                                <Field
                                  name="dateEnd"
                                  label="Data final"
                                  validate={FieldValidation.required}
                                  component={TextField}
                                  parse={FieldNormalize.DATE}
                                />
                              </Col>

                              <Col md="3">
                                <Field
                                  name="cpf"
                                  label="CPF/CNPJ (Opcional)"
                                  component={TextField}
                                  parse={FieldNormalize.CPF_CNPJ}
                                />
                              </Col>

                              <Col md="3">
                                <div className="fileinput text-center">
                                  <Link
                                    className="btn btn-round"
                                    style={{ margin: "25px 1px" }}
                                    onClick={() =>
                                      this.handleSearch(values, form)
                                    }
                                  >
                                    Pesquisar
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <Row>
                    <Col className="text-center" md="12">
                      <Card className="card-tasks">
                        <CardBody>
                          <ReactTable
                            data={this.state.lista}
                            filterable
                            columns={[
                              {
                                Header: "Id",
                                style: { whiteSpace: "unset" },
                                accessor: "IDENTIFICADOR",
                              },
                              {
                                Header: "CPF/CNPJ",
                                style: { whiteSpace: "unset" },
                                accessor: "CPF",
                                Cell: (row) => (
                                  <div>
                                    {FieldNormalize.CPF_CNPJ(row.original.CPF)}
                                  </div>
                                ),
                              },
                              {
                                Header: "Nome",
                                style: { whiteSpace: "unset" },
                                accessor: "NOME",
                              },
                              {
                                Header: "Telefone",
                                style: { whiteSpace: "unset" },
                                Cell: (row) => (
                                  <div>
                                    {FieldNormalize.CELULAR(
                                      row.original.TELEFONE
                                    )}
                                  </div>
                                ),
                              },
                              {
                                Header: "Hash Confia",
                                style: { whiteSpace: "unset" },
                                accessor: "HASH_CONFIA",
                              },
                              {
                                Header: "Status",
                                style: { whiteSpace: "unset" },
                                accessor: "STATUS_NOTIFICACAO",
                              },
                              {
                                Header: "Status aviso CPF",
                                style: { whiteSpace: "unset" },
                                accessor: "STATUS_NOTIFICACAO_CPF",
                              },
                              {
                                Header: "Status aviso e-mail",
                                style: { whiteSpace: "unset" },
                                accessor: "STATUS_NOTIFICACAO_EMAIL",
                              },
                              {
                                Header: "Status aviso SMS",
                                style: { whiteSpace: "unset" },
                                accessor: "STATUS_NOTIFICACAO_SMS",
                              },
                              {
                                Header: "Status aviso WhatsApp",
                                style: { whiteSpace: "unset" },
                                accessor: "STATUS_NOTIFICACAO_WHATSAPP",
                              },
                              {
                                Header: "Data",
                                style: { whiteSpace: "unset" },
                                accessor: "DATA_REGISTRO",
                                Cell: (row) => (
                                  <div>
                                    {MaskDate.TO_BR(row.original.DATA_REGISTRO)}
                                  </div>
                                ),
                              },
                            ]}
                            defaultPageSize={10}
                            showPaginationTop
                            showPaginationBottom={false}
                            showPageSizeOptions={false}
                            showPageJump={false}
                            previousText="ANTERIOR"
                            nextText="PRÓXIMO"
                            loadingText="Carregando..."
                            noDataText="Nenhum aviso encontrado"
                            pageText="Página"
                            ofText="de"
                            rowsText="linhas"
                            className="-striped -highlight defaul-pagination"
                          />
                        </CardBody>
                        <CardFooter>
                          <hr />
                          <div className="stats">
                            {this.props.notifications
                              ? this.props.notifications.length
                              : 0}{" "}
                            Avisos
                          </div>

                          <Col
                            className="col-md-3 ml-auto text-right"
                            md="3"
                            sm="3"
                          >
                            {this.props.notifications &&
                              this.props.notifications.length > 0 && (
                                <Link
                                  className="btn btn-round"
                                  onClick={() =>
                                    this.handleExport(values, form)
                                  }
                                  disabled={
                                    this.props.parts &&
                                    this.props.parts.length === 20
                                  }
                                >
                                  {"EXPORTAR"}
                                </Link>
                              )}
                          </Col>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                </form>
              )}
            />
          </LoadingOverlay>
        </div>
      </>
    );
  }
}

const onSubmit = async (values) => {};

const mapStateToProps = (state) => ({
  ...state.ListNotifications,
  ...state.Auth,
});

function mapDispatchToProps(dispatch) {
  return {
    getNotifications: (notifications) =>
      dispatch(getNotifications(notifications)),
    exportNotifications: (notifications) =>
      dispatch(exportNotifications(notifications)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListNotifications);
