const UNLOAD = 'confia/payment-methods/UNLOAD';
const LOAD = 'confia/payment-methods/LOAD';
const LOAD_SUCCESS = 'confia/payment-methods/LOAD_SUCCESS';
const LOAD_FAIL = 'confia/payment-methods/LOAD_FAIL';
const SAVE = 'confia/payment-methods/SAVE';
const SAVE_SUCCESS = 'confia/payment-methods/SAVE_SUCCESS';
const SAVE_FAIL = 'confia/payment-methods/SAVE_FAIL';
const UPDATE_FORM_STATE = 'confia/payment-methods/UPDATE_FORM_STATE';

export function load(companyId) {
  return {
    type: LOAD,
    payload: {
      request: {
        method: 'get',
        url: `/api/payment-methods/${companyId}`,
      }
    }
  };
}

export function unload() {
  return {
    type: UNLOAD,
  };
}

export function updateFormState(state) {
  return {
    type: UPDATE_FORM_STATE,
    payload: state
  };
}

export function saveCreditCard(companyId,values) {
  return {
    type: SAVE,
    payload: {
      request: {
        method: 'post',
        url: `/api/payment-methods/${companyId}`,
        data: values,
      }
    }
  };
}

const INITIAL_STATE = {paymentMethodsList: [], defaultPaymentId: null, error: false, message: false};

export default function PaymentMethods(state = INITIAL_STATE, action) {
  switch (action.type) {

    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethodsList: action.payload.data.paymentMethods,
        defaultPaymentId: action.payload.data.defaultPaymentId,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: 'Não foi possível recuperar as informações do usuário.',
        error: true,
      };

    case UPDATE_FORM_STATE:
      return {
       ...state,
       paymentMethodForm: action.payload.values
     };

     case SAVE:
       return {
         ...state,
         loading: true,
       };
     case SAVE_SUCCESS:
       return {
         ...state,
         loading: false,
         paymentMethodsList: action.payload.data.paymentMethods,
         defaultPaymentId: action.payload.data.defaultPaymentId,
       };
     case SAVE_FAIL:
       return {
         ...state,
         loading: false,
         errorMessage: 'Não foi possível salvar suas alterações.',
         error: true,
       };

    case UNLOAD:
      return {
        state: INITIAL_STATE,
      };

    // initial state
    default:
      return state;
  }
}
