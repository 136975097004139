import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import FieldValidation from "components/Fields/Validation/FieldValidation";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import TextField from "components/Fields/TextField";
import MaskDate from "Utils/MaskDate";
import { Card, CardHeader, CardBody, Row, Col, Button, CardFooter, CardTitle, Label, Input, Modal, ModalHeader } from "reactstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SelfDetails from "components/Others/SelfDetails";
import ReactWizard from "react-bootstrap-wizard";
import NotificationAlert from "react-notification-alert";
import fileDownload from "js-file-download";
import LoadingOverlay from "react-loading-overlay";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logoConfia from "stylesheets/img/logo/logo-confia-preto.png";
import "../../assets/scss/document-dropdown.scss";

import {
  unload,
  load,
  loadFile,
  removeFile,
  sendSms,
  loadDetails,
  loadSelfieDetails,
  saveDates,
  searchInsurance,
  updatePart,
  addSignParts,
  updatePhoneEmail,
  notifySigners,
  inactiveDocument,
  exportToExcel,
  revertRefused,
  revertSuspended,
  loadUsers,
} from "reducers/Documents";
import { showAlert, closeAlert } from "reducers/Notification";

import ReactTable from "react-table";
import moment from "moment";
import { debug } from "util";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <div className="threedots" />
  </a>
));

var API_LINK_URL = process.env.REACT_APP_API_LINK_URL;
class Documents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalDetails: false,
      modalInsurance: false,
      modalNotification: false,
      picture1: [],
      picture2: [],
      modalPicture: false,
      statusUsersOptions: [], // recebe os usuários da requisição = this.loadAllUsers()
      selectedUsersOptions: [], // recebe os userId das checkboxes
      dataInputSearch: "",
      resultNameInput: [],
      gerandoPDF: false,
    };
    this.clipboardRef = React.createRef();
  }

  // Manipula a pesquisa do nome do usuário
  handleInputUserName = (e) => {
    const input = e.target.value;
    this.setState({ dataInputSearch: input });

    if (input.length >= 4) {
      const filterResultsInput = this.state.statusUsersOptions.filter((value) => {
        return value.name.toLowerCase().includes(input.toLowerCase());
      });

      this.setState({ resultNameInput: filterResultsInput });
    } else {
      this.setState({ selectedUsersOptions: [] });

      this.setState({ resultNameInput: [] });
    }
  };

  // Mnipula os valores das checkbox dos usuários
  handleOptionToggle = (option) => {
    const isSelected = this.state.selectedUsersOptions.includes(option.userId);

    if (isSelected) {
      this.setState({
        selectedUsersOptions: [],
      });
    } else {
      this.setState({
        selectedUsersOptions: [{ userId: option.userId, name: option.name }],
        dataInputSearch: option.name,
        resultNameInput: [],
      });
    }
  };

  loadAllUsers = async () => {
    await this.props.loadUsers(this.props.company.companyId);
    if (this.props?.users?.length > 0) {
      const filterUsers = this.props.users.map((value) => ({ name: value.name, userId: value.userId }));
      this.setState({
        statusUsersOptions: [...filterUsers],
      });
    }
  };

  componentDidMount() {
    if (this.props.alertNewDocument) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div style={{ fontWeight: "bold" }}>{this.props.alertMessageNewDocument}</div>
          </div>
        ),
        type: "success",
        icon: "fa fa-check-circle",
        autoDismiss: 0,
      };
      this.refs.notificationAlert.notificationAlert(options);
    }
    if (this.props.company.companyId && (this.props.role === "admin" || this.props.role === "supervisor")) {
      // Faz a requisição para receber os users da company se o usuário logado for admin(1)
      this.loadAllUsers();
    }
  }

  componentWillUnmount() {
    this.props.unload();
  }

  handleSendSms = (doc) => (ev) => {
    ev.preventDefault();

    var signers = doc.parts.filter((part) => {
      if (part.notifyEmail || part.notifySms || part.notifyWhatsapp) {
        return part;
      }
    });

    if (signers.length === 0) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div style={{ fontWeight: "bold" }}>Não foram adicionados meios de comunicação para notificação dos assinantes. Copie o link manualmente e envie diretamente.</div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
    } else {
      this.props.sendSms(doc.documentId).then((response) => {
        if (response && response.type.includes("SUCCESS")) {
          var options = {};
          options = {
            place: "tc",
            message: (
              <div>
                <div style={{ fontWeight: "bold" }}>Notificação enviada com sucesso.</div>
              </div>
            ),
            type: "success",
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 7,
          };
          this.refs.notificationAlert.notificationAlert(options);
        } else {
          var options = {};
          options = {
            place: "tc",
            message: (
              <div>
                <div style={{ fontWeight: "bold" }}>Não foi possível enviar a notificação.</div>
              </div>
            ),
            type: "danger",
            icon: "fa fa-exclamation-triangle",
            autoDismiss: 7,
          };
          this.refs.notificationAlert.notificationAlert(options);
        }
      });
    }
  };

  /*
  handleClickDownloadIn = (id, status) => (ev) => {
    ev.preventDefault();
    Promise.resolve(this.props.loadFile(id, status))
      .then((response) => {
        console.log("RESPONSE->", response);
        console.log("-->", this.props.file);
        if (this.props.file.type === "application/zip") fileDownload(this.props.file, "docsConfia.zip");
        else fileDownload(this.props.file, "docConfia.pdf");
      })
      .catch((e) => {
        console.log(e);
      });
  };*/

  handleClickRemove = (id) => (ev) => {
    console.log("handleClickRemove");
    if (ev) {
      ev.preventDefault();
    }
    Promise.resolve(this.props.removeFile(id))
      .then(async (response) => {
        console.log("RESPONSE->", response);
        console.log("-->", this.props.file);
        await this.search(this.props.search);
        this.props.closeAlert();
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div style={{ fontWeight: "bold" }}>Documento excluido com sucesso.</div>
            </div>
          ),
          type: "success",
          icon: "fa fa-check-circle",
          autoDismiss: 6,
        };
        this.refs.notificationAlert.notificationAlert(options);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  search = async (values) => {
    let selectedUsersFilterName = [];
    const props = {
      companyId: this.props.company.companyId,
      values: {
        dateInit: MaskDate.TO_DATABASE_DATE(values.dateInit),
        dateEnd: MaskDate.TO_DATABASE_DATE(values.dateEnd),
        cpf: values.cpf,
        assinados: values.assinados,
        naoAssinados: values.naoAssinados,
        rascunho: values.rascunho,
        recusado: values.recusado,
        bloqueado: values.bloqueado,
        suspenso: values.suspenso,
        inativo: values.inativo,
        ativo: values.ativo,
        name: this.state.dataInputSearch,
        usuariosId: [],
        pesquisaUser: false,
      },
    };

    if (this.state.selectedUsersOptions.length > 0) {
      const selectedUsersFilter = this.state.selectedUsersOptions.map((option) => option.userId);
      props.values.usuariosId = selectedUsersFilter;
      props.values.pesquisaUser = true;
      // console.log("VALORES DOS INPUTS", props);
    } else if (this.state.dataInputSearch && this.state.dataInputSearch.length >= 4) {
      selectedUsersFilterName = this.state.statusUsersOptions
        .filter((option) => {
          return option.name.toLowerCase().includes(props.values.name.toLowerCase());
        })
        .map((option) => option.userId);

      // Verifica se o array tem mais de uma posição e retorna apenas a primeira (userId do usuário)
      if (selectedUsersFilterName.length > 1) {
        selectedUsersFilterName = [selectedUsersFilterName[0]];
      }

      props.values.usuariosId = selectedUsersFilterName;
      props.values.pesquisaUser = true;
    }

    await this.props.load(props);
    this.setState({ resultNameInput: [] });
  };

  exportToExcel = async (values) => {
    const props = {
      companyId: this.props.company.companyId,
      values: {
        dateInit: MaskDate.TO_DATABASE_DATE(values.dateInit),
        dateEnd: MaskDate.TO_DATABASE_DATE(values.dateEnd),
        cpf: values.cpf,
        assinados: values.assinados,
        naoAssinados: values.naoAssinados,
        rascunho: values.rascunho,
        recusado: values.recusado,
        bloqueado: values.bloqueado,
        suspenso: values.suspenso,
        inativo: values.inativo,
        ativo: values.ativo,
      },
    };
    const result = await this.props.exportToExcel(props);
    console.log("result", result);
    const fileName = "documentos.xlsx";
    fileDownload(result.payload.data, fileName);
  };

  handleSearch = async (values, form) => {
    if (!values.inativo && !values.ativo) {
      this.props.showAlert({
        type: "info",
        title: "Filtro de estado inválido",
        content: "Você precisa selecionar pelo menos um filtro de estado para prosseguir com a pesquisa.",
        showCancel: false,
        confirmBtnBsStyle: "primary",
        onConfirm: this.props.closeAlert,
        confirmBtnText: "Entendi",
      });

      return;
    }
    if (!values.assinados && !values.naoAssinados && !values.rascunho && !values.recusado && !values.bloqueado && !values.suspenso) {
      this.props.showAlert({
        type: "info",
        title: "Filtro de Situação inválido",
        content: `Você precisa selecionar os filtros pelo menos um filtro de situação para prosseguir com a pesquisa.`,
        showCancel: false,
        confirmBtnBsStyle: "primary",
        onConfirm: this.props.closeAlert,
        confirmBtnText: "Entendi",
      });

      return;
    }
    if (!values.dateInit || !values.dateEnd) {
      form.submit();
    } else {
      await this.props.saveDates(values);
      await this.search(values);
    }
  };

  toggleModalDetails = () => {
    this.setState({
      modalDetails: !this.state.modalDetails,
    });
  };

  toggleModalInsurance = () => {
    this.setState({
      modalInsurance: !this.state.modalInsurance,
    });
  };

  toggleModalNotification = () => {
    this.setState({
      modalNotification: !this.state.modalNotification,
    });
  };

  handleSelectCheckbox = (row, value, id) => {
    var part = row;

    if (id === 1) {
      part.notifySms = value == "true" ? true : false;
    } else if (id === 2) {
      part.notifyWhatsapp = value == "true" ? true : false;
    } else if (id === 3) {
      part.notifyEmail = value == "true" ? true : false;
    }

    this.props.updatePart(part);
  };

  copyToClipboard = async (ref) => {
    await navigator.clipboard.writeText(ref);
    var options = {
      place: "tc",
      message: (
        <div>
          <div style={{ fontWeight: "bold" }}>URL copiada para área de transferência.</div>
        </div>
      ),
      type: "success",
      icon: "fa fa-check-circle",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleSendWhatsapp = (doc, part) => {
    var name = part.name.toLowerCase().split(" ")[0];
    name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    const message = `Olá, ${name}! Você tem um documento para assinar com a Confia.\n\nAgora, você vai passar pelas etapas de identificação e biometria facial para assinar o seu documento. Clique neste link para começar: ${
      API_LINK_URL + "/?chave=" + part.identifier
    }\n\n*A Confia não é responsável pelo conteúdo do documento ou por eventuais negociações entre as partes.`;
    window.open(`https://wa.me/55${FieldNormalize.NUMBER(part.phone)}?text=${encodeURIComponent(message)}`, "_blank");
  };

  revertRefused = (documentId) => {
    Promise.resolve(this.props.revertRefused(documentId))
      .then(async (response) => {
        await this.search(this.props.search);
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div style={{ fontWeight: "bold" }}>Reversão de recusa realizada com sucesso.</div>
            </div>
          ),
          type: "success",
          icon: "fa fa-check-circle",
          autoDismiss: 6,
        };
        this.refs.notificationAlert.notificationAlert(options);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  revertSuspended = (documentId) => {
    Promise.resolve(this.props.revertSuspended(documentId))
      .then(async (response) => {
        await this.search(this.props.search);
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div style={{ fontWeight: "bold" }}>Reversão de suspensão realizada com sucesso.</div>
            </div>
          ),
          type: "success",
          icon: "fa fa-check-circle",
          autoDismiss: 6,
        };
        this.refs.notificationAlert.notificationAlert(options);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  async exportToPDF(part) {
    this.setState({ gerandoPDF: true });
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    var doc = new jsPDF(orientation, unit, size);
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    const marginLeft = 20;
    const marginLeft120 = 120;
    const marginLeft410 = 410;
    const marginLeft490 = 490;
    const fontSizeTitle = 16;
    const fontSizeSubTitle = 12;
    const fontSize = 10;

    var altura = 12.5;
    var atualY = 5;

    doc.setFontSize(fontSizeTitle);
    doc.setFont("helvetica", "normal");
    doc.text("TRILHA DE AUDITORIA", width / 2, 50, null, null, "center");
    doc.setFontSize(fontSize);
    if (this.props.company && this.props.company.name) {
      addText(`RAZÃO SOCIAL: ${this.props.company.name.toUpperCase()}`, 12);
      addText(`CNPJ: ${this.props.company.cnpj}`, 12);
    }
    addText(`NOME: ${part.name}`, 12);
    addText(`CPF: ${part.cpf}`, 12);
    addText(`IDENTIFICADOR: ${part.identifier}`, 12);
    addText(`ARQUIVO GERADO EM: ${new Date().toLocaleString("pt-br").replaceAll(",", "")}`, 12);

    ++atualY;
    doc.line(10, 168, 820, 168);
    doc.line(10, 168, 10, 148); // vertical line
    doc.line(10, 148, 820, 148);
    doc.line(820, 148, 820, 168); // vertical line
    doc.setFontSize(fontSizeSubTitle);
    doc.text("DATA", marginLeft, ++atualY * altura);
    doc.text("ETAPA", marginLeft120, atualY * altura);
    doc.text("IP", marginLeft410, atualY * altura);
    doc.text("LOCALIZAÇÃO", marginLeft490, atualY * altura);
    ++atualY;
    doc.setFontSize(fontSize);
    for (const log of part.logs) {
      let img1,
        img2 = "";
      if (log.image1) {
        img1 = this.props.selfie.find((item) => {
          return item.id == log.image1;
        });
        if (!img1) {
          await Promise.resolve(this.props.loadSelfieDetails(log.image1))
            .then((response) => {
              img1 = this.props.selfie.find((item) => {
                return item.id == log.image1;
              });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
      if (log.image2) {
        img2 = this.props.selfie.find((item) => {
          return item.id == log.image2;
        });
        if (!img2) {
          await Promise.resolve(this.props.loadSelfieDetails(log.image2))
            .then((response) => {
              img2 = this.props.selfie.find((item) => {
                return item.id == log.image2;
              });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
      await addTextCols(log.eventDateFormat, log.description, log.ip ? log.ip : "", log.latLong ? `${log.latLong} - ${log.latLongCidadeUF}` : "", img1 ? img1.file : "", img2 ? img2.file : "");
      ++atualY;
      //doc.line(10, atualY * altura, 820, atualY * altura);
      ++atualY;
    }

    function addText(text, marginLeft) {
      if ((atualY + 1) * altura > height - 20) {
        doc.addPage();
        doc.setFontSize(10);
        atualY = 7;
      }
      doc.text(text, marginLeft, ++atualY * altura);
    }

    async function addTextCols(text1, text2, text3, text4, image1, image2) {
      if (!image1 && !image2 && (atualY + 1) * altura > height - 30) {
        doc.addPage();
        doc.setFontSize(10);
        atualY = 3;
      } else if ((image1 || image2) && (atualY + 1) * altura > height - 240) {
        doc.addPage();
        doc.setFontSize(10);
        atualY = 3;
      }

      doc.text(text1, marginLeft, ++atualY * altura);
      doc.text(text2, marginLeft120, atualY * altura, { maxWidth: 280 });
      doc.text(text3, marginLeft410, atualY * altura);
      doc.text(text4, marginLeft490, atualY * altura, { maxWidth: 320 });
      --atualY;
      if (image1 || image2) {
        await addImages(image1, image2);
      }
    }

    async function addImages(image1, image2) {
      return new Promise(async (resolve, reject) => {
        await manterAspecto(image1, image2, 230, 210)
          .then(({ img1, img2 }) => {
            atualY = atualY + 2;
            if (img1) {
              doc.addImage(image1, "JPEG", !img2 ? 350 : 180, atualY * altura, img1.novaLargura, img1.novaAltura);
            }
            if (img2) {
              doc.addImage(image2, "JPEG", !img1 ? 350 : 440, atualY * altura, img2.novaLargura, img2.novaAltura);
            }
            atualY = atualY + 15;
          })
          .catch((erro) => {
            console.error(erro);
          });
        resolve();
      });
    }

    function manterAspecto(base641, base642, larguraDesejada, alturaDesejada) {
      const img1 = new Image();
      const img2 = new Image();

      // Carrega as imagens (se fornecidas)
      if (base641) img1.src = base641;
      if (base642) img2.src = base642;

      return new Promise((resolve, reject) => {
        const calcularNovasDimensoes = (img) => {
          const proporcaoOriginal = img.width / img.height;
          const proporcaoDesejada = larguraDesejada / alturaDesejada;

          let novaLargura, novaAltura;

          if (proporcaoOriginal > proporcaoDesejada) {
            novaLargura = larguraDesejada;
            novaAltura = larguraDesejada / proporcaoOriginal;
          } else {
            novaAltura = alturaDesejada;
            novaLargura = alturaDesejada * proporcaoOriginal;
          }

          return { novaLargura, novaAltura };
        };

        if (base641 && base642) {
          img1.onload = () => {
            const dimensoes1 = calcularNovasDimensoes(img1);
            img2.onload = () => {
              const dimensoes2 = calcularNovasDimensoes(img2);
              resolve({ img1: dimensoes1, img2: dimensoes2 });
            };
            img2.onerror = () => {
              reject("Erro ao carregar a segunda imagem.");
            };
          };
          img1.onerror = () => {
            reject("Erro ao carregar a primeira imagem.");
          };
        } else if (base641) {
          img1.onload = () => {
            resolve({ img1: calcularNovasDimensoes(img1) });
          };
          img1.onerror = () => {
            reject("Erro ao carregar a imagem.");
          };
        } else if (base642) {
          img2.onload = () => {
            resolve({ img2: calcularNovasDimensoes(img2) });
          };
          img2.onerror = () => {
            reject("Erro ao carregar a imagem.");
          };
        } else {
          reject("Nenhuma imagem fornecida.");
        }
      });
    }

    const pageCount = doc.internal.getNumberOfPages();
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(String(i) + "/" + String(pageCount), width - 20, height - 10, null, null, "right");
      doc.addImage(logoConfia, "PNG", marginLeft, height - 20, 45, 20);
    }

    this.setState({ gerandoPDF: false });
    doc.save(`Trilha-de-auditoria_${part.identifier}.pdf`);
  }

  render() {
    if (this.props.documentRedirectTo) {
      return <Redirect to={this.props.documentRedirectTo} />;
    }
    if (this.props.docDetails && this.props.docDetails.parts) {
      var content = this.props.docDetails.parts.map((part) => {
        return {
          stepName: part.name,
          stepIcon: part.status === "Assinado" ? "fa fa-check" : "fa fa-times",
          component: (
            <>
              <Row>
                <Col md="2" sm="2">
                  <Label>CPF</Label>
                  <Input defaultValue={part.cpf} disabled />
                </Col>
                <Col md="2" sm="2">
                  <Label>Status</Label>
                  <Input defaultValue={part.status} disabled />
                </Col>
                <Col md="2" sm="2">
                  <Label>Etapa da Assinatura</Label>
                  <Input defaultValue={`${part.signStep}º Etapa`} disabled />
                </Col>
                <Col md="4" sm="4">
                  <Label>E-mail</Label>
                  <Input defaultValue={part.email ? part.email : "-"} disabled />
                </Col>
                <Col md="2" sm="3">
                  <Label>Celular</Label>
                  <Input defaultValue={part.phone ? FieldNormalize.CELULAR(part.phone) : "-"} disabled />
                </Col>
              </Row>
              <Row>
                <Col md="2" sm="2">
                  <Label>SMS</Label>
                  <Input defaultValue={part.notifySms ? "Sim" : "Não"} disabled />
                </Col>
                <Col md="2" sm="2">
                  <Label>E-mail</Label>
                  <Input defaultValue={part.notifyEmail ? "Sim" : "Não"} disabled />
                </Col>
                <Col md="2" sm="2">
                  <Label>WhatsApp</Label>
                  <Input defaultValue={part.notifyWhatsapp ? "Sim" : "Não"} disabled />
                </Col>
                {part.identifier && (
                  <>
                    <Col md="5" sm="10" xs="10">
                      <Label>Link para assinatura</Label>
                      <Input defaultValue={API_LINK_URL + "/?chave=" + part.identifier} ref={this.clipboardRef} disabled />
                    </Col>
                    <Button
                      style={{ marginLeft: "-10px", marginTop: "25px" }}
                      color="default"
                      size="sm"
                      className="btn-icon btn-link edit"
                      onClick={() => {
                        const signUrl = API_LINK_URL + "/?chave=" + part.identifier;
                        this.copyToClipboard(signUrl);
                      }}
                    >
                      <i className="fa fa-copy" title="Copiar Link" />
                    </Button>{" "}
                  </>
                )}
              </Row>

              <Row>
                {part.commitments && (
                  <Col md="6" sm="6">
                    <Label>Assinando como</Label>
                    <Input defaultValue={JSON.parse(part.commitments).join(", ")} disabled />
                  </Col>
                )}
                {part.status == "Recusado" && (
                  <Col md="6" sm="6">
                    <Label>Motivo da recusa</Label>
                    <Input defaultValue={part.refusedReason} disabled />
                  </Col>
                )}
              </Row>

              <Row style={{ marginTop: "20px" }}>
                <Col m="4" className="text-right">
                  {!this.state.gerandoPDF ? (
                    <button className="btn btn-sm" onClick={() => this.exportToPDF(part)} disabled={!part.logs || part.logs.length == 0}>
                      <i className="fa fa-file-pdf-o" style={{ color: "#51bcda" }} />
                      &nbsp; Gerar PDF
                    </button>
                  ) : (
                    <button className="btn btn-sm" disabled>
                      <i className="spinner1"></i>&nbsp; Aguarde, gerando arquivo...
                    </button>
                  )}
                </Col>
                {part.identifier === null || !part.logs || (part.identifier !== null && !part.logs[0] && part.numberOfTries > 0) || (
                  <Col md="12" sm="12">
                    <ReactTable
                      data={part.logs}
                      columns={[
                        {
                          Header: "Etapa",
                          sortable: false,
                          filterable: false,
                          style: { whiteSpace: "unset" },
                          accessor: "description",
                        },
                        {
                          Header: "IP",
                          sortable: false,
                          filterable: false,
                          accessor: "ip",
                        },
                        {
                          Header: "Localização",
                          sortable: false,
                          filterable: false,
                          Cell: (row) => {
                            if (row.original.latLong) {
                              return (
                                <>
                                  <div style={{ whiteSpace: "normal" }}>{row.original.latLong}</div>
                                  <div className="text-center" style={{ whiteSpace: "normal" }}>
                                    {row.original.latLongCidadeUF}
                                  </div>
                                </>
                              );
                            } else {
                              return <div />;
                            }
                          },
                        },
                        {
                          Header: "Data",
                          sortable: false,
                          filterable: false,
                          Cell: (row) => {
                            return <div>{row.original.eventDateFormat}</div>;
                          },
                        },
                        {
                          sortable: false,
                          filterable: false,

                          Cell: (row) => {
                            if (row.original.image1 !== null) {
                              if (row.original.image2 !== null) {
                                return (
                                  <>
                                    <div className="justify-content-center">
                                      <SelfDetails src={row.original.image1} />
                                      <SelfDetails src={row.original.image2} />
                                    </div>
                                  </>
                                );
                              } else {
                                return (
                                  <div className="justify-content-center">
                                    <SelfDetails src={row.original.image1} />
                                  </div>
                                );
                              }
                            } else {
                              return <div />;
                            }
                          },
                        },
                      ]}
                      minRows={2}
                      sortable={false}
                      loadingText="Carregando..."
                      noDataText="Nenhuma tentativa de assinatura"
                      className="-striped -highlight"
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      showPageSizeOptions={false}
                      showPageJump={false}
                      previousText="ANTERIOR"
                      nextText="PRÓXIMO"
                      pageText="Página"
                      ofText="de"
                    />
                  </Col>
                )}
              </Row>
            </>
          ),
          stepProps: {
            values: this.props.docDetails ? (this.props.docDetails.parts ? this.props.docDetails.parts : {}) : {},
            error: this.props.error,
            errorMessage: this.props.errorMessage,
          },
        };
      });

      content.unshift({
        stepName: "Informações Gerais",
        stepIcon: "fa fa-file",
        component: (
          <>
            {this.props.docDetails && (
              <>
                <Row>
                  <Col md="4" sm="4">
                    <Label>Nome do documento</Label>
                    <Input defaultValue={this.props.docDetails.name} disabled />
                  </Col>
                  <Col md="4" sm="4">
                    <Label>Usuário</Label>
                    <Input defaultValue={this.props.docDetails.userName} disabled />
                  </Col>
                  <Col md="4" sm="4">
                    <Label>Data de envio</Label>
                    <Input defaultValue={MaskDate.TO_BR_DATETIME(this.props.docDetails.registrationDate)} disabled />
                  </Col>
                </Row>
                <Row>
                  <Col md="4" sm="4">
                    <Label>Tipo de Documento</Label>
                    <Input defaultValue={this.props.docDetails.type} disabled />
                  </Col>
                  <Col md="3" sm="3">
                    <Label>Status do Documento</Label>
                    <Input defaultValue={this.props.docDetails.status} disabled />
                  </Col>
                  <Col md="3" sm="3">
                    <Label>Data de Expiração do Documento</Label>
                    <Input defaultValue={this.props.docDetails.validateDate ? MaskDate.TO_BR(this.props.docDetails.validateDate) : "Não definida"} disabled />
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={{ size: "10", offset: 1 }} sm="12">
                    <ReactTable
                      data={this.props.docDetails.docs}
                      columns={[
                        {
                          Header: "Nome",
                          style: { whiteSpace: "unset" },
                          Cell: (row) => {
                            return <div>{"documento" + (this.props.docDetails.docs.indexOf(row.original) + 1)}</div>;
                          },
                        },
                        {
                          Header: "Ações",
                          sortable: false,
                          filterable: false,
                          width: 50,
                          Cell: (row) => {
                            if (this.props.docDetails.status === "Assinado") {
                              return (
                                <div className="actions-right">
                                  <a color="default" size="sm" style={{ color: "black" }} className="btn-icon btn-link like" href={"https://qrcode.confia.net.br/" + row.original.hash} target="_blank">
                                    <i className="fa fa-qrcode" />
                                  </a>{" "}
                                </div>
                              );
                            } else {
                              return <div />;
                            }
                          },
                        },
                      ]}
                      showPagination={false}
                      minRows={1}
                      sortable={false}
                      loadingText="Carregando..."
                      noDataText="Nenhum documento"
                      className="-striped -highlight"
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        ),
        stepProps: {
          values: this.props.docDetails ? this.props.docDetails : {},
          error: this.props.error,
          errorMessage: this.props.errorMessage,
        },
      });
    }

    return (
      <>
        <Modal
          isOpen={this.state.modalDetails && !this.props.loadingModal}
          toggle={this.toggleModalDetails}
          keyboard={false}
          style={{
            maxWidth: "60vw",
            width: "90%",
            margin: "auto",
            paddingBottom: "5vh",
            paddingTop: "5vh",
          }}
        >
          <ModalHeader toggle={this.toggleModalDetails} charCode="X"></ModalHeader>
          <NotificationAlert ref="notificationAlert" />
          <ReactWizard
            steps={content}
            navSteps
            description=""
            finishButtonClasses="btn-wd"
            nextButtonClasses="btn-wd"
            previousButtonClasses="btn-wd"
            nextButtonText="PRÓXIMO"
            previousButtonText="ANTERIOR"
            finishButtonText="SAIR"
            finishButtonClick={this.toggleModalDetails}
          />
        </Modal>

        <Modal
          isOpen={this.state.modalInsurance && !this.props.loadingModal}
          toggle={this.toggleModalInsurance}
          style={{
            maxWidth: "60vw",
            width: "90%",
            margin: "auto",
            paddingBottom: "5vh",
            paddingTop: "5vh",
          }}
        >
          <NotificationAlert ref="notificationAlert" />

          <ReactWizard
            steps={[
              {
                stepName: "Informações do seguro",
                stepIcon: "fa fa-lock",
                component: (
                  <>
                    {this.props.insuranceData ? (
                      <>
                        <Row>
                          <Col md="6" sm="6">
                            <Label>Identificador da apólice</Label>
                            <Input defaultValue={this.props.insuranceData.externalInsuranceId} disabled />
                          </Col>
                          <Col md="3" sm="3">
                            <Label>Status</Label>
                            <Input defaultValue={this.props.insuranceData.insuranceStatus} disabled />
                          </Col>
                          <Col md="3" sm="3">
                            <Label>Data da consulta</Label>
                            <Input defaultValue={MaskDate.TO_BR_DATE(this.props.insuranceData.statusDate)} disabled />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" sm="3">
                            <Label>Valor da transação</Label>
                            <Input defaultValue={this.props.insuranceData.creditValue} disabled />
                          </Col>
                          <Col md="3" sm="4">
                            <Label>Início de vigência</Label>
                            <Input defaultValue={MaskDate.TO_BR_DATE(this.props.insuranceData.insuranceStartDate)} disabled />
                          </Col>
                          <Col md="3" sm="4">
                            <Label>Fim de vigência</Label>
                            <Input defaultValue={MaskDate.TO_BR_DATE(this.props.insuranceData.insuranceEndDate)} disabled />
                          </Col>
                          <Col md="3" sm="4">
                            <Label>Limite da indenização</Label>
                            <Input defaultValue={this.props.insuranceData.insuranceCoverageValue} disabled />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" sm="12">
                            <Label>Notas</Label>
                            {this.props.insuranceData.insuranceCoverageNotes}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="12">
                            <Label>Inclusões</Label>
                            <br />
                            {this.props.insuranceData.insuranceCoverageInclusions}
                            <br />
                            <br />
                            <Label>Provedores</Label>
                            <br />
                            <div>
                              {this.props.insuranceData.insuranceBroker1} <br />
                              {this.props.insuranceData.insuranceInsurer} <br />
                              {this.props.insuranceData.insuranceSUSEPCode}
                            </div>
                            <br />
                            <Label>Condições gerais</Label>
                            <br />
                            <a href={this.props.insuranceData.insurancePDFLink} target="_blank" style={{ color: "black" }}>
                              Download
                            </a>
                          </Col>
                          <Col md="6" sm="12">
                            <Label>Exclusões</Label>
                            <br />
                            {this.props.insuranceData.insuranceCoverageExclusions}
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row>
                        <Col className="text-center">
                          Informações da apólice ainda não disponíveis para consulta.
                          <br />
                          Por favor tente novamente em cerca de 30 minutos.
                        </Col>
                      </Row>
                    )}
                  </>
                ),
                stepProps: {
                  values: this.props.insuranceData ? this.props.insuranceData : {},
                  error: this.props.error,
                  errorMessage: this.props.errorMessage,
                },
              },
            ]}
            navSteps
            description=""
            finishButtonClasses="btn-wd"
            nextButtonClasses="btn-wd"
            previousButtonClasses="btn-wd"
            nextButtonText="PRÓXIMO"
            previousButtonText="ANTERIOR"
            finishButtonText="SAIR"
            finishButtonClick={this.toggleModalInsurance}
          />
        </Modal>

        <Modal
          isOpen={this.state.modalNotification && !this.props.loadingModal}
          toggle={this.toggleModalNotification}
          style={{
            maxWidth: "80vw",
            width: "90%",
            margin: "auto",
            paddingBottom: "5vh",
            paddingTop: "5vh",
          }}
        >
          <NotificationAlert ref="notificationAlert" />
          <>
            {this.props.signParts && (
              <>
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col className="text-center" md="12">
                          <Card className="card-tasks">
                            <CardHeader className="text-center">
                              <CardTitle tag="h6">Notificar assinantes</CardTitle>
                            </CardHeader>

                            <CardBody>
                              <Row>
                                <Col>
                                  <ReactTable
                                    data={this.props.signParts}
                                    columns={[
                                      {
                                        Header: "Nome",
                                        style: { whiteSpace: "unset" },
                                        accessor: "name",
                                      },
                                      {
                                        Header: "Telefone",
                                        Cell: (row) => {
                                          return (
                                            <Col md="12">
                                              <Field
                                                name={"phone" + row.original.signPartId}
                                                component={TextField}
                                                parse={FieldNormalize.CELULAR}
                                                disabled={row.original.status === "Assinado"}
                                                defaultValue={row.original.phone ? FieldNormalize.CELULAR(row.original.phone) : ""}
                                              />
                                              <OnChange name={"phone" + row.original.signPartId}>
                                                {(value, previous) => {
                                                  console.log(value);
                                                  this.props.updatePhoneEmail({
                                                    part: row.original,
                                                    value,
                                                    type: 1,
                                                  });
                                                }}
                                              </OnChange>
                                            </Col>
                                          );
                                        },
                                      },
                                      {
                                        Header: "E-mail",
                                        Cell: (row) => {
                                          return (
                                            <Col md="12">
                                              <Field
                                                name={"email" + row.original.signPartId}
                                                component={TextField}
                                                validate={FieldValidation.composeValidators(FieldValidation.email)}
                                                maxLength="225"
                                                disabled={row.original.status === "Assinado"}
                                                defaultValue={row.original.email ? row.original.email : ""}
                                              />
                                              <OnChange name={"email" + row.original.signPartId}>
                                                {(value, previous) => {
                                                  console.log(value);
                                                  this.props.updatePhoneEmail({
                                                    part: row.original,
                                                    value,
                                                    type: 2,
                                                  });
                                                }}
                                              </OnChange>
                                            </Col>
                                          );
                                        },
                                      },
                                      {
                                        Header: "Meios de envio",
                                        sortable: false,
                                        filterable: false,
                                        style: { whiteSpace: "unset" },
                                        Cell: (row) => {
                                          if (row.original.status !== "Assinado") {
                                            return (
                                              <div>
                                                <Row>
                                                  {values[`phone${row.original.signPartId}`] && (
                                                    <>
                                                      <Col md="3" sm="12">
                                                        <Label check>
                                                          <Field
                                                            name={"signPartNotifySms" + row.original.signPartId}
                                                            component="input"
                                                            type="checkbox"
                                                            value="true"
                                                            disabled={!this.props.company.senderSms}
                                                          />
                                                          {" SMS"}
                                                          <OnChange name={"signPartNotifySms" + row.original.signPartId}>
                                                            {(value, previous) => {
                                                              this.handleSelectCheckbox(row.original, value, 1);
                                                            }}
                                                          </OnChange>
                                                        </Label>
                                                      </Col>
                                                      <Col md="5" sm="12">
                                                        <Label check>
                                                          <Field
                                                            name={"signPartNotifyWhatsapp" + row.original.signPartId}
                                                            component="input"
                                                            type="checkbox"
                                                            value="true"
                                                            disabled={!this.props.company.senderWpp}
                                                          />
                                                          {" WHATSAPP"}
                                                          <OnChange name={"signPartNotifyWhatsapp" + row.original.signPartId}>
                                                            {(value, previous) => {
                                                              this.handleSelectCheckbox(row.original, value, 2);
                                                            }}
                                                          </OnChange>
                                                        </Label>
                                                      </Col>
                                                    </>
                                                  )}
                                                  {values[`email${row.original.signPartId}`] && (
                                                    <Col md="4" sm="12">
                                                      <Label check>
                                                        <Field
                                                          name={"signPartNotifyEmail" + row.original.signPartId}
                                                          component="input"
                                                          type="checkbox"
                                                          value="true"
                                                          disabled={!this.props.company.senderEmail}
                                                        />
                                                        {" EMAIL"}
                                                        <OnChange name={"signPartNotifyEmail" + row.original.signPartId}>
                                                          {(value, previous) => {
                                                            this.handleSelectCheckbox(row.original, value, 3);
                                                          }}
                                                        </OnChange>
                                                      </Label>
                                                    </Col>
                                                  )}
                                                  {!values[`phone${row.original.signPartId}`] && !values[`email${row.original.signPartId}`] && <Col>Insira o telefone ou e-mail.</Col>}
                                                </Row>
                                              </div>
                                            );
                                          } else {
                                            return <div style={{ color: "green" }}>Assinado</div>;
                                          }
                                        },
                                      },
                                    ]}
                                    showPagination={false}
                                    minRows={1}
                                    sortable={false}
                                    loadingText="Carregando..."
                                    noDataText="Nenhum assinante"
                                    className="-striped -highlight"
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col m="6" className="text-left">
                                  <Button className="btn btn-round" onClick={this.toggleModalNotification}>
                                    {"CANCELAR"}
                                  </Button>
                                </Col>
                                <Col m="6" className="text-right">
                                  <Link
                                    className="btn btn-round"
                                    onClick={() => {
                                      this.props.notifySigners({
                                        parts: this.props.signParts,
                                        companyId: this.props.company.companyId,
                                      });
                                      this.setState({
                                        modalNotification: false,
                                      });
                                    }}
                                  >
                                    {"NOTIFICAR"}
                                  </Link>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </form>
                  )}
                />
              </>
            )}
          </>
        </Modal>

        <div className="content">
          <NotificationAlert ref="notificationAlert" />
          <LoadingOverlay active={this.props.loading} spinner text="Processando...">
            <Form
              onSubmit={onSubmit}
              initialValues={{
                dateInit: moment(new Date()).format("DD/MM/YYYY"),
                dateEnd: moment(new Date()).format("DD/MM/YYYY"),
              }}
              render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                <form onSubmit={handleSubmit}>
                  <div style={{ position: "sticky", top: "0", zIndex: "100" }}>
                    <Row>
                      <Col m="4" className="text-left">
                        <Link size="sm" className="btn btn-sm" to="/company/dashboard">
                          Cancelar
                        </Link>
                      </Col>
                      <Col m="4" className="text-center">
                        <h6 className="title" style={{ lineHeight: "44px", fontSize: ".85em" }}>
                          Documentos
                        </h6>
                      </Col>
                      <Col m="4" className="text-right">
                        <Link size="sm" className="btn btn-sm" to="/company/new-document">
                          Novo
                        </Link>
                      </Col>
                    </Row>
                  </div>

                  <Row>
                    <Col className="text-center" md="12">
                      <Card className="card-tasks">
                        <CardHeader className="text-center">
                          <CardTitle tag="h6">Selecione o período a ser consultado</CardTitle>
                        </CardHeader>

                        <CardBody>
                          <Row>
                            <Col md="2">
                              <Field name="dateInit" label="Data inicial" validate={FieldValidation.required} component={TextField} parse={FieldNormalize.DATE} />
                            </Col>

                            <Col md="2">
                              <Field name="dateEnd" label="Data final" validate={FieldValidation.required} component={TextField} parse={FieldNormalize.DATE} />
                            </Col>
                            <Col
                              md={`${this.props.role === "admin" || this.props.role === "supervisor" ? "auto" : "4"}`}
                              className={`${this.props.role === "admin" || this.props.role === "supervisor" ? "" : "d-flex justify-content-center"}`}
                            >
                              <div>
                                <label>Filtro de Situação:</label>
                                <br />
                                <div className="d-flex align-items-center">
                                  <div>
                                    <Field name="assinados" component="input" type="checkbox" defaultValue={true} />
                                    <label className="pl-2">Assinados &nbsp;</label>
                                  </div>
                                  <div className="pl-2">
                                    <Field name="naoAssinados" component="input" type="checkbox" defaultValue={true} />
                                    <label className="pl-2">Aguardando assinaturas</label>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  {/* <div>
                                  <Field name="excluidos" component="input" type="checkbox" defaultValue={false} />
                                  <label className="pl-2">Excluídos</label>
                                </div> */}
                                  <div>
                                    <Field name="rascunho" component="input" type="checkbox" defaultValue={false} />
                                    <label className="pl-2">Rascunho &nbsp;</label>
                                  </div>
                                  <div className="pl-2">
                                    <Field name="recusado" component="input" type="checkbox" defaultValue={true} />
                                    <label className="pl-2">Recusado</label>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <Field name="bloqueado" component="input" type="checkbox" defaultValue={true} />
                                    <label className="pl-2">Bloqueado</label>
                                  </div>
                                  <div className="pl-2">
                                    <Field name="suspenso" component="input" type="checkbox" defaultValue={true} />
                                    <label className="pl-2">Suspenso</label>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col md="auto">
                              <label>Estado do documento</label>
                              <br />
                              <div className="d-flex align-items-center">
                                <Field name="ativo" component="input" type="checkbox" defaultValue={true} />
                                <label className="pl-2">Ativos</label>
                              </div>
                              <div className="d-flex align-items-center">
                                <Field name="inativo" component="input" type="checkbox" defaultValue={false} />
                                <label className="pl-2">Inativos</label>
                              </div>
                            </Col>

                            {this.props.role === "admin" || this.props.role === "supervisor" ? (
                              <Col md="2">
                                <div className="w-100">
                                  <label>Filtrar por usuários</label>
                                  <br />

                                  <div className="drop w-100">
                                    <input
                                      className="drop-btn w-100 overflow-hidden"
                                      type="text"
                                      name="userName"
                                      // readOnly
                                      // value={
                                      //   this.state.selectedUsersOptions.length > 0 &&
                                      //   this.state.statusUsersOptions
                                      //     .filter((value) => this.state.selectedUsersOptions.includes(value.userId))
                                      //     .map((option) => option.name)
                                      //     .join(", ")
                                      // }
                                      value={this.state.dataInputSearch}
                                      onChange={(e) => {
                                        this.handleInputUserName(e);
                                      }}
                                    />
                                    <div className={`drop-content w-100 ${this.state.resultNameInput.length > 0 && "d-block"}`}>
                                      {this.props.users &&
                                        this.props.users.length > 0 &&
                                        this.state.resultNameInput.length > 0 &&
                                        this.state.resultNameInput.map((option, i, arr) => (
                                          <label key={i} className="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              checked={this.state.selectedUsersOptions.includes(option.userId)}
                                              className="d-none"
                                              onChange={(e) => {
                                                this.handleOptionToggle(option);
                                              }}
                                            />
                                            <span className="ml-1">{option.name}</span>
                                          </label>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            ) : null}

                            <Col md="3">
                              <Field name="cpf" label="CPF (Opcional)" component={TextField} parse={FieldNormalize.CPF} />
                            </Col>
                          </Row>
                          <Row>
                            <Col m="4" className="text-right">
                              <Button className="btn btn-sm" onClick={() => this.handleSearch(values, form)}>
                                Pesquisar
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-center" md="12">
                      <Card className="card-tasks">
                        <CardBody>
                          <Col m="4" className="text-right">
                            <Button
                              className="btn btn-round"
                              onClick={async () => {
                                await this.props.saveDates(values);
                                await this.search(this.props.search);
                              }}
                            >
                              <i className="fa fa-refresh" />
                            </Button>
                            <Button
                              className="btn btn-round"
                              onClick={async () => {
                                await this.props.saveDates(values);
                                await this.exportToExcel(this.props.search);
                              }}
                            >
                              <i className="fa fa-file-excel-o" />
                            </Button>
                          </Col>
                          <ReactTable
                            data={this.props.documents}
                            defaultFilterMethod={this.filterMethod}
                            filterable
                            columns={[
                              {
                                Header: "Id",
                                width: 45,
                                accessor: "hashId",
                              },
                              {
                                Header: "Documento",
                                accessor: "name",
                              },
                              {
                                Header: "Tipo",
                                accessor: "type",
                              },
                              {
                                Header: "Data Registro",
                                width: 180,
                                accessor: "registrationDate",
                                Cell: (row) => {
                                  return <div>{MaskDate.TO_BR_DATETIME(row.original.registrationDate)}</div>;
                                },
                              },
                              {
                                Header: "Situação",
                                accessor: "status",
                                // width: 150,
                                Cell: (row) => {
                                  if (row.original.status === "Assinado" && row.original.inactive === "Ativo") {
                                    return <div style={{ color: "green" }}>{row.original.status}</div>;
                                  } else if (row.original.status === "Assinado" && row.original.inactive === "Inativo") {
                                    return (
                                      <div style={{ color: "green" }}>
                                        {row.original.status} - {row.original.inactive}
                                      </div>
                                    );
                                  } else if (row.original.inactive === "Inativo") {
                                    return (
                                      <div style={{ color: "red" }}>
                                        {row.original.status} - {row.original.inactive}
                                      </div>
                                    );
                                  } else {
                                    return <div style={{ color: "red" }}>{row.original.status}</div>;
                                  }
                                },
                              },
                              {
                                Header: "Assinante",
                                accessor: "assinantesFilter",
                                style: { whiteSpace: "unset" },
                                Cell: (row, i) => {
                                  return (
                                    <div
                                      key={i}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        height: "100%",
                                      }}
                                    >
                                      {row.original.parts.map((part, i) => {
                                        if (part.status !== "Assinado") {
                                          return (
                                            <div
                                              key={i}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Link
                                                onClick={() => {
                                                  this.toggleModalDetails();
                                                  this.props.loadDetails(row.original.documentId);
                                                }}
                                              >
                                                <div style={{ color: "#2c2c2c" }}>
                                                  {part.name} (CPF: {part.cpf} - {part.status}){"  "}
                                                  <i style={{ color: "red" }} className="fa fa-exclamation-circle" />{" "}
                                                </div>
                                              </Link>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div key={i}>
                                              {part.name} (CPF: {part.cpf} - {part.status})
                                            </div>
                                          );
                                        }
                                      })}
                                    </div>
                                  );
                                },
                              },
                              {
                                Header: "Enviar Links",
                                width: 110,
                                Cell: (row) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        height: "100%",
                                      }}
                                    >
                                      {row.original.status !== "Assinado" &&
                                      row.original.status !== "Em Rascunho" &&
                                      row.original.status !== "Recusado" &&
                                      row.original.status !== "Suspenso" &&
                                      row.original.status !== "Bloqueado" ? (
                                        row.original.parts.map((part, i) => {
                                          return part.status !== "Assinado" &&
                                            row.original.status !== "Em Rascunho" &&
                                            row.original.status !== "Recusado" &&
                                            row.original.status !== "Suspenso" &&
                                            row.original.status !== "Bloqueado" ? (
                                            <div key={i}>
                                              {part.identifier && (
                                                <>
                                                  <Button
                                                    disabled={row.original.expired === 1}
                                                    color="default"
                                                    size="sm"
                                                    title={"Copiar link de assinatura do CPF " + part.cpf}
                                                    className="btn-icon btn-link edit"
                                                    onClick={() => {
                                                      const signUrl = API_LINK_URL + "/?chave=" + part.identifier;
                                                      this.copyToClipboard(signUrl);
                                                    }}
                                                  >
                                                    <i className="fa fa-copy" />
                                                  </Button>{" "}
                                                  {part.phone && (
                                                    <>
                                                      <Button
                                                        disabled={row.original.expired === 1}
                                                        color="default"
                                                        size="sm"
                                                        title="Enviar link de assinatura por WhatsApp"
                                                        className="btn-icon btn-link edit"
                                                        onClick={() => this.handleSendWhatsapp(row.original, part)}
                                                      >
                                                        <i className="fa fa-whatsapp" />
                                                      </Button>{" "}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          ) : (
                                            <div key={i}> - </div>
                                          );
                                        })
                                      ) : (
                                        <div> - </div>
                                      )}
                                    </div>
                                  );
                                },
                              },
                              {
                                Header: "Ações",
                                style: {
                                  whiteSpace: "unset",
                                  overflow: "visible",
                                },
                                width: 50,
                                sortable: false,
                                filterable: false,
                                Cell: (row) => {
                                  if (row.original.status === "Assinado" && (this.props.user.userId === row.original.userId || this.props.role === "supervisor" || this.props.role === "admin")) {
                                    if (row.original.rtdpjFileUrl) {
                                      return (
                                        <div className="actions-right">
                                          <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                              <Dropdown.Header>Opções</Dropdown.Header>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  this.toggleModalDetails();
                                                  this.props.loadDetails(row.original.documentId);
                                                }}
                                              >
                                                <i className="fa fa-info" /> Detalhes
                                              </Dropdown.Item>

                                              <Dropdown.Item href={row.original.rtdpjFileUrl} target="_blank">
                                                <i className="fa fa-barcode" /> Validação do registro
                                              </Dropdown.Item>

                                              <Dropdown.Item
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  Promise.resolve(this.props.loadFile(row.original.documentId, 1)).then((response) => {
                                                    console.log("RESPONSE->", response);
                                                    console.log("--->", this.props.file);
                                                    if (this.props.file.type === "application/zip") fileDownload(this.props.file, `${row.original.name}.zip`);
                                                    else fileDownload(this.props.file, `${row.original.name}.pdf`);
                                                  });
                                                }}
                                              >
                                                <i className="fa fa-download" /> Download
                                              </Dropdown.Item>

                                              {!(this.props.company.insurance && row.original.type === "contrato de crédito consignado") || (
                                                <>
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      this.toggleModalInsurance();
                                                      this.props.searchInsurance(row.original.groupId);
                                                    }}
                                                  >
                                                    <i className="fa fa-lock" /> Apólice
                                                  </Dropdown.Item>
                                                </>
                                              )}
                                              <Dropdown.Item
                                                disabled={this.props.role === "supervisor" && this.props.user.userId !== row.original.userId}
                                                onClick={() =>
                                                  this.props.showAlert({
                                                    type: "danger",
                                                    title: "Você realmente quer excluir esse documento?",
                                                    content:
                                                      "O documento que você deseja excluir já foi assinado pelos signatários. Ao excluí-lo ele não estará mais disponível porém o documento continua contabilizado em seu saldo.",
                                                    showCancel: true,
                                                    onCancel: this.props.closeAlert,
                                                    confirmBtnBsStyle: "danger",
                                                    cancelBtnBsStyle: "secondary",
                                                    onConfirm: this.handleClickRemove(row.original.groupId),
                                                    confirmBtnText: "Excluir",
                                                    cancelBtnText: "Cancelar",
                                                  })
                                                }
                                              >
                                                <i className="fa fa-close actions-right" /> Excluir
                                              </Dropdown.Item>
                                              {this.props.role === "admin" && (
                                                <>
                                                  {row.original.inactive === "Ativo" ? (
                                                    <>
                                                      <Dropdown.Item
                                                        onClick={async () => {
                                                          await this.props.inactiveDocument(row.original.documentId);
                                                          await this.search(this.props.search);
                                                        }}
                                                      >
                                                        <i className="fa fa-file-pdf-o actions-right pr-2" /> Inativar
                                                      </Dropdown.Item>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Dropdown.Item
                                                        onClick={async () => {
                                                          await this.props.inactiveDocument(row.original.documentId);
                                                          await this.search(this.props.search);
                                                        }}
                                                      >
                                                        <i className="fa fa-file-pdf-o actions-right pr-2" /> Ativar
                                                      </Dropdown.Item>
                                                    </>
                                                  )}
                                                </>
                                              )}
                                              <Dropdown.Item as={Link} to={`/company/new-document`} onClick={() => localStorage.setItem("copyId", row.original.documentId)}>
                                                <i className="fa fa-copy" /> Copiar Documento
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className="actions-right">
                                          <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                              <Dropdown.Header>Opções</Dropdown.Header>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  this.toggleModalDetails();
                                                  this.props.loadDetails(row.original.documentId);
                                                }}
                                              >
                                                <i className="fa fa-info" /> Detalhes
                                              </Dropdown.Item>

                                              <Dropdown.Item
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  Promise.resolve(this.props.loadFile(row.original.documentId, 1)).then((response) => {
                                                    console.log("RESPONSE->", response);
                                                    console.log("--->", this.props.file);
                                                    if (this.props.file.type === "application/zip") fileDownload(this.props.file, `${row.original.name}.zip`);
                                                    else fileDownload(this.props.file, `${row.original.name}.pdf`);
                                                  });
                                                }}
                                              >
                                                <i className="fa fa-download" /> Download
                                              </Dropdown.Item>

                                              {!(this.props.company.insurance && row.original.type === "contrato de crédito consignado") || (
                                                <>
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      this.toggleModalInsurance();
                                                      this.props.searchInsurance(row.original.groupId);
                                                    }}
                                                  >
                                                    <i className="fa fa-lock" /> Apólice
                                                  </Dropdown.Item>
                                                </>
                                              )}

                                              {this.props.role === "admin" && (
                                              <Dropdown.Item
                                                disabled={this.props.role === "supervisor" && this.props.user.userId !== row.original.userId}
                                                onClick={() =>
                                                  this.props.showAlert({
                                                    type: "danger",
                                                    title: "Você realmente quer excluir esse documento?",
                                                    content:
                                                      "O documento que você deseja excluir já foi assinado pelos signatários. Ao excluí-lo ele não estará mais disponível porém o documento continua contabilizado em seu saldo.",
                                                    showCancel: true,
                                                    onCancel: this.props.closeAlert,
                                                    confirmBtnBsStyle: "danger",
                                                    cancelBtnBsStyle: "secondary",
                                                    onConfirm: this.handleClickRemove(row.original.groupId),
                                                    confirmBtnText: "Excluir",
                                                    cancelBtnText: "Cancelar",
                                                  })
                                                }
                                              >
                                                <i className="fa fa-close actions-right" /> Excluir
                                              </Dropdown.Item>
                                              )}

                                              {this.props.role === "admin" && (
                                                <>
                                                  {row.original.inactive === "Ativo" ? (
                                                    <>
                                                      <Dropdown.Item
                                                        onClick={async () => {
                                                          await this.props.inactiveDocument(row.original.documentId);
                                                          await this.search(this.props.search);
                                                        }}
                                                      >
                                                        <i className="fa fa-file-pdf-o actions-right pr-2" /> Inativar
                                                      </Dropdown.Item>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Dropdown.Item
                                                        onClick={async () => {
                                                          await this.props.inactiveDocument(row.original.documentId);
                                                          await this.search(this.props.search);
                                                        }}
                                                      >
                                                        <i className="fa fa-file-pdf-o actions-right pr-2" /> Ativar
                                                      </Dropdown.Item>
                                                    </>
                                                  )}
                                                  {row.original.status === "Recusado" && (
                                                    <>
                                                      <Dropdown.Item onClick={() => this.revertRefused(row.original.documentId)}>
                                                        <i className="fa fa-retweet actions-right pr-2" /> Reverter Recusa
                                                      </Dropdown.Item>
                                                    </>
                                                  )}
                                                </>
                                              )}
                                              <Dropdown.Item as={Link} to={`/company/new-document`} onClick={() => localStorage.setItem("copyId", row.original.documentId)}>
                                                <i className="fa fa-copy" /> Copiar Documento
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      );
                                    }
                                  } else {
                                    if (row.original.partiallySigned === 1) {
                                      return (
                                        <div className="actions-right">
                                          <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                              <Dropdown.Header>Opções</Dropdown.Header>

                                              {row.original.status === "Em Rascunho" && (this.props.user.userId === row.original.userId || this.props.role !== "supervisor") ? (
                                                <>
                                                  <Dropdown.Item as={Link} to={`/company/edit-document/${row.original.documentId}`}>
                                                    <i className="fa fa-edit" /> Editar
                                                  </Dropdown.Item>

                                                  <Dropdown.Item
                                                    disabled={this.props.role === "supervisor" && this.props.user.userId !== row.original.userId}
                                                    onClick={() =>
                                                      this.props.showAlert({
                                                        type: "danger",
                                                        title: "Você realmente quer excluir esse documento?",
                                                        content:
                                                          "O documento que você deseja excluir já foi assinado por algum signatário. Ao excluí-lo ele não estará mais disponível porém o documento continua contabilizado em seu saldo.",
                                                        showCancel: true,
                                                        onCancel: this.props.closeAlert,
                                                        confirmBtnBsStyle: "danger",
                                                        cancelBtnBsStyle: "secondary",
                                                        onConfirm: this.handleClickRemove(row.original.groupId),
                                                        confirmBtnText: "Excluir",
                                                        cancelBtnText: "Cancelar",
                                                      })
                                                    }
                                                  >
                                                    <i className="fa fa-close actions-right" /> Excluir
                                                  </Dropdown.Item>

                                                  <Dropdown.Item as={Link} to={`/company/new-document`} onClick={() => localStorage.setItem("copyId", row.original.documentId)}>
                                                    <i className="fa fa-copy" /> Copiar Documento
                                                  </Dropdown.Item>
                                                </>
                                              ) : (
                                                <>
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      this.toggleModalDetails();
                                                      this.props.loadDetails(row.original.documentId);
                                                    }}
                                                  >
                                                    <i className="fa fa-info" /> Detalhes
                                                  </Dropdown.Item>

                                                  {this.props.company.sendMessage &&
                                                    !row.original.expired &&
                                                    row.original.status !== "Em Rascunho" &&
                                                    row.original.status !== "Recusado" &&
                                                    row.original.status !== "Suspenso" &&
                                                    row.original.status !== "Bloqueado" &&
                                                    row.original.inactive === "Ativo" && (
                                                      <>
                                                        <Dropdown.Item
                                                          onClick={() => {
                                                            this.toggleModalNotification();
                                                            this.props.addSignParts(
                                                              row.original.parts.map((part) => {
                                                                part.notifySms = false;
                                                                part.notifyEmail = false;
                                                                part.notifyWhatsapp = false;

                                                                return part;
                                                              })
                                                            );
                                                          }}
                                                        >
                                                          <i className="fa fa-bell" /> Notificação
                                                        </Dropdown.Item>
                                                      </>
                                                    )}

                                                  {row.original.status !== "Suspenso" && row.original.status !== "Bloqueado" && (
                                                    <>
                                                      {!(this.props.company.insurance && row.original.type === "contrato de crédito consignado") || (
                                                        <>
                                                          <Dropdown.Item
                                                            onClick={() => {
                                                              this.toggleModalInsurance();
                                                              this.props.searchInsurance(row.original.groupId);
                                                            }}
                                                          >
                                                            <i className="fa fa-lock" /> Apólice
                                                          </Dropdown.Item>
                                                        </>
                                                      )}

                                                      {this.props.user.userId === row.original.userId || this.props.role !== "supervisor" ? (
                                                        <Dropdown.Item
                                                          disabled={this.props.role === "supervisor" && this.props.user.userId !== row.original.userId}
                                                          onClick={() =>
                                                            this.props.showAlert({
                                                              type: "danger",
                                                              title: "Você realmente quer excluir esse documento?",
                                                              content:
                                                                "O documento que você deseja excluir já foi assinado por algum signatário. Ao excluí-lo ele não estará mais disponível porém o documento continua contabilizado em seu saldo.",
                                                              showCancel: true,
                                                              onCancel: this.props.closeAlert,
                                                              confirmBtnBsStyle: "danger",
                                                              cancelBtnBsStyle: "secondary",
                                                              onConfirm: this.handleClickRemove(row.original.groupId),
                                                              confirmBtnText: "Excluir",
                                                              cancelBtnText: "Cancelar",
                                                            })
                                                          }
                                                        >
                                                          <i className="fa fa-close actions-right" /> Excluir
                                                        </Dropdown.Item>
                                                      ) : null}
                                                    </>
                                                  )}

                                                  {this.props.role === "admin" && (
                                                    <>
                                                      {row.original.status !== "Suspenso" && row.original.status !== "Bloqueado" && (
                                                        <>
                                                          {row.original.inactive === "Ativo" ? (
                                                            <>
                                                              <Dropdown.Item
                                                                onClick={async () => {
                                                                  await this.props.inactiveDocument(row.original.documentId);
                                                                  await this.search(this.props.search);
                                                                }}
                                                              >
                                                                <i className="fa fa-file-pdf-o actions-right pr-2" /> Inativar
                                                              </Dropdown.Item>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <Dropdown.Item
                                                                onClick={async () => {
                                                                  await this.props.inactiveDocument(row.original.documentId);
                                                                  await this.search(this.props.search);
                                                                }}
                                                              >
                                                                <i className="fa fa-file-pdf-o actions-right pr-2" /> Ativar
                                                              </Dropdown.Item>
                                                            </>
                                                          )}
                                                        </>
                                                      )}

                                                      {row.original.status === "Recusado" && (
                                                        <>
                                                          <Dropdown.Item onClick={() => this.revertRefused(row.original.documentId)}>
                                                            <i className="fa fa-retweet actions-right pr-2" /> Reverter Recusa
                                                          </Dropdown.Item>
                                                        </>
                                                      )}
                                                      {row.original.status === "Suspenso" && (
                                                        <>
                                                          <Dropdown.Item onClick={() => this.revertSuspended(row.original.documentId)}>
                                                            <i className="fa fa-retweet actions-right pr-2" /> Reverter Suspensão
                                                          </Dropdown.Item>
                                                        </>
                                                      )}
                                                    </>
                                                  )}

                                                  {(this.props.user.userId === row.original.userId || this.props.role !== "supervisor") && (
                                                    <Dropdown.Item as={Link} to={`/company/new-document`} onClick={() => localStorage.setItem("copyId", row.original.documentId)}>
                                                      <i className="fa fa-copy" /> Copiar Documento
                                                    </Dropdown.Item>
                                                  )}
                                                </>
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className="actions-right">
                                          <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu size="sm" title="">
                                              <Dropdown.Header>Opções</Dropdown.Header>
                                              {row.original.status === "Em Rascunho" && (this.props.user.userId === row.original.userId || this.props.role !== "supervisor") ? (
                                                <>
                                                  <Dropdown.Item as={Link} to={`/company/edit-document/${row.original.documentId}`}>
                                                    <i className="fa fa-edit" /> Editar
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    disabled={this.props.role === "supervisor" && this.props.user.userId !== row.original.userId}
                                                    onClick={() =>
                                                      this.props.showAlert({
                                                        type: "danger",
                                                        title: "Você realmente quer excluir esse documento?",
                                                        content: "O documento não estará mais disponível e não será possível desfazer esta operação.",
                                                        showCancel: true,
                                                        onCancel: this.props.closeAlert,
                                                        confirmBtnBsStyle: "danger",
                                                        cancelBtnBsStyle: "secondary",
                                                        onConfirm: this.handleClickRemove(row.original.groupId),
                                                        confirmBtnText: "Excluir",
                                                        cancelBtnText: "Cancelar",
                                                      })
                                                    }
                                                  >
                                                    <i className="fa fa-close actions-right" /> Excluir
                                                  </Dropdown.Item>

                                                  <Dropdown.Item as={Link} to={`/company/new-document`} onClick={() => localStorage.setItem("copyId", row.original.documentId)}>
                                                    <i className="fa fa-copy" /> Copiar Documento
                                                  </Dropdown.Item>
                                                </>
                                              ) : (
                                                <>
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      this.toggleModalDetails();
                                                      this.props.loadDetails(row.original.documentId);
                                                    }}
                                                  >
                                                    <i className="fa fa-info" /> Detalhes
                                                  </Dropdown.Item>

                                                  {this.props.company.sendMessage === 1 &&
                                                    !row.original.expired &&
                                                    row.original.status !== "Em Rascunho" &&
                                                    row.original.status != "Recusado" &&
                                                    row.original.status !== "Suspenso" &&
                                                    row.original.status !== "Bloqueado" &&
                                                    row.original.inactive === "Ativo" && (
                                                      <>
                                                        <Dropdown.Item
                                                          onClick={() => {
                                                            this.toggleModalNotification();
                                                            this.props.addSignParts(
                                                              row.original.parts.map((part) => {
                                                                part.notifySms = false;
                                                                part.notifyEmail = false;
                                                                part.notifyWhatsapp = false;

                                                                return part;
                                                              })
                                                            );
                                                          }}
                                                        >
                                                          <i className="fa fa-bell" /> Notificação
                                                        </Dropdown.Item>
                                                      </>
                                                    )}

                                                  {row.original.status !== "Suspenso" && row.original.status !== "Bloqueado" && (
                                                    <>
                                                      {!(this.props.company.insurance && row.original.type === "contrato de crédito consignado") || (
                                                        <>
                                                          <Dropdown.Item
                                                            onClick={() => {
                                                              this.toggleModalInsurance();
                                                              this.props.searchInsurance(row.original.groupId);
                                                            }}
                                                          >
                                                            <i className="fa fa-lock" /> Apólice
                                                          </Dropdown.Item>
                                                        </>
                                                      )}
                                                    </>
                                                  )}

                                                  {(this.props.user.userId === row.original.userId || this.props.role !== "supervisor") && (
                                                    <Dropdown.Item
                                                      onClick={(ev) => {
                                                        ev.preventDefault();
                                                        Promise.resolve(this.props.loadFile(row.original.documentId, 0)).then((response) => {
                                                          console.log("RESPONSE->", response);
                                                          console.log("--->", this.props.file);
                                                          if (this.props.file.type === "application/zip") fileDownload(this.props.file, `${row.original.name}.zip`);
                                                          else fileDownload(this.props.file, `${row.original.name}.pdf`);
                                                        });
                                                      }}
                                                    >
                                                      <i className="fa fa-download" /> Download
                                                    </Dropdown.Item>
                                                  )}

                                                  {row.original.status !== "Suspenso" &&
                                                    row.original.status !== "Bloqueado" &&
                                                    (this.props.user.userId === row.original.userId || this.props.role !== "supervisor") && (
                                                      <>
                                                        <Dropdown.Item
                                                          disabled={this.props.role === "supervisor" && this.props.user.userId !== row.original.userId}
                                                          onClick={() =>
                                                            this.props.showAlert({
                                                              type: "danger",
                                                              title: "Você realmente quer excluir esse documento?",
                                                              content: "O documento não estará mais disponível e não será possível desfazer esta operação.",
                                                              showCancel: true,
                                                              onCancel: this.props.closeAlert,
                                                              confirmBtnBsStyle: "danger",
                                                              cancelBtnBsStyle: "secondary",
                                                              onConfirm: this.handleClickRemove(row.original.groupId),
                                                              confirmBtnText: "Excluir",
                                                              cancelBtnText: "Cancelar",
                                                            })
                                                          }
                                                        >
                                                          <i className="fa fa-close actions-right" /> Excluir
                                                        </Dropdown.Item>
                                                      </>
                                                    )}

                                                  {this.props.role === "admin" && (
                                                    <>
                                                      {row.original.status !== "Suspenso" && row.original.status !== "Bloqueado" && (
                                                        <>
                                                          {row.original.inactive === "Ativo" ? (
                                                            <>
                                                              <Dropdown.Item
                                                                onClick={async () => {
                                                                  await this.props.inactiveDocument(row.original.documentId);
                                                                  await this.search(this.props.search);
                                                                }}
                                                              >
                                                                <i className="fa fa-file-pdf-o actions-right pr-2" /> Inativar
                                                              </Dropdown.Item>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <Dropdown.Item
                                                                onClick={async () => {
                                                                  await this.props.inactiveDocument(row.original.documentId);
                                                                  await this.search(this.props.search);
                                                                }}
                                                              >
                                                                <i className="fa fa-file-pdf-o actions-right pr-2" /> Ativar
                                                              </Dropdown.Item>
                                                            </>
                                                          )}
                                                        </>
                                                      )}

                                                      {row.original.status === "Recusado" && (
                                                        <>
                                                          <Dropdown.Item onClick={() => this.revertRefused(row.original.documentId)}>
                                                            <i className="fa fa-retweet actions-right pr-2" /> Reverter Recusa
                                                          </Dropdown.Item>
                                                        </>
                                                      )}
                                                      {row.original.status === "Suspenso" && (
                                                        <>
                                                          <Dropdown.Item onClick={() => this.revertSuspended(row.original.documentId)}>
                                                            <i className="fa fa-retweet actions-right pr-2" /> Reverter Suspensão
                                                          </Dropdown.Item>
                                                        </>
                                                      )}
                                                    </>
                                                  )}

                                                  {(this.props.user.userId === row.original.userId || this.props.role !== "supervisor") && (
                                                    <Dropdown.Item as={Link} to={`/company/new-document`} onClick={() => localStorage.setItem("copyId", row.original.documentId)}>
                                                      <i className="fa fa-copy" /> Copiar Documento
                                                    </Dropdown.Item>
                                                  )}
                                                </>
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      );
                                    }
                                  }
                                },
                              },
                            ]}
                            defaultPageSize={10}
                            showPaginationTop
                            showPaginationBottom={false}
                            showPageSizeOptions={false}
                            showPageJump={false}
                            previousText="ANTERIOR"
                            nextText="PRÓXIMO"
                            loadingText="Carregando..."
                            noDataText="Nenhum documento encontrado"
                            pageText="Página"
                            ofText="de"
                            rowsText="linhas"
                            className="-striped -highlight defaul-pagination"
                          />
                        </CardBody>
                        <CardFooter>
                          <hr />
                          <div className="stats">{this.props.documents ? this.props.documents.length : 0} Documentos</div>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                </form>
              )}
            />
          </LoadingOverlay>
        </div>
      </>
    );
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
  };
}

const onSubmit = async (values) => {
  window.alert(JSON.stringify(values, 0, 2));
};

const mapStateToProps = (state) => ({
  ...state.Auth,
  ...state.Documents,
  alertNewDocument: state.Documents.alert,
  alertMessageNewDocument: state.Documents.alertMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    unload: () => dispatch(unload()),
    load: (companyId) => dispatch(load(companyId)),
    loadUsers: (companyId) => dispatch(loadUsers(companyId)),
    loadDetails: (documentId) => dispatch(loadDetails(documentId)),
    loadSelfieDetails: (imageId) => dispatch(loadSelfieDetails(imageId)),
    loadFile: (documentId, status) => dispatch(loadFile(documentId, status)),
    removeFile: (documentId) => dispatch(removeFile(documentId)),
    showAlert: (alertProps) => dispatch(showAlert(alertProps)),
    closeAlert: () => dispatch(closeAlert(0)),
    sendSms: (documentId) => dispatch(sendSms(documentId)),
    saveDates: (values) => dispatch(saveDates(values)),
    searchInsurance: (groupId) => dispatch(searchInsurance(groupId)),
    updatePart: (part) => dispatch(updatePart(part)),
    addSignParts: (signParts) => dispatch(addSignParts(signParts)),
    updatePhoneEmail: (part) => dispatch(updatePhoneEmail(part)),
    notifySigners: (signParts) => dispatch(notifySigners(signParts)),
    inactiveDocument: (documentId) => dispatch(inactiveDocument(documentId)),
    exportToExcel: (companyId) => dispatch(exportToExcel(companyId)),
    revertRefused: (documentId) => dispatch(revertRefused(documentId)),
    revertSuspended: (documentId) => dispatch(revertSuspended(documentId)),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
