import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import avatar from "assets/img/faces/ayo-ogunseinde-2.jpg";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      //urls plano suspenso
      if (
        this.props.company &&
        [0, 2].includes(this.props.company.status) &&
        !["/dashboard", "/documents", "/profile", "/payment-methods", "/change-plan", "/list-notifications"].includes(prop.path)
      ) {
        return null;
      }

      if (
        (this.props.company &&
          this.props.company.plan === "faturado" &&
          this.props.company.limit &&
          prop.path !== "/new-user" &&
          prop.path !== "/users" &&
          ((prop.path !== "/new-notification" && prop.path !== "/list-notifications") || !this.props.company.notification)) ||
        prop.role.indexOf(this.props.role) === -1 ||
        prop.hide ||
        ((prop.path === "/new-notification" || prop.path === "/list-notifications") && !this.props.company.notification)
      ) {
        return null;
      }

      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li className={this.getCollapseInitialState(prop.views) ? "active" : ""} key={key}>
            <a
              href="#"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div className="sidebar" data-color={this.props.bgColor} data-active-color={this.props.activeColor}>
        <div className="logo">
          <a href="#" className="simple-text logo-mini">
            <div className="logo-img">
              <img alt="..." src={require("stylesheets/img/logo/IconConfia512x512.png")} />
            </div>
          </a>
          <a href="#" className="simple-text logo-normal">
            <img alt="..." style={{ height: "27px" }} src={require("stylesheets/img/logo/confia_site_branca.png")} />
          </a>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          {this.props.company && (
            <div className="user">
              <div className="info">
                <a href="#" data-toggle="collapse" aria-expanded={this.state.openAvatar} onClick={() => this.setState({ openAvatar: !this.state.openAvatar })}>
                  <span>
                    <i className={"fa fa-user ml-3 mr-3"} />
                    {this.props.user ? this.props.user.name.split(" ")[0] : ""}
                    <b className="caret" />
                  </span>
                </a>
                <Collapse isOpen={this.state.openAvatar}>
                  <ul className="nav">
                    <li>
                      <NavLink to="/company/profile" activeClassName="">
                        <span className="sidebar-mini-icon">EP</span>
                        <span className="sidebar-normal">Editar Perfil</span>
                      </NavLink>

                      {!(this.props.company.plan === "plano_outro" || this.props.company.plan === "plano_boleto") && (
                        <>
                          {/*
                          <NavLink to="/company/change-plan" activeClassName="">
                            <span className="sidebar-mini-icon">AP</span>
                            <span className="sidebar-normal">Alterar Plano</span>
                          </NavLink>*/}
                          {this.props.company.limit >= 10 && (
                            <NavLink to="/company/payment-methods" activeClassName="">
                              <span className="sidebar-mini-icon">FP</span>
                              <span className="sidebar-normal">Formas de Pagamento</span>
                            </NavLink>
                          )}
                        </>
                      )}
                    </li>
                  </ul>
                </Collapse>
              </div>
            </div>
          )}
          {/* {this.props.showItens === true && */}
          <Nav>{this.createLinks(this.props.routes)}</Nav>
          {/* } */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Auth,
});

export default connect(mapStateToProps)(Sidebar);
