import React from "react";

import { Row, Col } from "reactstrap";

function NotFound(props) {
  return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <h1 className="text-center text-gray f-size-11-5">404</h1>
              <h2 className="text-center text-gray">Página não encontrada </h2>
              <h4 className="text-center text-gray">Ooooups! Aparentemente você está perdido.</h4>
            </Col>
          </Row>
        </div>
      </>
    );
}

export default NotFound;
