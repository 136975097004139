import React from "react";
import { connect } from "react-redux";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import PrivateNavbar from "components/Navbar/PrivateNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Forbidden from "components/Forbidden/Forbidden";
import EditarPerfilUsuario from "containers/Usuario/EditarPerfilUsuario";
import SelectCompany from "./SelectCompany";
import ChangePlan from "../ChangePlan/ChangePlan";

import routes from "privateRoutes.js";

import { loginToken } from "reducers/Auth";
import { getVisibleAlert } from "reducers/Notification";

import { IdleTimeOutModal } from "./IdleModal";
import IdleTimer from "react-idle-timer";

var ps;

var horarioOpenDialog;
var timerId = 0;
let timer = null;

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      timeout: 100000 * 12,
      maxTempOpenDialog: 1000 * 31,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      redirect: null,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  _onAction(e) {
    this._mountParameters();
  }

  _onActive(e) {
    this._mountParameters();
  }

  _onIdle(e) {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      this.removeToken();
      document.getElementById("redirectLogin").click();
    } else {
      this.horarioOpenDialog = new Date().getTime();
      setTimeout(
        function () {
          this.setState({ showModal: true });
        }.bind(this),
        1000
      ); //necessario essa pausa que timer e o clearInterval juntamente com a abertura da dialog fiquem sincronizados

      if (!this.timerId) {
        var _30Seconds = 30,
          display = document.querySelector("#time");
        this.startTimer(_30Seconds, display);
      }
      this.setState({ isTimedOut: true });
      this.timer = setTimeout(() => this._mountParameters(), this.state.maxTempOpenDialog);
    }
  }

  _mountParameters() {
    if (this.state.showModal) {
      var tempoPercorrido = Math.abs(new Date().getTime() - this.horarioOpenDialog);
      if (tempoPercorrido > this.state.maxTempOpenDialog) {
        clearTimeout(timer);
        this.removeToken();
        document.getElementById("redirectLogin").click();
      }
    } else {
      this.setState({ isTimedOut: false });
    }
  }

  handleClose() {
    clearInterval(this.timerId);
    this.idleTimer.reset();
    this.setState({ showModal: false });
    this.timerId = 0;
    this.horarioOpenDialog = null;
  }

  handleLogout() {
    this.setState({ showModal: false });
    clearInterval(this.timerId);
    clearTimeout(timer);
    this.timerId = 0;
    this.removeToken();
    document.getElementById("redirectLogin").click();
    window.location.href = `${process.env.REACT_APP_KEYCLOAK_URL}/protocol/openid-connect/logout?client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}redirect_uri=${process.env.REACT_APP_KEYCLOAK_REDIRECT_URI}`;
  }

  startTimer(duration, display) {
    var timer = duration,
      minutes,
      seconds;
    this.timerId = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = minutes + ":" + seconds;

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  removeToken() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("refreshToken");
  }

  componentDidMount() {
    if (!this.props.user) {
      //Try to reconect using token
      this.props.loginToken();
    }

    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = (routes) => {
    if (this.props.company) {
      // console.log(this.props.company.authenticationEnabled);
      return routes.map((prop, key, arr) => {
        if (prop.name === "Autenticações" && this.props.company.authenticationEnabled !== 1) {
          const removeIndexArr = arr.findIndex((value) => value.name === "Autenticações");
          console.log("Rota Autenticaçõs removida ", removeIndexArr);
          arr.splice(removeIndexArr, 1);
        }
        if (prop.collapse) {
          return this.getRoutes(prop.views);
        }
        if (prop.role.indexOf(this.props.role) > -1) {
          return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
        } else {
          return <Route path={prop.layout + prop.path} component={Forbidden} name={prop.name} key={key} />;
        }
      });
    } else {
      return routes.map((prop, key) => {
        if (prop.collapse) {
          return this.getRoutes(prop.views);
        }
        return <Route path={prop.layout + prop.path} component={SelectCompany} name={prop.name} key={key} />;
      });
    }
  };
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  render() {
    if (this.props.redirectLogin) {
      this.removeToken();
      return document.getElementById("redirectLogin").click();
    }
    return (
      <div className="wrapper">
        <a href="/" id="redirectLogin"></a>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
        <IdleTimeOutModal showModal={this.state.showModal} handleClose={this.handleClose} handleLogout={this.handleLogout} />
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          //showItens={this.props.balance != null}
        />
        <div className="main-panel" ref="mainPanel">
          <PrivateNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Switch>
            <Route path="/company/edit-profile" component={EditarPerfilUsuario} name="Editar Perfil" />
          </Switch>

          {this.props.visibleAlert && <SweetAlert {...this.props.visibleAlert}>{this.props.visibleAlert.content}</SweetAlert>}

          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !== -1 ? null : <Footer fluid />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Auth,
  ...state.Dashboard,
  visibleAlert: getVisibleAlert(state.Notification),
});

function mapDispatchToProps(dispatch) {
  return {
    loginToken: () => dispatch(loginToken()),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
