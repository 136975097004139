const UNLOAD = 'confia/profile/UNLOAD';
const LOAD = 'confia/profile/LOAD';
const LOAD_SUCCESS = 'confia/profile/LOAD_SUCCESS';
const LOAD_FAIL = 'confia/profile/LOAD_FAIL';
const SAVE = 'confia/profile/SAVE';
const SAVE_SUCCESS = 'confia/profile/SAVE_SUCCESS';
const SAVE_FAIL = 'confia/profile/SAVE_FAIL';

export function load(userId) {
  return {
    type: LOAD,
    payload: {
      request: {
        method: 'get',
        url: `/api/profile/${userId}`,
        data: userId,
      }
    }
  };
}

export function unload() {
  return {
    type: UNLOAD,
  };
}

export function save(values) {
  return {
    type: SAVE,
    payload: {
      request: {
        method: 'put',
        url: `/api/profile/${values.userId}`,
        data: values,
      }
    }
  };
}

const INITIAL_STATE = {profile: null, error: false, message: false};

export default function Profile(state = INITIAL_STATE, action) {
  switch (action.type) {

    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.data.profile,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: 'Não foi possível recuperar as informações do usuário.',
        error: true,
      };

    case SAVE:
      return {
        ...state,
        loading: true,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.data.profile,
      };
    case SAVE_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: 'Não foi possível salvar suas alterações.',
        error: true,
      };

    case UNLOAD:
      return {
        state: INITIAL_STATE,
      };

    // initial state
    default:
      return state;
  }
}
