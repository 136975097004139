import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// reactstrap components
import { Badge, Button, Card, CardHeader, CardBody, CardFooter, CardTitle, Label, FormGroup, Input, Alert, Row, Col, UncontrolledTooltip } from "reactstrap";

import { load, unload } from "../../reducers/Dashboard";
import { documentsNotSigned } from "../../reducers/Documents";
import moment from "moment";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    {
      this.state = {
        redirect: false,
      };
    }
  }
  componentWillMount() {
    if (this.props.company) {
      this.props.load(this.props.company.companyId);
    }
  }

  componentWillUnmount() {
    if (this.props.company) {
      this.props.load(this.props.company.companyId);
    }
  }

  render() {
    /*if (this.props.redirectTo) {
      return <Redirect to={this.props.redirectTo} />;
    }*/
    if (!this.props.company) {
      return;
    }
    return (
      <>
        <div className="content">
          <Row>
            {
              this.props.company.plan === "faturado" ||
                (!this.props.balance && !this.props.company.limit && (
                  <>
                    <Col sm="12">
                      <Card className="card-plain">
                        <CardBody>
                          <Alert color="warning" className="text-center">
                            <span>
                              <h4>
                                Seu plano não foi ativado ainda.
                                <br />
                                Em breve nossa equipe entrará em contato com você, caso necessário envie um email para suporte@confia.net.br
                              </h4>
                            </span>
                          </Alert>
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                ))
              // )
            }
            <Col lg="4" md="6" sm="6">
              <Link to="/company/documents">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-paper text-muted" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Documentos</p>
                          <CardTitle tag="p">{this.props.qtdDocs}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <Row>
                      <Col md="6" xs="6">
                        <div className="text-left stats" />
                      </Col>
                      <Col md="6" xs="6" className="text-right">
                        Período atual
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Link>
            </Col>

            <Col lg="4" md="6" sm="6">
              <Card
                className="card-stats"
                onClick={() => {
                  const pesquisa = {
                    companyId: this.props.company.companyId,
                    values: {
                      dateInit: this.props.user.registrationDate,
                      dateEnd: moment(new Date()).format("YYYY-MM-DD"),
                    },
                  };
                  this.props.documentsNotSigned(pesquisa);
                  this.setState({ redirect: true });
                }}
              >
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-simple-remove text-muted" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Documentos não assinados</p>
                        <CardTitle tag="p">{this.props.qtdDocsNotSigned}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Row>
                    <Col md="6" xs="6">
                      <div className="text-left stats" />
                    </Col>
                    <Col md="6" xs="6" className="text-right">
                      Período atual
                    </Col>
                  </Row>
                </CardFooter>
                {this.state.redirect ? <Redirect to="/company/documents" /> : ""}
              </Card>
            </Col>

            {(this.props.company.plan === "plano_outro" && this.props.company.limit === 9999) || this.props.company.plan === "faturado" || (
              <Col lg="4" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-sound-wave text-muted" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Saldo Restante</p>
                          <CardTitle tag="p">{this.props.balance}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <Row>
                      <Col md="6" xs="6">
                        <div className="text-left stats" />
                      </Col>
                      <Col md="6" xs="6" className="text-right">
                        Período atual
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            )}
          </Row>
          {/*
          <Row>
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-paper text-muted" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Documentos Registrados</p>
                        <CardTitle tag="p">0</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Row>
                    <Col md="6" xs="6">
                      <div className="text-left stats">
                        <Link to={'/cartorio/registro-documentos'} className="text-muted">
                        <i className="fa fa-list" />
                        Listar
                        </Link>
                      </div>
                    </Col>
                    <Col md="6" xs="6">

                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Link to={'/cartorio/detalhes-emolumentos'}>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-single-02 text-muted" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Usuários</p>
                          <CardTitle tag="p">0</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                      <Row>
                        <Col md="6" xs="6">
                          <div className="text-left stats">
                            <Link to={'/cartorio/registro-documentos'} className="text-muted">
                            <i className="fa fa-list" />
                            Listar
                            </Link>
                          </div>
                        </Col>
                        <Col md="6" xs="6">

                        </Col>
                      </Row>
                  </CardFooter>
                </Card>
              </Link>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-sound-wave text-muted" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Saldo Contratado</p>
                        <CardTitle tag="p">0</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Row>
                    <Col md="6" xs="6">
                      <div className="text-left stats">
                        <Link to={'/cartorio/registro-documentos'} className="text-muted">
                        <i className="fa fa-ellipsis-h" />
                        Alterar Plano
                        </Link>
                      </div>
                    </Col>
                    <Col md="6" xs="6">

                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Auth,
  ...state.Dashboard,
});

function mapDispatchToProps(dispatch) {
  return {
    load: (companyId) => dispatch(load(companyId)),
    unload: () => dispatch(unload()),
    documentsNotSigned: (values) => dispatch(documentsNotSigned(values)),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
