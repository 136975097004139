import React from "react";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import { Button, Card, CardHeader, CardBody, CardFooter, Container, Col, Row } from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import TextField from "components/Fields/TextField";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import FieldValidation from "components/Fields/Validation/FieldValidation";
import { encryptStringWithXORtoHex } from "Utils/Utils";
import ReCAPTCHA from "react-google-recaptcha";

import { login, loginWithIDRC, newPassword, ping } from "reducers/Auth";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      captchaLoginOk: false,
      isDev: process.env.NODE_ENV === "development",
    };
    this.loginCaptchaRef = React.createRef();
  }

  async componentDidMount() {
    // Verifica se o URL contém um código de autorização
    const code = new URLSearchParams(window.location.search).get("code");
    if (code) {
      this.authenticateUser(code);
    }

    await this.props.ping();

    document.body.classList.toggle("login-page");
    if (this.props.message === true) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>
              <strong>{this.props.successMessage}</strong>
            </div>
          </div>
        ),
        type: "success",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  onSubmit = async (values) => {
    if (this.state.isDev || this.state.captchaLoginOk) {
      var l = {
        cpf: values.cpf,
        password: encryptStringWithXORtoHex(values.password, values.cpf),
        captcha: this.state.isDev ? "dev-captcha" : this.loginCaptchaRef.current.getValue(),
      };
      Promise.resolve(this.props.login(l))
        .then((response) => {
          this.loginCaptchaRef.current.reset();
          this.setState({ captchaLoginOk: false });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>
              <strong>Resolva o captcha para prosseguir.</strong>
            </div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
    }
  };

  onChange = () => {
    this.setState({ captchaLoginOk: true });
  };

  componentWillReceiveProps(nextProps) {
    // A qualquer momento que props.email mude, atualize o estado.
    if (nextProps.error !== this.props.error) {
      if (nextProps.error === true) {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>{nextProps.errorMessage}</div>
            </div>
          ),
          type: "danger",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    }

    if (nextProps.message !== this.props.message) {
      if (nextProps.message === true) {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>
                <strong>{nextProps.successMessage}</strong>
              </div>
            </div>
          ),
          type: "success",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    }
  }

  authenticateUser = async (code) => {
    try {
      await this.props.loginWithIDRC(code);
    } catch (error) {
      console.error("Authentication error:", error);
      const options = {
        place: "tc",
        message: (
          <div>
            <div>
              <strong>Erro ao efetuar login com IDRC</strong>
            </div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);

      window.location.href = `${process.env.REACT_APP_KEYCLOAK_URL}/protocol/openid-connect/logout?client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}redirect_uri=${process.env.REACT_APP_KEYCLOAK_REDIRECT_URI}`;
    }
  };

  redirectToProviderLogin = () => {
    //pegar a url da janela
    const url = window.location.origin;
    const acrValues = "urn:idrc:loa:substancial";

    // gerar um state aleatório
    const state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    window.location.href = `${process.env.REACT_APP_KEYCLOAK_URL}/protocol/openid-connect/auth?client_id=${
      process.env.REACT_APP_KEYCLOAK_CLIENT_ID
    }&redirect_uri=${url}&response_type=code&scope=openid%20profile%20email&state=${state}&acr_values=${encodeURIComponent(acrValues)}&prompt=login`;
  };

  render() {
    if (this.props.redirectTo) {
      return <Redirect to={this.props.redirectTo} />;
    }

    return (
      <div className="login-page">
        <NotificationAlert ref="notificationAlert" />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <div className="col-md-8 ml-auto mr-auto mb-5 mt-5">
                <img alt="..." src={require("stylesheets/img/logo/confia_site_branca.png")} />
              </div>
              <Form
                onSubmit={this.onSubmit}
                initialValues={{}}
                render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                  <form onSubmit={handleSubmit}>
                    <Card className="card-login">
                      <CardHeader>
                        <CardHeader>
                          <h4 className="header text-center">ACESSE SUA CONTA</h4>
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                        <Col className="mx-auto " md="12">
                          <Field name="cpf" placeholder="CPF" component={TextField} parse={FieldNormalize.CPF} validate={FieldValidation.required} />
                        </Col>
                        <Col className="mx-auto" md="12">
                          <Field name="password" placeholder="SENHA" component={TextField} type={"password"} validate={FieldValidation.required} />
                        </Col>
                        <Col md="12" style={{ display: "flex", justifyContent: "center" }}>
                          {!this.state.isDev && (
                            <ReCAPTCHA
                              sitekey="6Lfyr-EcAAAAAA0U0kVhxSpJE65AdCMnLgkxJB2S"
                              onChange={this.onChange}
                              onExpired={() => this.setState({ captchaLoginOk: false })}
                              ref={this.loginCaptchaRef}
                              hl="pt-BR"
                            />
                          )}
                        </Col>
                      </CardBody>
                      <CardFooter>
                        <Col className="mx-auto" md="12">
                          <Button disabled={!this.state.isDev && !this.state.captchaLoginOk} block className="btn-round mb-3" color="default" type="submit">
                            Acessar minha conta
                          </Button>
                        </Col>
                        <Col className="mx-auto text-center" md="12">
                          <Button
                            className="btn-link"
                            color="default"
                            onClick={() => {
                              if (this.state.captchaLoginOk) {
                                console.log("->", values);
                                if (values.cpf) {
                                  const requestValues = {
                                    cpf: values.cpf,
                                    captcha: this.loginCaptchaRef.current.getValue(),
                                  };

                                  Promise.resolve(this.props.newPassword(requestValues))
                                    .then((response) => {
                                      this.loginCaptchaRef.current.reset();
                                      this.setState({ captchaLoginOk: false });
                                    })
                                    .catch((e) => {
                                      console.log(e);
                                      var options = {};
                                      options = {
                                        place: "tc",
                                        message: (
                                          <div>
                                            <div>
                                              <strong>CPF inválido.</strong>
                                            </div>
                                          </div>
                                        ),
                                        type: "danger",
                                        icon: "now-ui-icons ui-1_bell-53",
                                        autoDismiss: 7,
                                      };
                                      this.refs.notificationAlert.notificationAlert(options);
                                    });
                                } else {
                                  var options = {};
                                  options = {
                                    place: "tc",
                                    message: (
                                      <div>
                                        <div>
                                          <strong>Informe o CPF.</strong>
                                        </div>
                                      </div>
                                    ),
                                    type: "danger",
                                    icon: "now-ui-icons ui-1_bell-53",
                                    autoDismiss: 7,
                                  };
                                  this.refs.notificationAlert.notificationAlert(options);
                                }
                              } else {
                                var options = {};
                                options = {
                                  place: "tc",
                                  message: (
                                    <div>
                                      <div>
                                        <strong>Resolva o captcha para prosseguir.</strong>
                                      </div>
                                    </div>
                                  ),
                                  type: "danger",
                                  icon: "now-ui-icons ui-1_bell-53",
                                  autoDismiss: 7,
                                };
                                this.refs.notificationAlert.notificationAlert(options);
                              }
                            }}
                          >
                            Esqueci minha senha
                          </Button>
                        </Col>
                      </CardFooter>
                      <CardFooter>
                        <Col className="mx-auto" md="12">
                          <Link color="default" to="/register" className="btn btn-block btn-round mb-3">
                            Criar uma conta
                          </Link>
                          {/* <Link color="default" to="/singlePurchase" className="btn btn-block btn-round mb-3">
                            Compra avulsa
                          </Link> */}
                        </Col>
                      </CardFooter>
                      <CardFooter>
                        <Col className="mx-auto" md="12">
                          <button className="btn btn-round mb-3" style={{ backgroundColor: "#B5D441", color: "#fff", width: "100%" }} onClick={this.redirectToProviderLogin}>
                            <img src={require("stylesheets/img/logo/idrc2.svg")} style={{ width: "20px", marginRight: "10px" }} alt="IDRC" />
                            Login com IDRC
                          </button>
                        </Col>
                      </CardFooter>
                    </Card>
                  </form>
                )}
              />
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("stylesheets/img/bg/bgEmpresas.jpeg")})`,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Auth,
});

function mapDispatchToProps(dispatch) {
  return {
    login: (values) => dispatch(login(values)),
    loginWithIDRC: (code) => dispatch(loginWithIDRC(code)),
    newPassword: (cpf) => dispatch(newPassword(cpf)),
    ping: () => dispatch(ping()),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
