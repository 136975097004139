import React from "react";
import PropTypes from "prop-types";

import { Button, Col } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

class DocumentUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
      });
    };
    reader.readAsDataURL(file);

    console.log('FILE',file);

    this.props.addFile(file);
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
    });
    this.refs.fileInput.value = null;
    this.props.removeFile();
  }
  render() {
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" accept="application/pdf" />
        <div>
          {this.state.file === null ? (
            <Button className="btn-round" onClick={() => this.handleClick()} style={{margin: '25px 1px'}}>
              {"SELECIONE O DOCUMENTO"}
            </Button>
          ) : (
            <span>
              <Col className="mx-0 mb-0" md="12" sm="12">
              <label>{this.state.file.name}</label>
              </Col>
              <Col className="mx-0 mt-0" md="12" sm="12">
              <Button className="btn-round" onClick={() => this.handleClick()} style={{margin: '0px 0px'}}>
                Alterar
              </Button>
              {this.props.avatar ? <br /> : null}
              <Button
                color="danger"
                className="btn-round"
                onClick={() => this.handleRemove()}
              >
                <i className="fa fa-times" />
                Excluir
              </Button>
              </Col>
            </span>
          )}
        </div>
      </div>
    );
  }
}

DocumentUpload.propTypes = {
  addFile: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
}

export default DocumentUpload;
