const UNLOAD = 'confia/new-notification/UNLOAD';
const ADD_FILE = 'confia/new-notification/ADD_FILE';
const REMOVE_FILE = 'confia/new-notification/REMOVE_FILE';
const PROCESS = 'confia/new-notification/PROCESS';
const PROCESS_SUCCESS = 'confia/new-notification/PROCESS_SUCCESS';
const SAVE = 'confia/new-notification/SAVE';
const SAVE_SUCCESS = 'confia/new-notification/SAVE_SUCCESS';
const PROCESS_LIST_RESULT = 'confia/new-notification/PROCESS_LIST_RESULT';
const REMOVE_LINE = 'confia/new-notification/REMOVE_LINE';

export function unload() {
  return {
    type: UNLOAD,
  };
}

export function addFile(file) {
  return {
    type: ADD_FILE,
    payload: file,
  };
}

export function removeFile() {
  return {
    type: REMOVE_FILE,
  };
}

export function processList(values) {
  return {
    type: PROCESS_LIST_RESULT,
    payload: values
  };
}

export function process(values) {
  const formData = new FormData();
  formData.append('file', values.file);

  return {
    type: PROCESS,
    payload: {
      request: {
        method: 'post',
        url: `/api/notification/transformXlsxToJson/`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      }
    }
  };
}

export function save(values,companyId) {
  return {
    type: SAVE,
    payload: {
      request: {
        method: 'post',
        url: `/api/notification/${companyId}`,
        data: values,
      }
    }
  };
}

export function removeLine(id) {
  console.log('removeLine:',id);
  return {
    type: REMOVE_LINE,
    payload: id,
  };
}

const INITIAL_STATE = { file: null, all: [], alert: false, alertMessage: null };

export default function NewNotification(state = INITIAL_STATE, action) {
  switch (action.type) {

    case SAVE_SUCCESS:
      return {
        ...state,
        all: [],
        file: null
      };

    case ADD_FILE:
      return {
        ...state,
        file: action.payload,
      };

    case REMOVE_FILE:
      return {
        ...state,
        file: null
      };

    case PROCESS_LIST_RESULT:
      return {
        ...state,
        all: action.payload
      };

      case PROCESS_SUCCESS:
        return {
          ...state,
          all: action.payload.data.arquivo,
          alert: true,
          alertMessage: 'Planilha lida com sucesso.',
        };

      case REMOVE_LINE:
        return {
          ...state,
          all: [
              ...state.all.slice(0, action.payload),
              ...state.all.slice(action.payload + 1)
          ],

        };

      case UNLOAD:
        return INITIAL_STATE

    default:
      return state;
  }
}
