const GET_REPORT_FINANCIAL = "confia/FinancialReport/GET_REPORT_FINANCIAL";
const GET_REPORT_FINANCIAL_SUCCESS = "confia/FinancialReport/GET_REPORT_FINANCIAL_SUCCESS";
const GET_REPORT_FINANCIAL_FAIL = "confia/FinancialReport/GET_REPORT_FINANCIAL_FAIL";
const UNLOAD = "confia/FinancialReport/UNLOAD";

export function getReportFinancial(props) {
  return {
    type: GET_REPORT_FINANCIAL,
    payload: {
      request: {
        method: "post",
        url: `/api/balanceReport`,
        data: props,
      },
    },
  };
}

export function unload() {
  return {
    type: UNLOAD,
  };
}

const INITIAL_STATE = { all: [], loadingPage: false, errorReportFin: false, errorMessageFin: null, successMessage: null };

export default function FinancialReport(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_REPORT_FINANCIAL:
      return {
        ...state,
        loadingPage: true,
      };
    case GET_REPORT_FINANCIAL_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        all: action.payload.data.result ? action.payload.data.result : [],
      };
    case GET_REPORT_FINANCIAL_FAIL:
      return {
        ...state,
        loadingPage: false,
        errorReportFin: true,
        errorMessageFin: "Não foi possível recuperar detalhes do documento.",
      };
    case UNLOAD:
      return {
        state: INITIAL_STATE,
      };
    default:
      return state;
  }
}
