import React from "react";

import { Row, Col } from "reactstrap";

function Forbidden(props) {
  return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <h1 className="text-center text-gray f-size-11-5">403</h1>
              <h2 className="text-center text-gray">Acesso não permitido </h2>
              <h4 className="text-center text-gray">Ooooups! Você não tem permissão para acessar essa página.</h4>
            </Col>
          </Row>
        </div>
      </>
    );
}

export default Forbidden;
