import React from "react";
import { Form, Field } from 'react-final-form'
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  ButtonGroup,
  CardTitle,
} from "reactstrap";

import TextField from 'components/Fields/TextField';
import FieldNormalize from 'components/Fields/Normalize/FieldNormalize';
import FieldValidation from 'components/Fields/Validation/FieldValidation';
import ImageUpload from 'components/CustomUpload/ImageUpload.js';

class EditarPerfilUsuario extends React.Component {

  render() {
    return (
      <>
        <div className="content">
          <Form
          onSubmit={onSubmit}
          initialValues={{ stooge: 'larry', employed: true }}
          render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
            <form onSubmit={handleSubmit}>

              <div style={{position:'sticky',top:'0','z-index':'100'}}>
                <Row>
                  <Col m="4" className="text-left">
                      <Button size="sm">Cancelar</Button>
                  </Col>
                  <Col m="4" className="text-center">
                      <h6 className="title" style={{lineHeight: '44px',fontSize: '.85em'}}>Usuário</h6>
                  </Col>
                  <Col m="4" className="text-right">
                      <Button color="primary" size="sm">Salvar</Button>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <h5 className="title">Editar Perfil</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="12" className="text-center">
                          <ImageUpload avatar/>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-auto" md="4">
                          <Field name="cpf" label="CPF" placeholder="CPF" component={TextField} required parse={FieldNormalize.CPF} validate={FieldValidation.required}/>
                        </Col>
                        <Col className="mx-auto" md="8">
                            <Field name="nome" label="Nome" placeholder="Nome" component={TextField} required validate={FieldValidation.required}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-auto" md="12">
                            <Field name="emailResponsavel" label="E-mail" placeholder="E-mail do responsável" component={TextField} type="email" required validate={FieldValidation.composeValidators(FieldValidation.required,FieldValidation.email)}/>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

              </Row>
            </form>
          )}
          />
        </div>
      </>
    );
  }
}

const onSubmit = async values => {
//  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2))
}

export default EditarPerfilUsuario;
