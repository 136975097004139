import React from 'react';
import { Route } from "react-router-dom";
import { connect } from 'react-redux';

import newStylesRouter from 'variables/noStyleRoute';
import Footer from "components/Footer/Footer.js";
import PublicNavbar from "components/Navbar/PublicNavbar"


function PublicRoute({ component: Component, ...rest }) {

  
  return(
    <>
      <div className="wrapper wrapper-full-page" >
        <div className= {`${window.location.pathname === "/singlePurchase" ? "singlePurchasePage" : "full-page section-image"}`} style={{paddingTop: 0}}>
          <Route
              {...rest}
              render={props =>
                <Component {...props} />
              }
            />
          <Footer fluid />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  ...state.Auth,
});

export default connect(mapStateToProps)(PublicRoute);
