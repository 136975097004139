import React from "react";
import {Link} from 'react-router-dom';
import { Form } from 'react-final-form'
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  CardFooter,
  CardTitle,
  Table,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle

} from "reactstrap";

import TextField from 'components/Fields/TextField';
import SelectField from 'components/Fields/SelectField';
import FieldNormalize from 'components/Fields/Normalize/FieldNormalize';
import FieldValidation from 'components/Fields/Validation/FieldValidation';

class DetalheDocumento extends React.Component {



  render() {
    return (
      <>
        <div className="content">
          <Form
          onSubmit={onSubmit}
          initialValues={{ stooge: 'larry', employed: true }}
          render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
            <form onSubmit={handleSubmit}>



              <div style={{position:'sticky',top:'0','z-index':'100'}}>
                <Row>
                  <Col m="4" className="text-left">
                    <Link onClick={e => {e.preventDefault();this.props.history.goBack()}} className="btn btn-default btn-sm">Voltar</Link>
                  </Col>
                  <Col m="4" className="text-center">
                      <h6 className="title" style={{lineHeight: '44px',fontSize: '.85em'}}>Documento</h6>
                  </Col>
                  <Col m="4" className="text-right">
                      
                  </Col>
                </Row>
              </div>

              <Row>
                <Col className="text-center" md="12">

                </Col>
              </Row>
            </form>
          )}
          />
        </div>
      </>
    );
  }
}

const onSubmit = async values => {
//  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2))
}

export default DetalheDocumento;
