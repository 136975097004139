const UNLOAD = 'confia/dashboard/UNLOAD';
const LOAD = 'confia/dashboard/LOAD';
const LOAD_SUCCESS = 'confia/dashboard/LOAD_SUCCESS';
const LOAD_FAIL = 'confia/dashboard/LOAD_FAIL';

export function load(companyId) {
  return {
    type: LOAD,
    payload: {
      request: {
        method: 'get',
        url: `/api/balance/${companyId}`,
      }
    }
  };
}

export function unload() {
  return {
    type: UNLOAD,
  };
}

const INITIAL_STATE = {qtdDocs: 0, balance: null};

export default function Dashboard(state = INITIAL_STATE, action) {
  switch (action.type) {

    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        qtdDocs: action.payload.data.currentBalance ? action.payload.data.currentBalance.qtdDocs : 0,
        qtdDocsNotSigned: action.payload.data.currentBalance ? action.payload.data.currentBalance.qtdDocsNotSigned : 0,
        balance: action.payload.data.currentBalance ? action.payload.data.currentBalance.balance : 0,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: 'Não foi possível recuperar as informações do usuário.',
        error: true,
      };

    case UNLOAD:
      return {
        state: INITIAL_STATE,
      };

    // initial state
    default:
      return state;
  }
}
