const CHANGE_PLAN = 'confia/change-plan/CHANGE_PLAN';
const LOAD = 'confia/change-plan/LOAD';
const LOAD_SUCCESS = 'confia/change-plan/LOAD_SUCCESS';
const LOAD_FAIL = 'confia/change-plan/LOAD_FAIL';
const SAVE = 'confia/change-plan/SAVE';
const SAVE_SUCCESS = 'confia/change-plan/SAVE_SUCCESS';
const SAVE_FAIL = 'confia/change-plan/SAVE_FAIL';
const UNLOAD = 'confia/change-plan/UNLOAD';
const ACTIVATE_PLAN = 'confia/change-plan/ACTIVATE_PLAN';
const ACTIVATE_PLAN_SUCCESS = 'confia/change-plan/ACTIVATE_PLAN_SUCCESS';
const ACTIVATE_PLAN_FAIL = 'confia/change-plan/ACTIVATE_PLAN_FAIL';
const SUSPEND_PLAN = 'confia/change-plan/SUSPEND_PLAN';
const SUSPEND_PLAN_SUCCESS = 'confia/change-plan/SUSPEND_PLAN_SUCCESS';
const SUSPEND_PLAN_FAIL = 'confia/change-plan/SUSPEND_PLAN_FAIL';



export function changePlan (value){
    return {
        type: CHANGE_PLAN,
        payload: { selectedPlan: value }
    };
}

export function suspendPlan (value){
  return {
      type: SUSPEND_PLAN,
      payload: {
        request: {
          method: 'post',
          url: `/api/suspend-plan/${value}`
        }
      }
  };
}

export function activatePlan (value){
  return {
      type: ACTIVATE_PLAN,
      payload: {
        request: {
          method: 'post',
          url: `/api/activate-plan/${value}`
        }
      }
  };
}


export function unload() {
  return {
    type: UNLOAD,
  };
}

export function loadSimulation(value) {
  return {
    type: LOAD,
    payload: {
      request: {
        method: 'get',
        url: `/api/change-plan/${value.companyId}/${value.plan_identifier}`
      }
    }
  };
}

export function save(value) {
  console.log('SAVE-->',value);

  return {
    type: SAVE,
    payload: {
      request: {
        method: 'post',
        url: `/api/change-plan/${value.companyId}/${value.plan_identifier}`,
      }
    }
  };
}

const INITIAL_STATE = { selectedPlan: "" };

export default function ChangePlan(state = INITIAL_STATE, action){
  switch (action.type) {
    case CHANGE_PLAN:
      return {
        ...state,
        selectedPlan: action.payload.selectedPlan
      };

    case LOAD:
      return {
        ...state,
        loading: true
      };

    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        simulation: action.payload.data.simulation
      };

    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: 'Não foi possível simular a troca de plano para o usuário.',
        error: true
      };

    case SAVE:
      return {
        ...state,
        loading: true,
      };

    case SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: true,
        alertMessage: 'Plano atualizado com sucesso.',
      };

    case SAVE_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: 'Não foi possível atualizar o plano.',
        error: true,
      };

    case ACTIVATE_PLAN:
      return {
        ...state,
        loading: true,
      };

    case ACTIVATE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: true,
        alertMessage: 'Plano ativado com sucesso.',
      };

      case SUSPEND_PLAN:
        return {
          ...state,
          loading: true,
        };

      case SUSPEND_PLAN_SUCCESS:
        return {
          ...state,
          loading: false,
          alert: true,
          alertMessage: 'Plano suspenso com sucesso.',
        };

      case SUSPEND_PLAN_FAIL:
        return {
          ...state,
          loading: false,
          errorMessage: 'Não foi possível suspender o plano.',
          error: true,
        };

    case ACTIVATE_PLAN_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: 'Não foi possível ativar o plano.',
        error: true,
      };


    case UNLOAD:
      return {
        state: INITIAL_STATE,
      };

    default:
      return state;
  }
};
