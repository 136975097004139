const UNLOAD = "confia/new-document/UNLOAD";
const UNLOAD_NOTIFY = "confia/new-document/UNLOAD_NOTIFY";
const CLEAR_REDIRECT_NOTIFY = "confia/new-document/CLEAR_REDIRECT_NOTIFY";
const SAVE = "confia/new-document/SAVE";
const SAVE_SUCCESS = "confia/new-document/SAVE_SUCCESS";
const SAVE_FAIL = "confia/new-document/SAVE_FAIL";
const ADD_PART = "confia/new-document/ADD_PART";
const REMOVE_PART = "confia/new-document/REMOVE_PART";
const LOAD = "confia/new-document/LOAD";
const LOAD_SUCCESS = "confia/new-document/LOAD_SUCCESS";
const LOAD_FAIL = "confia/new-document/LOAD_FAIL";
const SEARCH_BY_CPF = "confia/new-document/SEARCH_BY_CPF";
const SEARCH_BY_CPF_SUCCESS = "confia/new-document/SEARCH_BY_CPF_SUCCESS";
const SEARCH_BY_CPF_FAIL = "confia/new-document/SEARCH_BY_CPF_FAIL";
const UPDATE_FORM_STATE = "confia/new-document/UPDATE_FORM_STATE";
const NOTIFY_SIGNERS = "confia/new-document/NOTIFY_SIGNERS";
const NOTIFY_SIGNERS_SUCCESS = "confia/new-document/NOTIFY_SIGNERS_SUCCESS";
const NOTIFY_SIGNERS_FAIL = "confia/new-document/NOTIFY_SIGNERS_FAIL";
const UPDATE_PART = "confia/new-document/UPDATE_PART";
const DOCUMENT_EDIT = "confia/new-document/DOCUMENT_EDIT";
const DOCUMENT_EDIT_FAIL = "confia/new-document/DOCUMENT_EDIT_FAIL";
const DOCUMENT_EDIT_SUCCESS = "confia/new-document/DOCUMENT_EDIT_SUCCESS";
const REMOVE_ALL_PART = "confia/new-document/REMOVE_ALL_PART";
const DOCUMENT_FINALIZE = "confia/new-document/DOCUMENT_FINALIZE";
const DOCUMENT_FINALIZE_SUCCESS = "confia/new-document/DOCUMENT_FINALIZE_SUCCESS";
const DOCUMENT_FINALIZE_FAIL = "confia/new-document/DOCUMENT_FINALIZE_FAIL";
const COMMITMENTS = "confia/new-document/COMMITMENTS";
const COMMITMENTS_SUCCESS = "confia/new-document/COMMITMENTS_SUCCESS";
const COMMITMENTS_FAIL = "confia/new-document/COMMITMENTS_FAIL";

export function unload() {
  return {
    type: UNLOAD,
  };
}

export function unloadNotify() {
  return {
    type: UNLOAD_NOTIFY,
  };
}

export function clearRedirectNotify() {
  return {
    type: CLEAR_REDIRECT_NOTIFY,
  };
}

export function load(companyId) {
  return {
    type: LOAD,
    payload: {
      request: {
        method: "get",
        url: `/api/balance/${companyId}`,
      },
    },
  };
}

export function searchByCPF(cpf) {
  return {
    type: SEARCH_BY_CPF,
    payload: {
      request: {
        method: "get",
        url: `/api/user/${encodeURI(cpf)}`,
      },
    },
  };
}

export function updateFormState(state) {
  return {
    type: UPDATE_FORM_STATE,
    payload: state,
  };
}

export function save(values) {
  console.log("SAVE-->", values);
  const formData = new FormData();

  for (var i = 0; i < values.files.length; i++) {
    formData.append("file[]", values.files[i]);
  }
  formData.append("parts", values.parts);
  formData.append("name", values.name);
  formData.append("type", values.type);
  formData.append("companyId", values.companyId);
  formData.append("userId", values.userId);
  formData.append("senderName", values.senderName);
  formData.append("metadata", values.metadata);
  formData.append("insuranceValue", values.insuranceValue);
  formData.append("validateDate", values.validateDate);

  return {
    type: SAVE,
    payload: {
      request: {
        method: "post",
        url: `/api/document/save`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      },
    },
  };
}

export function update(values) {
  const formData = new FormData();

  for (var i = 0; i < values.files.length; i++) {
    if (values.files[i].idBd) {
      formData.append("fileIds[]", values.files[i].idBd);
    } else {
      formData.append("file[]", values.files[i]);
    }
  }
  formData.append("parts", values.parts);
  formData.append("name", values.name);
  formData.append("type", values.type);
  formData.append("companyId", values.companyId);
  formData.append("userId", values.userId);
  formData.append("senderName", values.senderName);
  formData.append("metadata", values.metadata);
  formData.append("insuranceValue", values.insuranceValue);
  formData.append("validateDate", values.validateDate);
  formData.append("documentId", values.documentId);
  return {
    type: SAVE,
    payload: {
      request: {
        method: "put",
        url: `/api/document/save`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      },
    },
  };
}

export function addPart(part) {
  return {
    type: ADD_PART,
    payload: part,
  };
}

export function removePart(id) {
  return {
    type: REMOVE_PART,
    payload: id,
  };
}

export function updatePart(part) {
  return {
    type: UPDATE_PART,
    payload: part,
  };
}

export function removeAllParts() {
  return {
    type: REMOVE_ALL_PART,
  };
}

export function notifySigners(props) {
  return {
    type: NOTIFY_SIGNERS,
    payload: {
      request: {
        method: "post",
        url: `/api/document/notification/${props.companyId}`,
        data: { parts: props.parts },
      },
    },
  };
}

export function documentEdit(documentId) {
  return {
    type: DOCUMENT_EDIT,
    payload: {
      request: {
        method: "get",
        url: `/api/document/${documentId}`,
      },
    },
  };
}

export function finalize(documentId) {
  return {
    type: DOCUMENT_FINALIZE,
    payload: {
      request: {
        method: "post",
        url: `/api/document/finalize/${documentId}`,
        data: {},
      },
    },
  };
}

export function getCommitments() {
  return {
    type: COMMITMENTS,
    payload: {
      request: {
        method: "get",
        url: `/api/document/commitments/all`,
      },
    },
  };
}

const INITIAL_STATE = {
  error: false,
  errorMessage: null,
  alert: false,
  alertMessage: null,
  parts: [],
  balance: 0,
  part: null,
  signParts: [],
  documentType: null,
  documentForm: null,
  documentId: null,
  commitments: [],
  signPartsNotify: [],
};

// const INITIAL_STATE_NOTIFY = {
//   signPartsNotify: [],
// };

export default function NewDocument(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE:
      return {
        ...state,
        loading: true,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        documentId: action.payload.data.documentId,
      };
    case SAVE_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível enviar o documento.",
        error: true,
      };

    case DOCUMENT_FINALIZE:
      return {
        ...state,
        loading: true,
      };
    case DOCUMENT_FINALIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        //signParts: action.payload.data.users,
        signPartsNotify: action.payload.data.users,
        documentType: action.payload.data.documentType,
        newDocumentRedirectTo: "/company/notify-parts",
      };
    case DOCUMENT_FINALIZE_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível enviar o documento.",
        error: true,
      };

    case ADD_PART:
      return {
        ...state,
        parts: [...state.parts, action.payload],
      };
    case REMOVE_PART:
      return {
        ...state,
        parts: state.parts.filter((part) => part.id !== action.payload),
      };
    case REMOVE_ALL_PART:
      return {
        ...state,
        parts: [],
      };
    case UPDATE_PART:
      return {
        ...state,
        signParts: state.signParts.map((part) => {
          if (part.partCpf === action.payload.cpf) {
            part.notifyEmail = action.payload.notifyEmail;
            part.notifyWhatsapp = action.payload.notifyWhatsapp;
            part.notifySms = action.payload.notifySms;
          }

          return part;
        }),
      };

    case NOTIFY_SIGNERS:
      return {
        ...state,
        loading: true,
      };
    case NOTIFY_SIGNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        newDocumentRedirectTo: "/company/documents",
        alert: true,
        alertMessage: "Assinantes notificados com sucesso.",
      };
    case NOTIFY_SIGNERS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível notificar assinantes.",
        error: true,
      };

    case LOAD:
      return {
        ...state,
        loading: true,
        newDocumentRedirectTo: null,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        balance: action.payload.data.currentBalance ? action.payload.data.currentBalance.balance : 0,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar o saldo.",
        error: true,
      };

    case SEARCH_BY_CPF:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_BY_CPF_SUCCESS:
      return {
        ...state,
        loading: false,
        part: action.payload.data.user ? action.payload.data.user : null,
        documentForm: { ...state.documentForm, ...action.payload.data.user },
      };
    case SEARCH_BY_CPF_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível pesquisar pelo CPF.",
        error: true,
      };

    case UPDATE_FORM_STATE:
      return {
        ...state,
        documentForm: action.payload.values,
      };

    case DOCUMENT_EDIT_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar documento.",
        error: true,
      };

    case DOCUMENT_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        documentEdit: action.payload.data.document ? action.payload.data.document : null,
      };

    case COMMITMENTS_SUCCESS:
      return {
        ...state,
        commitments: action.payload.data && action.payload.data.values ? action.payload.data.values : null,
      };

    case COMMITMENTS_FAIL:
      return {
        ...state,
        errorMessage: "Não foi possível recuperar os comprometimentos.",
        error: true,
      };

    case CLEAR_REDIRECT_NOTIFY:
      return {
        ...state,
        newDocumentRedirectTo: null,
      };

    case UNLOAD:
      return {
        ...state,
        error: false,
        errorMessage: null,
        alert: false,
        alertMessage: null,
        parts: [],
        balance: 0,
        part: null,
        signParts: [],
        documentType: null,
        documentForm: null,
        documentId: null,
        commitments: [],
      };

    case UNLOAD_NOTIFY:
      return {
        ...state,
        newDocumentRedirectTo: null,
        signPartsNotify: [],
      };

    // initial state
    default:
      return state;
  }
}
