import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Input,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  Button,
  UncontrolledAlert,
  Modal,
} from "reactstrap";
import {Field} from 'react-final-form';
import NotificationAlert from 'react-notification-alert';

import TextField from 'components/Fields/TextField';
import FieldNormalize from 'components/Fields/Normalize/FieldNormalize';
import FieldValidation from 'components/Fields/Validation/FieldValidation';

// core components

class ChoosePlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalMoreInfo: false
    };
  }

  toggleModalInfo = () => {
    this.setState({
      modalMoreInfo: !this.state.modalMoreInfo
    });
  };

  isValidated = () => {

    var ret = true;

    if (!this.props.selectedPlan) {
      ret = false;
    }

    if (!ret) {
      var options = {};
      options = {
        place: 'tc',
        message: (
          <div>
            <div>
              É necessário escolher um plano para prosseguir.
            </div>
          </div>
        ),
        type: 'danger',
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7
      };
      this.refs.notificationAlert.notificationAlert(options);
    }

    return ret;

  };
  render() {
    return (
      <>
        <h5 className="info-text">
          ESCOLHA O PLANO QUE DESEJA CONTRATAR
        </h5>


        <NotificationAlert ref="notificationAlert" />

          <Modal
            isOpen={this.state.modalMoreInfo}
            toggle={this.toggleModalInfo}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.toggleModalInfo}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <h5 className="modal-title" id="myModalLabel">
                Recursos disponíveis em todos os planos?
              </h5>
            </div>
            <div className="modal-body">
              <div className="instruction">
                <Row>
                  <Col md="12">
                    <strong>1. Registro do processo com fé pública.</strong>
                    <p className="description">
                      Todo o processo de assinatura digital dos documentos é registrado em um dos 3500 cartórios de Registro de Títulos e Documentos e Pessoas Jurídicas do Brasil.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <strong>2. Validade e segurança jurídica.</strong>
                    <p className="description">
                      Além do processo registrado em cartório, nosso processo de assinatura digital está em conformidade com todas as normas e leis que regulam as assinaturas eletrônicas e digitais no Brasil.
                      MP 2.200-2, de 24 de agosto de 2001.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <strong>3. Assinatura digital qualificada.</strong>
                    <p className="description">
                      Todas as assinaturas são realizadas através de certificado digital.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <strong>4. Usuários ilimitados.</strong>
                    <p className="description">
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <strong>5. Personalização de notificações.</strong>
                    <p className="description">
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <strong>6. Avisos e notificações de assinaturas por SMS / E-mail.</strong>
                    <p className="description">
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <strong>7. Gestão de assinaturas.</strong>
                    <p className="description">
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <strong>8. Biblioteca jurídica.</strong>
                    <p className="description">
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <strong>9. Integração e compartilhamento de arquivos (redes sociais e drives de armazenamento).</strong>
                    <p className="description">
                    </p>
                  </Col>
                </Row>
              </div>
              <p>
                Possui alguma dúvida? Entre em contato conosco: <a href="mailto:someone@example.com">suporte@confia.net.br</a>
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              <Button
                className="btn-round"
                color="default"
                data-dismiss="modal"
                type="button"
                onClick={this.toggleModalInfo}
              >
                Ok!
              </Button>
            </div>
          </Modal>

        <Row className="justify-content-center">
          <Col md="12">
              <Row>
                <Col lg="4">
                  <Card className={this.props.selectedPlan === "plano_50_1000" ? "card-pricing text-white bg-dark" : "card-pricing"}>
                    <CardHeader>
                      <h6 className={this.props.selectedPlan === "plano_50_1000" ? "card-category text-white" : "card-category"} style={{textTransform: 'uppercase'}}>Até 50 documentos/mês</h6>
                    </CardHeader>
                    <CardBody>
                      <CardTitle tag="h3">R$ 1000,00 <br/> <div style={{fontSize: '0.5em',lineHeight: '0em'}}>por mês</div></CardTitle>
                      <ul>
                        <li>Preço unitário R$ 20,00</li>
                      </ul>
                    </CardBody>
                    <CardFooter>
                      <Button
                        className={this.props.selectedPlan === "plano_50_1000" ? "btn-round text-dark bg-light" : "btn-round"}
                        color="default"
                        href="#pablo"
                        onClick={e => {e.preventDefault(); this.props.choosePlan('plano_50_1000')}}
                      >
                        {this.props.selectedPlan === 'plano_50_1000' &&
                          <span className="btn-label">
                            <i className="nc-icon nc-check-2" />
                          </span>
                        }
                        {this.props.selectedPlan === "plano_50_1000" ? "SELECIONADO" : "SELECIONAR"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className={this.props.selectedPlan === "plano_100_1900" ? "card-pricing text-white bg-dark" : "card-pricing"}>
                    <CardHeader>
                      <h6 className={this.props.selectedPlan === "plano_100_1900" ? "card-category text-white" : "card-category"} style={{textTransform: 'uppercase'}}>Até 100 documentos/mês</h6>
                    </CardHeader>
                    <CardBody>
                      <CardTitle tag="h3">R$ 1900,00 <br/> <div style={{fontSize: '0.5em',lineHeight: '0em'}}>por mês</div></CardTitle>
                      <ul>
                        <li>Preço unitário R$ 19,00</li>
                      </ul>
                    </CardBody>
                    <CardFooter>
                      <Button
                        className={this.props.selectedPlan === "plano_100_1900" ? "btn-round text-dark bg-light" : "btn-round"}
                        color="default"
                        href="#pablo"
                        onClick={e => {e.preventDefault(); this.props.choosePlan('plano_100_1900')}}
                      >
                        {this.props.selectedPlan === 'plano_100_1900' &&
                          <span className="btn-label">
                            <i className="nc-icon nc-check-2" />
                          </span>
                        }
                        {this.props.selectedPlan === "plano_100_1900" ? "SELECIONADO" : "SELECIONAR"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className={this.props.selectedPlan === "plano_200_3600" ? "card-pricing text-white bg-dark" : "card-pricing"}>
                    <CardHeader>
                      <h6 className={this.props.selectedPlan === "plano_200_3600" ? "card-category text-white" : "card-category"} style={{textTransform: 'uppercase'}}>Até 200 documentos/mês</h6>
                    </CardHeader>
                    <CardBody>
                      <CardTitle tag="h3">R$ 3.600,00 <br/> <div style={{fontSize: '0.5em',lineHeight: '0em'}}>por mês</div></CardTitle>
                      <ul>
                        <li>Preço unitário R$ 18,00</li>
                      </ul>
                    </CardBody>
                    <CardFooter>
                      <Button
                        className={this.props.selectedPlan === "plano_200_3600" ? "btn-round text-dark bg-light" : "btn-round"}
                        color="default"
                        href="#pablo"
                        onClick={e => {e.preventDefault(); this.props.choosePlan('plano_200_3600')}}
                      >
                        {this.props.selectedPlan === 'plano_200_3600' &&
                          <span className="btn-label">
                            <i className="nc-icon nc-check-2" />
                          </span>
                        }
                        {this.props.selectedPlan === "plano_200_3600" ? "SELECIONADO" : "SELECIONAR"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className={this.props.selectedPlan === "plano_outro" ? "card-pricing text-white bg-dark" : "card-pricing"}>
                    <CardHeader>
                    </CardHeader>
                    <CardBody>
                      <CardTitle tag="h3">Empresas com mais de 200 documentos/mês</CardTitle>
                      <ul>
                        <li>Entraremos em contato com você.</li>
                      </ul>
                    </CardBody>
                    <CardFooter>
                      <Button
                        className={this.props.selectedPlan === "plano_outro" ? "btn-round text-dark bg-light" : "btn-round"}
                        color="default"
                        href="#pablo"
                        onClick={e => {e.preventDefault(); this.props.choosePlan('plano_outro')}}
                      >
                        {this.props.selectedPlan === 'plano_outro' &&
                          <span className="btn-label">
                            <i className="nc-icon nc-check-2" />
                          </span>
                        }
                        {this.props.selectedPlan === "plano_outro" ? "SELECIONADO" : "SELECIONAR"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  * Nossos planos são cobrados mensalmente, as assinaturas contratadas devem ser utilizadas até a data da próxima cobrança. Você também pode solicitar o cancelamento a qualquer momento entrando em contato pelo suporte@confia.net.br. Para mais informações consulte nosso <a href="https://www.confia.net.br/privacidade" target="_blank" className="btn-link text-muted m-0 p-0 btn btn-default">termo de uso</a>.
                </Col>
              </Row>
            </Col>

        </Row>
      </>
    );
  }
}

export default ChoosePlan;
