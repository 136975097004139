import React from "react";
import {connect} from 'react-redux';
import { Form, Field, FormSpy } from 'react-final-form';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  CardTitle,
} from "reactstrap";
import NotificationAlert from 'react-notification-alert';
import {Link} from "react-router-dom";
import ReactTable from "react-table";

import TextField from 'components/Fields/TextField';
import FieldNormalize from 'components/Fields/Normalize/FieldNormalize';
import FieldValidation from 'components/Fields/Validation/FieldValidation';
import {encryptStringWithXORtoHex} from 'Utils/Utils';

import {load, unload, updateFormState, saveCreditCard} from '../../reducers/PaymentMethods';

import CreditCard from '../Register/Card';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from '../Register/cardUtils';

class Profile extends React.Component {

  componentWillMount() {
    if (this.props.user) {
      this.props.load(this.props.company.companyId);
    }

  }

  componentWillUnmount() {
    this.props.unload();
  }

  onSubmit = async values => {
    console.log('SUBMIT:',values);

    this.props.saveCreditCard(this.props.company.companyId, values).then((v) => {
      if (v && v.type.includes('SUCCESS')) {
        var options = {};
        options = {
          place: 'tc',
          message: (
            <div>
              <div>
                Suas informações foram alteradas com sucesso.
              </div>
            </div>
          ),
          type: 'success',
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
      } else {
        var options = {};
        options = {
          place: 'tc',
          message: (
            <div>
              <div>
                Não foi possível adicionar o cartão de crédito.
              </div>
            </div>
          ),
          type: 'danger',
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    });

  };

  render() {
    var tableLength = 1;
    if (this.props.paymentMethodsList && this.props.paymentMethodsList.length > 0) {
      tableLength = this.props.paymentMethodsList.length;
      console.log('TABLE LENGTH->',tableLength,'-',this.props.paymentMethodsList.length);
    }
    return (
      <>
        <div className="content">
          <NotificationAlert ref="notificationAlert" />
          <Form
          onSubmit={this.onSubmit}
          initialValues={this.props.paymentMethodForm}
          validate={values => {
            const errors = {}
            for(var i in values.password) {
              if (+values.password[+i+1] == +values.password[i]+1 && +values.password[+i+2] == +values.password[i]+2) {
                errors.password = 'Não escolha números sequenciais';
              }
            }
            let regex = /.*(\d)\1{2}.*/;
            if (regex.test(values.password)) {
                errors.password = 'Não use dígitos repetidos';
            }
            if (values.password !== values.passwordConfirm) {
              console.log(values.password, values.passwordConfirm);
              errors.passwordConfirm = 'As senhas estão diferentes. Digite novamente.'
            }
            return errors;
          }}
          render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
            <form onSubmit={handleSubmit} id='paymentMethodForm'>
              <FormSpy onChange={state => {
                  this.props.updateFormState(state)
              }} />
              <Row>
                <Col md="12">
                  <Row>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Formas de Pagamento</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col className="text-center" md="12">
                            <ReactTable
                              data={this.props.paymentMethodsList}
                              filterable
                              columns={[
                                {
                                  Header: "",
                                  style: { 'whiteSpace': 'unset' },
                                  Cell: row => {
                                    if (row.original.id == this.props.defaultPaymentId) {
                                      return (
                                        <div>
                                          Ativo
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <div>
                                        </div>
                                      )
                                    }

                                  }
                                },
                                {
                                  Header: "Bandeira",
                                  style: { 'whiteSpace': 'unset' },
                                  Cell: row => {
                                    return (
                                      <div>
                                        {row.original.data.brand}
                                      </div>
                                    )
                                  }
                                },
                                {
                                  Header: "Cartão",
                                  style: { 'whiteSpace': 'unset' },
                                  Cell: row => {
                                    return (
                                      <div>
                                        {row.original.data.display_number}
                                      </div>
                                    )
                                  }
                                },
                                {
                                  Header: "Validade",
                                  style: { 'whiteSpace': 'unset' },
                                  Cell: row => {
                                    return (
                                      <div>
                                        {row.original.data.month}/{row.original.data.year}
                                      </div>
                                    )
                                  }
                                },
                              ]}
                              defaultPageSize={tableLength}
                              pageSize={tableLength}
                              showPaginationTop
                              showPaginationBottom={false}
                              showPageSizeOptions={false}
                              showPageJump={false}
                              previousText="ANTERIOR"
                              nextText="PRÓXIMO"
                              loadingText="Carregando..."
                              noDataText='Nenhuma informações encontrado'
                              pageText='Página'
                              ofText="de"
                              rowsText="linhas"
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight defaul-pagination"
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Adicionar Cartão</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row>
                        <Col className="mx-auto mb-3" lg="6">
                          <CreditCard
                              number={(this.props.paymentMethodForm && this.props.paymentMethodForm.cardNumber) ? this.props.paymentMethodForm.cardNumber : ''}
                              name={(this.props.paymentMethodForm && this.props.paymentMethodForm.cardName) ? this.props.paymentMethodForm.cardName : ''}
                              expiry={(this.props.paymentMethodForm && this.props.paymentMethodForm.cardValid) ? this.props.paymentMethodForm.cardValid : ''}
                              cvc={(this.props.paymentMethodForm && this.props.paymentMethodForm.cardCVC) ? this.props.paymentMethodForm.cardCVC : ''}

                            />
                        </Col>
                        <Col className="mx-auto " lg="6">
                          <Col className="mx-auto " md="12">
                            <Field name="cardNumber" placeholder="* Número do cartão" component={TextField} validate={FieldValidation.required} format={formatCreditCardNumber}/>
                          </Col>
                          <Col className="mx-auto " md="12">
                            <Field name="cardName" placeholder="* Nome impresso no cartão" component={TextField} validate={FieldValidation.required}/>
                          </Col>
                          <Col className="mx-auto " md="12">
                            <Field name="cardValid" placeholder="* Validade" component={TextField} validate={FieldValidation.required} format={formatExpirationDate} maxLength='5'/>
                          </Col>
                          <Col className="mx-auto " md="12">
                            <Field name="cardCVC" placeholder="* Código de segurança" component={TextField} validate={FieldValidation.required} format={formatCVC}/>
                          </Col>
                        </Col>
                        </Row>
                        <Row>
                          <Col m="6" className="text-right">
                            <Button className="btn-round" >
                              {"SALVAR"}
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  </Row>
                </Col>
              </Row>
            </form>
          )}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state.PaymentMethods,
  ...state.Auth,
});

function mapDispatchToProps(dispatch) {
  return {
    load: companyId => dispatch(load(companyId)),
    unload: () => dispatch(unload()),
    updateFormState: state => dispatch(updateFormState(state)),
    saveCreditCard: (companyId, values) => dispatch(saveCreditCard(companyId, values)),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
