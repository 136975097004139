const ERROR = "confia/register/ERROR";
const SEARCH_ADDRESS = "confia/register/SEARCH_ADDRESS";
const SEARCH_ADDRESS_SUCCESS = "confia/register/SEARCH_ADDRESS_SUCCESS";
const SEARCH_ADDRESS_FAIL = "confia/register/SEND_ADDRESS_FAIL";
const CHOOSE_PLAN = "confia/register/CHOOSE_PLAN";
const REGISTER = "confia/register/REGISTER";
export const REGISTER_SUCCESS = "confia/register/REGISTER_SUCCESS";
const REGISTER_FAIL = "confia/register/REGISTER_FAIL";
const UPDATE_FORM_STATE = "confia/register/UPDATE_FORM_STATE";
const UNLOAD = "confia/register/UNLOAD";
const SEARCH_BY_CPF = "confia/register/SEARCH_BY_CPF";
const SEARCH_BY_CPF_SUCCESS = "confia/register/SEARCH_BY_CPF_SUCCESS";
const SEARCH_BY_CPF_FAIL = "confia/register/SEARCH_BY_CPF_FAIL";

const INITIAL_STATE = { error: false, errorMessage: null, loading: false, selectedPlan: null, cpfExists: null, modalRegister: false };

export function unload() {
  return {
    type: UNLOAD,
  };
}

export function validationError(msg) {
  return {
    type: ERROR,
    payload: { msg: msg },
  };
}

export function searchAddress(cep) {
  return {
    type: SEARCH_ADDRESS,
    payload: {
      request: {
        method: "post",
        url: `/api/address/search/${cep}`,
      },
    },
  };
}

export function searchByCPF(cpf) {
  return {
    type: SEARCH_BY_CPF,
    payload: {
      request: {
        method: "get",
        url: `/api/existing-user/${encodeURI(cpf)}`,
      },
    },
  };
}

export function choosePlan(plan) {
  return {
    type: CHOOSE_PLAN,
    payload: { plan: plan },
  };
}

export function register(values) {
  return {
    type: REGISTER,
    payload: {
      request: {
        method: "post",
        url: `/api/register/`,
        data: values,
      },
    },
  };
}

export function updateFormState(state) {
  return {
    type: UPDATE_FORM_STATE,
    payload: state,
  };
}

export default function Register(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UNLOAD:
      return {
        state: INITIAL_STATE,
      };

    case ERROR:
      return {
        ...state,
        errorMessage: action.payload.msg ? action.payload.msg : "Campos obrigatórios não preenchidos",
        error: true,
      };

    case SEARCH_ADDRESS:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        address: action.payload.data.endereco ? action.payload.data.endereco : null,
        registerForm: { ...state.registerForm, ...action.payload.data.endereco },
        errorMessage: action.payload.data.endereco ? null : "O Cep digitado é inválido.",
        error: action.payload.data.endereco ? false : true,
      };
    case SEARCH_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível localizar seu endereço.",
        error: true,
      };

    case SEARCH_BY_CPF:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_BY_CPF_SUCCESS:
      return {
        ...state,
        loading: false,
        cpfExists: action.payload.data.user ? true : false,
        registerForm: { ...state.registerForm, ...action.payload.data.user },
      };
    case SEARCH_BY_CPF_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível pesquisar pelo CPF.",
        error: true,
      };

    case CHOOSE_PLAN:
      return {
        ...state,
        selectedPlan: action.payload.plan,
      };

    case UPDATE_FORM_STATE:
      return {
        ...state,
        registerForm: action.payload.values,
      };

    case REGISTER:
      return {
        ...state,
        loading: true,
        registerError: false,
        modalRegister: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        registerError: false,
        afterMessage: "Seus dados foram cadastrados com sucesso, em breve nossa equipe entrará em contato com você.",
      };
    case REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        afterMessage: "Ops, ocorreu um erro",
        errorMessage: "Ops, ocorreu um erro",
        registerError: true,
      };

    // initial state
    default:
      return state;
  }
}
