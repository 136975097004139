const UNLOAD = "confia/documents/UNLOAD";
const EXCEL = "confia/documents/EXCEL";
const LOAD = "confia/documents/LOAD";
const LOAD_DOC_NOT_SIGNED = "confia/documents/LOAD_DOC_NOT_SIGNED";
const LOAD_DOC_NOT_SIGNED_SUCCESS = "confia/documents/LOAD_DOC_NOT_SIGNED_SUCCESS";
const LOAD_DOC_NOT_SIGNED_FAIL = "confia/documents/LOAD_DOC_NOT_SIGNED_FAIL";
const LOAD_SUCCESS = "confia/documents/LOAD_SUCCESS";
const LOAD_FAIL = "confia/documents/LOAD_FAIL";
const LOAD_DETAILS = "confia/documents/LOAD_DETAILS";
const LOAD_DETAILS_SUCCESS = "confia/documents/LOAD_DETAILS_SUCCESS";
const LOAD_DETAILS_FAIL = "confia/documents/LOAD_DETAILS_FAIL";
const LOAD_SELFIE = "confia/documents/LOAD_SELFIE";
const LOAD_SELFIE_SUCCESS = "confia/documents/LOAD_SELFIE_SUCCESS";
const LOAD_SELFIE_FAIL = "confia/documents/LOAD_SELFIE_FAIL";
const LOAD_FILE = "confia/documents/LOAD_FILE";
const LOAD_FILE_SUCCESS = "confia/documents/LOAD_FILE_SUCCESS";
const LOAD_FILE_FAIL = "confia/documents/LOAD_FILE_FAIL";
const SEND_SMS = "confia/documents/SEND_SMS";
const SEND_SMS_SUCCESS = "confia/documents/SEND_SMS_SUCCESS";
const SEND_SMS_FAIL = "confia/documents/SEND_SMS_FAIL";
const REMOVE_FILE = "confia/documents/REMOVE_FILE";
const REMOVE_FILE_SUCCESS = "confia/documents/REMOVE_FILE_SUCCESS";
const REMOVE_FILE_FAIL = "confia/documents/REMOVE_FILE_FAIL";
const SAVE_DATES = "confia/documents/SAVE_DATES";
const SEARCH_INSURANCE = "confia/documents/SEARCH_INSURANCE";
const SEARCH_INSURANCE_SUCCESS = "confia/documents/SEARCH_INSURANCE_SUCCESS";
const SEARCH_INSURANCE_FAIL = "confia/documents/SEARCH_INSURANCE_FAIL";
const UPDATE_PART = "confia/documents/UPDATE_PART";
const ADD_SIGNPARTS = "confia/documents/ADD_SIGNPARTS";
const NOTIFY_SIGNERS = "confia/documents/NOTIFY_SIGNERS";
const NOTIFY_SIGNERS_SUCCESS = "confia/documents/NOTIFY_SIGNERS_SUCCESS";
const NOTIFY_SIGNERS_FAIL = "confia/documents/NOTIFY_SIGNERS_FAIL";
const UPDATE_PHONE_EMAIL = "confia/documents/UPDATE_PHONE_EMAIL";
const FILE_DOCUMENT = "confia/documents/FILE_DOCUMENT";
const FILE_DOCUMENT_SUCCESS = "confia/documents/FILE_DOCUMENT_SUCCESS";
const FILE_DOCUMENT_FAIL = "confia/documents/FILE_DOCUMENT_FAIL";
const REVERT_REFUSED = "confia/documents/REVERT_REFUSED";
const REVERT_REFUSED_SUCCESS = "confia/documents/REVERT_REFUSED_SUCCESS";
const REVERT_REFUSED_FAIL = "confia/documents/REVERT_REFUSED_FAIL";
const REVERT_SUSPENDED = "confia/documents/REVERT_SUSPENDED";
const REVERT_SUSPENDED_SUCCESS = "confia/documents/REVERT_SUSPENDED_SUCCESS";
const REVERT_SUSPENDED_FAIL = "confia/documents/REVERT_SUSPENDED_FAIL";
const LOAD_USERS = "confia/documents/LOAD_USERS";
const LOAD_USERS_SUCCESS = "confia/documents/LOAD_USERS_SUCCESS";
const LOAD_USERS_FAIL = "confia/documents/LOAD_USERS_FAIL";

export function load(props) {
  return {
    type: LOAD,
    payload: {
      request: {
        method: "post",
        url: `/api/document/company/${props.companyId}`,
        data: props.values,
      },
    },
  };
}

export function exportToExcel(props) {
  return {
    type: EXCEL,
    payload: {
      request: {
        method: "post",
        url: `/api/document/excel/company/${props.companyId}`,
        data: props.values,
        headers: {
          Accept: "application/vnd.ms-excel",
        },
        responseType: "blob",
      },
    },
  };
}

export function loadDetails(documentId) {
  return {
    type: LOAD_DETAILS,
    payload: {
      request: {
        method: "get",
        url: `/api/documents/details/${documentId}`,
      },
    },
  };
}

export function loadSelfieDetails(imageId) {
  return {
    type: LOAD_SELFIE,
    payload: {
      request: {
        method: "get",
        url: `/api/documents/details/image/${imageId}`,
      },
    },
  };
}

export function unload() {
  return {
    type: UNLOAD,
  };
}

export function loadFile(documentId, status) {
  return {
    type: LOAD_FILE,
    payload: {
      request: {
        method: "get",
        url: `/api/documents/file/${documentId}${status === 1 ? "/signed" : ""}`,
        headers: {
          Accept: "application/pdf",
        },
        responseType: "blob",
      },
    },
  };
}

export function removeFile(groupId) {
  return {
    type: REMOVE_FILE,
    payload: {
      request: {
        method: "delete",
        url: `/api/documents/${groupId}`,
      },
    },
  };
}

export function inactiveDocument(documentId) {
  return {
    type: FILE_DOCUMENT,
    payload: {
      request: {
        method: "put",
        url: `/api/document/inactive/${documentId}`,
      },
    },
  };
}

export function revertRefused(documentId) {
  return {
    type: REVERT_REFUSED,
    payload: {
      request: {
        method: "put",
        url: `/api/document/revertRefused/${documentId}`,
      },
    },
  };
}

export function revertSuspended(documentId) {
  return {
    type: REVERT_SUSPENDED,
    payload: {
      request: {
        method: "put",
        url: `/api/document/revertSuspended/${documentId}`,
      },
    },
  };
}

export function sendSms(documentId) {
  return {
    type: SEND_SMS,
    payload: {
      request: {
        method: "post",
        url: `api/document/${documentId}/send-sms`,
      },
    },
  };
}

export function saveDates(values) {
  return {
    type: SAVE_DATES,
    payload: { search: values },
  };
}

function propertyAssinanteFilter(value) {
  let documents = value;
  for (const i of documents) {
    let valor = "";
    i.parts.map((item) => {
      valor += `${item.name} (CPF: ${item.cpf} - ${item.status})`;
      return valor;
    });
    i.assinantesFilter = valor;
  }

  return documents;
}

export function searchInsurance(groupId) {
  return {
    type: SEARCH_INSURANCE,
    payload: {
      request: {
        method: "get",
        url: `/api/document/insurance/${groupId}`,
      },
    },
  };
}

export function updatePhoneEmail(part) {
  return {
    type: UPDATE_PHONE_EMAIL,
    payload: part,
  };
}

export function updatePart(part) {
  return {
    type: UPDATE_PART,
    payload: part,
  };
}

export function addSignParts(signParts) {
  return {
    type: ADD_SIGNPARTS,
    payload: signParts,
  };
}

export function notifySigners(props) {
  const signParts = props.parts.filter((part) => {
    return part.status !== "Assinado";
  });

  return {
    type: NOTIFY_SIGNERS,
    payload: {
      request: {
        method: "post",
        url: `/api/document/notification/${props.companyId}`,
        data: { parts: signParts },
      },
    },
  };
}
export function documentsNotSigned(props) {
  return {
    type: LOAD_DOC_NOT_SIGNED,
    payload: {
      request: {
        method: "post",
        url: `/api/document/company/${props.companyId}`,
        data: props.values,
      },
    },
  };
}

export function loadUsers(companyId) {
  return {
    type: LOAD_USERS,
    payload: {
      request: {
        method: "get",
        url: `/api/company/users/${companyId}`,
      },
    },
  };
}

const INITIAL_STATE = { documents: [], file: null, loadingModal: false, docDetails: {}, search: {}, signParts: [], selfie: [], users: [] };

export default function Documents(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_USERS:
      return {
        ...state,
      };
    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.data.users,
      };
    case LOAD_USERS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar os usuários.",
        error: true,
      };
    case LOAD:
      console.log(action.paylod);
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        //documents: documentsNotSigned(action.payload.data.documents),
        documents: propertyAssinanteFilter(action.payload.data.documents),
      };

    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar documentos.",
        error: true,
      };

    case LOAD_DETAILS:
      return {
        ...state,
        loadingModal: true,
      };
    case LOAD_DETAILS_SUCCESS:
      return {
        ...state,
        loadingModal: false,
        docDetails: action.payload.data.docDetails,
        selfie: [],
      };
    case LOAD_DETAILS_FAIL:
      return {
        ...state,
        loadingModal: false,
        error: true,
        errorMessage: "Não foi possível recuperar detalhes do documento.",
      };

    case LOAD_SELFIE:
      return {
        ...state,
        loading: false,
      };
    case LOAD_SELFIE_SUCCESS:
      return {
        ...state,
        loading: false,
        selfie: [...state.selfie, action.payload.data],
      };
    case LOAD_SELFIE_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar a imagem.",
        error: true,
      };

    case LOAD_FILE:
      return {
        ...state,
        loading: true,
      };
    case LOAD_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        file: action.payload.data,
      };
    case LOAD_FILE_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar as informações do usuário.",
        error: true,
      };

    case SEND_SMS:
      return {
        ...state,
        loading: true,
      };
    case SEND_SMS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEND_SMS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível notificar os não assinantes.",
        error: true,
      };

    case SAVE_DATES:
      return {
        ...state,
        search: action.payload.search,
      };

    case SEARCH_INSURANCE:
      return {
        ...state,
        loadingModal: true,
        loading: true,
      };
    case SEARCH_INSURANCE_SUCCESS:
      return {
        ...state,
        loadingModal: false,
        loading: false,
        insuranceData: action.payload.data.insuranceData,
      };
    case SEARCH_INSURANCE_FAIL:
      return {
        ...state,
        loadingModal: false,
        loading: false,
        error: true,
        errorMessage: "Não foi possível buscar dados do seguro.",
        insuranceData: null,
      };

    case UPDATE_PHONE_EMAIL:
      return {
        ...state,
        signParts: state.signParts.map((part) => {
          if (part.cpf === action.payload.part.cpf) {
            if (action.payload.type === 1) {
              part.phone = action.payload.value;
            } else if (action.payload.type === 2) {
              part.email = action.payload.value;
            }
          }

          return part;
        }),
      };

    case UPDATE_PART:
      return {
        ...state,
        signParts: state.signParts.map((part) => {
          if (part.cpf === action.payload.cpf) {
            part.notifyEmail = action.payload.notifyEmail;
            part.notifyWhatsapp = action.payload.notifyWhatsapp;
            part.notifySms = action.payload.notifySms;
          }

          return part;
        }),
      };

    case ADD_SIGNPARTS:
      return {
        ...state,
        signParts: action.payload,
      };

    case NOTIFY_SIGNERS:
      return {
        ...state,
        loading: true,
      };
    case NOTIFY_SIGNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: true,
        alertMessage: "Assinantes notificados com sucesso.",
      };
    case NOTIFY_SIGNERS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível notificar assinantes.",
        error: true,
      };
    case FILE_DOCUMENT:
      return {
        ...state,
        loading: true,
      };
    case FILE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: true,
        alertMessage: "Documento Arquivado com sucesso.",
      };
    case FILE_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        alert: true,
        alertMessage: "Não foi possível arquivar esse documento",
      };
    case REVERT_REFUSED:
      return {
        ...state,
        loading: true,
      };
    case REVERT_REFUSED_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: true,
        alertMessage: "Reversão de recusa realizada com sucesso.",
      };
    case REVERT_REFUSED_FAIL:
      return {
        ...state,
        loading: false,
        alert: true,
        alertMessage: "Não foi possível reverter a recusa do documento",
      };
    case REVERT_SUSPENDED: {
      return {
        ...state,
        loading: true,
      };
    }
    case REVERT_SUSPENDED_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: true,
        alertMessage: "Reversão de suspensão realizada com sucesso.",
      };
    case REVERT_SUSPENDED_FAIL:
      return {
        ...state,
        loading: false,
        alert: true,
        alertMessage: "Não foi possível reverter a suspensão do documento",
      };
    case UNLOAD:
      return {
        state: INITIAL_STATE,
      };

    case LOAD_DOC_NOT_SIGNED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_DOC_NOT_SIGNED_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.payload.data.documents.filter((doc) => doc.status === "Não Assinado"),
      };
    case LOAD_DOC_NOT_SIGNED_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar documentos.",
        error: true,
      };

    // initial state
    default:
      return state;
  }
}
