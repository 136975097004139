import React from "react";
import { Form, Field } from 'react-final-form';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  ButtonGroup
} from "reactstrap";

import TextField from 'components/Fields/TextField';
import SelectField from 'components/Fields/SelectField';
import FieldNormalize from 'components/Fields/Normalize/FieldNormalize';
import FieldValidation from 'components/Fields/Validation/FieldValidation';

class Cartorio extends React.Component {



  render() {
    return (
      <>
        <div className="content">
          <Form
          onSubmit={onSubmit}
          initialValues={{ stooge: 'larry', employed: true }}
          render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
            <form onSubmit={handleSubmit}>



              <div style={{position:'sticky',top:'0','z-index':'100'}}>
                <Row>
                  <Col m="4" className="text-left">
                      <Button size="sm">Cancelar</Button>
                  </Col>
                  <Col m="4" className="text-center">
                      <h6 className="title" style={{lineHeight: '44px',fontSize: '.85em'}}>DADOS CARTÓRIO</h6>
                  </Col>
                  <Col m="4" className="text-right">
                      <Button color="primary" size="sm">Salvar</Button>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <h5 className="title">Cartório</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="mx-auto" md="6">
                          <Field name="cns" label="CNS (Código Nacional de Serventia)" placeholder="Código Nacional de Serventia" component={TextField} required parse={FieldNormalize.CNS} validate={FieldValidation.required}/>
                        </Col>
                        <Col className="mx-auto" md="6">
                          <Field name="cnpj" label="CNPJ" placeholder="CNPJ" component={TextField} required parse={FieldNormalize.CNPJ} validate={FieldValidation.required}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-auto" md="12">
                            <Field name="razaoSocial" label="Razão Social" placeholder="Razão Social" component={TextField} required validate={FieldValidation.required}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-auto" md="12">
                            <Field name="emailCartorio" label="E-mail" placeholder="E-mail do cartório" component={TextField} type="email" required validate={FieldValidation.composeValidators(FieldValidation.required,FieldValidation.email)}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-auto" md="4">
                          <Field name="cep" label="CEP" placeholder="Código de Endereçamento Postal" component={TextField} type="number" parse={FieldNormalize.CEP} required validate={FieldValidation.required}/>
                        </Col>
                        <Col className="mx-auto" md="4">
                          <Field name="uf" label="UF" placeholder="UF" component={SelectField}
                            options={[{id:0, desc:'AC'},{id:1, desc:'DF'}]}
                            textField="desc" valueField="id"
                          />
                        </Col>
                        <Col className="mx-auto" md="4">
                          <Field name="cidade" label="Cidade" placeholder="Cidade" component={SelectField}
                            options={[{id:0, desc:'Brasília'}]}
                            textField="desc" valueField="id"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Field name="endereco" label="Endereço" placeholder="Endereço" component={TextField} required validate={FieldValidation.required}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-auto" md="4">
                          <Field name="numero" label="Numero" placeholder="Numero" component={TextField} required validate={FieldValidation.required}/>
                        </Col>
                        <Col className="mx-auto" md="4">
                          <Field name="complemento" label="Complemento" placeholder="Complemento" component={TextField} />
                        </Col>
                        <Col className="mx-auto" md="4">
                          <Field name="bairro" label="Bairro" placeholder="Endereço" component={TextField} required validate={FieldValidation.required}/>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <h5 className="title">Responsável</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="mx-auto" md="4">
                          <Field name="cpf" label="CPF" placeholder="CPF" component={TextField} required parse={FieldNormalize.CPF} validate={FieldValidation.required}/>
                        </Col>
                        <Col className="mx-auto" md="8">
                            <Field name="nome" label="Nome" placeholder="Nome" component={TextField} required validate={FieldValidation.required}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-auto" md="12">
                            <Field name="emailResponsavel" label="E-mail" placeholder="E-mail do responsável" component={TextField} type="email" required validate={FieldValidation.composeValidators(FieldValidation.required,FieldValidation.email)}/>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

              </Row>
            </form>
          )}
          />
        </div>
      </>
    );
  }
}

const onSubmit = async values => {
//  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2))
}

export default Cartorio;
