import React from 'react';
import Modal from 'react-bootstrap4-modal';
import { Link } from 'react-router-dom';
import {
    Col
  } from "reactstrap";

export const IdleTimeOutModal = ({showModal, handleClose, handleLogout, remainingTime}) => {

    return (
        <Modal visible={showModal} onHide={handleClose}>
            <form id="loginForm">
                <div className="modal-header">
                <div>
                    <h5> Sua sessão será finalizada por inatividade em <span id="time">00:30</span> segundos! Deseja permanecer no sistema?</h5>
                </div>
                </div>
                <div className="modal-footer">
                <Col m="4" className="text-right">
                    <Link className="btn btn-sm btn-success" onClick={handleClose}>Sim</Link>
                    <Link className="btn btn-sm btn-danger" onClick={handleLogout}>Não</Link>
                    </Col>
                </div>
            </form>
        </Modal>
    )
}