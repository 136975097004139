import moment from "moment";

const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

const required = (value) => (value ? undefined : "Campo obrigatório");
const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? "Endereço de email inválido" : undefined);

const name = (value) => {
  if (value) {
    value = value.trim();
    return value && !/^[a-zA-ZÀ-ÖØ-öø-ÿ]+((['. -][a-zA-ZÀ-ÖØ-öø-ÿ ])?[a-zA-ZÀ-ÖØ-öø-ÿ]*)*$/g.test(value)
      ? 'Formato de nome inválido. Certifique-se de não incluir caracteres como "," ou ";"'
      : undefined;
  }
};

const cpf = (value) => {
  if (value) {
    var soma = 0;
    var resto;
    var inputCPF = value.replace(/[^\d]/g, "");

    if (inputCPF == "00000000000") return "CPF inválido";
    var i = 0;
    for (i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(inputCPF.substring(9, 10))) return "CPF inválido";

    soma = 0;
    for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(inputCPF.substring(10, 11))) return "CPF inválido";
    return undefined;
  }
};

const cnpj = (value) => {
  if (value) {
    var cnpj = value.replace(/[^\d]+/g, "");

    if (cnpj == "") return "CNPJ inválido";

    if (cnpj.length != 14) return "CNPJ inválido";

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999"
    )
      return "CNPJ inválido";

    // Valida DVs
    var tamanho = cnpj.length - 2;
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return "CNPJ inválido";

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return "CNPJ inválido";

    return undefined;
  }
};

const date = (value) => {
  if (!value) return undefined;
  var matches = /^(\d{2})[-\/](\d{2})[-\/](\d{4})$/.exec(value);
  var matches2 = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/i.test(value);

  if (matches == null || !matches2) return "Data Inválida";

  var d = matches[1];
  var m = matches[2] - 1;
  var y = matches[3];
  var composedDate = new Date(y, m, d);

  if (composedDate.getDate() == d && composedDate.getMonth() == m && composedDate.getFullYear() == y) {
    var partesData = value.split("/");
    var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
    var hoje = new Date();
    hoje = new Date(hoje.getFullYear(), hoje.getUTCMonth(), hoje.getUTCDate());
    if (data < hoje) {
      return "Data Inválida";
    }

    return undefined;
  } else {
    return "Data Inválida";
  }
};

export default {
  composeValidators,
  required,
  email,
  cpf,
  cnpj,
  date,
  name,
};
