import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import FieldValidation from 'components/Fields/Validation/FieldValidation';
import TextField from 'components/Fields/TextField';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  ButtonGroup,
  ButtonDropdown,
  UncontrolledCollapse,
  Button,
  CardFooter,
  CardTitle,
  Table,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import { showAlert, closeAlert } from 'reducers/Notification';

import ReactTable from "react-table";
import ArchiveUpload from "components/CustomUpload/ArchiveUpload";
import { unload, addFile, removeFile, process, save, processList, removeLine } from 'reducers/NewNotification';
import NotificationAlert from 'react-notification-alert';
import FieldNormalize from 'components/Fields/Normalize/FieldNormalize';

const { v4: uuidv4 } = require('uuid');

class NewNotifications extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showCadastro: false
    };
  }

  componentWillUnmount() {
    this.props.unload();
  }

  addNotification = (values, form) =>{
    if(!values.nome || !values.cpf || !values.telefone || !values.email || !values.nrTitulo || !values.descTitulo || !values.atrasoTitulo || !values.valorTitulo){
      form.submit();
    }else {
      var notification = {
        ID: uuidv4(),
        NOME: values.nome,
        CPF: values.cpf,
        TELEFONE: values.telefone,
        EMAIL: values.email,
        'NUMERO DO TITULO': values.nrTitulo,
        'DESCRICAO DO TITULO': values.descTitulo,
        'ATRASO DO TITULO': values.atrasoTitulo,
        'VALOR DO TITULO': values.valorTitulo,
      }
      this.props.all.push(notification)
      form.change('nome', '');
      form.resetFieldState('nome');
      form.change('cpf', '');
      form.resetFieldState('cpf');
      form.change('telefone', '');
      form.resetFieldState('telefone');
      form.change('email', '');
      form.resetFieldState('email');
      form.change('nrTitulo', '');
      form.resetFieldState('nrTitulo');
      form.change('descTitulo', '');
      form.resetFieldState('descTitulo');
      form.change('atrasoTitulo', '');
      form.resetFieldState('atrasoTitulo');
      form.change('valorTitulo', '');
      form.resetFieldState('valorTitulo');
    }
  }

  save = () =>{
    this.props.save(this.props.all,this.props.company.companyId).then((res) => {
      if(res && res.type.includes('SUCCESS')){
        var options = {};
        options = {
          place: 'tc',
          message: (
            <div>
              <div>
                Avisos enviados com sucesso.
              </div>
            </div>
          ),
          type: 'success',
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
      }else{
        var options = {};
        options = {
          place: 'tc',
          message: (
            <div>
              <div>
                Não foi possível processar o arquivo.
            </div>
            </div>
          ),
          type: 'danger',
          icon: "fa fa-exclamation-triangle",
          autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    })
  }

  handleClickRemove = (id) => {
    this.props.closeAlert();
    this.props.removeLine(id);

  }

  render() {
    return (
      <>
        <div className="content">
        <NotificationAlert ref="notificationAlert" />
          <Form
            onSubmit={onSubmit}
            initialValues={{ stooge: 'larry', employed: true }}
            render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Row>
                    <Col m="4" className="text-left">
                      <Link size="sm" className="btn btn-sm" to="/company/dashboard">Cancelar</Link>
                      {!this.state.showCadastro &&
                        <Link className="btn btn-sm" onClick={() => { this.setState({ showCadastro: !this.state.showCadastro }) }}>Adicionar Aviso</Link>
                      }
                    </Col>
                    <Col m="4" className="text-center">
                      <h6 className="title" style={{ lineHeight: '44px', fontSize: '.85em' }}>Cadastrar Avisos Extrajudiciais</h6>
                    </Col>
                    <Col m="4" className="text-right">
                      <ArchiveUpload addFile={this.props.addFile} removeFile={this.props.removeFile} process={this.props.process}
                        labelButton="Importar Arquivo" typeAccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                      {this.props.all && this.props.all.length > 0 &&
                        <Link className="btn btn-sm" onClick={() => this.save()}>Processar registros</Link>
                      }
                    </Col>
                  </Row>
                  {this.state.showCadastro &&
                    <Row>
                      <Col className="text-center" md="12">
                        <Card className="card-tasks">
                          <CardHeader>
                            <CardTitle tag="h4">Adicionar Aviso Extrajudicial</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col md="3" sm="4">
                                <Field name="nome" label="Nome" validate={FieldValidation.required} component={TextField} />
                              </Col>
                              <Col md="3" sm="4">
                                <Field name="cpf" label="CPF/CNPJ" required component={TextField} parse={FieldNormalize.CPF_CNPJ} validate={FieldValidation.composeValidators(FieldValidation.cpf)} />
                              </Col>
                              <Col md="3" sm="4">
                                <Field name="telefone" label="Celular" validate={FieldValidation.required} component={TextField} parse={FieldNormalize.CELULAR} />
                              </Col>
                              <Col md="3" sm="4">
                                <Field name="email" label="E-mail" validate={FieldValidation.required} component={TextField} validate={FieldValidation.composeValidators(FieldValidation.email)} />
                              </Col>
                              <Col md="3" sm="4">
                                <Field name="nrTitulo" label="Número do título" validate={FieldValidation.required} component={TextField} />
                              </Col>
                              <Col md="3" sm="4">
                                <Field name="descTitulo" label="Descrição do título" validate={FieldValidation.required} component={TextField} />
                              </Col>
                              <Col md="3" sm="4">
                                <Field name="atrasoTitulo" label="Data de atraso do título" validate={FieldValidation.required} component={TextField} maxLength='500' />
                              </Col>
                              <Col md="3" sm="4">
                                <Field name="valorTitulo" label="Valor do título" validate={FieldValidation.required} component={TextField} parse={FieldNormalize.PERCENT_INPUT_FORMAT} />
                              </Col>
                              <Col m="4" className="text-right">
                                {this.state.showCadastro &&
                                  <Link className="btn btn-sm" onClick={() => { this.setState({ showCadastro: !this.state.showCadastro }) }}>Cancelar</Link>
                                }
                                <Link className="btn btn-sm" onClick={() => this.addNotification(values, form)}>Adicionar</Link>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  }
                </div>

                <Row>
                  <Col className="text-center" md="12">
                    <Card className="card-tasks">
                      <CardBody>
                        <ReactTable
                          data={this.props.all}
                          filterable
                          columns={[
                            {
                              Header: "Nome",
                              style: { 'white-space': 'unset' },
                              accessor: "NOME"
                            },
                            {
                              Header: "CPF/CNPJ",
                              style: { 'white-space': 'unset' },
                              accessor: "CPF"
                            },
                            {
                              Header: "Telefone",
                              style: { 'white-space': 'unset' },
                              accessor: "TELEFONE"
                            },
                            {
                              Header: "E-mail",
                              accessor: "EMAIL",
                              style: { 'white-space': 'unset' },
                            },
                            {
                              Header: "Número do título",
                              accessor: "NUMERO DO TITULO"
                            },
                            {
                              Header: "Descrição do título",
                              accessor: "DESCRICAO DO TITULO",
                              style: { 'white-space': 'unset' },
                            },
                            {
                              Header: "Data de atraso do título",
                              accessor: "ATRASO DO TITULO",
                              style: { 'white-space': 'unset' },
                            },
                            {
                              Header: "Valor do título",
                              accessor: "VALOR DO TITULO"
                            },
                            {
                              Header: "Ações",
                              sortable: false,
                              filterable: false,
                              width: 50,
                              Cell: row => {
                                return (
                                  <div className="actions-right">
                                      <Button
                                        color="default"
                                        size="sm"
                                        className="btn-icon btn-link edit"
                                        onClick={() => this.props.showAlert({
                                          type: 'danger',
                                          title: 'Você realmente quer excluir esse registro?',
                                          showCancel: true,
                                          onCancel: this.props.closeAlert,
                                          confirmBtnBsStyle: 'danger',
                                          cancelBtnBsStyle: 'secondary',
                                          onConfirm: () => this.handleClickRemove(row.index),
                                          confirmBtnText: "Excluir",
                                          cancelBtnText: "Cancelar",
                                        })}
                                      >
                                        <i className="fa fa-close" />
                                      </Button>{" "}
                                    </div>
                                 )
                              }
                            }
                          ]}
                          defaultPageSize={10}
                          showPaginationTop
                          showPaginationBottom={false}
                          showPageSizeOptions={false}
                          showPageJump={false}
                          previousText="ANTERIOR"
                          nextText="PRÓXIMO"
                          loadingText="Carregando..."
                          noDataText='Nenhum aviso encontrado'
                          pageText='Página'
                          ofText="de"
                          rowsText="linhas"
                          /*
                            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                          */
                          className="-striped -highlight defaul-pagination"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </form>
            )}
          />
        </div>
      </>
    );
  }
}

const onSubmit = async values => {
}

const mapStateToProps = state => ({
  ...state.NewNotification,
  ...state.Auth
});

function mapDispatchToProps(dispatch) {
  return {
    unload: () => dispatch(unload()),
    addFile: file => dispatch(addFile(file)),
    removeFile: () => dispatch(removeFile()),
    save: (values,companyId) => dispatch(save(values,companyId)),
    process: values => dispatch(process(values)),
    processList: values => dispatch(processList(values)),
    showAlert: alertProps => dispatch(showAlert(alertProps)),
    closeAlert: () => dispatch(closeAlert()),
    removeLine: id => dispatch(removeLine(id))
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(NewNotifications);
