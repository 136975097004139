import React from "react";
import { Form, FormSpy } from "react-final-form";

import { connect } from "react-redux";
import { Container, Col, Row, Modal, ModalHeader, ModalBody, Spinner, Button } from "reactstrap";
import ReactWizard from "react-bootstrap-wizard";
import { Link, Redirect } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

import { encryptStringWithXORtoHex } from "Utils/Utils";
import NotificationAlert from "react-notification-alert";

import PersonalData from "./PersonalData";
import CompanyData from "./CompanyData";
import CompanyAddress from "./CompanyAddress";
import ChoosePlan from "./ChoosePlan";
import Payment from "./Payment";

import { validationError, searchAddress, choosePlan, register, updateFormState, unload } from "reducers/Register";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      redirectToIndex: false,
    };
  }
  componentDidMount() {
    document.body.classList.toggle("register-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
    this.props.unload();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.registerError) {
      if (this.props.registerError !== nextProps.registerError) {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>Ocorreu um erro, tente mais tarde.</div>
            </div>
          ),
          type: "danger",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    }
  }

  onSubmit = async (values) => {
    console.log("SUBMIT:", values);
  };

  render() {
    return (
      <div className="register-page">
        <NotificationAlert ref="notificationAlert" />
        <Container>
          <Modal isOpen={this.props.modalRegister} size="md">
            <ModalHeader>
              <p>Olá, Seja Bem-Vindo!</p>
            </ModalHeader>
            <ModalBody className="text-center">
              {this.props.loading ? (
                <Spinner>Aguarde um instante, estamos processando as informações ...</Spinner>
              ) : (
                <>
                  <p>
                    <strong>{this.props.afterMessage}</strong>
                  </p>
                  <Link className="btn btn-sm" to="/">
                    OK
                  </Link>
                </>
              )}
            </ModalBody>
          </Modal>
          <Row>
            <Col className="ml-auto mr-auto" lg="12" md="12">
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <div className="col-md-8 ml-auto mr-auto">
                  <img alt="..." src={require("stylesheets/img/logo/logoConfiaEmpresas.png")} />
                </div>
              </Col>
              <Form
                onSubmit={this.onSubmit}
                initialValues={this.props.registerForm}
                render={({ handleSubmit, form, submitting, pristine, values, validating, valid, errors }) => (
                  <form onSubmit={handleSubmit} id="registerForm">
                    <FormSpy
                      onChange={(state) => {
                        this.props.updateFormState(state);
                      }}
                    />
                    <Col className="mr-auto ml-auto" md="10">
                      <ReactWizard
                        steps={[
                          {
                            stepName: "Empresa",
                            stepIcon: "nc-icon nc-bank",
                            component: CompanyData,
                            stepProps: {
                              values: values,
                              error: this.props.error,
                              errorMessage: this.props.errorMessage,
                              validationError: this.props.validationError,
                            },
                          },
                          {
                            stepName: "Seus Dados",
                            stepIcon: "nc-icon nc-single-02",
                            component: PersonalData,
                            stepProps: {
                              values: values,
                              error: this.props.error,
                              errorMessage: this.props.errorMessage,
                              validationError: this.props.validationError,
                              searchByCPF: this.props.searchByCPF,
                              cpfExists: this.props.cpfExists,
                            },
                          },
                          {
                            stepName: "Endereço",
                            stepIcon: "nc-icon nc-pin-3",
                            component: CompanyAddress,
                            stepProps: {
                              values: values,
                              error: this.props.error,
                              errorMessage: this.props.errorMessage,
                              validationError: this.props.validationError,
                              searchAddress: this.props.searchAddress,
                              //By pass por ter removido a aba de plano
                              choosePlan: this.props.choosePlan,
                            },
                          },
                          // {
                          //   stepName: "Escolha o Plano",
                          //   stepIcon: "nc-icon nc-bullet-list-67",
                          //   component: ChoosePlan,
                          //   stepProps: {
                          //     values: values,
                          //     error: this.props.error,
                          //     errorMessage: this.props.errorMessage,
                          //     validationError: this.props.validationError,
                          //     choosePlan: this.props.choosePlan,
                          //     selectedPlan: this.props.selectedPlan,
                          //   },
                          // },
                          {
                            stepName: "Finalizar",
                            stepIcon: "nc-icon nc-credit-card",
                            component: Payment,
                            stepProps: {
                              values: values,
                              error: this.props.error,
                              errorMessage: this.props.errorMessage,
                              validationError: this.props.validationError,
                              selectedPlan: this.props.selectedPlan,
                            },
                          },
                        ]}
                        navSteps
                        validate={true}
                        title="CRIE SUA CONTA"
                        description="Informe os dados da sua empresa para se cadastrar."
                        headerTextCenter
                        finishButtonClasses="btn-wd"
                        nextButtonClasses="btn-wd"
                        previousButtonClasses="btn-wd"
                        nextButtonText="PRÓXIMO"
                        previousButtonText="ANTERIOR"
                        finishButtonText="ENVIAR"
                        finishButtonClick={async (v) => {
                          console.log("FINISH", this.props.registerForm);
                          var val = this.props.registerForm;
                          val.selectedPlan = this.props.selectedPlan;
                          this.props.register(val);
                        }}
                      />
                    </Col>
                  </form>
                )}
              />
              <Col className="mr-auto ml-auto" md="10">
                <Link color="default" to="/" className="btn mb-0 mt-0">
                  Voltar
                </Link>
              </Col>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("stylesheets/img/bg/bgEmpresas.jpeg")})`,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Register,
});

function mapDispatchToProps(dispatch) {
  return {
    validationError: (values) => dispatch(validationError(values)),
    searchAddress: (cep) => dispatch(searchAddress(cep)),
    choosePlan: (plan) => dispatch(choosePlan(plan)),
    register: (values) => dispatch(register(values)),
    updateFormState: (state) => dispatch(updateFormState(state)),
    unload: () => dispatch(unload()),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
