const LOAD = "confia/single-purchase/LOAD";
const GENERATE_CODE = "confia/single-purchase/GENERATE_CODE";
const GENERATE_CODE_SUCCESS = "confia/single-purchase/GENERATE_CODE_SUCCESS";
const GENERATE_CODE_FAIL = "confia/single-purchase/GENERATE_CODE_FAIL";
const VALIDATE_CODE = "confia/single-purchase/VALIDATE_CODE";
const VALIDATE_CODE_SUCCESS = "confia/single-purchase/VALIDATE_CODE_SUCCESS";
const VALIDATE_CODE_FAIL = "confia/single-purchase/VALIDATE_CODE_FAIL";
const SAVE_DOCUMENT = "confia/single-purchase/SAVE_DOCUMENT";
const SAVE_DOCUMENT_SUCCESS = "confia/single-purchase/SAVE_DOCUMENT_SUCCESS";
const SAVE_DOCUMENT_FAIL = "confia/single-purchase/SAVE_DOCUMENT_FAIL";
const ADD_PART_DOCUMENT = "confia/single-purchase/ADD_PART_DOCUMENT";
const REMOVE_PART_DOCUMENT = "confia/single-purchase/REMOVE_PART_DOCUMENT";
const LOGIN_TOKEN_DOCUMENT = "confia/single-purchase/LOGIN_TOKEN_DOCUMENT";
const LOGIN_TOKEN_DOCUMENT_SUCCESS = "confia/single-purchase/LOGIN_TOKEN_DOCUMENT_SUCCESS";
const LOGIN_TOKEN_DOCUMENT_FAIL = "confia/single-purchase/LOGIN_TOKEN_DOCUMENT_FAIL";
const PRICE_RANGE = "confia/single-purchase/PRICE_RANGE";
const PRICE_RANGE_SUCCESS = "confia/single-purchase/PRICE_RANGE_SUCCESS";
const PRICE_RANGE_FAIL = "confia/single-purchase/PRICE_RANGE_FAIL";
const PAYMENT = "confia/single-purchase/PAYMENT";
const PAYMENT_SUCCESS = "confia/single-purchase/PAYMENT_SUCCESS";
const PAYMENT_FAIL = "confia/single-purchase/PAYMENT_FAIL";
const NOTIFY_SIGNERS = "confia/single-purchase/NOTIFY_SIGNERS";
const NOTIFY_SIGNERS_SUCCESS = "confia/single-purchase/NOTIFY_SIGNERS_SUCCESS";
const NOTIFY_SIGNERS_FAIL = "confia/single-purchase/NOTIFY_SIGNERS_FAIL";
const UPDATE_PART = "confia/single-purchase/UPDATE_PART";
const UNLOAD_NOTIFY = "confia/single-purchase/UNLOAD_NOTIFY";
const UNLOAD_NEW_DOCUMENT = "confia/single-purchase/UNLOAD_NEW_DOCUMENT";

export function loginToken() {
  return {
    type: LOGIN_TOKEN_DOCUMENT,
    payload: {
      request: {
        method: "post",
        url: `/api/singlePurchase/token`,
        data: {},
      },
    },
  };
}

export function getParamsUrl(params) {
  let urlSinglePurchase = new URLSearchParams(window.location.search);
  let typeUrl = urlSinglePurchase.get(params);
  return {
    type: LOAD,
    typeUrl: typeUrl,
  };
}

export function generateCode(props) {
  return {
    type: GENERATE_CODE,
    payload: {
      request: {
        method: "post",
        url: "/api/singlePurchase/generateCode",
        data: props,
      },
    },
  };
}

export function validateCode(props) {
  return {
    type: VALIDATE_CODE,
    payload: {
      request: {
        method: "post",
        url: "/api/singlePurchase/validateCode",
        data: props,
      },
    },
  };
}

export function save(values) {
  const formData = new FormData();
  for (var i = 0; i < values.files.length; i++) {
    formData.append("file[]", values.files[i]);
  }
  formData.append("parts", values.parts);
  formData.append("name", values.name);
  formData.append("type", values.type);
  formData.append("companyId", values.companyId);
  formData.append("userId", values.userId);
  formData.append("senderName", values.senderName);
  formData.append("metadata", values.metadata);

  return {
    type: SAVE_DOCUMENT,
    payload: {
      request: {
        method: "post",
        url: `/api/document/`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      },
    },
  };
}

export function addPart(part) {
  return {
    type: ADD_PART_DOCUMENT,
    payload: part,
  };
}

export function removePart(id) {
  return {
    type: REMOVE_PART_DOCUMENT,
    payload: id,
  };
}

export function priceRange(companyId) {
  return {
    type: PRICE_RANGE,
    payload: {
      request: {
        method: "get",
        url: `/api/singlePurchase/company/${companyId}/priceRange/`,
      },
    },
  };
}

export function payment(values) {
  return {
    type: PAYMENT,
    payload: {
      request: {
        method: "post",
        url: "/api/singlePurchase/payment",
        data: values,
      },
    },
  };
}

export function updatePart(part) {
  return {
    type: UPDATE_PART,
    payload: part,
  };
}

export function notifySigners(props) {
  return {
    type: NOTIFY_SIGNERS,
    payload: {
      request: {
        method: "post",
        url: `/api/document/notification/${props.companyId}`,
        data: { parts: props.parts },
      },
    },
  };
}

export function unloadNotify() {
  return {
    type: UNLOAD_NOTIFY,
  };
}

export function unloadNewDocument() {
  return {
    type: UNLOAD_NEW_DOCUMENT,
  };
}

function calculeAmount(state, add) {
  console.log(state);
  var signatureAmount = state.priceRangeData ? state.priceRangeData.signatureAmount : 4;
  if (add) {
    var numberOfCharges = Math.ceil((state.parts.length + 1) / signatureAmount);
  } else {
    var numberOfCharges = Math.ceil((state.parts.length - 1) / signatureAmount);
  }
  var result = (numberOfCharges * state.priceRangeData.price).toString();
  return result;
}

const INITIAL_STATE = { typeUrl: null, email: null, error: false, errorMessage: null, successMessage: null, parts: [], signParts: [], documentType: null, amount: "0" };

export default function SinglePurchase(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        typeUrl: action.typeUrl,
      };
    case GENERATE_CODE:
      return {
        ...state,
        loading: true,
        error: false,
        email: action.payload.request.data.email,
      };
    case GENERATE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GENERATE_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: "Não foi possível realizar a operação.",
      };
    case LOGIN_TOKEN_DOCUMENT:
    case VALIDATE_CODE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOGIN_TOKEN_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        userToken: action.payload.data.login.token,
        refreshToken: action.payload.data.login.refresh,
        company: action.payload.data.login.company,
        user: action.payload.data.login.user,
        redirectToSingle: false,
      };
    case VALIDATE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessageCode: null,
        userToken: action.payload.data.login.token,
        refreshToken: action.payload.data.login.refresh,
        company: action.payload.data.login.company,
        user: action.payload.data.login.user,
        redirectToSingle: false,
        redirectTo: state.typeUrl ? `/singlePurchase/documents?type=${state.typeUrl}` : "/singlePurchase/documents",
      };
    case LOGIN_TOKEN_DOCUMENT_FAIL:
    case VALIDATE_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessageCode: action.payload.error.includes("401") ? "Código Expirado." : action.payload.error.includes("404") ? "Código Inválido." : "Não foi possível realizar a operação.",
        redirectToSingle: action.payload.error.includes("403") || action.payload.error.includes("500") ? true : false,
      };

    case SAVE_DOCUMENT:
      return {
        ...state,
        loading: true,
      };
    case SAVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        signParts: action.payload.data.users,
        documentType: action.payload.data.documentType,
        redirectToNotify: true,
      };
    case SAVE_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível enviar o documento.",
        error: true,
      };

    case ADD_PART_DOCUMENT:
      return {
        ...state,
        parts: [...state.parts, action.payload],
        amount: calculeAmount(state, true),
      };
    case REMOVE_PART_DOCUMENT:
      return {
        ...state,
        parts: state.parts.filter((part) => part.id !== action.payload),
        amount: calculeAmount(state),
      };
    case PRICE_RANGE:
      return {
        ...state,
        loading: true,
      };
    case PRICE_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        priceRangeData: action.payload.data.priceRange,
      };
    case PRICE_RANGE_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível carregar os valores.",
      };

    case PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        //redirectTo: "/singlePurchase",
      };
    case PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        errorMessagePayment: "Erro ao realizar cobrança no cartão.",
        error: false,
      };

    case UPDATE_PART:
      return {
        ...state,
        signParts: state.signParts.map((part) => {
          if (part.partCpf === action.payload.cpf) {
            part.notifyEmail = action.payload.notifyEmail;
            part.notifyWhatsapp = action.payload.notifyWhatsapp;
            part.notifySms = action.payload.notifySms;
          }

          return part;
        }),
      };

    case NOTIFY_SIGNERS:
      return {
        ...state,
        loading: true,
      };
    case NOTIFY_SIGNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        redirectToSingle: true,
        alert: true,
        alertMessage: "Assinantes notificados com sucesso.",
      };
    case NOTIFY_SIGNERS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível notificar assinantes.",
        error: true,
      };
    case UNLOAD_NOTIFY:
      return {
        ...state,
        signParts: [],
        documentType: null,
        redirectToNotify: false,
      };
    case UNLOAD_NEW_DOCUMENT:
      return {
        ...state,
        parts: [],
        amount: "0",
      };
    default:
      return state;
  }
}
