const LOGIN = "confia/auth/LOGIN";
const LOGIN_SUCCESS = "confia/auth/LOGIN_SUCCESS";
const LOGIN_FAIL = "confia/auth/LOGIN_FAIL";
const LOGIN_WITH_IDRC = "confia/auth/LOGIN_WITH_IDRC";
const LOGIN_WITH_IDRC_SUCCESS = "confia/auth/LOGIN_WITH_IDRC_SUCCESS";
const LOGIN_WITH_IDRC_FAIL = "confia/auth/LOGIN_WITH_IDRC_FAIL";
const NEW_PASSWORD = "confia/auth/NEW_PASSWORD";
const NEW_PASSWORD_SUCCESS = "confia/auth/NEW_PASSWORD_SUCCESS";
const NEW_PASSWORD_FAIL = "confia/auth/NEW_PASSWORD_FAIL";
const LOGIN_TOKEN = "confia/auth/LOGIN_TOKEN";
const LOGIN_TOKEN_SUCCESS = "confia/auth/LOGIN_TOKEN_SUCCESS";
const LOGIN_TOKEN_FAIL = "confia/auth/LOGIN_TOKEN_FAIL";
const SELECT_COMPANY = "confia/auth/SELECT_COMPANY";
const PING = "confia/auth/PING";

const INITIAL_STATE = { redirectTo: null, role: "", successMessage: null, errorMessage: null, company: null, companies: [], redirectLogin: false, authenticationEnabled: {} };

function getRoleString(role) {
  switch (role) {
    case 1:
      return "admin";
    case 2:
      return "oper";
    case 5:
      return "supervisor";
    // Adicione mais cases conforme necessário para mapear outros papéis a strings
    // Exemplo: case 3: return "manager";
    default:
      return "";
  }
}

export function ping() {
  return {
    type: PING,
    payload: {
      request: {
        method: "get",
        url: `/api/status`,
      },
    },
  };
}

export function login(values) {
  return {
    type: LOGIN,
    payload: {
      request: {
        method: "post",
        url: `/api/auth/login`,
        data: values,
      },
    },
  };
}

export function loginWithIDRC(code) {
  return {
    type: LOGIN_WITH_IDRC,
    payload: {
      request: {
        method: "post",
        url: `/api/auth/idrc`,
        data: { code: code },
      },
    },
  };
}

export function selectCompany(company) {
  return {
    type: SELECT_COMPANY,
    payload: { company: company },
  };
}

export function loginToken() {
  return {
    type: LOGIN_TOKEN,
    payload: {
      request: {
        method: "post",
        url: `/api/auth/token`,
        data: {},
      },
    },
  };
}

export function newPassword(values) {
  return {
    type: NEW_PASSWORD,
    payload: {
      request: {
        method: "post",
        url: `/api/auth/newPasswordEmail`,
        data: values,
      },
    },
  };
}

export default function Auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_TOKEN:
    case LOGIN:
    case LOGIN_WITH_IDRC:
      return {
        ...state,
        loading: true,
        error: false,
        message: false,
      };
    case LOGIN_TOKEN_SUCCESS:
    case LOGIN_WITH_IDRC_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userToken: action.payload.data.login.token,
        refreshToken: action.payload.data.login.refresh,
        errorMessage: null,
        successMessage: null,
        cpf: action.payload.data.login.cpf,
        company: action.payload.data.login.companies.length === 1 ? action.payload.data.login.companies[0] : null,
        role: action.payload.data.login.companies.length === 1 && getRoleString(action.payload.data.login.companies[0].roleId),
        user: action.payload.data.login.user,
        companies: action.payload.data.login.companies,
        redirectTo: "/company/dashboard",
        redirectLogin: false,
        authenticationEnabled: action.payload.data.login.authenticationEnabled, // números de contratadas da empresa
      };

    case SELECT_COMPANY:
      return {
        ...state,
        company: action.payload.company,
        // role: action.payload.company.roleId === 1 ? "admin" : "oper", // Lógica anterior errada
        role: getRoleString(action.payload.company.roleId),
      };

    case LOGIN_TOKEN_FAIL:
    case LOGIN_WITH_IDRC_FAIL:
      return {
        ...state,
        loading: false,
        userToken: null,
        refreshToken: null,
        cpf: null,
        errorMessage: action.payload.errorMessageResponse.includes("Usuário não possui empresas associadas.")
          ? "O CPF informado não possui permissão para acessar a aplicação!"
          : "Usuário ou senha inválidos.",
        error: true,
        redirectLogin: true,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        userToken: null,
        refreshToken: null,
        cpf: null,
        errorMessage: action.payload.error.includes("401")
          ? "Seu acesso foi bloqueado, entre em contato com o administrador da sua empresa para ser desbloqueado ou clique em esqueci minha senha."
          : "Usuário ou senha inválidos.",
        error: true,
        redirectLogin: true,
      };

    case NEW_PASSWORD:
      return {
        ...state,
        loading: true,
        message: false,
      };
    case NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: action.payload.data.message,
        message: true,
      };
    case NEW_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "CPF inválido.",
        error: true,
      };

    // initial state
    default:
      return state;
  }
}
