//Paginas do Cartorio
import Cartorio from "containers/Cartorio/Cartorio.js";
import DashboardCartorio from "containers/Dashboard/DashboardCartorio.js";
import RegistrarDocumentos from "containers/Documentos/RegistrarDocumentos";
import DetalhesEmolumentos from "containers/Emolumentos/DetalhesEmolumentos";
import DetalheDocumento from "containers/Documentos/DetalheDocumento";

//Paginas Empresa
import Dashboard from "containers/Dashboard/Dashboard.js";
import Documents from "./containers/Document/Documents";
import Authentications from "./containers/Authentication/Authentications";
import Users from "./containers/User/Users";
import NewDocument from "./containers/Document/NewDocument";
import NewUser from "./containers/User/NewUser";
import Profile from "./containers/Profile/Profile";
import PaymentMethods from "./containers/PaymentMethods/PaymentMethods";
import ChangePlan from "./containers/ChangePlan/ChangePlan";
import NewNotification from "./containers/Notification/NewNotification";
import ListNotifications from "./containers/Notification/ListNotifications";
import NotifyParts from "./containers/Document/NotifyParts";
import FinancialReport from "./containers/Report/FinancialReport";

const routes = [
  {
    path: "/dashboard",
    name: "Painel Gerencial",
    icon: "fa fa-th-large",
    component: Dashboard,
    layout: "/company",
    role: ["admin", "oper", "supervisor"],
  },
  {
    path: "/new-document",
    name: "Novo Documento",
    icon: "fa fa-file",
    component: NewDocument,
    layout: "/company",
    role: ["admin", "oper", "supervisor"],
  },
  {
    path: "/edit-document/:id",
    name: "Edição Documento",
    icon: "fa fa-file",
    component: NewDocument,
    layout: "/company",
    role: ["admin", "oper", "supervisor"],
    hide: true,
  },
  {
    path: "/documents",
    name: "Documentos",
    icon: "fa fa-folder-open",
    component: Documents,
    layout: "/company",
    role: ["admin", "oper", "supervisor"],
  },
  {
    path: "/authentications",
    name: "Autenticações",
    icon: "fa fa-check",
    component: Authentications,
    layout: "/company",
    role: ["admin", "oper", "supervisor"],
  },
  {
    path: "/notify-parts",
    name: "Notificar assinantes",
    component: NotifyParts,
    layout: "/company",
    role: ["admin", "oper"],
    hide: true,
  },
  {
    path: "/new-user",
    name: "Novo Usuário",
    icon: "fa fa-user-plus",
    component: NewUser,
    layout: "/company",
    role: ["admin"],
  },
  {
    path: "/users",
    name: "Usuários",
    icon: "fa fa-users",
    component: Users,
    layout: "/company",
    role: ["admin"],
  },
  {
    path: "/profile",
    name: "Perfil",
    icon: "nc-icon nc-single-02",
    component: Profile,
    layout: "/company",
    role: ["admin", "oper", "supervisor"],
    hide: true,
  },
  {
    path: "/payment-methods",
    name: "Formas de Pagamento",
    icon: "nc-icon nc-single-02",
    component: PaymentMethods,
    layout: "/company",
    role: ["admin", "oper"],
    hide: true,
  },

  /*{
    path: "/change-plan",
    name: "Alterar Plano",
    icon: "nc-icon nc-single-02",
    component: ChangePlan,
    layout: "/company",
    role: ["admin", "oper"],
    hide: true,
  },*/
  {
    path: "/new-notification",
    name: "Novo Aviso",
    icon: "nc-icon nc-tag-content",
    component: NewNotification,
    layout: "/company",
    role: ["admin", "oper"],
  },
  {
    path: "/list-notifications",
    name: "Ver Avisos",
    icon: "nc-icon nc-bullet-list-67",
    component: ListNotifications,
    layout: "/company",
    role: ["admin", "oper"],
  },
  {
    path: "/financial-report",
    name: "Relatório Financeiro",
    icon: "fa fa-file-text-o",
    component: FinancialReport,
    layout: "/company",
    role: ["admin", "oper"],
  },
];

export default routes;
