import React from "react";
import icon from "../../assets/img/atualizacao-02.png";
import { Row, Col } from "reactstrap";

function MaintenancePage(props) {
  return (
    <>
      <div className="content">
        <Row>
          <Col>
            <img
              src={icon}
              style={{ maxHeight: "105px" }}
              alt=""
              className="img-fluid mx-auto d-block mt-4"
            />
            <h2 className="text-center text-gray">SISTEMA EM ATUALIZAÇÃO</h2>
            <h4 className="text-center text-gray" style={{ margin: "0 2rem" }}>
              Estamos trabalhando para reestabelecer os nossos serviços o mais
              rápido possível. Por favor, tente mais tarde.
            </h4>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default MaintenancePage;
