import React from "react";
import { connect } from "react-redux";
import { loadSelfieDetails } from "reducers/Documents";
import { Modal } from "reactstrap";

class SelfDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPicture: false,
    };

    let img;
    img = this.props.selfie.find((item) => {
      return item.id == props.src;
    });
    if (!img) {
      Promise.resolve(this.props.loadSelfieDetails(props.src))
        .then((response) => {
          img = this.props.selfie.find((item) => {
            return item.id == props.src;
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  render() {
    let img;
    img = this.props.selfie.find((item) => {
      return item.id == this.props.src;
    });
    if (!img) {
      Promise.resolve(this.props.loadSelfieDetails(this.props.src))
        .then((response) => {
          img = this.props.selfie.find((item) => {
            return item.id == this.props.src;
          });
        })
        .catch((e) => {
          console.log(e);
        });
      return (
        <>
          <span className="spinner-grow spinner-grow-sm">{"  "} </span>
          Carregando...
        </>
      );
    }

    window.onkeydown = (e) => {
      if ((this.state.modalPicture && e.key === "Escape") || e.key === "Esc") {
        this.setState({ modalPicture: false });
      }
    };

    return (
      <>
        <img src={img.file} onClick={() => this.setState({ modalPicture: true })} height="40px" style={{ paddingLeft: "15px" }} />

        <Modal isOpen={this.state.modalPicture} size="md" onClick={() => this.setState({ modalPicture: false })}>
          <button type="submit" className="btn btn-round" style={{ position: "absolute", right: "10px" }}>
            <span style={{ fontSize: "55px" }}>&times;</span>
          </button>

          <img src={img.file} onClick={() => this.setState({ modalPicture: false })} height="40%" width="500px" />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Documents,
});
function mapDispatchToProps(dispatch) {
  return {
    loadSelfieDetails: (imageId) => dispatch(loadSelfieDetails(imageId)),
  }; // here we're mapping actions to props
}
export default connect(mapStateToProps, mapDispatchToProps)(SelfDetails);
