import React from "react";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Form, Field} from 'react-final-form';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  CardFooter,
  CardTitle,
  Table,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import NotificationAlert from 'react-notification-alert';

import TextField from 'components/Fields/TextField';
import SelectField from 'components/Fields/SelectField';
import FieldNormalize from 'components/Fields/Normalize/FieldNormalize';
import FieldValidation from 'components/Fields/Validation/FieldValidation';
import DocumentUpload from "components/CustomUpload/DocumentUpload";

import {selectCompany} from 'reducers/Auth';

import ReactTable from "react-table";

class SelectCompany extends React.Component {
  constructor(props) {
    super(props);
  }

  onSubmit = async values => {
    this.props.selectCompany(_.filter(this.props.companies, {companyId: values.company.value})[0]);
  };

  render() {
    return (
      <>
        <div className="content">
          <NotificationAlert ref="notificationAlert" />
            <LoadingOverlay
              active={this.props.loading}
              spinner
              text='Processando...'
              >
              <Form
              onSubmit={this.onSubmit}
              initialValues={{}}

              render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="text-center" md="12">
                      <Card className="card-tasks">
                        <CardHeader>
                          <CardTitle tag="h4">Selecione sua Empresa</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col className="mx-auto" md="12">
                              <Field name="company" label="Empresa" placeholder="Empresa" component={SelectField} required validate={FieldValidation.required}
                                options={this.props.companies}
                                textField="name" valueField="companyId"
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <div style={{position:'sticky',top:'0','zIndex':'100'}}>
                        <Row>
                          <Col m="6" className="text-left">
                          </Col>
                          <Col m="6" className="text-right">
                            <Button className="btn btn-round">
                              {"SELECIONAR"}
                            </Button>
                          </Col>

                        </Row>
                      </div>
                    </Col>
                  </Row>
                </form>
              )}
              />
          </LoadingOverlay>
        </div>

      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state.Auth,
});

function mapDispatchToProps(dispatch) {
  return {
    selectCompany: company => dispatch(selectCompany(company)),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCompany);
