import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// reactstrap components
import { Input, Row, Col, Label, Button, UncontrolledAlert } from "reactstrap";
import { Field } from "react-final-form";
import NotificationAlert from "react-notification-alert";

import Card from "./Card";
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from "./cardUtils";

import TextField from "components/Fields/TextField";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import FieldValidation from "components/Fields/Validation/FieldValidation";

// core components

class CompanyAddress extends React.Component {
  constructor(props) {
    super(props);
  }

  isValidated = () => {
    if (this.props.selectedPlan === "plano_outro") {
      return true;
    }
    var ret = true;

    if (!this.props.values.cpf_cnpj) {
      ret = false;
    }
    if (!this.props.values.cardNumber) {
      ret = false;
    }
    if (!this.props.values.cardName) {
      ret = false;
    }
    if (!this.props.values.cardValid) {
      ret = false;
    }
    if (!this.props.values.cardCVC) {
      ret = false;
    }

    if (!ret) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>Campos obrigatórios não preenchidos.</div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
    }

    return ret;
  };

  render() {
    if (this.props.selectedPlan === "plano_outro") {
      return (
        <>
          <h5 className="info-text">Obrigada pelas informações, clique em “ENVIAR” e em breve a nossa equipe vai entrar em contato com você para explicar os nossos planos e condições.</h5>
        </>
      );
    } else {
      return (
        <>
          <p className="info-text">Informe os dados do seu cartão</p>
          <NotificationAlert ref="notificationAlert" />

          <Row className="">
            <Col className="mx-auto mb-3" lg="5">
              <Card
                number={this.props.values && this.props.values.cardNumber ? this.props.values.cardNumber : ""}
                name={this.props.values && this.props.values.cardName ? this.props.values.cardName : ""}
                expiry={this.props.values && this.props.values.cardValid ? this.props.values.cardValid : ""}
                cvc={this.props.values && this.props.values.cardCVC ? this.props.values.cardCVC : ""}
              />
            </Col>
            <Col className="mx-auto " lg="6" md="6" sm="12">
              <Col className="mx-auto " md="12">
                <Field name="cpf_cnpj" label="CPF/CNPJ*" placeholder="" component={TextField} validate={FieldValidation.required} parse={FieldNormalize.CPF_CNPJ} />
              </Col>
              <Col className="mx-auto " md="12">
                <Field name="cardNumber" label="Número do Cartão*" placeholder="" component={TextField} validate={FieldValidation.required} format={formatCreditCardNumber} />
              </Col>
              <Col className="mx-auto " md="12">
                <Field name="cardName" label="Nome impresso no Cartão*" placeholder="" component={TextField} validate={FieldValidation.required} />
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Field name="cardValid" label="Validade*" placeholder="" component={TextField} validate={FieldValidation.required} format={formatExpirationDate} maxLength="5" />
                  </Col>
                  <Col>
                    <Field name="cardCVC" label="Código de segurança*" placeholder="" component={TextField} validate={FieldValidation.required} format={formatCVC} />
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </>
      );
    }
  }
}

TextField.propTypes = {
  values: PropTypes.object.isRequired,
};

export default CompanyAddress;
