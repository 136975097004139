import React from "react";
import { Form, Field } from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import { Redirect } from "react-router-dom";
import {connect} from 'react-redux';

import FieldValidation from 'components/Fields/Validation/FieldValidation';
import TextField from 'components/Fields/TextField';
import owasp from 'owasp-password-strength-test';
import {encryptStringWithXORtoHex} from 'Utils/Utils';

import {validateToken, newPassword} from 'reducers/NewPassword';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Row,
} from "reactstrap";

owasp.config({
  allowPassphrases: false,
  minLength: 8,
});

class NewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordValue: "",
      minLengthTestPasses: false,
      maxLengthTestPasses: true,
      repeatedCharactersTestPasses: true,
      containsUppercaseLetterTestPasses: false,
      containsLowercaseLetterTestPasses: false,
      containsNumberTestPasses: false,
      containsSpecialCharacterTestPasses: false
    };
  }

  onPasswordChange = (value) => {
    const { failedTests: failed, passedTests: passed } = owasp.test(
      value
    );

    const testPassed = testIndex => {
      return !failed.includes(testIndex) && passed.includes(testIndex);
    };

    this.setState({
      passwordValue: value,
      minLengthTestPasses: testPassed(0),
      maxLengthTestPasses: testPassed(1),
      repeatedCharactersTestPasses: testPassed(2),
      containsLowercaseLetterTestPasses: testPassed(3),
      containsUppercaseLetterTestPasses: testPassed(4),
      containsNumberTestPasses: testPassed(5),
      containsSpecialCharacterTestPasses: testPassed(6)
    });
  };

  componentWillMount() {
    if(!this.props.client) {
      var token = this.props.match.params.token;
      this.props.validateToken(token);
    }  
  }

  onSubmit = async values => {
    var props = {
      clientId: this.props.client.clientId,
      cpf: this.props.client.cpf,
      password: encryptStringWithXORtoHex(values.password, this.props.client.cpf),
      token: this.props.match.params.token
    };

    await this.props.newPassword(props);
  };

  render() {

    if(this.props.redirectTo) {
      return (<Redirect to={this.props.redirectTo} />);
    }

    return (
      <div className="content">
        <Form
          onSubmit={this.onSubmit}
          validate={values => {
            const errors = {};
            if (!this.state.minLengthTestPasses) {
              errors.password = 'São necessários pelo menos 8 caracteres.';
            } 
            else if (!this.state.repeatedCharactersTestPasses) {
              errors.password = 'Não use dígitos repetidos';
            }
            else if (!this.state.containsUppercaseLetterTestPasses) {
              errors.password = 'É necessário incluir pelo menos uma letra maiúscula.';
            } 
            else if (!this.state.containsLowercaseLetterTestPasses) {
              errors.password = 'É necessário incluir pelo menos uma letra minúscula.';
            } 
            else if (!this.state.containsNumberTestPasses) {
              errors.password = 'É necessário incluir pelo menos um número.';
            }
            if (values.password !== values.passwordConfirm) {
              errors.passwordConfirm = 'As senhas estão diferentes. Digite novamente.'
            }
            return errors;
          }}
          render={({ handleSubmit, form, submitting, pristine, values, validating, valid, errors }) => (
            <form onSubmit={handleSubmit}>
              { this.props.client ?
                  <Row>
                    <Col className="mx-auto" md="6">
                      <Card className="card-login">
                        <CardHeader>
                          <CardHeader>
                            <h4 className="header text-center">DIGITE SUA NOVA SENHA</h4>
                          </CardHeader>
                        </CardHeader>

                        <CardBody>
                          <Col className="mx-auto " md="12">
                            <Field 
                              name="password" 
                              placeholder="SENHA"
                              component={TextField} 
                              type={"password"} 
                              validate={FieldValidation.required}
                            />
                            <OnChange name="password">
                              {(value, previous) => {
                                this.onPasswordChange(value);
                              }}
                            </OnChange>
                          </Col>
                          <Col className="mx-auto" md="12">
                            <Field 
                              name="passwordConfirm" 
                              placeholder="CONFIRMAR A SENHA" 
                              component={TextField} 
                              type={"password"} 
                              validate={FieldValidation.required}
                            />
                          </Col>
                          <br />
                        </CardBody>

                        <CardFooter>
                          <Col className="mx-auto" md="12">
                            <Button
                              block
                              className="btn-round mb-3"
                              color="default"
                              type="submit"
                            >
                              Mudar senha
                            </Button>
                          </Col>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                :
                  <Row>
                    <Col md="12">
                      <h2 className="text-center text-gray">Link Inválido</h2>
                      <h4 className="text-center text-gray">Link inválido ou expirado.</h4>
                    </Col>
                  </Row>
              }
            </form>
          )}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.NewPassword,
});

function mapDispatchToProps(dispatch) {
  return {
    newPassword: props => dispatch(newPassword(props)),
    validateToken: token => dispatch(validateToken(token)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);