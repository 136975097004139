import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import LoadingOverlay from "react-loading-overlay";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Card, CardHeader, CardBody, Row, Col, Button, CardTitle } from "reactstrap";
import NotificationAlert from "react-notification-alert";

import TextField from "components/Fields/TextField";
import SelectField from "components/Fields/SelectField";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import FieldValidation from "components/Fields/Validation/FieldValidation";

import { save, addPart, removePart, load, searchByCPF, updateFormState, documentEdit, unload, update, removeAllParts, finalize, getCommitments } from "reducers/NewDocument";
import { showAlert, closeAlert } from "reducers/Notification";

import ReactTable from "react-table";
import MaskDate from "Utils/MaskDate";
import MultiSelectField from "components/Fields/MultiSelectField";

class NewDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [
        {
          id: 1,
          desc: "Etapa 1",
        },
      ],
      hasSteps: false,
      files: [],
    };
    this.fileInput = React.createRef();
  }

  async componentWillMount() {
    if (this.props.company) {
      await this.props.getCommitments();
      this.props.load(this.props.company.companyId);
      if (this.props.match && this.props.match.params && this.props.match.params.id) {
        await this.documentEdit(this.props.match.params.id);
      } else if (localStorage.getItem("copyId")) {
        await this.documentEdit(localStorage.getItem("copyId"), true);
        localStorage.removeItem("copyId");
      }
    }
  }

  componentWillUnmount() {
    this.props.unload();
  }

  documentEdit = async (documentId, copy) => {
    this.props.documentEdit(documentId).then((res) => {
      if (res) {
        if (res.payload.data.values.validateDate) {
          res.payload.data.values.validateDate = MaskDate.TO_BR(res.payload.data.values.validateDate);
        }

        if (!copy) {
          this.setState({
            files: res.payload.data.values.files,
          });
        } else {
          delete res.payload.data.values.documentId;
        }
        this.props.updateFormState(res.payload.data);

        let signStep = res.payload.data.values.parts.map((item) => {
          return item.partSignStep;
        });
        let uniqueItems = [...new Set(signStep)];
        if (uniqueItems.length > 1) {
          let position = 2;
          let stepsPosition = [];
          while (position <= uniqueItems.length) {
            let step = {
              id: position,
              desc: "Etapa " + position,
            };
            stepsPosition.push(step);
            position++;
          }
          this.setState({
            steps: [...this.state.steps, ...stepsPosition],
            hasSteps: true,
          });
        }
        for (const item of res.payload.data.values.parts) {
          let part = item;
          if (copy) {
            delete part.idBd;
          }
          part.actions = (
            <div className="actions-right">
              <Link color="danger" size="sm" className="btn btn-icon btn-link like" onClick={this.handleRemovePart(part.partCpf)}>
                <i className="fa fa-times" />
              </Link>{" "}
            </div>
          );
          this.props.addPart(part);
        }
      } else {
        document.getElementById("idCancel").click();
      }
    });
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        files: [...this.state.files, file],
      });
    };
    reader.readAsDataURL(file);

    console.log("FILE", file);
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleClick = () => {
    this.fileInput.current.click();
  };

  handleRemoveFile = (file) => {
    this.setState({
      files: this.state.files.filter((element) => element !== file),
    });
  };

  handleAddPart = (values, form) => (ev) => {
    ev.preventDefault();

    // validating cpf
    if (FieldValidation.cpf(values.signPartCpf)) {
      return;
    }

    // validating name
    if (FieldValidation.name(values.signPartName)) {
      return;
    }

    if (this.props.parts.find((p) => p.id === values.signPartCpf)) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>Cpf já adicionado.</div>
          </div>
        ),
        type: "warning",
        icon: "fa fa-exclamation-triangle",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
      return;
    }

    if (values.signPartName && values.signPartCpf && (this.state.hasSteps ? values.signPartStep : true)) {
      var part = {
        id: values.signPartCpf,
        partName: values.signPartName,
        partSignStep: this.state.hasSteps ? values.signPartStep.value : 1,
        partCpf: values.signPartCpf,
        partPhone: values.signPartPhone,
        partEmail: values.signPartEmail,
        partCommitments: values.commitMents && values.commitMents.length > 0 ? values.commitMents.map((item) => item.label) : null,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Link color="danger" size="sm" className="btn btn-icon btn-link like" onClick={this.handleRemovePart(values.signPartCpf)}>
              <i className="fa fa-times" />
            </Link>{" "}
          </div>
        ),
      };
      this.props.addPart(part);
      form.change("signPartName", "");
      form.resetFieldState("signPartName");
      form.change("signPartCpf", "");
      form.resetFieldState("signPartCpf");
      // if (this.state.hasSteps) {
      //   form.change("signPartStep", "");
      //   form.resetFieldState("signPartStep");
      // }
      form.change("signPartPhone", "");
      form.resetFieldState("signPartPhone");
      form.change("signPartEmail", "");
      form.resetFieldState("signPartEmail");
      form.change("commitMents", "");
      form.resetFieldState("commitMents");
    } else {
      form.submit();
    }
  };

  handleRemovePart = (id) => (ev) => {
    ev.preventDefault();
    this.props.removePart(id);
  };

  handleRemoveStep = () => (ev) => {
    ev.preventDefault();
    if (this.props.parts.filter((part) => part.partSignStep === this.state.steps[this.state.steps.length - 1].id).length === 0) {
      this.setState({
        steps: this.state.steps.filter((step) => step.id !== this.state.steps[this.state.steps.length - 1].id),
      });
    } else {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>Não é possível remover esta etapa pois ainda há assinantes registrados nela.</div>
          </div>
        ),
        type: "warning",
        icon: "fa fa-exclamation-triangle",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
    }
  };

  handleSend = (values, form, finalize) => (ev) => {
    ev.preventDefault();
    form.submit();

    if (!this.state.files[0]) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>Selecione um documento.</div>
          </div>
        ),
        type: "danger",
        icon: "fa fa-exclamation-triangle",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
      return;
    }

    // check if values are valid
    if (!values.name || !values.type || !values.senderName) {
      return;
    }

    if (values.type.value === "Contrato de Financiamento de Veículos" && (!values.veiculos || (values.veiculos && values.veiculos.length === 0))) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>Adicione pelo menos um chassi/gravame</div>
          </div>
        ),
        type: "danger",
        icon: "fa fa-exclamation-triangle",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
      return;
    }

    if (values.type.value === "Contrato de Financiamento de Veículos")
      for (let veiculo of values.veiculos) {
        if (!veiculo) return;
        if (veiculo.chassi === "" || veiculo.gravame === "") return;
      }

    if (values.type.value === "Contrato de Financiamento de Veículos" && (!values.docCarNumber || !values.veiculos || !values.uf)) return;

    if (!this.validateSignSteps()) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>Cada etapa deve possuir pelo menos um assinante</div>
          </div>
        ),
        type: "danger",
        icon: "fa fa-exclamation-triangle",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
      return;
    }

    var vp = this.props.parts.slice();
    var doc = {
      documentId: values.documentId,
      name: values.name,
      type: values.type.value ? values.type.value : values.type,
      senderName: values.senderName,
      metadata: JSON.stringify({
        contrato: values.docCarNumber,
        veiculos: values.veiculos,
        //gravame: values.gravame,
        uf: values.uf ? values.uf.value : "",
      }),
      companyId: this.props.company.companyId,
      userId: this.props.user.userId,
      parts: JSON.stringify(
        vp.map(function (item) {
          delete item.actions;
          delete item.id;
          return JSON.stringify(item);
        })
      ),
      files: this.state.files,
      validateDate: MaskDate.TO_DATABASE_DATE(values.validateDate),
    };

    if (doc.documentId) {
      this.props.update(doc).then(async (v) => {
        if (v && v.type.includes("SUCCESS")) {
          if (!finalize) {
            this.props.removeAllParts();
            this.setState({
              files: [],
              steps: [
                {
                  id: 1,
                  desc: "Etapa 1",
                },
              ],
              hasSteps: false,
            });
            await this.documentEdit(v.payload.data.idDocument);
            var options = {};
            options = {
              place: "tc",
              message: (
                <div>
                  <div style={{ fontWeight: "bold" }}>Documento salvo com sucesso.</div>
                </div>
              ),
              type: "success",
              icon: "now-ui-icons ui-1_bell-53",
              autoDismiss: 7,
            };
            this.refs.notificationAlert.notificationAlert(options);
          } else {
            this.props.finalize(v.payload.data.idDocument);
          }
        } else {
          var options = {};
          options = {
            place: "tc",
            message: (
              <div>
                <div>Não foi possível enviar seu documento.</div>
              </div>
            ),
            type: "danger",
            icon: "fa fa-exclamation-triangle",
            autoDismiss: 7,
          };
          this.refs.notificationAlert.notificationAlert(options);
        }
      });
    } else {
      this.props.save(doc).then(async (v) => {
        if (v && v.type.includes("SUCCESS")) {
          if (!finalize) {
            this.props.removeAllParts();
            this.setState({
              files: [],
              steps: [
                {
                  id: 1,
                  desc: "Etapa 1",
                },
              ],
              hasSteps: false,
            });
            await this.documentEdit(v.payload.data.idDocument);
            var options = {};
            options = {
              place: "tc",
              message: (
                <div>
                  <div style={{ fontWeight: "bold" }}>Documento salvo com sucesso.</div>
                </div>
              ),
              type: "success",
              icon: "now-ui-icons ui-1_bell-53",
              autoDismiss: 7,
            };
            this.refs.notificationAlert.notificationAlert(options);
          } else {
            this.props.finalize(v.payload.data.idDocument);
          }
        } else {
          var options = {};
          options = {
            place: "tc",
            message: (
              <div>
                <div>Não foi possível enviar seu documento.</div>
              </div>
            ),
            type: "danger",
            icon: "fa fa-exclamation-triangle",
            autoDismiss: 7,
          };
          this.refs.notificationAlert.notificationAlert(options);
        }
      });
    }
  };

  handleDontChoseSteps = (form) => (ev) => {
    ev.preventDefault();

    this.setState({
      ...this.state,
      steps: [
        {
          id: 1,
          desc: "Etapa 1",
        },
      ],
      hasSteps: false,
    });

    form.change("signPartStep", "");
    form.resetFieldState("signPartStep");
  };

  validateSignSteps = (e) => {
    let partsPerStep = new Array(this.state.steps.length + 1).fill(0);
    for (let part of this.props.parts) {
      partsPerStep[part.partSignStep] += 1;
    }
    for (let index in partsPerStep) {
      if (index > 0 && partsPerStep[index] === 0) {
        return false;
      }
    }
    return true;
  };

  render() {
    if (this.props.newDocumentRedirectTo) {
      return <Redirect to={this.props.newDocumentRedirectTo} />;
    }
    return (
      <>
        <div className="content">
          <NotificationAlert ref="notificationAlert" />
          <LoadingOverlay active={this.props.loading} spinner text="Processando...">
            <Form
              mutators={{ ...arrayMutators }}
              onSubmit={onSubmit}
              initialValues={this.props.documentForm}
              validate={(values) => {
                const errors = {};
                if (!this.props.parts || this.props.parts.length === 0) {
                  if (!values.signPartName || values.signPartName.trim() === "") {
                    errors.signPartName = "Campo obrigatório";
                  }
                  if (!values.signPartCpf || values.signPartCpf.trim() === "") {
                    errors.signPartCpf = "Campo obrigatório";
                  }
                  if (!values.signPartStep) {
                    errors.signPartStep = "Campo obrigatório";
                  }
                } else {
                  if (!values.senderName || values.senderName.trim() === "") {
                    errors.senderName = "Campo obrigatório";
                  }
                  if (!values.name || values.name.trim() === "") {
                    errors.name = "Campo obrigatório";
                  }
                  if (!values.type) {
                    errors.type = "Campo obrigatório";
                  }
                }
                return errors;
              }}
              render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                <form onSubmit={handleSubmit} id="documentForm">
                  <FormSpy
                    onChange={(state) => {
                      this.props.updateFormState(state);
                    }}
                  />
                  <p className="text-right">Saldo de assinaturas: {this.props.balance}</p>
                  <Row>
                    <Col className="text-center" md="12">
                      <Card className="card-tasks">
                        <CardHeader>
                          <CardTitle tag="h4">Documento</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="3" sm="4">
                              <Field name="senderName" label="Remetente" validate={FieldValidation.required} component={TextField} maxLength="20" />
                            </Col>
                            <Col md="4" sm="4">
                              <Field name="name" label="Nome do documento" validate={FieldValidation.required} component={TextField} maxLength="45" />
                            </Col>
                            <Col md="3" sm="4">
                              <Field
                                name="type"
                                label="Tipo do documento"
                                validate={FieldValidation.required}
                                component={SelectField}
                                options={[
                                  { id: "ata", desc: "ATA" },
                                  {
                                    id: "contrato de crédito consignado",
                                    desc: "CCB CONSIGNADO",
                                  },
                                  { id: "contrato", desc: "CONTRATO" },
                                  { id: "documento", desc: "DOCUMENTO" },
                                  { id: "procuração", desc: "PROCURAÇÃO" },
                                  { id: "outros", desc: "OUTROS" },
                                ]}
                                textField="desc"
                                valueField="id"
                                placeholder="Selecione"
                              />
                            </Col>
                            <Col md="2" lg="2" sm="12">
                              <Field name="validateDate" label="Data validade do documento" component={TextField} parse={FieldNormalize.DATE} validate={FieldValidation.date} />
                            </Col>
                            <Col className="ml-auto text-right" md="3" sm="4">
                              <div className="fileinput text-center">
                                <input type="file" onChange={this.handleImageChange} ref={this.fileInput} accept="application/pdf" />
                                <div>
                                  <Button className="btn-round" multiple={true} onClick={() => this.handleClick()} style={{ margin: "25px 1px" }}>
                                    {"ADICIONAR DOCUMENTO"}
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {this.state.files[0] && (
                            <>
                              <Row>
                                <Col className="text-center" md="12">
                                  <Card className="card-tasks">
                                    <CardBody>
                                      <ReactTable
                                        data={this.state.files}
                                        columns={[
                                          {
                                            Header: "Nome",
                                            style: { whiteSpace: "unset" },
                                            accessor: "name",
                                          },
                                          {
                                            Header: "Ações",
                                            sortable: false,
                                            filterable: false,
                                            width: 50,
                                            Cell: (row) => {
                                              return (
                                                <div className="actions-right">
                                                  <Button color="default" size="sm" className="btn-icon btn-link edit" onClick={() => this.handleRemoveFile(row.original)}>
                                                    <i className="fa fa-close" />
                                                  </Button>{" "}
                                                </div>
                                              );
                                            },
                                          },
                                        ]}
                                        showPagination={false}
                                        minRows={1}
                                        sortable={false}
                                        loadingText="Carregando..."
                                        noDataText="Nenhum assinante"
                                        className="-striped -highlight"
                                      />
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </>
                          )}
                          {values.type && values.type.value === "Contrato de Financiamento de Veículos" && (
                            <>
                              <Row>
                                <Col md="3" sm="4">
                                  <Field name="docCarNumber" label="Número do Contrato" validate={FieldValidation.required} component={TextField} maxLength="20" />
                                </Col>
                                <Col md="3" sm="4">
                                  <Field
                                    name="uf"
                                    label="UF"
                                    validate={FieldValidation.required}
                                    component={SelectField}
                                    options={[
                                      { id: "AC", desc: "AC" },
                                      { id: "BA", desc: "BA" },
                                      { id: "MA", desc: "MA" },
                                      { id: "MG", desc: "MG" },
                                      { id: "PB", desc: "PB" },
                                      { id: "PE", desc: "PE" },
                                      { id: "PR", desc: "PR" },
                                      { id: "SC", desc: "SC" },
                                      { id: "SP", desc: "SP" },
                                    ]}
                                    textField="desc"
                                    valueField="id"
                                  />
                                </Col>
                              </Row>
                              <FieldArray name="veiculos">
                                {({ fields }) => (
                                  <div>
                                    {fields.map((name, index) => (
                                      <Row>
                                        <Col md="3" sm="4">
                                          <div
                                            style={{
                                              display: "grid",
                                              gridTemplateColumns: "6fr 1fr",
                                            }}
                                          >
                                            <Field name={`${name}.chassi`} label="Chassi" validate={FieldValidation.required} component={TextField} maxLength="21" />
                                            <Button
                                              style={{
                                                margin: "30px 0 30px 5px",
                                              }}
                                              color="default"
                                              size="sm"
                                              className="btn-icon btn-link edit"
                                              onClick={() => fields.remove(index)}
                                            >
                                              <i className="fa fa-trash" />
                                            </Button>{" "}
                                          </div>
                                        </Col>
                                        <Col md="3" sm="4">
                                          <Field name={`${name}.gravame`} label="Gravame" validate={FieldValidation.required} parse={FieldNormalize.NUMBER} component={TextField} maxLength="8" />
                                        </Col>
                                      </Row>
                                    ))}
                                    <Row>
                                      <Col md="3" sm="4">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                            height: "100%",
                                          }}
                                        >
                                          <Button className="btn btn-round" onClick={() => fields.push("")}>
                                            ADICIONAR CHASSI/GRAVAME
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </FieldArray>
                            </>
                          )}
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader>
                          {/* <CardTitle tag="h4">Etapas de Assinatura</CardTitle> */}
                          <Row>
                            <Col>
                              {!this.state.hasSteps ? (
                                <Button
                                  disabled={this.props.parts.length >= 1}
                                  onClick={() =>
                                    this.setState({
                                      ...this.state,
                                      hasSteps: true,
                                    })
                                  }
                                >
                                  Quero escolher a ordem das assinaturas
                                </Button>
                              ) : (
                                <Button disabled={this.props.parts.length >= 1} onClick={this.handleDontChoseSteps(form)}>
                                  Não quero escolher a ordem das assinaturas
                                </Button>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mx-auto" md="12" sm="12">
                              Aqui você pode escolher em quantas etapas ocorrerá a assinatura do documento. Antes de adicionar assinantes defina a quantidade de etapas e quando for adicionar um
                              assinante escolha em qual etapa ele deve assinar.
                              <br />
                              <p style={{ fontSize: "12px" }}>* Os assinantes de uma determinada etapa só poderão assinar quando todos os assinantes da etapa anterior assinarem também.</p>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {this.state.hasSteps && (
                            <>
                              <Stepper alternativeLabel nonLinear style={{ overflowX: "auto" }}>
                                {this.state.steps.map((step) => (
                                  <Step key={step.id} active={true}>
                                    <StepLabel>Etapa {step.id}</StepLabel>
                                    <div style={{ maxHeight: "75px" }}>
                                      <p style={{ fontSize: "10px" }}>
                                        {this.props.parts
                                          .filter((part) => part.partSignStep === step.id)
                                          .slice(0, 4)
                                          .map((part) => (
                                            <>
                                              {part.partName}
                                              <br />
                                            </>
                                          ))}
                                        {this.props.parts.filter((part) => part.partSignStep === step.id).length > 4 && "....."}
                                      </p>
                                    </div>
                                  </Step>
                                ))}
                              </Stepper>
                              <Button
                                disabled={this.props.parts.length >= 1}
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    steps: [
                                      ...this.state.steps,
                                      {
                                        id: this.state.steps.length + 1,
                                        desc: "Etapa " + (this.state.steps.length + 1),
                                      },
                                    ],
                                  });
                                }}
                              >
                                Adicionar Etapa
                              </Button>
                              <Button
                                disabled={this.props.parts.length >= 1 || this.state.steps.length === 1}
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    steps: this.state.steps.filter((step) => step.id !== this.state.steps[this.state.steps.length - 1].id),
                                  });
                                }}
                              >
                                Remover Etapa
                              </Button>
                              <Row>
                                <Col className="mx-auto" md="12" sm="12">
                                  {this.props.parts.length >= 1 && (
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Para modificar o número de etapas ou escolher se vão haver etapas não pode haver nenhum assinante cadastrado ainda.
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            </>
                          )}
                        </CardBody>
                      </Card>
                      <Card className="card-tasks">
                        <CardHeader>
                          <CardTitle tag="h4">Assinantes</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col className="mx-auto" md="2" sm="2">
                              <Field name="signPartCpf" label="CPF" component={TextField} required parse={FieldNormalize.CPF} validate={FieldValidation.composeValidators(FieldValidation.cpf)} />
                              <OnChange name="signPartCpf">
                                {(value, previous) => {
                                  if (value.replace(/[^\d]/g, "").length === 11) {
                                    this.props.searchByCPF(value);
                                    console.log("PESQUISAR PELO CPF");
                                  }
                                }}
                              </OnChange>
                            </Col>
                            <Col className="mx-auto" md={this.state.hasSteps ? "3" : "4"} sm={this.state.hasSteps ? "3" : "4"}>
                              <Field name="signPartName" label="Nome" component={TextField} validate={FieldValidation.composeValidators(FieldValidation.name)} required maxLength="500" />
                            </Col>
                            <Col className="mx-auto" md="2" sm="2">
                              <Field name="signPartPhone" label="Celular" component={TextField} parse={FieldNormalize.CELULAR} />
                            </Col>
                            {this.state.hasSteps && (
                              <Col className="mx-auto" md="2" sm="2">
                                <Field
                                  name="signPartStep"
                                  label="Etapa de assinatura"
                                  required
                                  component={SelectField}
                                  options={this.state.steps}
                                  placeholder="Selecione"
                                  textField="desc"
                                  valueField="id"
                                />
                              </Col>
                            )}
                            <Col className="mx-auto" md={this.state.hasSteps ? "3" : "4"} sm={this.state.hasSteps ? "3" : "4"}>
                              <Field name="signPartEmail" label="E-mail" component={TextField} validate={FieldValidation.composeValidators(FieldValidation.email)} maxLength="225" />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <Field
                                name="commitMents"
                                label="Assinar como"
                                component={MultiSelectField}
                                options={this.props.commitments}
                                placeholder="Selecione"
                                textField="description"
                                valueField="commitmentId"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-md-3 ml-auto text-right" md="3" sm="3">
                              <Link className="btn btn-round" onClick={this.handleAddPart(values, form)}>
                                {"ADICIONAR ASSINANTE"}
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mx-auto" md="12">
                              <ReactTable
                                data={this.props.parts}
                                columns={
                                  !this.state.hasSteps
                                    ? [
                                        {
                                          Header: "Nome",
                                          accessor: "partName",
                                        },
                                        {
                                          Header: "CPF",
                                          accessor: "partCpf",
                                        },
                                        {
                                          Header: "Celular",
                                          accessor: "partPhone",
                                        },
                                        {
                                          Header: "E-mail",
                                          accessor: "partEmail",
                                        },
                                        {
                                          id: "partCommitments",
                                          Header: "Assinando como",
                                          accessor: (row) => {
                                            if (row.partCommitments) {
                                              return row.partCommitments.map((item) => <div>{item}</div>);
                                            }
                                          },
                                        },
                                        {
                                          Header: "Ações",
                                          accessor: "actions",
                                          width: 50,
                                        },
                                      ]
                                    : [
                                        {
                                          Header: "Nome",
                                          accessor: "partName",
                                        },
                                        {
                                          Header: "CPF",
                                          accessor: "partCpf",
                                        },
                                        {
                                          id: "partSignStep",
                                          Header: "Etapa de assinatura",
                                          accessor: (row) => "Etapa " + row.partSignStep,
                                        },
                                        {
                                          Header: "Celular",
                                          accessor: "partPhone",
                                        },
                                        {
                                          Header: "E-mail",
                                          accessor: "partEmail",
                                        },
                                        {
                                          id: "partCommitments",
                                          Header: "Assinando como",
                                          accessor: (row) => {
                                            if (row.partCommitments) {
                                              return row.partCommitments.map((item) => <div>{item}</div>);
                                            }
                                          },
                                        },
                                        {
                                          Header: "Ações",
                                          accessor: "actions",
                                          width: 50,
                                        },
                                      ]
                                }
                                showPagination={false}
                                minRows={1}
                                sortable={false}
                                loadingText="Carregando..."
                                noDataText="Nenhum assinante"
                                className="-striped -highlight"
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <div style={{ position: "sticky", top: "0", zIndex: "100" }}>
                        <Row>
                          <Col m="6" className="text-left">
                            <Link id="idCancel" className="btn btn-round" to="/company/dashboard">
                              {"CANCELAR"}
                            </Link>
                          </Col>

                          {this.props.company.plan === "faturado" || this.props.balance > 0 ? (
                            <Col m="6" className="text-right">
                              <Link className="btn btn-round" onClick={this.handleSend(values, form)} disabled={this.props.parts.length === 0}>
                                {"SALVAR"}
                              </Link>
                              <Link className="btn btn-round" onClick={this.handleSend(values, form, true)} disabled={this.props.parts.length === 0}>
                                {"FINALIZAR CADASTRO"}
                              </Link>
                            </Col>
                          ) : (
                            <>
                              {this.props.balance < 1 && (
                                <Col m="6" className="text-right">
                                  <Link className="btn btn-round" disabled={true}>
                                    {`PRÓXIMO (Saldo insuficiente)`}
                                  </Link>
                                </Col>
                              )}
                            </>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </form>
              )}
            />
          </LoadingOverlay>
        </div>
      </>
    );
  }
}

const onSubmit = async (values) => {
  //  await sleep(300)
};

const mapStateToProps = (state) => ({
  ...state.Auth,
  ...state.NewDocument,
  alertNewDocument: state.NewDocument.alert,
  alertMessageNewDocument: state.NewDocument.alertMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    save: (values) => dispatch(save(values)),
    update: (values) => dispatch(update(values)),
    addPart: (part) => dispatch(addPart(part)),
    removePart: (id) => dispatch(removePart(id)),
    load: (companyId) => dispatch(load(companyId)),
    searchByCPF: (cpf) => dispatch(searchByCPF(cpf)),
    updateFormState: (state) => dispatch(updateFormState(state)),
    showAlert: (alertProps) => dispatch(showAlert(alertProps)),
    closeAlert: () => dispatch(closeAlert(0)),
    documentEdit: (documentId) => dispatch(documentEdit(documentId)),
    unload: () => dispatch(unload()),
    removeAllParts: () => dispatch(removeAllParts()),
    finalize: (documentId) => dispatch(finalize(documentId)),
    getCommitments: () => dispatch(getCommitments()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDocument);
