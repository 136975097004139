import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import LoadingOverlay from "react-loading-overlay";

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import {
  unload,
  changePlan,
  loadSimulation,
  save,
  suspendPlan,
  activatePlan,
} from "../../reducers/ChangePlan";
import { selectCompany } from "../../reducers/Auth";

class ChangePlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  componentWillMount() {
    if (this.props.company) {
      this.props.changePlan(this.props.company.plan);
    }
  }

  componentWillUnmount() {
    this.props.unload();
  }

  handleSubmit = async (values) => {
    if (!this.props.selectedPlan) return;
    this.setState({ modal: false });
    this.props
      .save({
        companyId: this.props.company.companyId,
        plan_identifier: this.props.selectedPlan,
      })
      .then(async (v) => {
        if (v && v.type.includes("SUCCESS")) {
          console.log("STATUS PLANO: " + this.props.company.status);
          if (this.props.company.status === 2) {
            //Se estava suspenso
            var ret = await this.props.activatePlan(
              this.props.company.companyId
            );
            if (ret.payload.status === 200)
              this.props.selectCompany({ ...this.props.company, status: 1 });
          }
          this.props.selectCompany({
            ...this.props.company,
            plan: this.props.selectedPlan,
          });
          var options = {};
          options = {
            place: "tc",
            message: (
              <div>
                <div>Seu plano foi alterado com sucesso.</div>
              </div>
            ),
            type: "success",
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 7,
          };
          this.refs.notificationAlert.notificationAlert(options);
        } else {
          var options = {};
          options = {
            place: "tc",
            message: (
              <div>
                <div>Não foi possível alterar seu plano.</div>
              </div>
            ),
            type: "danger",
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 7,
          };
          this.refs.notificationAlert.notificationAlert(options);
        }
      });
  };

  render() {
    return (
      <>
        {this.props.simulation &&
          this.props.simulation.new_plan === this.props.selectedPlan && (
            <Modal isOpen={this.state.modal}>
              <ModalHeader>Atualizar Plano</ModalHeader>
              <ModalBody style={{ fontSize: "17px" }}>
                <Table>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <th style={{ borderTop: "0px" }} scope="row">
                        Valor:
                      </th>
                      <td style={{ borderTop: "0px" }}>
                        R$
                        {(this.props.simulation.cost +
                          this.props.simulation.discount) /
                          100}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Desconto:</th>
                      <td>R${this.props.simulation.discount / 100}</td>
                    </tr>
                    <tr>
                      <th scope="row">Meses de plano até a próxima fatura:</th>
                      <td>{this.props.simulation.cycles}</td>
                    </tr>
                    <tr>
                      <th scope="row">Data da próxima fatura:</th>
                      <td>
                        {this.props.simulation.expires_at.slice(8, 10)}/
                        {this.props.simulation.expires_at.slice(5, 7)}/
                        {this.props.simulation.expires_at.slice(0, 4)}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Total a ser pago:</th>
                      <td>R${this.props.simulation.cost / 100}</td>
                    </tr>
                  </tbody>
                </Table>
                <i>
                  Observação: estes valores são calculados de acordo com o saldo
                  restante do seu plano atual
                </i>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{ width: "100px" }}
                  className="btn-round"
                  href="#pablo"
                  color="default"
                  onClick={() => this.setState({ modal: !this.state.modal })}
                >
                  Cancelar
                </Button>
                {"   "}
                <Button
                  style={{ width: "100px", margin: "10px" }}
                  className="btn-round"
                  href="#pablo"
                  color="default"
                  onClick={this.handleSubmit}
                >
                  Confirmar
                </Button>
              </ModalFooter>
            </Modal>
          )}
        <div className="content">
          <LoadingOverlay
            active={this.props.loading}
            spinner
            text={
              this.state.modal
                ? "Carregando informações do novo plano"
                : "Atualizando plano..."
            }
          >
            <div>
              <Row className="justify-content-center">
                <Col m="4" className="text-center">
                  <h6
                    className="title"
                    style={{ lineHeight: "44px", fontSize: ".85em" }}
                  >
                    Alterar Plano
                  </h6>
                  <p> *Após selecionar o plano clique em confirmar </p>
                </Col>
              </Row>

              <NotificationAlert ref="notificationAlert" />

              <Row className="justify-content-center">
                <Col md="12">
                  <Row>
                    <Col lg="4">
                      <Card
                        className={
                          this.props.selectedPlan === "plano_50_1000" ||
                          (this.props.location.state &&
                            this.props.location.state.plan) === "plano_50_1000"
                            ? "card-pricing text-white bg-dark"
                            : "card-pricing"
                        }
                      >
                        <CardHeader>
                          <h6
                            className={
                              this.props.selectedPlan === "plano_50_1000" ||
                              (this.props.location.state &&
                                this.props.location.state.plan) ===
                                "plano_50_1000"
                                ? "card-category text-white"
                                : "card-category"
                            }
                          >
                            Até 50 documentos/mês
                          </h6>
                        </CardHeader>
                        <CardBody>
                          <CardTitle tag="h3">
                            R$ 1000,00 <br />{" "}
                            <div
                              style={{ fontSize: "0.5em", lineHeight: "0em" }}
                            >
                              por mês
                            </div>
                          </CardTitle>
                          <ul>
                            <li>Preço unitário R$ 20,00</li>
                          </ul>
                        </CardBody>
                        <CardFooter>
                          <Button
                            className={
                              this.props.selectedPlan === "plano_50_1000"
                                ? "btn-round text-dark bg-light"
                                : "btn-round"
                            }
                            disabled={
                              this.props.company.plan === "plano_50_1000"
                                ? true
                                : false
                            }
                            color="default"
                            href="#pablo"
                            onClick={() =>
                              this.props.changePlan("plano_50_1000")
                            }
                          >
                            {this.props.selectedPlan === "plano_50_1000" && (
                              <span className="btn-label">
                                <i className="nc-icon nc-check-2" />
                              </span>
                            )}
                            {this.props.company.plan === "plano_50_1000"
                              ? "Seu plano atual"
                              : this.props.selectedPlan === "plano_50_1000"
                              ? "SELECIONADO"
                              : "SELECIONAR"}
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card
                        className={
                          this.props.selectedPlan === "plano_100_1900" ||
                          (this.props.location.state &&
                            this.props.location.state.plan) === "plano_100_1900"
                            ? "card-pricing text-white bg-dark"
                            : "card-pricing"
                        }
                      >
                        <CardHeader>
                          <h6
                            className={
                              this.props.selectedPlan === "plano_100_1900" ||
                              (this.props.location.state &&
                                this.props.location.state.plan) ===
                                "plano_100_1900"
                                ? "card-category text-white"
                                : "card-category"
                            }
                          >
                            Até 100 documentos/mês
                          </h6>
                        </CardHeader>
                        <CardBody>
                          <CardTitle tag="h3">
                            R$ 1900,00 <br />{" "}
                            <div
                              style={{ fontSize: "0.5em", lineHeight: "0em" }}
                            >
                              por mês
                            </div>
                          </CardTitle>
                          <ul>
                            <li>Preço unitário R$ 19,00</li>
                          </ul>
                        </CardBody>
                        <CardFooter>
                          <Button
                            className={
                              this.props.selectedPlan === "plano_100_1900"
                                ? "btn-round text-dark bg-light"
                                : "btn-round"
                            }
                            disabled={
                              this.props.company.plan === "plano_100_1900"
                                ? true
                                : false
                            }
                            color="default"
                            href="#pablo"
                            onClick={() =>
                              this.props.changePlan("plano_100_1900")
                            }
                          >
                            {this.props.selectedPlan === "plano_100_1900" && (
                              <span className="btn-label">
                                <i className="nc-icon nc-check-2" />
                              </span>
                            )}
                            {this.props.company.plan === "plano_100_1900"
                              ? "Seu plano atual"
                              : this.props.selectedPlan === "plano_100_1900"
                              ? "SELECIONADO"
                              : "SELECIONAR"}
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card
                        className={
                          this.props.selectedPlan === "plano_200_3600" ||
                          (this.props.location.state &&
                            this.props.location.state.plan) === "plano_200_3600"
                            ? "card-pricing text-white bg-dark"
                            : "card-pricing"
                        }
                      >
                        <CardHeader>
                          <h6
                            className={
                              this.props.selectedPlan === "plano_200_3600" ||
                              (this.props.location.state &&
                                this.props.location.state.plan) ===
                                "plano_200_3600"
                                ? "card-category text-white"
                                : "card-category"
                            }
                          >
                            Até 200 documentos/mês
                          </h6>
                        </CardHeader>
                        <CardBody>
                          <CardTitle tag="h3">
                            R$ 3.600,00 <br />{" "}
                            <div
                              style={{ fontSize: "0.5em", lineHeight: "0em" }}
                            >
                              por mês
                            </div>
                          </CardTitle>
                          <ul>
                            <li>Preço unitário R$ 18,00</li>
                          </ul>
                        </CardBody>
                        <CardFooter>
                          <Button
                            className={
                              this.props.selectedPlan === "plano_200_3600"
                                ? "btn-round text-dark bg-light"
                                : "btn-round"
                            }
                            disabled={
                              this.props.company.plan === "plano_200_3600"
                                ? true
                                : false
                            }
                            color="default"
                            href="#pablo"
                            onClick={() =>
                              this.props.changePlan("plano_200_3600")
                            }
                          >
                            {this.props.selectedPlan === "plano_200_3600" && (
                              <span className="btn-label">
                                <i className="nc-icon nc-check-2" />
                              </span>
                            )}
                            {this.props.company.plan === "plano_200_3600"
                              ? "Seu plano atual"
                              : this.props.selectedPlan === "plano_200_3600"
                              ? "SELECIONADO"
                              : "SELECIONAR"}
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card
                        style={{ maxHeight: "284px" }}
                        className="card-pricing"
                      >
                        <CardHeader></CardHeader>
                        <CardBody>
                          <CardTitle tag="h3">
                            {this.props.company.status === 1
                              ? "Cancelar Plano"
                              : "Plano Cancelado"}
                          </CardTitle>
                          <ul>
                            {this.props.company.status === 1 ? (
                              <li>
                                Cancele seu plano. Você não será cobrado em sua
                                próxima fatura e poderá utilizar de seu saldo
                                restante de documentos até a data de vencimento
                                do seu plano.
                              </li>
                            ) : (
                              <li>
                                Seu plano está cancelado. Você pode reativá-lo e
                                continuar com seu plano atual ou trocar de plano
                                e reativá-lo automaticamente.
                              </li>
                            )}
                          </ul>
                        </CardBody>
                        <CardFooter>
                          <Button
                            className="btn-round"
                            color={
                              this.props.company.status === 1
                                ? "danger"
                                : "success"
                            }
                            href="#pablo"
                            onClick={async () => {
                              let ret = "";
                              if (this.props.company.status === 1)
                                ret = await this.props.suspendPlan(
                                  this.props.company.companyId
                                );
                              else
                                ret = await this.props.activatePlan(
                                  this.props.company.companyId
                                );
                              if (ret.payload.status === 200)
                                this.props.selectCompany({
                                  ...this.props.company,
                                  status:
                                    this.props.company.status === 1 ? 2 : 1,
                                });
                            }}
                          >
                            {this.props.company.status === 1
                              ? "CANCELAR PLANO"
                              : "ATIVAR PLANO"}
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      * Nossos planos são cobrados mensalmente, você pode
                      solicitar o cancelamento a qualquer momento entrando em
                      contato com nosso atendimento. Para mais informações
                      consulte nosso <strong>termo de uso</strong>.
                    </Col>
                    <Col className="text-right">
                      <Button
                        style={{ width: "150px" }}
                        className="btn-round"
                        color="default"
                        disabled={
                          this.props.selectedPlan === this.props.company.plan
                        }
                        href="#pablo"
                        onClick={() => {
                          this.setState({ modal: !this.state.modal });
                          this.props.loadSimulation({
                            companyId: this.props.company.companyId,
                            plan_identifier: this.props.selectedPlan,
                          });
                        }}
                      >
                        CONFIRMAR
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </LoadingOverlay>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Auth,
  ...state.ChangePlan,
});

function mapDispatchToProps(dispatch) {
  return {
    changePlan: (value) => dispatch(changePlan(value)),
    unload: () => dispatch(unload()),
    loadSimulation: (value) => dispatch(loadSimulation(value)),
    save: (value) => dispatch(save(value)),
    suspendPlan: (value) => dispatch(suspendPlan(value)),
    activatePlan: (value) => dispatch(activatePlan(value)),
    selectCompany: (value) => dispatch(selectCompany(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePlan);
