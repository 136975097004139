import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import PrivateRoute from "./containers/PrivateRoute/PrivateRoute";
import PublicRoute from "containers/PublicRoute/PublicRoute";

import Home from "./containers/Home/Home";
import Register from "./containers/Register/Register";
import NotFound from "components/NotFound/NotFound";
import NewPassword from "containers/NewPassword/NewPassword";
import MaintenancePage from "components/MaintenancePage/MaintenancePage";
import SinglePurchase from "./containers/SinglePurchase/SinglePurchase";
import NewDocument from "./containers/SinglePurchase/NewDocument";
import NotifyParts from "containers/SinglePurchase/NotifyParts";
import Documents from "containers/SinglePurchase/Documents";

const hist = createBrowserHistory();

const router = (
  <Router history={hist} onUpdate={() => window.scrollTo(0, 0)}>
    <Switch>
      {process.env.REACT_APP_IN_MAINTENANCE && process.env.REACT_APP_IN_MAINTENANCE === "true" && <PublicRoute path="/" component={MaintenancePage} />}
      <PublicRoute exact path="/" component={Home} />
      <PublicRoute exact path="/register" component={Register} />
      <PublicRoute exact path="/singlePurchase" component={SinglePurchase} />
      <PublicRoute exact path="/singlePurchase/newDocument" component={NewDocument} />
      <PublicRoute exact path="/singlePurchase/notifyParts" component={NotifyParts} />
      <PublicRoute exact path="/singlePurchase/documents" component={Documents} />
      <PublicRoute exact path="/:token" component={NewPassword} />

      <Route path="/company" render={(props) => <PrivateRoute {...props} />} />
      <PublicRoute path="/*" component={NotFound} />
    </Switch>
  </Router>
);

export { router };
