const GET_NOTIFICATIONS = "confia/ListNotifications/GET_NOTIFICATIONS";
const GET_NOTIFICATIONS_SUCCESS =
  "confia/ListNotifications/GET_NOTIFICATIONS_SUCCESS";

const EXPORT_NOTIFICATIONS = "confia/ListNotifications/EXPORT_NOTIFICATIONS";
const EXPORT_NOTIFICATIONS_SUCCESS =
  "confia/ListNotifications/EXPORT_NOTIFICATIONS_SUCCESS";
const EXPORT_NOTIFICATIONS_FAIL =
  "confia/ListNotifications/EXPORT_NOTIFICATIONS_FAIL";

export function getNotifications(props) {
  return {
    type: GET_NOTIFICATIONS,
    payload: {
      request: {
        method: "post",
        url: `/api/notifications/${props.companyId}`,
        data: props.values,
      },
    },
  };
}

export function exportNotifications(props) {
  return {
    type: EXPORT_NOTIFICATIONS,
    payload: {
      request: {
        method: "post",
        url: `/api/notifications/${props.companyId}/export`,
        data: props.values,
      },
    },
  };
}

const INITIAL_STATE = { notifications: [], file: null, loadingPage: false };

export default function ListNotifications(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        loadingPage: true,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        notifications: action.payload.data ? action.payload.data : [],
      };

    case EXPORT_NOTIFICATIONS:
      return {
        ...state,
        loadingPage: true,
      };
    case EXPORT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        file: action.payload.data.file,
      };
    case EXPORT_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loadingPage: false,
        errorMessage: "Não foi exportar avisos.",
        error: true,
      };

    default:
      return state;
  }
}
