const UNLOAD = "confia/authentications/UNLOAD";
const LOAD = "confia/authentications/LOAD";
const LOAD_SUCCESS = "confia/authentications/LOAD_SUCCESS";
const LOAD_FAIL = "confia/authentications/LOAD_FAIL";
const LOAD_DETAILS = "confia/authentications/LOAD_DETAILS";
const LOAD_DETAILS_SUCCESS = "confia/authentications/LOAD_DETAILS_SUCCESS";
const LOAD_DETAILS_FAIL = "confia/authentications/LOAD_DETAILS_FAIL";
const LOAD_SELFIE = "confia/authentications/LOAD_SELFIE";
const LOAD_SELFIE_SUCCESS = "confia/authentications/LOAD_SELFIE_SUCCESS";
const LOAD_SELFIE_FAIL = "confia/authentications/LOAD_SELFIE_FAIL";

const SAVE_DATES = "confia/authentications/SAVE_DATES";

export function load(props) {
  console.log({ props });

  return {
    type: LOAD,
    payload: {
      request: {
        method: "post",
        url: `/api/authentications/company/${props.companyId}`,
        data: props.values,
      },
    },
  };
}

export function loadDetails(authenticationId) {
  return {
    type: LOAD_DETAILS,
    payload: {
      request: {
        method: "get",
        url: `/api/authentications/details/${authenticationId}`,
      },
    },
  };
}

export function loadSelfieDetails(imageId) {
  return {
    type: LOAD_SELFIE,
    payload: {
      request: {
        method: "get",
        url: `/api/authentications/details/image/${imageId}`,
      },
    },
  };
}

export function unload() {
  return {
    type: UNLOAD,
  };
}

export function saveDates(values) {
  console.log({ values });

  return {
    type: SAVE_DATES,
    payload: { search: values },
  };
}

const INITIAL_STATE = { authentications: [], file: null, loadingModal: false, authDetails: {}, search: {}, selfie: [] };
console.log(INITIAL_STATE.search);

export default function Authentications(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD:
      console.log(action.paylod);
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        authentications: action.payload.data.authentications,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar Autenticações.",
        error: true,
      };

    case LOAD_DETAILS:
      return {
        ...state,
        loadingModal: true,
      };
    case LOAD_DETAILS_SUCCESS:
      return {
        ...state,
        loadingModal: false,
        authDetails: action.payload.data.authDetails,
        selfie: [],
      };
    case LOAD_DETAILS_FAIL:
      return {
        ...state,
        loadingModal: false,
        error: true,
        errorMessage: "Não foi possível recuperar detalhes do documento.",
      };

    case LOAD_SELFIE:
      return {
        ...state,
        loading: false,
      };
    case LOAD_SELFIE_SUCCESS:
      return {
        ...state,
        loading: false,
        selfie: [...state.selfie, action.payload.data],
      };
    case LOAD_SELFIE_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar a imagem.",
        error: true,
      };

    case SAVE_DATES:
      console.log(action.payload.search);
      return {
        ...state,
        search: action.payload.search,
      };

    case UNLOAD:
      return {
        state: INITIAL_STATE,
      };

    // initial state
    default:
      return state;
  }
}
