import React from "react";
import {Link} from 'react-router-dom';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";


class DashboardCartorio extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="4" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-paper text-info" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Documentos Para Registros</p>
                        <CardTitle tag="p">150</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Row>
                    <Col md="6" xs="6">
                      <div className="text-left stats">
                        <Link to={'/cartorio/registro-documentos'} className="text-muted">
                        <i className="fa fa-check" />
                        Registrar
                        </Link>
                      </div>
                    </Col>
                    <Col md="6" xs="6">
                      <div className="text-right stats">
                        <Link className="text-muted">
                        <i className="fa fa-refresh" />
                        Atualizar
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="4" md="6" sm="6">
              <Link to={'/cartorio/detalhes-emolumentos'}>
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-money-coins text-success" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Emolumentos</p>
                          <CardTitle tag="p">R$ 1.345,53</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                      <Row>
                        <Col md="6" xs="6">
                          <div className="text-left stats">
                            <i className="fa fa-calendar-o" />
                            a receber até hoje
                          </div>
                        </Col>
                        <Col md="6" xs="6">
                          <div className="text-right stats">
                            <Link className="text-muted">
                            <i className="fa fa-search" />
                            </Link>
                          </div>
                        </Col>
                      </Row>
                  </CardFooter>
                </Card>
              </Link>
            </Col>

          </Row>
        </div>
      </>
    );
  }
}

export default DashboardCartorio;
