import { createStore, applyMiddleware, compose } from "redux";
import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";
import logger from "redux-logger";

import { reducers } from "./reducers/index";
import { getCredentials, setCredentials } from "./Utils/Credentials";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  withCredentials: true,
});

const middlewareConfig = {
  interceptors: {
    request: [
      {
        success: async function ({ getState, dispatch, getSourceAction }, req) {
          const credentials = await getCredentials();
          if (credentials) {
            req.headers.Authorization = `Bearer ${credentials}`;
          }
          return req;
        },
        error: function ({ getState, dispatch, getSourceAction }, error) {
          return error;
        },
      },
    ],
    response: [
      {
        success: function ({ getState, dispatch, getSourceAction }, res) {
          const urls = ["/api/auth/login", "/api/singlePurchase/validateCode", "/api/auth/idrc"];
          if (urls.includes(res.config.url) && res.status === 200) {
            setCredentials(res.data.login.token, res.data.login.refresh);
          }
          return Promise.resolve(res);
        },
        error: function ({ getState, dispatch, getSourceAction }, error) {
          return Promise.reject(error);
        },
      },
    ],
  },
  onError: ({ action, dispatch, error }) => {
    const errorResponse = error.message; // Mensagem de erro genérica da response
    const errorMessageResponse = error.response ? error.response.data.message : errorResponse; // Mensagem de erro customizada(json)
    dispatch({ type: action.type + "_FAIL", payload: { error: errorResponse, errorMessageResponse: errorMessageResponse } });
  },
};

// add the middlewares
let middlewares;
if (process.env.NODE_ENV !== "production") {
  middlewares = [axiosMiddleware(client, middlewareConfig), logger];
} else {
  middlewares = [axiosMiddleware(client, middlewareConfig)];
}

// apply the middleware
let middleware = applyMiddleware(...middlewares);

// add the redux dev tools
if (process.env.NODE_ENV !== "production" && window.devToolsExtension) {
  middleware = compose(middleware, window.devToolsExtension());
}

// create the store
const store = createStore(reducers, middleware);

// export
export { store };
