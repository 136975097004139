import React from "react";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import { Button, Col } from "reactstrap";

class ArchiveUpload extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        file: null,
      };
      this.handleImageChange = this.handleImageChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.handleRemove = this.handleRemove.bind(this);
    }
    handleImageChange(e) {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
        this.setState({
          file: file,
        });
      };
      reader.readAsDataURL(file);  
      this.props.addFile(file);
      var doc = {
        file: file,
      }
      if(this.props.process){        
        this.props.process(doc);
      }
    }
    handleSubmit(e) {
      e.preventDefault();
      // this.state.file is the file/image uploaded
      // in this function you can save the image (this.state.file) on form submit
      // you have to call it yourself
    }
    handleClick() {
      this.refs.fileInput.click();
    }
    handleRemove() {
      this.setState({
        file: null,
      });
      this.refs.fileInput.value = null;
      this.props.removeFile();
    }
    render() {
      return (
        <div className="fileinput text-center">
          <input type="file" onChange={this.handleImageChange} ref="fileInput" accept={this.props.typeAccept} />
          <div>
            {this.state.file === null ? (
              <Link className="btn btn-sm" onClick={() => this.handleClick()}>
                {this.props.labelButton}
              </Link>
            ) : (
              <span>
                <Col className="mx-0 mb-0" md="12" sm="12">
                <label>{this.state.file.name}</label>
                </Col>
                <Col className="mx-0 mt-0" md="12" sm="12">
                <Link className="btn btn-sm" onClick={() => this.handleClick()} style={{margin: '0px 0px'}}>
                  Alterar
                </Link>
                {this.props.avatar ? <br /> : null}
                <Link className="btn btn-sm btn-danger"  onClick={() => this.handleRemove()}>
                  <i className="fa fa-times" />
                  Excluir
                </Link>
                </Col>
              </span>
            )}
          </div>
        </div>
      );
    }
  }
  
  ArchiveUpload.propTypes = {
    labelButton: PropTypes.string.isRequired,
    typeAccept: PropTypes.string.isRequired,
    addFile: PropTypes.func.isRequired,
    removeFile: PropTypes.func.isRequired,
    process: PropTypes.func
  }
  
  export default ArchiveUpload;