import React from "react";
import classnames from "classnames";
// reactstrap components
import { Input, Row, Col, Label, UncontrolledAlert } from "reactstrap";
import { Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import NotificationAlert from "react-notification-alert";

import TextField from "components/Fields/TextField";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import FieldValidation from "components/Fields/Validation/FieldValidation";

// core components

class CompanyAddress extends React.Component {
  constructor(props) {
    super(props);
  }

  isValidated = () => {
    var ret = true;

    if (!this.props.values.zipCode) {
      ret = false;
    }
    if (!this.props.values.state) {
      ret = false;
    }
    if (!this.props.values.city) {
      ret = false;
    }
    if (!this.props.values.neighborhood) {
      ret = false;
    }
    if (!this.props.values.address) {
      ret = false;
    }
    if (!this.props.values.number) {
      ret = false;
    }

    if (!ret) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>Campos obrigatórios não preenchidos.</div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
    }

    return ret;
  };
  render() {
    return (
      <>
        <h5 className="info-text">ENDEREÇO DA EMPRESA</h5>
        {this.props.error && (
          <Col className="mx-auto " md="12">
            <UncontrolledAlert color="danger" fade={true}>
              <span>
                <b>{this.props.errorMessage}</b>
              </span>
            </UncontrolledAlert>
          </Col>
        )}
        <NotificationAlert ref="notificationAlert" />
        <Row className="justify-content-center">
          <Col className="mx-auto " md="12">
            <Field name="zipCode" placeholder="* CEP" component={TextField} parse={FieldNormalize.CEP} validate={FieldValidation.required} />
            <OnChange name="zipCode">
              {(value, previous) => {
                if (value.replace(/[^\d]/g, "").length === 8) {
                  this.props.searchAddress(value.replace(/[^\d]/g, ""));
                }
                //By pass por ter removido a aba de plano
                this.props.choosePlan("plano_outro");
              }}
            </OnChange>
          </Col>
          <Col className="mx-auto " md="4">
            <Field name="state" placeholder="* Estado" component={TextField} validate={FieldValidation.required} />
          </Col>
          <Col className="mx-auto " md="4">
            <Field name="city" placeholder="* Cidade" component={TextField} validate={FieldValidation.required} />
          </Col>
          <Col className="mx-auto " md="4">
            <Field name="neighborhood" placeholder="* Bairro" component={TextField} validate={FieldValidation.required} />
          </Col>
          <Col className="mx-auto " md="8">
            <Field name="address" placeholder="* Endereço" component={TextField} validate={FieldValidation.required} />
          </Col>
          <Col className="mx-auto " md="4">
            <Field name="number" placeholder="* Número" component={TextField} validate={FieldValidation.required} />
          </Col>
          <Col className="mx-auto " md="12">
            <Field name="complement" placeholder="Complemento" component={TextField} />
          </Col>
        </Row>
      </>
    );
  }
}

export default CompanyAddress;
