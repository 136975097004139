const UNLOAD = "confia/new-user/UNLOAD";
const SAVE = "confia/new-user/SAVE";
const SAVE_SUCCESS = "confia/new-user/SAVE_SUCCESS";
const SAVE_FAIL = "confia/new-user/SAVE_FAIL";
const LOAD = "confia/new-user/LOAD";
const LOAD_SUCCESS = "confia/new-user/LOAD_SUCCESS";
const LOAD_FAIL = "confia/new-user/LOAD_FAIL";
const SEARCH_BY_CPF = "confia/new-user/SEARCH_BY_CPF";
const SEARCH_BY_CPF_SUCCESS = "confia/new-user/SEARCH_BY_CPF_SUCCESS";
const SEARCH_BY_CPF_FAIL = "confia/new-user/SEARCH_BY_CPF_FAIL";
const UPDATE_FORM_STATE = "confia/new-user/UPDATE_FORM_STATE";

export function updateFormState(state) {
  return {
    type: UPDATE_FORM_STATE,
    payload: state,
  };
}

export function unload() {
  return {
    type: UNLOAD,
  };
}

export function load(userId) {
  return {
    type: LOAD,
    payload: {
      request: {
        method: "get",
        url: `/api/user/${userId}`,
      },
    },
  };
}

export function save(values, companyId) {
  return {
    type: SAVE,
    payload: {
      request: {
        method: "post",
        url: `/api/company/${companyId}/user/`,
        data: values,
      },
    },
  };
}

export function searchByCPF(cpf) {
  return {
    type: SEARCH_BY_CPF,
    payload: {
      request: {
        method: "get",
        url: `/api/existing-user/${encodeURI(cpf)}`,
      },
    },
  };
}

const INITIAL_STATE = {
  error: false,
  errorMessage: null,
  alert: false,
  alertMessage: null,
  user: null,
};

export default function NewUser(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE:
      return {
        ...state,
        loading: true,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        document: action.payload.data.document,
      };
    case SAVE_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível cadastrar o usuário.",
        error: true,
      };

    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        balance: action.payload.data.currentBalance
          ? action.payload.data.currentBalance.balance
          : 0,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar os dados.",
        error: true,
      };

    case UPDATE_FORM_STATE:
      return {
        ...state,
        registerForm: action.payload.values,
      };

    case SEARCH_BY_CPF:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_BY_CPF_SUCCESS:
      return {
        ...state,
        loading: false,
        registerForm: { ...state.registerForm, ...action.payload.data.user },
      };
    case SEARCH_BY_CPF_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível pesquisar pelo CPF.",
        error: true,
      };

    case UNLOAD:
      return INITIAL_STATE;

    // initial state
    default:
      return state;
  }
}
