const UNLOAD = "confia/users/UNLOAD";
const LOAD = "confia/users/LOAD";
const LOAD_SUCCESS = "confia/users/LOAD_SUCCESS";
const LOAD_FAIL = "confia/users/LOAD_FAIL";
const REMOVE_USER = "confia/users/REMOVE_USER";
const REMOVE_USER_SUCCESS = "confia/users/REMOVE_USER";
const REMOVE_USER_FAIL = "confia/users/REMOVE_USER";
const UNLOCK_USER = "confia/users/UNLOCK_USER";
const UNLOCK_USER_SUCCESS = "confia/users/UNLOCK_USER";
const UNLOCK_USER_FAIL = "confia/users/UNLOCK_USER";
const SAVE = "confia/users/SAVE";
const SAVE_SUCCESS = "confia/users/SAVE_SUCCESS";
const SAVE_FAIL = "confia/users/SAVE_FAIL";

export function load(companyId) {
  return {
    type: LOAD,
    payload: {
      request: {
        method: "get",
        url: `/api/company/${companyId}/user/`,
      },
    },
  };
}

export function unload() {
  return {
    type: UNLOAD,
  };
}

export function removeUser(props) {
  return {
    type: REMOVE_USER,
    payload: {
      request: {
        method: "delete",
        url: `/api/user/${props.companyId}/${props.userId}`,
      },
    },
  };
}

export function unlockUser(userId) {
  return {
    type: UNLOCK_USER,
    payload: {
      request: {
        method: "post",
        url: `/api/user/unlock/${userId}`,
      },
    },
  };
}

export function save(props) {
  return {
    type: SAVE,
    payload: {
      request: {
        method: "put",
        url: `/api/user/${props.userId}/${props.companyId}`,
        data: props.values,
      },
    },
  };
}

const INITIAL_STATE = { users: [], file: null };

export default function Users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.data.users,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar as informações do usuário.",
        error: true,
      };

    case REMOVE_USER:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_USER_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível realizar a operação.",
      };

    case UNLOCK_USER:
      return {
        ...state,
        loading: true,
      };
    case UNLOCK_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: true,
        alertMessage: "Usuário desbloqueado com sucesso.",
      };
    case UNLOCK_USER_FAIL:
      return {
        ...state,
        loading: false,
      };

    case SAVE:
      return {
        ...state,
        loading: true,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SAVE_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível salvar suas alterações.",
        error: true,
      };

    case UNLOAD:
      return {
        state: INITIAL_STATE,
      };

    // initial state
    default:
      return state;
  }
}
