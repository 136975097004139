import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import LoadingOverlay from "react-loading-overlay";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Card, CardHeader, CardBody, CardFooter, Row, Col, Button, CardTitle, Container, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import NotificationAlert from "react-notification-alert";

import TextField from "components/Fields/TextField";
import SelectField from "components/Fields/SelectField";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import FieldValidation from "components/Fields/Validation/FieldValidation";
import Payment from "containers/Register/Payment";

import { save, addPart, removePart, loginToken, priceRange, payment, unloadNewDocument, getParamsUrl } from "reducers/SinglePurchase";

import ReactTable from "react-table";

let IUGU_PROD = process.env.REACT_APP_IUGU_PROD;
class NewDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      modalCard: false,
      popover: false,
    };
    this.fileInput = React.createRef();
  }

  async componentDidMount() {
    if (!this.props.user) {
      //Try to reconect using token
      await this.props.loginToken();
    }
    if (this.props.company) {
      await this.props.priceRange(this.props.company.companyId);
    }

    this.props.getParamsUrl("type");
  }

  componentWillUnmount() {
    this.props.unloadNewDocument();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error) {
      if (nextProps.error === true) {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>{nextProps.errorMessage}</div>
            </div>
          ),
          type: "danger",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    }

    if (nextProps.message !== this.props.message) {
      if (nextProps.message === true) {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>
                <strong>{nextProps.successMessage}</strong>
              </div>
            </div>
          ),
          type: "success",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    }
  }

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        files: [...this.state.files, file],
      });
    };
    reader.readAsDataURL(file);

    console.log("FILE", file);
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleClick = () => {
    this.fileInput.current.click();
  };

  handleRemoveFile = (file) => {
    this.setState({
      files: this.state.files.filter((element) => element !== file),
    });
  };

  handleAddPart = (values, form) => (ev) => {
    ev.preventDefault();

    // validating cpf
    if (FieldValidation.cpf(values.signPartCpf)) {
      return;
    }

    if (this.props.parts.find((p) => p.id === values.signPartCpf)) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>Cpf já adicionado.</div>
          </div>
        ),
        type: "warning",
        icon: "fa fa-exclamation-triangle",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
      return;
    }

    if (values.signPartName && values.signPartCpf) {
      var part = {
        id: values.signPartCpf,
        partName: values.signPartName,
        partSignStep: 1,
        partCpf: values.signPartCpf,
        partPhone: values.signPartPhone,
        partEmail: values.signPartEmail,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Link color="danger" size="sm" className="btn btn-icon btn-link like" onClick={this.handleRemovePart(values.signPartCpf)}>
              <i className="fa fa-times" />
            </Link>{" "}
          </div>
        ),
      };
      this.props.addPart(part);
      form.change("signPartName", "");
      form.resetFieldState("signPartName");
      form.change("signPartCpf", "");
      form.resetFieldState("signPartCpf");
      form.change("signPartPhone", "");
      form.resetFieldState("signPartPhone");
      form.change("signPartEmail", "");
      form.resetFieldState("signPartEmail");
    } else {
      form.submit();
    }
  };

  handleRemovePart = (id) => (ev) => {
    ev.preventDefault();
    this.props.removePart(id);
  };

  handleSend = (values, form) => (ev) => {
    ev.preventDefault();
    form.submit();

    console.log(values);

    if (!this.state.files[0]) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>Selecione um documento.</div>
          </div>
        ),
        type: "danger",
        icon: "fa fa-exclamation-triangle",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
      return;
    }

    // check if values are valid
    if (!values.name || !values.type || !values.senderName) {
      return;
    }

    this.setState({ modalCard: !this.state.modalCard });
  };

  savePayment = (values, form) => (ev) => {
    ev.preventDefault();
    form.submit();

    console.log(values);
    if (!values.cpf_cnpj || !values.cardNumber || !values.cardName || !values.cardValid || !values.cardCVC) {
      return;
    }

    window.Iugu.setAccountID("B80CAA12CC124C34A24C152208D762C2");
    var validCard = values.cardValid.split("/");
    var pos = values.cardName.indexOf(" ");
    var params = window.Iugu.CreditCard(values.cardNumber, validCard[0], validCard[1], values.cardName.substring(0, pos), values.cardName.substring(pos + 1), values.cardCVC);
    //params = window.Iugu.CreditCard("4111111111111111", "09", "2022", "Nome", "Sobrenome", "728");
    if (IUGU_PROD === "false") {
      window.Iugu.setTestMode(true);
    }

    window.Iugu.createPaymentToken(params, (response) => {
      if (response.errors) {
        if (document.getElementById("msgDataInvalid")) {
          document.getElementById("msgDataInvalid").style.display = "block";
          setTimeout(() => {
            document.getElementById("msgDataInvalid").style.display = "none";
          }, 3000);
        }
      } else {
        console.log(response.id);
        var params = {
          paymentToken: response.id,
          email: this.props.user.email,
          companyId: this.props.company.companyId,
          qtdParts: this.props.parts.length,
          userId: this.props.user.userId,
          cpf_cnpj: values.cpf_cnpj,
          name: values.cardName,
        };
        this.props.payment(params).then((res) => {
          if (res && res.type.includes("SUCCESS")) {
            var vp = this.props.parts.slice();
            var doc = {
              name: values.name,
              type: values.type.value,
              senderName: values.senderName,
              metadata: null,
              companyId: this.props.company.companyId,
              userId: this.props.user.userId,
              parts: JSON.stringify(
                vp.map(function (item) {
                  delete item.actions;
                  delete item.id;
                  return JSON.stringify(item);
                })
              ),
              files: this.state.files,
            };
            this.props.save(doc);
          } else {
            if (document.getElementById("msg")) {
              document.getElementById("msg").style.display = "block";
              setTimeout(() => {
                document.getElementById("msg").style.display = "none";
              }, 3000);
            }
          }
        });
      }
    });
  };

  onSubmit = async (values) => {
    console.log("SUBMIT:", values);
  };

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
  };
  render() {
    if (this.props.redirectToSingle) {
      return <Redirect to={"/singlePurchase"} />;
    } else if (this.props.redirectToNotify) {
      if (this.props.typeUrl === "rtd") {
        return <Redirect to={"/singlePurchase/notifyParts?type=rtd"} />;
      } else {
        return <Redirect to={"/singlePurchase/notifyParts"} />;
      }
    }
    return (
      <>
        <div className="login-page">
          <NotificationAlert ref="notificationAlert" />
          <Container>
            <Row>
              <div className="col-md-2 ml-auto mr-auto mt-5">
                <img alt="..." src={require("stylesheets/img/logo/confia_site_branca.png")} />
              </div>
              <Col className="ml-auto mr-auto mt-5" lg="12" md="4">
                <LoadingOverlay active={this.props.loading} spinner text="Processando...">
                  <Form
                    onSubmit={this.onSubmit}
                    initialValues={{}}
                    validate={(values) => {
                      const errors = {};
                      if (!this.props.parts || this.props.parts.length === 0) {
                        if (!values.signPartName || values.signPartName.trim() === "") {
                          errors.signPartName = "Campo obrigatório";
                        }
                        if (!values.signPartCpf || values.signPartCpf.trim() === "") {
                          errors.signPartCpf = "Campo obrigatório";
                        }
                        if (!values.signPartStep) {
                          errors.signPartStep = "Campo obrigatório";
                        }
                      } else {
                        if (!values.senderName || values.senderName.trim() === "") {
                          errors.senderName = "Campo obrigatório";
                        }
                        if (!values.name || values.name.trim() === "") {
                          errors.name = "Campo obrigatório";
                        }
                        if (!values.type) {
                          errors.type = "Campo obrigatório";
                        }
                      }
                      return errors;
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                      <form onSubmit={handleSubmit}>
                        <Card>
                          <CardHeader className="boder-buttom">
                            <h6>
                              NOVO DOCUMENTO
                              <i className="fa fa-question-circle-o fa-lg" id="Popover1" onClick={() => this.setState({ popover: !this.state.popover })} style={{ paddingLeft: "10px" }} />
                            </h6>
                          </CardHeader>
                          <CardHeader>
                            <Row>
                              <Col md="3" sm="4">
                                <Field name="senderName" label="Remetente" validate={FieldValidation.required} component={TextField} maxLength="20" />
                              </Col>
                              <Col md="3" sm="4">
                                <Field name="name" label="Nome do documento" validate={FieldValidation.required} component={TextField} maxLength="45" />
                              </Col>
                              <Col md="3" sm="4">
                                <Field
                                  name="type"
                                  label="Tipo do documento"
                                  validate={FieldValidation.required}
                                  component={SelectField}
                                  options={[
                                    { id: "ata", desc: "ATA" },
                                    {
                                      id: "contrato de crédito consignado",
                                      desc: "CCB CONSIGNADO",
                                    },
                                    { id: "contrato", desc: "CONTRATO" },
                                    { id: "documento", desc: "DOCUMENTO" },
                                    { id: "procuração", desc: "PROCURAÇÃO" },
                                    { id: "outros", desc: "OUTROS" },
                                  ]}
                                  textField="desc"
                                  valueField="id"
                                  placeholder="Selecione"
                                />
                              </Col>

                              <Col md="3" sm="4">
                                <div className="fileinput text-center">
                                  <input type="file" onChange={this.handleImageChange} ref={this.fileInput} accept="application/pdf" />
                                  <div>
                                    <Button className="btn-round" multiple={true} onClick={() => this.handleClick()} style={{ margin: "25px 1px", backgroundColor: "#818c8c" }}>
                                      {"ADICIONAR DOCUMENTO EM PDF"}
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {this.state.files[0] && (
                              <>
                                <Row>
                                  <Col className="listDocuments" md="12" lg="12" sm="12">
                                    Documentos Anexados
                                  </Col>
                                  <Col className="listDocuments" md="12" lg="12" sm="12">
                                    {this.state.files.map((document) => (
                                      <p>
                                        {document.name} <i className="fa fa-times" onClick={() => this.handleRemoveFile(document)} />
                                      </p>
                                    ))}
                                  </Col>
                                </Row>
                              </>
                            )}
                          </CardHeader>
                          <CardBody className="border-bottom">
                            <CardHeader className="border-bottom">
                              <h6>Assinantes</h6>
                            </CardHeader>
                            <Row>
                              <Col md="2" sm="12" lg="2">
                                <Field name="signPartCpf" label="CPF" component={TextField} required parse={FieldNormalize.CPF} validate={FieldValidation.composeValidators(FieldValidation.cpf)} />
                              </Col>
                              <Col md="3" sm="12" lg="3">
                                <Field name="signPartName" label="Nome" component={TextField} required maxLength="500" />
                              </Col>
                              <Col sm="12" lg="2" md="2">
                                <Field name="signPartPhone" label="Celular" component={TextField} parse={FieldNormalize.CELULAR} />
                              </Col>
                              <Col lg="3" md="3" sm="12">
                                <Field name="signPartEmail" label="E-mail" component={TextField} validate={FieldValidation.composeValidators(FieldValidation.email)} maxLength="225" />
                              </Col>
                              {this.state.hasSteps && (
                                <Col className="mx-auto" md="2" sm="2">
                                  <Field
                                    name="signPartStep"
                                    label="Etapa de assinatura"
                                    required
                                    component={SelectField}
                                    options={this.state.steps}
                                    placeholder="Selecione"
                                    textField="desc"
                                    valueField="id"
                                  />
                                </Col>
                              )}
                              <Col>
                                <Link
                                  className="btn btn-round"
                                  style={{ marginTop: "25px", backgroundColor: "#818c8c" }}
                                  onClick={this.handleAddPart(values, form)}
                                  disabled={this.props.parts && this.props.parts.length === 50}
                                >
                                  {"ADICIONAR ASSINANTE"}
                                </Link>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="justify-content-center mx-auto" md="12">
                                <h6 className="mt-3 mb-2">LISTA DE ASSINANTES</h6>
                                <ReactTable
                                  data={this.props.parts}
                                  columns={[
                                    {
                                      Header: "Nome",
                                      accessor: "partName",
                                      headerClassName: "headTable",
                                    },
                                    {
                                      Header: "CPF",
                                      accessor: "partCpf",
                                      headerClassName: "headTable",
                                    },
                                    {
                                      Header: "Celular",
                                      accessor: "partPhone",
                                      headerClassName: "headTable",
                                    },
                                    {
                                      Header: "E-mail",
                                      accessor: "partEmail",
                                      headerClassName: "headTable",
                                    },
                                    {
                                      Header: "EXCLUIR",
                                      accessor: "actions",
                                      width: 80,
                                      headerClassName: "headTable text-left",
                                    },
                                  ]}
                                  showPagination={true}
                                  showPaginationTop={false}
                                  defaultPageSize={20}
                                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                                  showPaginationBottom={true}
                                  minRows={1}
                                  sortable={false}
                                  loadingText="Carregando..."
                                  noDataText="Nenhum assinante"
                                  className="-striped -highlight"
                                  pageText="Página"
                                  ofText="de"
                                  rowsText="linhas"
                                />
                              </Col>
                            </Row>
                          </CardBody>
                          <CardFooter className="mt-3 ">
                            <Row>
                              <Col className="amout text-center" md="4" lg="4" sm="12">
                                <p>
                                  VALOR TOTAL : <span style={{ fontWeight: "700" }}>{Number(this.props.amount).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</span>{" "}
                                </p>
                              </Col>
                              <Col md="5" lg="5" />

                              <Col className="text-right">
                                <Link className="btn btn-round" style={{ backgroundColor: "#818c8c", marginRight: "20px" }} to="/singlePurchase/documents">
                                  {"CANCELAR"}
                                </Link>
                                <Link className="btn btn-round" onClick={this.handleSend(values, form)}>
                                  {"PRÓXIMO"}
                                </Link>
                              </Col>
                            </Row>
                          </CardFooter>
                        </Card>

                        <Modal isOpen={this.state.modalCard} toggle={this.toggle} className={this.props.className} backdrop="static" size="lg">
                          <LoadingOverlay active={this.props.loading} spinner text="Processando...">
                            <CardBody>
                              <Row>
                                <Col className="border-bottom">
                                  <h6> PAGAMENTO</h6>
                                </Col>
                              </Row>
                              <Col>
                                <Payment values={values} />
                              </Col>
                            </CardBody>

                            <ModalFooter>
                              <Button className="mr-2" style={{ backgroundColor: "#8a8c8c" }} onClick={() => this.setState({ modalCard: !this.state.modalCard })}>
                                CANCELAR
                              </Button>

                              <Button className="mr-5" onClick={this.savePayment(values, form)}>
                                CONFIRMAR PAGAMENTO
                              </Button>
                            </ModalFooter>
                            <div id="msgDataInvalid" style={{ display: "none" }} class="error-msg text-center">
                              <i class="fa fa-check" />
                              {"Dados inválidos."}
                            </div>
                            <div id="msg" style={{ display: "none" }} class="error-msg text-center">
                              <i class="fa fa-check" />
                              {this.props.errorMessagePayment}
                            </div>
                          </LoadingOverlay>
                        </Modal>
                      </form>
                    )}
                  />
                </LoadingOverlay>
              </Col>
            </Row>
            <Popover placement="bottom" isOpen={this.state.popover} target="Popover1" onClick={() => this.setState({ popover: !this.state.popover })}>
              <PopoverHeader className="mt-3" style={{ color: "#ffffff", fontWeight: "700" }}>
                ANEXAR DOCUMENTOS
              </PopoverHeader>
              <PopoverBody>
                <p>O arquivo a ser anexado precisa estar em PDF.</p>
                <h6>ADICIONAR ASSINANTES</h6>
                <p>Preencha os campos CPF, nome, celular e e-mail de quem vai assinar o documento.</p>
                <h6>LISTA DE ASSINANTES</h6>
                <p>A lista dos assinantes mostra os dados de quem vai assinar o documento. Esse é o momento de conferir os dados do assinante.</p>
              </PopoverBody>
            </Popover>
          </Container>
        </div>
      </>
    );
  }
}

const onSubmit = async (values) => {
  //  await sleep(300)
};

const mapStateToProps = (state) => ({
  ...state.SinglePurchase,
  alertNewDocument: state.NewDocument.alert,
  alertMessageNewDocument: state.NewDocument.alertMessage,
});

function finalModal(state) {
  return (
    <>
      <Modal isOpen={state}>
        <h1>teste</h1>
      </Modal>
    </>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    save: (values) => dispatch(save(values)),
    addPart: (part) => dispatch(addPart(part)),
    removePart: (id) => dispatch(removePart(id)),
    loginToken: () => dispatch(loginToken()),
    priceRange: (companyId) => dispatch(priceRange(companyId)),
    payment: (values) => dispatch(payment(values)),
    unloadNewDocument: () => dispatch(unloadNewDocument()),
    getParamsUrl: (params) => dispatch(getParamsUrl(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDocument);
