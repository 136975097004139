import React from "react";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import { Button, Card, CardHeader, CardBody, CardFooter, Container, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import TextField from "components/Fields/TextField";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import FieldValidation from "components/Fields/Validation/FieldValidation";
import ReCAPTCHA from "react-google-recaptcha";
import { generateCode, validateCode, getParamsUrl } from "reducers/SinglePurchase";
import MaskedInput from "react-maskedinput";

class SinglePurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      code: "",
      captchaOk: false,
      typeUrl: null,
      warningModal: true,
      rcpj: false,
    };
    this.captchaRef = React.createRef();
  }

  componentDidMount() {
    this.props.getParamsUrl("type");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error) {
      if (nextProps.error === true) {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>{nextProps.errorMessage}</div>
            </div>
          ),
          type: "danger",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    }

    if (nextProps.message !== this.props.message) {
      if (nextProps.message === true) {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>
                <strong>{nextProps.successMessage}</strong>
              </div>
            </div>
          ),
          type: "success",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    }
  }

  onSubmit = async (values) => {};

  handleSend = (values, form) => (ev) => {
    console.log("--->> Send");
    ev.preventDefault();
    form.submit();

    if (!values.email || values.email.length === 0) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>Informe um E-mail.</div>
          </div>
        ),
        type: "danger",
        icon: "fa fa-exclamation-triangle",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
      return;
    }

    var params = {
      email: values.email,
      captcha: this.captchaRef.current.getValue(),
    };

    this.props.generateCode(params).then((res) => {
      if (res && res.type.includes("SUCCESS")) {
        // this.captchaRef.current.reset();
        // this.setState({ captchaLoginOk: false });
        this.setState({
          modal: !this.state.modal,
        });
      }
    });
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  _onChange = (e) => {
    var code = e.target.value.replace(/[^a-z0-9]/gi, "");
    if (code.length === 6) {
      var params = {
        email: this.props.email,
        lastCode: code,
      };
      this.props.validateCode(params).then((res) => {
        if (res && res.type.includes("SUCCESS")) {
          this.setState({
            modal: !this.state.modal,
          });
        } else {
          if (document.getElementById("msg")) {
            document.getElementById("msg").style.display = "block";
            setTimeout(() => {
              document.getElementById("msg").style.display = "none";
            }, 3000);
          }
        }
      });
    }
  };

  render() {
    if (this.props.redirectTo) {
      return <Redirect to={this.props.redirectTo} />;
    }
    return (
      <div>
        <NotificationAlert ref="notificationAlert" />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="12" md="6">
              <div className="col-md-3 ml-auto mr-auto mb-2 mt-5">
                <img alt="..." src={require("stylesheets/img/logo/confia_site_branca.png")} />
              </div>
              <Row>
                <Col className="header text-center text-white">
                  {/* {this.props.typeUrl === "RTD" || this.props.typeUrl === "rtd" ? ( */}
                  <>
                    <h5>A partir de agora, você está na plataforma de assinatura eletrônica da Confia</h5>
                  </>
                  {/* ) : (
                    <>
                      <h5>
                        A única assinatura eletrônica oficial dos Cartórios RTDPJ <br />
                        do Brasil, totalmente integrada à Central Nacional dos Cartórios.
                      </h5>
                    </>
                  )} */}
                </Col>
              </Row>

              <Form
                onSubmit={this.onSubmit}
                initialValues={{}}
                render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                  <form onSubmit={handleSubmit}>
                    <Card>
                      {this.props.typeUrl === "RTD" || this.props.typeUrl === "rtd" ? (
                        <>
                          <CardHeader style={{ backgroundColor: "#ffffff" }}>
                            <Col className="text-center border-bottom">
                              <h4>Aqui você assina o seu documento com segurança jurídica em poucos cliques, siga os passos:</h4>
                              <img alt="..." src={require("stylesheets/img/icones/line-single-02.png")} />
                              <br />
                              <br />
                              <br />
                            </Col>
                          </CardHeader>
                          <CardBody style={{ backgroundColor: "#ffffff" }}>
                            <div className="text-center">
                              <h5>
                                <br /> Preencha seu e-mail e comece a enviar documentos <br /> pagando apenas <strong>R$ 6,50</strong> por assinatura.
                              </h5>
                            </div>
                          </CardBody>

                          <div>
                            <Modal isOpen={this.state.warningModal}>
                              <ModalHeader>
                                <p>ANTES DE CONTINUAR, INFORME O TIPO DE DOCUMENTO QUE DESEJA ASSINAR:</p>
                              </ModalHeader>
                              {this.state.rcpj ? (
                                <ModalBody>
                                  <Row>
                                    <Col className="text-center">
                                      <p style={{ fontWeight: 700, fontSize: 18 }}>
                                        Se você quer criar, alterar, encerrar uma empresa ou fazer qualquer alteração em contratos que necessitam de conexão com a RedeSim, entre em contato com o
                                        suporte da Central RTDPJBrasil para conhecer as alternativas de envio eletrônico. 
                                      </p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="text-center">
                                      <Button onClick={() => this.setState({ warningModal: false })}>OK</Button>
                                    </Col>
                                  </Row>
                                </ModalBody>
                              ) : (
                                <>
                                  <ModalBody>
                                    <Row>
                                      <Col className="text-center">
                                        <Button onClick={() => this.setState({ warningModal: false })}>Registro de Títulos e Documentos</Button>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="text-center">
                                        <Button onClick={() => this.setState({ rcpj: true })}>
                                          Registro Civil de Pessoas Jurídicas
                                          <br /> (Criação, alteração ou encerramento de empresa)
                                        </Button>
                                      </Col>
                                    </Row>
                                  </ModalBody>
                                </>
                              )}
                            </Modal>
                          </div>
                        </>
                      ) : (
                        <>
                          <CardBody style={{ backgroundColor: "#ffffff" }}>
                            <div className="text-center">
                              <h5 className="mt-5">
                                {" "}
                                Aqui você assina o seu documento com segurança jurídica <br /> em poucos cliques. Preencha seu e-mail e comece a enviar <br />
                                documentos pagando apenas <strong>R$ 6,50</strong> por assinatura.
                              </h5>
                            </div>
                          </CardBody>
                        </>
                      )}

                      <CardBody>
                        <Col className="mx-auto " md="4">
                          <Field
                            name="email"
                            placeholder="Digite o E-mail"
                            component={TextField}
                            type="email"
                            required
                            validate={FieldValidation.composeValidators(FieldValidation.required, FieldValidation.email)}
                          />
                        </Col>
                        <Col style={{ display: "flex", justifyContent: "center" }} md="12">
                          <ReCAPTCHA
                            sitekey="6Lfyr-EcAAAAAA0U0kVhxSpJE65AdCMnLgkxJB2S"
                            onChange={() => this.setState({ captchaOk: true })}
                            onExpired={() => this.setState({ captchaOk: false })}
                            ref={this.captchaRef}
                            hl="pt-BR"
                          />
                        </Col>
                        <Col className="mx-auto mt-5" md="6">
                          <h5 className="text-secondary mb-2 text-center">
                            Ao clicar no botão abaixo você aceita os{" "}
                            <a href="https://www.confia.net.br/privacidade" target="_blank" className="link-dark">
                              termos de uso e política de privacidade.
                            </a>
                          </h5>
                        </Col>
                        <Col className="mx-auto" md="4">
                          <Link to="" color="default" disabled={!this.state.captchaOk} className="btn btn-block btn-round mb-3" onClick={this.handleSend(values, form)}>
                            Aceito os termos de uso
                          </Link>
                        </Col>
                      </CardBody>
                      <CardFooter style={{ backgroundColor: "#e3e3e3" }}>
                        <Col className="mt-5" md="12">
                          <h3 className="header text-center">
                            <strong style={{ color: "#2f2f2f" }}>VANTAGENS DA ASSINATURA CONFIA</strong>
                          </h3>
                        </Col>
                        <Row>
                          <Col className="text-center">
                            <img style={{ height: "180px", objectFit: "cover" }} alt="..." src={require("stylesheets/img/icones/Confia-08.png")} />
                            <div style={{ fontSize: "18px", marginTop: "-35px" }}>Está associada à pessoa de maneira única e intransferível. Identificação por biometria facial.</div>
                          </Col>
                          <Col className="text-center">
                            <img style={{ height: "180px", objectFit: "cover" }} alt="..." src={require("stylesheets/img/icones/Confia-05.png")} />
                            <div style={{ fontSize: "18px", marginTop: "-35px" }}>Qualquer modificação no documento posterior às assinaturas é detectável, evitando fraudes.</div>
                          </Col>
                          <Col className="text-center">
                            <img style={{ height: "180px", objectFit: "cover" }} alt="..." src={require("stylesheets/img/icones/Confia-06.png")} />
                            <div style={{ fontSize: "18px", marginTop: "-35px" }}>Utiliza certificado próprio com validade jurídica, sem a necessidade de um certificado ICP-Brasil.</div>
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                    <div>
                      <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop="static">
                        <ModalHeader>Atenção</ModalHeader>
                        <ModalBody>Foi enviado um código de acesso para o E-mail informado. Digite o código no campo abaixo.</ModalBody>

                        <CardBody>
                          <Col>
                            <div className="col-6 d-grid" style={{ margin: "auto" }}>
                              <MaskedInput mask="1 1 1 1 1 1" name="code" size="30" className="text-dark text-center" onChange={this._onChange} />
                            </div>
                          </Col>
                          <div id="msg" style={{ display: "none" }} class="error-msg text-center">
                            <i class="fa fa-check" />
                            {this.props.errorMessageCode}
                          </div>
                        </CardBody>

                        {/* <ModalFooter>
                          <Button color="primary" onClick={this.toggle}>
                            Do Something
                          </Button>{" "}
                          <Button color="secondary" onClick={this.toggle}>
                            Cancel
                          </Button>
                        </ModalFooter> */}
                      </Modal>
                    </div>
                  </form>
                )}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.SinglePurchase,
});

function mapDispatchToProps(dispatch) {
  return {
    generateCode: (props) => dispatch(generateCode(props)),
    validateCode: (props) => dispatch(validateCode(props)),
    getParamsUrl: (params) => dispatch(getParamsUrl(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SinglePurchase);
