import React from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { Card, CardHeader, CardBody, Row, Col, Button, CardTitle } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { Link } from "react-router-dom";
import owasp from "owasp-password-strength-test";

import TextField from "components/Fields/TextField";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import FieldValidation from "components/Fields/Validation/FieldValidation";
import { encryptStringWithXORtoHex } from "Utils/Utils";

import { load, unload, save } from "../../reducers/Profile";

owasp.config({
  allowPassphrases: false,
  minLength: 8,
});

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordValue: "",
      minLengthTestPasses: false,
      maxLengthTestPasses: true,
      repeatedCharactersTestPasses: true,
      containsUppercaseLetterTestPasses: false,
      containsLowercaseLetterTestPasses: false,
      containsNumberTestPasses: false,
      containsSpecialCharacterTestPasses: false,
    };
  }

  componentWillMount() {
    if (this.props.user) {
      this.props.load(this.props.user.userId);
    }
  }

  componentWillUnmount() {
    this.props.unload();
  }

  onPasswordChange = (value) => {
    const { failedTests: failed, passedTests: passed } = owasp.test(value);

    const testPassed = (testIndex) => {
      return !failed.includes(testIndex) && passed.includes(testIndex);
    };

    this.setState({
      passwordValue: value,
      minLengthTestPasses: testPassed(0),
      maxLengthTestPasses: testPassed(1),
      repeatedCharactersTestPasses: testPassed(2),
      containsLowercaseLetterTestPasses: testPassed(3),
      containsUppercaseLetterTestPasses: testPassed(4),
      containsNumberTestPasses: testPassed(5),
      containsSpecialCharacterTestPasses: testPassed(6),
    });
  };

  onSubmit = async (values) => {
    var v = {
      cpf: values.cpf,
      name: values.name,
      phone: values.phone,
      email: values.email,
      userId: this.props.user.userId,
    };
    if (values.password) {
      v.password = encryptStringWithXORtoHex(values.password, values.cpf);
    }

    this.props.save(v).then((v) => {
      if (v && v.type.includes("SUCCESS")) {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>Suas informações foram alteradas com sucesso.</div>
            </div>
          ),
          type: "success",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      } else {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>Não foi possível alterar seus dados.</div>
            </div>
          ),
          type: "danger",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    });
  };

  render() {
    return (
      <>
        <div className="content">
          <NotificationAlert ref="notificationAlert" />
          <Form
            onSubmit={this.onSubmit}
            initialValues={this.props.profile}
            validate={(values) => {
              const errors = {};
              if (values.password) {
                if (!this.state.minLengthTestPasses) {
                  errors.password = "São necessários pelo menos 8 caracteres.";
                } else if (!this.state.repeatedCharactersTestPasses) {
                  errors.password = "Não use dígitos repetidos";
                } else if (!this.state.containsUppercaseLetterTestPasses) {
                  errors.password = "É necessário incluir pelo menos uma letra maiúscula.";
                } else if (!this.state.containsLowercaseLetterTestPasses) {
                  errors.password = "É necessário incluir pelo menos uma letra minúscula.";
                } else if (!this.state.containsNumberTestPasses) {
                  errors.password = "É necessário incluir pelo menos um número.";
                }
                if (values.password !== values.passwordConfirm) {
                  errors.passwordConfirm = "As senhas estão diferentes. Digite novamente.";
                }
              }

              return errors;
            }}
            render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md="12">
                    <Row>
                      <Col md="7">
                        <Card>
                          <CardHeader>
                            <CardTitle tag="h4">Perfil</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col className="mx-auto" md="4">
                                <Field name="cpf" label="CPF" placeholder="CPF" component={TextField} required parse={FieldNormalize.CPF} validate={FieldValidation.required} disabled />
                              </Col>
                              <Col className="mx-auto" md="8">
                                <Field name="name" label="Nome" placeholder="Nome" component={TextField} required validate={FieldValidation.required} />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mx-auto" md="4">
                                <Field
                                  name="phone"
                                  label="Telefone"
                                  placeholder="Telefone"
                                  component={TextField}
                                  type="tel"
                                  required
                                  parse={FieldNormalize.CELULAR}
                                  validate={FieldValidation.composeValidators(FieldValidation.required)}
                                />
                              </Col>
                              <Col className="mx-auto" md="8">
                                <Field
                                  name="email"
                                  label="E-mail"
                                  placeholder="E-mail"
                                  component={TextField}
                                  type="email"
                                  required
                                  validate={FieldValidation.composeValidators(FieldValidation.required, FieldValidation.email)}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="5">
                        <Card>
                          <CardHeader>
                            <CardTitle tag="h4">Alterar senha</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col className="mx-auto" md="12">
                                <Field name="password" label="Senha" placeholder="Senha" component={TextField} required type="password" />
                                <OnChange name="password">
                                  {(value, previous) => {
                                    this.onPasswordChange(value);
                                  }}
                                </OnChange>
                              </Col>
                              <Col className="mx-auto" md="12">
                                <Field name="passwordConfirm" label="Confirme sua senha" placeholder="Confirme sua senha" required component={TextField} type={"password"} />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <div style={{ position: "sticky", top: "0", "z-index": "100" }}>
                      <Row>
                        <Col m="6" className="text-left">
                          <Link color="default" to="/company/dashboard" className="btn btn-round">
                            CANCELAR
                          </Link>
                        </Col>
                        <Col m="6" className="text-right">
                          <Button className="btn-round">{"SALVAR"}</Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </form>
            )}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Profile,
  ...state.Auth,
});

function mapDispatchToProps(dispatch) {
  return {
    load: (userId) => dispatch(load(userId)),
    unload: () => dispatch(unload()),
    save: (values) => dispatch(save(values)),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
