import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import FieldValidation from "components/Fields/Validation/FieldValidation";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import TextField from "components/Fields/TextField";
import MaskDate from "Utils/MaskDate";
import { Card, CardHeader, CardBody, Row, Col, Button, CardFooter, CardTitle, Label, Input, Modal, ModalHeader } from "reactstrap";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import SelfDetailsAuth from "components/Others/SelfDetailsAuth";
import ReactWizard from "react-bootstrap-wizard";
import NotificationAlert from "react-notification-alert";
import fileDownload from "js-file-download";
import LoadingOverlay from "react-loading-overlay";

import { unload, load, loadDetails, loadSelfieDetails, saveDates } from "reducers/Authentications";
import { showAlert, closeAlert } from "reducers/Notification";

import ReactTable from "react-table";
import moment from "moment";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <div className="threedots" />
  </a>
));

var API_LINK_URL = process.env.REACT_APP_API_LINK_URL;
class Authentications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalDetails: false,
      modalInsurance: false,
      modalNotification: false,
      picture1: [],
      picture2: [],
      modalPicture: false,
    };
    this.clipboardRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.alertNewDocument) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div style={{ fontWeight: "bold" }}>{this.props.alertMessageNewDocument}</div>
          </div>
        ),
        type: "success",
        icon: "fa fa-check-circle",
        autoDismiss: 0,
      };
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  componentWillUnmount() {
    this.props.unload();
  }

  search = async (values) => {
    const props = {
      companyId: this.props.company.companyId,
      values: {
        dateInit: MaskDate.TO_DATABASE_DATE(values.dateInit),
        dateEnd: MaskDate.TO_DATABASE_DATE(values.dateEnd),
        cpf: values.cpf,
        transacaoAndamento: values.transacaoAndamento,
        autenticado: values.autenticado,
        naoAutenticado: values.naoAutenticado,
        autCancelada: values.autCancelada,
        todosNenhum: values.todosNenhum,
      },
    };
    await this.props.load(props);
  };

  handleSearch = async (values, form) => {
    if (!values.transacaoAndamento && !values.autenticado && !values.naoAutenticado && !values.autCancelada && !values.todosNenhum) {
      this.props.showAlert({
        type: "info",
        title: "Filtro de Situação inválido",
        content: `Você precisa selecionar os filtros pelo menos um filtro de situação para prosseguir com a pesquisa.`,
        showCancel: false,
        confirmBtnBsStyle: "primary",
        onConfirm: this.props.closeAlert,
        confirmBtnText: "Entendi",
      });

      return;
    }
    if (!values.dateInit || !values.dateEnd) {
      form.submit();
    } else {
      await this.props.saveDates(values);
      await this.search(values);
    }
  };

  toggleModalDetails = () => {
    this.setState({
      modalDetails: !this.state.modalDetails,
    });
  };

  copyToClipboard = async (ref) => {
    await navigator.clipboard.writeText(ref);
    var options = {
      place: "tc",
      message: (
        <div>
          <div style={{ fontWeight: "bold" }}>URL copiada para área de transferência.</div>
        </div>
      ),
      type: "success",
      icon: "fa fa-check-circle",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    if (this.props.authDetails && this.props.authDetails.length > 0) {
      const auths = this.props.authDetails.slice(0, -1);
      var content = [
        {
          stepName: this.props.authDetails[this.props.authDetails.length - 1]?.name,
          stepIcon: this.props.authDetails[this.props.authDetails.length - 1]?.situacao === "Usuário autenticado com sucesso" ? "fa fa-check" : "fa fa-times",
          component: (
            <>
              <Container>
                <Row className="justify-content-center">
                  <Col md={4} sm={10}>
                    <Label className="w-100 text-center">CPF</Label>
                    <Input defaultValue={this.props.authDetails[this.props.authDetails.length - 1]?.cpf} disabled />
                  </Col>
                  <Col md={4} sm={10}>
                    <Label className="w-100 text-center">Status</Label>
                    <Input defaultValue={this.props.authDetails[this.props.authDetails.length - 1]?.situacao} disabled />
                  </Col>
                  <Col md={4} sm={10}>
                    <Label className="w-100 text-center">Link para autenticação</Label>
                    <div className="d-flex justify-content-start align-items-center" style={{ height: "31px" }}>
                      <Input
                        defaultValue={API_LINK_URL + "/?chave=" + this.props.authDetails[this.props.authDetails.length - 1]?.primaryIdentifier}
                        ref={this.clipboardRef}
                        disabled
                        className="h-100"
                      />
                      <Button
                        color="default"
                        className="btn-icon btn-link edit"
                        style={{ margin: "0", width: "min-content", overflow: "hidden" }}
                        onClick={() => {
                          const signUrl = API_LINK_URL + "/?chave=" + this.props.authDetails[this.props.authDetails.length - 1]?.primaryIdentifier;
                          this.copyToClipboard(signUrl);
                        }}
                      >
                        <i className="fa fa-copy" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>

              <Row style={{ marginTop: "15px" }}>
                {this.props.authDetails.length > 0 && (
                  <Col md="12" sm="12">
                    <ReactTable
                      data={auths}
                      columns={[
                        {
                          Header: "Etapa",
                          sortable: false,
                          filterable: false,
                          style: { wordBreak: "break-all", whiteSpace: "normal", textOverflow: "inherit" },
                          width: 290,
                          accessor: "description",
                        },
                        {
                          Header: "IP",
                          sortable: false,
                          filterable: false,
                          accessor: "ip",
                          style: { wordBreak: "break-all", whiteSpace: "normal", textOverflow: "inherit" },
                          width: 150,
                        },
                        {
                          Header: "Localização",
                          sortable: false,
                          filterable: false,
                          style: { wordBreak: "break-all", whiteSpace: "normal", textOverflow: "inherit" },
                          width: 400,
                          Cell: (row) => {
                            if (row.original.latLong) {
                              return (
                                <>
                                  <div>
                                    {row.original.latLong} - {row.original.latLongCidadeUF}
                                  </div>
                                </>
                              );
                            } else {
                              return <div />;
                            }
                          },
                        },
                        {
                          Header: "Data",
                          sortable: false,
                          filterable: false,
                          accessor: "eventDate",
                          style: { wordBreak: "break-all", whiteSpace: "normal", textOverflow: "inherit" },
                          width: 180,
                          Cell: (row) => {
                            return <div>{MaskDate.TO_BR_DATETIME(row.original.eventDate)}</div>;
                          },
                        },
                        {
                          sortable: false,
                          filterable: false,
                          style: { wordBreak: "break-all", whiteSpace: "normal", textOverflow: "inherit" },

                          Cell: (row) => {
                            if (row.original.image1 !== null) {
                              if (row.original.image2 !== null) {
                                return (
                                  <>
                                    <div className="d-flex justify-content-center w-100">
                                      <SelfDetailsAuth src={row.original.image1} />
                                      <SelfDetailsAuth src={row.original.image2} />
                                    </div>
                                  </>
                                );
                              } else {
                                return (
                                  <div className="d-flex justify-content-center w-100">
                                    <SelfDetailsAuth src={row.original.image1} />
                                  </div>
                                );
                              }
                            } else {
                              return <div />;
                            }
                          },
                        },
                      ]}
                      maxRows={8}
                      minRows={8}
                      sortable={false}
                      loadingText="Carregando..."
                      noDataText="Nenhum detalhe de autenticação..."
                      className="-striped -highlight"
                      defaultPageSize={8}
                      showPaginationTop
                      showPaginationBottom={false}
                      showPageSizeOptions={false}
                      showPageJump={false}
                      previousText="ANTERIOR"
                      nextText="PRÓXIMO"
                      pageText="Página"
                      ofText="de"
                    />
                  </Col>
                )}
              </Row>
            </>
          ),
          stepProps: {
            values: this.props.authDetails.length > 0 ? this.props.authDetails : {},
            error: this.props.error,
            errorMessage: this.props.errorMessage,
          },
        },
      ];
    }

    return (
      <>
        <Modal
          isOpen={this.state.modalDetails && !this.props.loadingModal}
          toggle={this.toggleModalDetails}
          keyboard={false}
          style={{
            maxWidth: "70vw",
            width: "90%",
            margin: "auto",
            paddingBottom: "5vh",
            paddingTop: "5vh",
          }}
        >
          <ModalHeader toggle={this.toggleModalDetails} charCode="X"></ModalHeader>
          <NotificationAlert ref="notificationAlert" />
          <ReactWizard steps={content} navSteps description={""} finishButtonClasses="btn-wd" finishButtonText="SAIR" finishButtonClick={this.toggleModalDetails} />
        </Modal>

        {/* Conteúdo a ser mostrado da authenticaions */}
        <div className="content">
          <NotificationAlert ref="notificationAlert" />
          <LoadingOverlay active={this.props.loading} spinner text="Processando...">
            <Form
              onSubmit={onSubmit}
              initialValues={{
                dateInit: moment(new Date()).format("DD/MM/YYYY"),
                dateEnd: moment(new Date()).format("DD/MM/YYYY"),
              }}
              render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                <form onSubmit={handleSubmit}>
                  <div style={{ position: "sticky", top: "0", zIndex: "100" }}>
                    <Row>
                      <Col m="4" className="text-left">
                        <Link size="sm" className="btn btn-sm" to="/company/dashboard">
                          Cancelar
                        </Link>
                      </Col>
                      <Col m="4" className="text-center">
                        <h6 className="title" style={{ lineHeight: "44px", fontSize: ".85em" }}>
                          Autenticações
                        </h6>
                      </Col>
                      <Col m="4" className="text-right"></Col>
                    </Row>
                  </div>

                  <Row>
                    <Col className="text-center" md="12">
                      <Card className="card-tasks">
                        <CardHeader className="text-center">
                          <CardTitle tag="h6">Selecione o período a ser consultado</CardTitle>
                        </CardHeader>

                        <CardBody>
                          <Row>
                            <Col md="2">
                              <Field name="dateInit" label="Data inicial" validate={FieldValidation.required} component={TextField} parse={FieldNormalize.DATE} />
                            </Col>

                            <Col md="2">
                              <Field name="dateEnd" label="Data final" validate={FieldValidation.required} component={TextField} parse={FieldNormalize.DATE} />
                            </Col>
                            <Col md="5" className="">
                              <div className="mt-0 mx-auto" style={{ width: "60%" }}>
                                <label>Filtro de Situação:</label>
                                <br />

                                <div className="d-flex align-items-center justify-content-between w-100">
                                  <div className="w-50">
                                    <Field name="todosNenhum" component="input" type="checkbox" defaultValue={true} />
                                    <label className="pl-2">Todos &nbsp;</label>
                                  </div>
                                  <div className="pl-2 w-50">
                                    <Field name="autenticado" component="input" type="checkbox" defaultValue={false} />
                                    <label className="pl-2">Autenticado</label>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                  <div className="w-50">
                                    <Field name="transacaoAndamento" component="input" type="checkbox" defaultValue={false} />
                                    <label className="pl-2">Transação em andamento &nbsp;</label>
                                  </div>
                                  <div className="pl-2 w-50">
                                    <Field name="autCancelada" component="input" type="checkbox" defaultValue={false} />
                                    <label className="pl-2">Cancelada</label>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                  <div className="w-50">
                                    <Field name="naoAutenticado" component="input" type="checkbox" defaultValue={false} />
                                    <label className="pl-2">Não autenticado &nbsp;</label>
                                  </div>
                                </div>
                              </div>
                            </Col>

                            <Col md="3">
                              <Field name="cpf" label="CPF (Opcional)" component={TextField} parse={FieldNormalize.CPF} />
                            </Col>
                          </Row>
                          <Row>
                            <Col m="4" className="text-right">
                              <Button className="btn btn-sm" onClick={() => this.handleSearch(values, form)}>
                                Pesquisar
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-center" md="12">
                      <Card className="card-tasks">
                        <CardBody>
                          <Col m="4" className="text-right">
                            <Button
                              className="btn btn-round"
                              onClick={async () => {
                                await this.props.saveDates(values);
                                await this.search(this.props.search);
                              }}
                            >
                              <i className="fa fa-refresh" />
                            </Button>
                          </Col>
                          <ReactTable
                            data={this.props.authentications}
                            defaultFilterMethod={this.filterMethod}
                            filterable
                            columns={[
                              {
                                Header: "Id",
                                width: 45,
                                accessor: "authenticationId",
                              },
                              {
                                Header: "CPF",
                                accessor: "cpf",
                              },
                              {
                                Header: "Data criação",
                                accessor: "registrationDate",
                                Cell: (row) => {
                                  return <div>{row.original.registrationDate && MaskDate.TO_BR_DATE(row.original.registrationDate)}</div>;
                                },
                              },
                              {
                                Header: "Situação",
                                accessor: "situacao",
                                width: 300,
                              },
                              {
                                Header: "Nome",
                                accessor: "name",
                                style: { whiteSpace: "unset" },
                              },

                              {
                                Header: "Ações",
                                style: {
                                  whiteSpace: "unset",
                                  overflow: "visible",
                                },
                                width: 50,
                                sortable: false,
                                filterable: false,
                                Cell: (row) => {
                                  return (
                                    <div className="actions-right">
                                      <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title="">
                                          <Dropdown.Header>Opções</Dropdown.Header>
                                          <Dropdown.Item
                                            onClick={() => {
                                              this.toggleModalDetails();
                                              this.props.loadDetails(row.original.authenticationId);
                                            }}
                                          >
                                            <i className="fa fa-info" /> Detalhes
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  );
                                },
                              },
                            ]}
                            defaultPageSize={10}
                            showPaginationTop
                            showPaginationBottom={false}
                            showPageSizeOptions={false}
                            showPageJump={false}
                            previousText="ANTERIOR"
                            nextText="PRÓXIMO"
                            loadingText="Carregando..."
                            noDataText="Nenhuma autenticação encontrada"
                            pageText="Página"
                            ofText="de"
                            rowsText="linhas"
                            className="-striped -highlight defaul-pagination"
                          />
                        </CardBody>
                        <CardFooter>
                          <hr />
                          <div className="stats">{this.props.authentications ? this.props.authentications.length : 0} Autenticações</div>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                </form>
              )}
            />
          </LoadingOverlay>
        </div>
      </>
    );
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
  };
}

const onSubmit = async (values) => {
  //  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2));
};

const mapStateToProps = (state) => ({
  ...state.Auth,
  ...state.Authentications,
  alertNewDocument: state.Authentications.alert,
  alertMessageNewDocument: state.Authentications.alertMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    unload: () => dispatch(unload()),
    load: (companyId) => dispatch(load(companyId)),
    loadDetails: (authenticationId) => dispatch(loadDetails(authenticationId)),
    loadSelfieDetails: (imageId) => dispatch(loadSelfieDetails(imageId)),
    showAlert: (alertProps) => dispatch(showAlert(alertProps)),
    closeAlert: () => dispatch(closeAlert(0)),
    saveDates: (values) => dispatch(saveDates(values)),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(Authentications);
