const NEW_PASSWORD = 'confia/new-password/NEW_PASSWORD';
const NEW_PASSWORD_SUCCESS = 'confia/new-password/NEW_PASSWORD_SUCCESS';
const NEW_PASSWORD_FAIL = 'confia/new-password/NEW_PASSWORD_FAIL';
const VALIDATE_TOKEN = 'confia/new-password/VALIDATE_TOKEN';
const VALIDATE_TOKEN_SUCCESS = 'confia/new-password/VALIDATE_TOKEN_SUCCESS';
const VALIDATE_TOKEN_FAIL = 'confia/new-password/VALIDATE_TOKEN_FAIL';

export function newPassword(props) {
  return {
    type: NEW_PASSWORD,
    payload: {
      request: {
        method: 'post',
        url: '/api/auth/newPassword',
        data: props
      }
    }
  }
}

export function validateToken(token) {
  return {
    type: VALIDATE_TOKEN,
    payload: {
      request: {
        method: 'post',
        url: `/api/auth/newPasswordToken/${token}`,
      }
    }
  }
}

const INITIAL_STATE = { client: null, redirectTo: null }

export default function NewPassword(state = INITIAL_STATE, action) {
  switch(action.type) {
    case NEW_PASSWORD:
      return {
        ...state,
        loading: true
      };
    case NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        redirectTo: '/',
      };
    case NEW_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: 'Não foi possível realizar a operação.',
      };

    case VALIDATE_TOKEN:
      return {
        ...state,
        loading: true
      };
    case VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        client: action.payload.data.client,
      };
    case VALIDATE_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: 'Link inválido!',
      };

    default:
      return state;
  }
}