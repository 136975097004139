import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import FieldValidation from "components/Fields/Validation/FieldValidation";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import TextField from "components/Fields/TextField";
import MaskDate from "Utils/MaskDate";
import { Card, CardHeader, CardBody, Row, Col, Button, CardFooter, CardTitle, Label, Input, Modal, Container, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import Dropdown from "react-bootstrap/Dropdown";

import ReactWizard from "react-bootstrap-wizard";
import NotificationAlert from "react-notification-alert";
import fileDownload from "js-file-download";
import LoadingOverlay from "react-loading-overlay";

import { unload, load, loadFile, saveDates, updatePart, addSignParts, notifySigners, updatePhoneEmail } from "reducers/Documents";

import { loginToken, getParamsUrl } from "reducers/SinglePurchase";
import { showAlert, closeAlert } from "reducers/Notification";

import ReactTable from "react-table";
import moment from "moment";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <div className="threedots" />
  </a>
));

var API_LINK_URL = process.env.REACT_APP_API_LINK_URL;
class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalNotification: false,
      popover: false,
    };
    this.clipboardRef = React.createRef();
  }

  async componentDidMount() {
    if (!this.props.user) {
      //Try to reconect using token
      await this.props.loginToken();
    }
    this.props.getParamsUrl("type");
  }

  componentWillUnmount() {
    this.props.unload();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error) {
      if (nextProps.error === true) {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>{nextProps.errorMessage}</div>
            </div>
          ),
          type: "danger",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    }

    if (nextProps.message !== this.props.message) {
      if (nextProps.message === true) {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>
                <strong>{nextProps.successMessage}</strong>
              </div>
            </div>
          ),
          type: "success",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    }
  }

  handleClickDownloadIn = (id, status) => (ev) => {
    ev.preventDefault();
    Promise.resolve(this.props.loadFile(id, status))
      .then((response) => {
        console.log("RESPONSE->", response);
        console.log("-->", this.props.file);
        if (this.props.file.type === "application/zip") fileDownload(this.props.file, "docsConfia.zip");
        else fileDownload(this.props.file, "docConfia.pdf");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  search = async (values) => {
    const props = {
      companyId: this.props.company.companyId,
      values: {
        dateInit: MaskDate.TO_DATABASE_DATE(values.dateInit),
        dateEnd: MaskDate.TO_DATABASE_DATE(values.dateEnd),
        cpf: values.cpf,
        userId: this.props.user.userId,
      },
    };

    await this.props.load(props);
  };

  handleSearch = async (values, form) => {
    if (!values.dateInit || !values.dateEnd) {
      form.submit();
    } else {
      await this.props.saveDates({
        dateInit: values.dateInit,
        dateEnd: values.dateEnd,
        cpf: values.cpf,
      });
      await this.search(values);
    }
  };

  toggleModalNotification = () => {
    this.setState({
      modalNotification: !this.state.modalNotification,
    });
  };

  handleSelectCheckbox = (row, value, id) => {
    var part = row;

    if (id === 1) {
      part.notifySms = value == "true" ? true : false;
    } else if (id === 2) {
      part.notifyWhatsapp = value == "true" ? true : false;
    } else if (id === 3) {
      part.notifyEmail = value == "true" ? true : false;
    }

    this.props.updatePart(part);
  };

  copyToClipboard = async (ref) => {
    await navigator.clipboard.writeText(ref);
    var options = {
      place: "tc",
      message: (
        <div>
          <div style={{ fontWeight: "bold" }}>URL copiada para área de transferência.</div>
        </div>
      ),
      type: "success",
      icon: "fa fa-check-circle",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleSendWhatsapp = (doc, part) => {
    var name = part.name.toLowerCase().split(" ")[0];
    name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    const message = `Olá, ${name}! Você tem um documento para assinar com a Confia.\n\nAgora, você vai passar pelas etapas de identificação e biometria facial para assinar o seu documento. Clique neste link para começar: ${
      API_LINK_URL + "/?chave=" + part.identifier
    }\n\n*A Confia não é responsável pelo conteúdo do documento ou por eventuais negociações entre as partes.`;
    window.open(`https://wa.me/55${FieldNormalize.NUMBER(part.phone)}?text=${encodeURIComponent(message)}`, "_blank");
  };

  handleLogout = () => {
    this.removeToken();
    document.getElementById("redirectLogin").click();
  };

  removeToken() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("refreshToken");
  }

  render() {
    if (this.props.redirectToSingle) {
      return <Redirect to={"/singlePurchase"} />;
    }
    return (
      <>
        <Modal
          isOpen={this.state.modalNotification && !this.props.loadingModal}
          toggle={this.toggleModalNotification}
          style={{
            maxWidth: "80vw",
            width: "90%",
            margin: "auto",
            paddingBottom: "5vh",
            paddingTop: "5vh",
          }}
        >
          <NotificationAlert ref="notificationAlert" />
          <>
            {this.props.signParts && (
              <>
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col className="text-center" md="12">
                          <Card className="card-tasks">
                            <CardHeader className="text-center">
                              <CardTitle tag="h6">Notificar assinantes</CardTitle>
                            </CardHeader>

                            <CardBody>
                              <Row>
                                <Col>
                                  <ReactTable
                                    data={this.props.signParts}
                                    columns={[
                                      {
                                        Header: "Nome",
                                        style: { whiteSpace: "unset" },
                                        accessor: "name",
                                      },
                                      {
                                        Header: "Telefone",
                                        Cell: (row) => {
                                          return (
                                            <Col md="12">
                                              <Field
                                                name={"phone" + row.original.signPartId}
                                                component={TextField}
                                                parse={FieldNormalize.CELULAR}
                                                disabled={row.original.status === "Assinado"}
                                                defaultValue={row.original.phone ? FieldNormalize.CELULAR(row.original.phone) : ""}
                                              />
                                              <OnChange name={"phone" + row.original.signPartId}>
                                                {(value, previous) => {
                                                  console.log(value);
                                                  this.props.updatePhoneEmail({
                                                    part: row.original,
                                                    value,
                                                    type: 1,
                                                  });
                                                }}
                                              </OnChange>
                                            </Col>
                                          );
                                        },
                                      },
                                      {
                                        Header: "E-mail",
                                        Cell: (row) => {
                                          return (
                                            <Col md="12">
                                              <Field
                                                name={"email" + row.original.signPartId}
                                                component={TextField}
                                                validate={FieldValidation.composeValidators(FieldValidation.email)}
                                                maxLength="225"
                                                disabled={row.original.status === "Assinado"}
                                                defaultValue={row.original.email ? row.original.email : ""}
                                              />
                                              <OnChange name={"email" + row.original.signPartId}>
                                                {(value, previous) => {
                                                  console.log(value);
                                                  this.props.updatePhoneEmail({
                                                    part: row.original,
                                                    value,
                                                    type: 2,
                                                  });
                                                }}
                                              </OnChange>
                                            </Col>
                                          );
                                        },
                                      },
                                      {
                                        Header: "Meios de envio",
                                        sortable: false,
                                        filterable: false,
                                        style: { whiteSpace: "unset" },
                                        Cell: (row) => {
                                          if (row.original.status !== "Assinado") {
                                            return (
                                              <div>
                                                <Row>
                                                  {values[`phone${row.original.signPartId}`] && (
                                                    <>
                                                      <Col md="3" sm="12">
                                                        <Label check>
                                                          <Field name={"signPartNotifySms" + row.original.signPartId} component="input" type="checkbox" value="true" />
                                                          {" SMS"}
                                                          <OnChange name={"signPartNotifySms" + row.original.signPartId}>
                                                            {(value, previous) => {
                                                              this.handleSelectCheckbox(row.original, value, 1);
                                                            }}
                                                          </OnChange>
                                                        </Label>
                                                      </Col>
                                                      <Col md="5" sm="12">
                                                        <Label check>
                                                          <Field name={"signPartNotifyWhatsapp" + row.original.signPartId} component="input" type="checkbox" value="true" />
                                                          {" WHATSAPP"}
                                                          <OnChange name={"signPartNotifyWhatsapp" + row.original.signPartId}>
                                                            {(value, previous) => {
                                                              this.handleSelectCheckbox(row.original, value, 2);
                                                            }}
                                                          </OnChange>
                                                        </Label>
                                                      </Col>
                                                    </>
                                                  )}
                                                  {values[`email${row.original.signPartId}`] && (
                                                    <Col md="4" sm="12">
                                                      <Label check>
                                                        <Field name={"signPartNotifyEmail" + row.original.signPartId} component="input" type="checkbox" value="true" />
                                                        {" EMAIL"}
                                                        <OnChange name={"signPartNotifyEmail" + row.original.signPartId}>
                                                          {(value, previous) => {
                                                            this.handleSelectCheckbox(row.original, value, 3);
                                                          }}
                                                        </OnChange>
                                                      </Label>
                                                    </Col>
                                                  )}
                                                  {!values[`phone${row.original.signPartId}`] && !values[`email${row.original.signPartId}`] && <Col>Insira o telefone ou e-mail.</Col>}
                                                </Row>
                                              </div>
                                            );
                                          } else {
                                            return <div style={{ color: "green" }}>Assinado</div>;
                                          }
                                        },
                                      },
                                    ]}
                                    showPagination={false}
                                    minRows={1}
                                    sortable={false}
                                    loadingText="Carregando..."
                                    noDataText="Nenhum assinante"
                                    className="-striped -highlight"
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col m="6" className="text-left">
                                  <Button className="btn btn-round" onClick={this.toggleModalNotification}>
                                    {"CANCELAR"}
                                  </Button>
                                </Col>
                                <Col m="6" className="text-right">
                                  <Link
                                    className="btn btn-round"
                                    onClick={() => {
                                      this.props.notifySigners({
                                        parts: this.props.signParts,
                                        companyId: this.props.company.companyId,
                                      });
                                      this.setState({
                                        modalNotification: false,
                                      });
                                    }}
                                  >
                                    {"NOTIFICAR"}
                                  </Link>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </form>
                  )}
                />
              </>
            )}
          </>
        </Modal>
        <div className="login-page">
          <a href="/singlePurchase" id="redirectLogin" />
          <NotificationAlert ref="notificationAlert" />
          <Container>
            <Row>
              <div className="col-md-2 ml-auto mr-auto mt-5">
                <img alt="..." src={require("stylesheets/img/logo/confia_site_branca.png")} />
              </div>
              <Col className="ml-auto mr-auto mt-5" lg="12" md="4">
                <LoadingOverlay active={this.props.loading} spinner text="Processando...">
                  <Form
                    onSubmit={onSubmit}
                    initialValues={{
                      dateInit: moment(new Date()).format("DD/MM/YYYY"),
                      dateEnd: moment(new Date()).format("DD/MM/YYYY"),
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                      <form onSubmit={handleSubmit}>
                        <Card>
                          <CardHeader>
                            <h6>NOVO DOCUMENTO</h6>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col md="10" lg="10" sm="12">
                                <p>
                                  Clicando em <strong>"NOVO DOCUMENTO"</strong> você pode anexar o arquivo que precisa ser assinado. Aceitamos apenas arquivos em PDF.
                                </p>
                              </Col>
                              <Col className="text-center">
                                {this.props.typeUrl === "rtd" ? (
                                  <>
                                    <Link size="sm" className="btn btn-sm" style={{ width: "100%" }} to={`/singlePurchase/newDocument?type=${this.props.typeUrl}`}>
                                      NOVO DOCUMENTO
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link size="sm" className="btn btn-sm" style={{ width: "100%" }} to="/singlePurchase/newDocument">
                                      NOVO DOCUMENTO
                                    </Link>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardHeader className="border-bottom">
                            <h6>
                              PESQUISAR DOCUMENTO <i className="fa fa-search" />
                            </h6>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col>
                                <p>Selecione o período a ser consultado</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="2" lg="2" sm="12">
                                <Field name="dateInit" label="Data inicial" validate={FieldValidation.required} component={TextField} parse={FieldNormalize.DATE} />
                              </Col>
                              <Col md="2" lg="2" sm="12">
                                <Field name="dateEnd" label="Data final" validate={FieldValidation.required} component={TextField} parse={FieldNormalize.DATE} />
                              </Col>
                              <Col md="6" lg="6" sm="12">
                                <Field name="cpf" label="CPF (Opcional)" component={TextField} parse={FieldNormalize.CPF} />
                              </Col>
                              <Col md="2" lg="2" sm="12" className="text-right">
                                <Button className="btn btn-sm" style={{ width: "100%", marginTop: "30px" }} onClick={() => this.handleSearch(values, form)}>
                                  Pesquisar
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                          <CardBody>
                            <Row>
                              <Col md="9" lg="9" sm="9">
                                {" "}
                                <h6>
                                  DOCUMENTOS{" "}
                                  <i className="fa fa-question-circle-o fa-lg" id="Popover1" onClick={() => this.setState({ popover: !this.state.popover })} style={{ paddingLeft: "10px" }} />
                                </h6>
                              </Col>
                              <Col> </Col>
                            </Row>
                            <Row>
                              <Col>
                                <ReactTable
                                  data={this.props.documents}
                                  defaultFilterMethod={this.filterMethod}
                                  columns={[
                                    {
                                      Header: "NOME DO DOCUMENTO",
                                      accessor: "name",
                                      headerClassName: "headTable",
                                    },
                                    {
                                      Header: "Tipo",
                                      accessor: "type",
                                      headerClassName: "headTable",
                                    },
                                    {
                                      Header: "STATUS",
                                      accessor: "status",
                                      headerClassName: "headTable",
                                      width: 150,
                                      Cell: (row) => {
                                        if (row.original.status === "Assinado") {
                                          return <div style={{ color: "green" }}>{row.original.status}</div>;
                                        } else {
                                          return <div style={{ color: "red" }}>{row.original.status}</div>;
                                        }
                                      },
                                    },
                                    {
                                      Header: "ASSINANTES",
                                      accessor: "assinantesFilter",
                                      headerClassName: "headTable",
                                      style: { whiteSpace: "unset" },
                                      Cell: (row, i) => {
                                        return (
                                          <div
                                            key={i}
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "space-between",
                                              height: "100%",
                                            }}
                                          >
                                            {row.original.parts.map((part, i) => {
                                              if (part.status !== "Assinado") {
                                                return (
                                                  <div
                                                    key={i}
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <div style={{ color: "#2c2c2c" }}>
                                                      {part.name} (CPF: {part.cpf} - {part.status}){"  "}
                                                      <i style={{ color: "red" }} className="fa fa-exclamation-circle" />{" "}
                                                    </div>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div key={i}>
                                                    {part.name} (CPF: {part.cpf} - {part.status})
                                                  </div>
                                                );
                                              }
                                            })}
                                          </div>
                                        );
                                      },
                                    },
                                    {
                                      Header: "ENVIAR LINK",
                                      headerClassName: "headTable",
                                      width: 110,
                                      Cell: (row) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              height: "100%",
                                            }}
                                          >
                                            {row.original.status !== "Assinado" ? (
                                              row.original.parts.map((part, i) => {
                                                return part.status !== "Assinado" ? (
                                                  <div key={i}>
                                                    {part.identifier && (
                                                      <>
                                                        <Button
                                                          color="default"
                                                          size="sm"
                                                          title={"Copiar link de assinatura do CPF " + part.cpf}
                                                          className="btn-icon btn-link edit"
                                                          onClick={() => {
                                                            const signUrl = API_LINK_URL + "/?chave=" + part.identifier;
                                                            this.copyToClipboard(signUrl);
                                                          }}
                                                        >
                                                          <i className="fa fa-copy" />
                                                        </Button>{" "}
                                                        {part.phone && (
                                                          <>
                                                            <Button
                                                              color="default"
                                                              size="sm"
                                                              title="Enviar link de assinatura por WhatsApp"
                                                              className="btn-icon btn-link edit"
                                                              onClick={() => this.handleSendWhatsapp(row.original, part)}
                                                            >
                                                              <i className="fa fa-whatsapp" />
                                                            </Button>{" "}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </div>
                                                ) : (
                                                  <div> - </div>
                                                );
                                              })
                                            ) : (
                                              <div> - </div>
                                            )}
                                          </div>
                                        );
                                      },
                                    },
                                    {
                                      Header: "OPÇÕES",
                                      headerClassName: "headTable text-center",
                                      style: {
                                        whiteSpace: "unset",
                                        overflow: "visible",
                                      },
                                      width: 100,
                                      sortable: false,
                                      filterable: false,
                                      Cell: (row) => {
                                        if (row.original.status === "Assinado") {
                                          if (row.original.rtdpjFileUrl) {
                                            return (
                                              <div className="actions-left">
                                                <Dropdown>
                                                  <Dropdown.Toggle as={CustomToggle} />
                                                  <Dropdown.Menu size="sm" title="">
                                                    <Dropdown.Header>Opções</Dropdown.Header>

                                                    <Dropdown.Item href={row.original.rtdpjFileUrl} target="_blank">
                                                      <i className="fa fa-barcode" /> Validação do registro
                                                    </Dropdown.Item>

                                                    <Dropdown.Item onClick={this.handleClickDownloadIn(row.original.documentId, 1)}>
                                                      <i className="fa fa-download" /> Download
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div className="actions-right">
                                                <Dropdown>
                                                  <Dropdown.Toggle as={CustomToggle} />
                                                  <Dropdown.Menu size="sm" title="">
                                                    <Dropdown.Header>Opções</Dropdown.Header>

                                                    <Dropdown.Item onClick={this.handleClickDownloadIn(row.original.documentId, 1)}>
                                                      <i className="fa fa-download" /> Download
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            );
                                          }
                                        } else {
                                          if (row.original.partiallySigned === 1) {
                                            return (
                                              <div className="actions-right">
                                                <Dropdown>
                                                  <Dropdown.Toggle as={CustomToggle} />
                                                  <Dropdown.Menu size="sm" title="">
                                                    <Dropdown.Header>Opções</Dropdown.Header>

                                                    {!this.props.company.sendMessage || (
                                                      <>
                                                        <Dropdown.Item
                                                          onClick={() => {
                                                            this.toggleModalNotification();
                                                            this.props.addSignParts(
                                                              row.original.parts.map((part) => {
                                                                part.notifySms = false;
                                                                part.notifyEmail = false;
                                                                part.notifyWhatsapp = false;

                                                                return part;
                                                              })
                                                            );
                                                          }}
                                                        >
                                                          <i className="fa fa-bell" /> Notificação
                                                        </Dropdown.Item>
                                                      </>
                                                    )}
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div className="actions-right">
                                                <Dropdown>
                                                  <Dropdown.Toggle as={CustomToggle} />
                                                  <Dropdown.Menu size="sm" title="">
                                                    <Dropdown.Header>Opções</Dropdown.Header>

                                                    {!this.props.company.sendMessage || (
                                                      <>
                                                        <Dropdown.Item
                                                          onClick={() => {
                                                            this.toggleModalNotification();
                                                            this.props.addSignParts(
                                                              row.original.parts.map((part) => {
                                                                part.notifySms = false;
                                                                part.notifyEmail = false;
                                                                part.notifyWhatsapp = false;

                                                                return part;
                                                              })
                                                            );
                                                          }}
                                                        >
                                                          <i className="fa fa-bell" /> Notificação
                                                        </Dropdown.Item>
                                                      </>
                                                    )}

                                                    <Dropdown.Item onClick={this.handleClickDownloadIn(row.original.documentId, 0)}>
                                                      <i className="fa fa-download" /> Download
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            );
                                          }
                                        }
                                      },
                                    },
                                  ]}
                                  defaultPageSize={8}
                                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                                  showPaginationTop={true}
                                  showPaginationBottom={false}
                                  showPageSizeOptions={true}
                                  showPageJump={true}
                                  previousText="ANTERIOR"
                                  nextText="PRÓXIMO"
                                  loadingText="Carregando..."
                                  noDataText="Nenhum documento encontrado"
                                  pageText="Página"
                                  ofText="de"
                                  rowsText="linhas"
                                  className="-striped -highlight defaul-pagination"
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </form>
                    )}
                  />
                </LoadingOverlay>
              </Col>
            </Row>
            <Popover placement="bottom" isOpen={this.state.popover} target="Popover1" onClick={() => this.setState({ popover: !this.state.popover })}>
              <PopoverHeader className="mt-3" style={{ color: "#ffffff", fontWeight: "700" }}>
                LISTA DE DOCUMENTOS
              </PopoverHeader>
              <PopoverBody>
                Nesta página você pode consultar os documentos enviados, o status de cada arquivo e se as assinaturas já foram concluidas. Além disso, pode reenviar o link de assinatura pelo seu
                próprio WhatsApp nos ícones do lado direito.
              </PopoverBody>
            </Popover>
          </Container>
        </div>
      </>
    );
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
  };
}

const onSubmit = async (values) => {
  //  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2));
};

const mapStateToProps = (state) => ({
  ...state.SinglePurchase,
  ...state.Documents,
  alertNewDocument: state.Documents.alert,
  alertMessageNewDocument: state.Documents.alertMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    unload: () => dispatch(unload()),
    load: (companyId) => dispatch(load(companyId)),
    loadFile: (documentId, status) => dispatch(loadFile(documentId, status)),
    showAlert: (alertProps) => dispatch(showAlert(alertProps)),
    closeAlert: () => dispatch(closeAlert(0)),
    saveDates: (values) => dispatch(saveDates(values)),
    updatePart: (part) => dispatch(updatePart(part)),
    addSignParts: (signParts) => dispatch(addSignParts(signParts)),
    loginToken: () => dispatch(loginToken()),
    updatePhoneEmail: (part) => dispatch(updatePhoneEmail(part)),
    notifySigners: (signParts) => dispatch(notifySigners(signParts)),
    getParamsUrl: (params) => dispatch(getParamsUrl(params)),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
