import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import LoadingOverlay from "react-loading-overlay";
import { OnChange } from "react-final-form-listeners";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  CardFooter,
  CardTitle,
  Table,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import TextField from "components/Fields/TextField";
import SelectField from "components/Fields/SelectField";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import FieldValidation from "components/Fields/Validation/FieldValidation";
import DocumentUpload from "components/CustomUpload/DocumentUpload";

import { unload, save, load, searchByCPF, updateFormState } from "reducers/NewUser";

import ReactTable from "react-table";

class NewUser extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    /*if (this.props.company) {
      this.props.load(this.props.company.companyId);
    }*/
  }

  componentWillUnmount() {
    this.props.unload();
  }

  onSubmit = async (values, form) => {
    values.roleId = values.role.value;
    this.props.save(values, this.props.company.companyId).then((v) => {
      if (v && v.type.includes("SUCCESS")) {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>Usuário cadastrado.</div>
            </div>
          ),
          type: "success",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
        Object.keys(values).forEach((key) => {
          if (key !== "roleId") {
            form.change(key, "");
            form.resetFieldState(key);
          }
        });
      } else {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>Não foi possível cadastrar usuário.</div>
            </div>
          ),
          type: "danger",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    });
  };

  render() {
    return (
      <>
        <div className="content">
          <NotificationAlert ref="notificationAlert" />
          <LoadingOverlay active={this.props.loading} spinner text="Processando...">
            <Form
              onSubmit={this.onSubmit}
              initialValues={this.props.registerForm}
              render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                <form onSubmit={handleSubmit}>
                  <FormSpy
                    onChange={(state) => {
                      this.props.updateFormState(state);
                    }}
                  />
                  <Row>
                    <Col className="text-center" md="12">
                      <Card className="card-tasks">
                        <CardHeader>
                          <CardTitle tag="h4">Usuário</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col className="mx-auto" md="4" sm="4">
                              <Field name="cpf" label="CPF" component={TextField} required parse={FieldNormalize.CPF} validate={FieldValidation.required} />
                              <OnChange name="cpf">
                                {(value, previous) => {
                                  if (value.replace(/[^\d]/g, "").length === 11) {
                                    this.props.searchByCPF(value);
                                    console.log("PESQUISAR PELO CPF");
                                  }
                                }}
                              </OnChange>
                            </Col>
                            <Col className="mx-auto" md="8" sm="8">
                              <Field name="name" label="Nome" component={TextField} required maxLength="500" validate={FieldValidation.required} />
                            </Col>
                            <Col className="mx-auto" md="4" sm="4">
                              <Field name="phone" label="Celular" component={TextField} required parse={FieldNormalize.CELULAR} validate={FieldValidation.required} />
                            </Col>
                            <Col className="mx-auto" md="4" sm="4">
                              <Field
                                name="email"
                                label="E-mail"
                                component={TextField}
                                validate={FieldValidation.composeValidators(FieldValidation.email, FieldValidation.required)}
                                required
                                maxLength="225"
                              />
                            </Col>
                            <Col className="mx-auto" md="4" sm="4">
                              <Field
                                name="role"
                                label="Perfil"
                                placeholder="Perfil"
                                component={SelectField}
                                required
                                validate={FieldValidation.required}
                                options={[
                                  { id: 1, desc: "Administrador" },
                                  { id: 2, desc: "Operador" },
                                  { id: 5, desc: "Supervisor" },
                                ]}
                                textField="desc"
                                valueField="id"
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <div
                        style={{
                          position: "sticky",
                          top: "0",
                          "z-index": "100",
                        }}
                      >
                        <Row>
                          <Col m="6" className="text-left">
                            <Link className="btn btn-round" to="/company/dashboard">
                              {"CANCELAR"}
                            </Link>
                          </Col>
                          <Col m="6" className="text-right">
                            <Button className="btn btn-round">{"SALVAR USUÁRIO"}</Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </form>
              )}
            />
          </LoadingOverlay>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Auth,
  ...state.NewUser,
});

function mapDispatchToProps(dispatch) {
  return {
    save: (values, companyId) => dispatch(save(values, companyId)),
    unload: () => dispatch(unload()),
    load: (userId) => dispatch(load(userId)),
    searchByCPF: (cpf) => dispatch(searchByCPF(cpf)),
    updateFormState: (state) => dispatch(updateFormState(state)),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
