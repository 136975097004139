import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Card, CardHeader, CardBody, Row, Col, Button, CardFooter, CardTitle, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Form, Field } from "react-final-form";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import { OnChange } from "react-final-form-listeners";
import ReactTable from "react-table";
import NotificationAlert from "react-notification-alert";

import { unloadNotify, updatePart, notifySigners, clearRedirectNotify } from "reducers/NewDocument";

var API_LINK_URL = process.env.REACT_APP_API_LINK_URL;

class NotifyParts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfiaNotificationOpen: false,
      confiaCheckBox: false,
      showModal: false,
    };
  }

  componentDidMount() {
    this.props.clearRedirectNotify();
  }

  componentWillUnmount() {
    this.props.unloadNotify();
  }

  copyToClipboard = async (link) => {
    await navigator.clipboard.writeText(link);
    var options = {
      place: "tc",
      message: (
        <div>
          <div>URL copiado para área de transferência.</div>
        </div>
      ),
      type: "success",
      icon: "fa fa-check-circle",
      autoDismiss: 2,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleSendWhatsapp = (props) => {
    var name = props.name.toLowerCase().split(" ")[0];
    name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    const message = `Olá, ${name}! Você tem um documento para assinar com a Confia.\n\nAgora, você vai passar pelas etapas de identificação e biometria facial para assinar o seu documento. Clique neste link para começar: ${
      API_LINK_URL + "/?chave=" + props.identifier
    }\n\n*A Confia não é responsável pelo conteúdo do documento ou por eventuais negociações entre as partes.`;
    window.open(`https://wa.me/55${FieldNormalize.NUMBER(props.phone)}?text=${encodeURIComponent(message)}`, "_blank");
  };

  handleSelectCheckbox = (row, value, id) => {
    var part = row;

    if (id === 1) {
      part.notifySms = value == "true" ? true : false;
    } else if (id === 2) {
      part.notifyWhatsapp = value == "true" ? true : false;
    } else if (id === 3) {
      part.notifyEmail = value == "true" ? true : false;
    }

    this.props.updatePart(part);
  };

  render() {
    /*(if (this.props.newDocumentRedirectTo && this.props.newDocumentRedirectTo !== "/company/notify-parts") {
      return <Redirect to={this.props.newDocumentRedirectTo} />;
    }*/
    // Configuração base da tabela
    var table = [
      {
        Header: "CPF",
        sortable: false,
        filterable: false,
        style: { "white-space": "unset" },
        accessor: "cpf",
      },
      {
        Header: "Nome",
        sortable: false,
        filterable: false,
        accessor: "name",
      },
      {
        Header: "Enviar link de assinatura",
        sortable: false,
        filterable: false,
        Cell: (row) => (
          <div className="actions-right">
            {row.original.phone && (
              <>
                <Button color="default" size="sm" title="Enviar link de assinatura por WhatsApp" className="btn-icon btn-link edit" onClick={() => this.handleSendWhatsapp(row.original)}>
                  <i className="fa fa-whatsapp" />
                </Button>{" "}
              </>
            )}
            <Button
              color="default"
              size="sm"
              title="Copiar link de assinatura"
              className="btn-icon btn-link edit"
              onClick={() => this.copyToClipboard(API_LINK_URL + "/?chave=" + row.original.identifier)}
            >
              <i className="fa fa-copy" />
            </Button>{" "}
          </div>
        ),
      },
    ];

    // Adicionar colunas condicionalmente
    if (this.props.company.sendMessage) {
      table.push({
        Header: "Notificar pela Confia",
        sortable: false,
        filterable: false,
        Cell: (row) => (
          <Row>
            {row.original.phone && (
              <>
                <Col sm="4">
                  <Label check>
                    <Field name={"signPartNotifySms" + row.original.signPartId} component="input" type="checkbox" value="true" disabled={!this.props.company.senderSms} />
                    {" SMS"}
                    <OnChange name={"signPartNotifySms" + row.original.signPartId}>
                      {(value, previous) => {
                        this.setState({ confiaCheckBox: true });
                        this.handleSelectCheckbox(row.original, value, 1);
                      }}
                    </OnChange>
                  </Label>
                </Col>
                <Col sm="4">
                  <Label check>
                    <Field name={"signPartNotifyWhatsapp" + row.original.signPartId} component="input" type="checkbox" value="true" disabled={!this.props.company.senderWpp} />
                    {" WHATSAPP"}
                    <OnChange name={"signPartNotifyWhatsapp" + row.original.signPartId}>
                      {(value, previous) => {
                        this.setState({ confiaCheckBox: true });
                        this.handleSelectCheckbox(row.original, value, 2);
                      }}
                    </OnChange>
                  </Label>
                </Col>
              </>
            )}
            {row.original.email && (
              <Col sm="4">
                <Label check>
                  <Field name={"signPartNotifyEmail" + row.original.signPartId} component="input" type="checkbox" value="true" disabled={!this.props.company.senderEmail} />
                  {" EMAIL"}
                  <OnChange name={"signPartNotifyEmail" + row.original.signPartId}>
                    {(value, previous) => {
                      this.setState({ confiaCheckBox: true });
                      this.handleSelectCheckbox(row.original, value, 3);
                    }}
                  </OnChange>
                </Label>
              </Col>
            )}
            {!row.original.phone && !row.original.email && (
              <Col>
                <p className="text-wrap">É necessário que os dados do assinante sejam colocados ao cadastrar o documento para podermos enviar a notificação.</p>
              </Col>
            )}
          </Row>
        ),
      });
    }

    // Adiciona coluna de verificação de PEP
    if (this.props.company?.checkPep === 2 || this.props.company?.checkPep === 3) {
      table.push({
        Header: "PEP",
        sortable: false,
        filterable: false,
        accessor: "pep",
        Cell: (row) => (
          <p className={`text-wrap ${row.original.validations?.some((validation) => validation.type === "pep" && validation.value) ? "text-danger" : ""}`}>
            {row.original.validations?.some((validation) => validation.type === "pep") ? (row.original.validations.find((validation) => validation.type === "pep").value ? "SIM" : "NÃO") : "N/A"}
          </p>
        ),
      });
    }

    // Adiciona coluna de verificação de CPF
    if (this.props.company?.checkCpfStatus === 2 || this.props.company?.checkCpfStatus === 3) {
      table.push({
        Header: "Status do CPF",
        sortable: false,
        filterable: false,
        accessor: "statusCpf",
        Cell: (row) => (
          <p className={`text-wrap ${row.original.validations?.some((validation) => validation.type === "cpf" && validation.value.toUpperCase() === "IRREGULAR") ? "text-danger" : ""}`}>
            {row.original.validations?.some((validation) => validation.type === "cpf") ? row.original.validations.find((validation) => validation.type === "cpf").value.toUpperCase() : "N/A"}
          </p>
        ),
      });
    }

    // Adiciona coluna de verificação de Mandado de Prisão
    if (this.props.company?.checkArrestWarrant === 2 || this.props.company?.checkArrestWarrant === 3) {
      table.push({
        Header: "Mandado de Prisão",
        sortable: false,
        filterable: false,
        accessor: "cpfArrestWarrants",
        Cell: (row) => (
          <p className={`text-wrap ${row.original.validations?.some((validation) => validation.type === "prisonWarrant" && validation.value) ? "text-danger" : ""}`}>
            {row.original.validations?.some((validation) => validation.type === "prisonWarrant")
              ? row.original.validations.find((validation) => validation.type === "prisonWarrant").value
                ? "SIM"
                : "NÃO"
              : "N/A"}
          </p>
        ),
      });
    }

    return (
      <>
        <div className="content">
          <NotificationAlert ref="notificationAlert" />

          <Modal isOpen={this.state.showModal}>
            {this.state.confiaCheckBox ? (
              <>
                <ModalBody>
                  <p>
                    <strong>Documento pronto para assinatura. Os links foram enviados pelos canais selecionados.</strong>
                  </p>
                </ModalBody>
              </>
            ) : (
              <>
                <ModalBody>
                  {" "}
                  <p>
                    <strong>Documento pronto para assinatura. Não esqueça de enviar os links de assinatura aos assinantes. .</strong>
                  </p>
                </ModalBody>
              </>
            )}
            <ModalFooter>
              <Link className="btn btn-round mr-2" to="/company/documents">
                {"OK"}
              </Link>
            </ModalFooter>
          </Modal>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col className="text-center" md="12">
                    <Card className="card-tasks">
                      <CardHeader>
                        <CardTitle tag="h4">
                          Documento cadastrado com sucesso! <i style={{ color: "green" }} className="fa fa-check" />
                        </CardTitle>
                      </CardHeader>

                      <CardHeader>
                        <CardTitle tag="h5">Notificar assinantes</CardTitle>
                      </CardHeader>

                      <CardBody>
                        <ReactTable
                          data={this.props.signPartsNotify}
                          columns={table}
                          showPagination={false}
                          minRows={1}
                          sortable={false}
                          loadingText="Carregando..."
                          noDataText="Nenhum assinate para o documento"
                          className="-striped -highlight"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  {this.props.company.sendMessage ? (
                    <>
                      <Col m="6" className="text-left" />
                      <Col m="6" className="text-right">
                        <Button
                          className="btn btn-round"
                          onClick={() => {
                            this.props.notifySigners({
                              parts: this.props.signPartsNotify,
                              companyId: this.props.company.companyId,
                            });
                            this.setState({ showModal: true });
                          }}
                        >
                          {"OK"}
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col m="6" className="text-right">
                        <Button className="btn btn-round" onClick={() => this.setState({ showModal: true })}>
                          {"OK"}
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </form>
            )}
          />
        </div>
      </>
    );
  }
}

const onSubmit = async (values) => {
  //  await sleep(300)
};

const mapStateToProps = (state) => ({
  ...state.Auth,
  ...state.NewDocument,
});

function mapDispatchToProps(dispatch) {
  return {
    unloadNotify: () => dispatch(unloadNotify()),
    updatePart: (part) => dispatch(updatePart(part)),
    notifySigners: (props) => dispatch(notifySigners(props)),
    clearRedirectNotify: () => dispatch(clearRedirectNotify()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifyParts);
