import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormGroup } from "reactstrap";

import Select from "react-select";

class SelectField extends Component {
  renderOptions(options, valueField, textField, blank) {
    if (options) {
      var opts = [];
      if (blank) {
        opts.push({ value: "", label: "" });
      }
      options.map((option) => {
        opts.push({ value: option[valueField], label: option[textField] });
      });
      return opts;
    } else {
      return null;
    }
  }

  render() {
    return (
      <FormGroup className={`has-label ${this.props.meta.touched && !this.props.meta.valid ? "has-danger" : ""}`} style={{ zIndex: 999 }}>
        {this.props.label && (
          <label>
            {this.props.label}
            {this.props.required ? " *" : ""}
          </label>
        )}
        <Select
          className={`react-select ${this.props.meta.touched && !this.props.meta.valid ? "danger" : "defult"}`}
          classNamePrefix="react-select"
          {...this.props.input}
          options={this.renderOptions(this.props.options, this.props.valueField ? this.props.valueField : "id", this.props.textField ? this.props.textField : "name", this.props.blank)}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          invalid={this.props.meta.error && this.props.meta.touched}
          value={this.renderOptions(this.props.options, this.props.valueField ? this.props.valueField : "id", this.props.textField ? this.props.textField : "name", this.props.blank).find(
            (obj) => obj.value === this.props.input.value
          )}
        />
        {this.props.meta.error && this.props.meta.touched && (
          <div className="text-right">
            <label className="text-right error">{this.props.meta.error}</label>
          </div>
        )}
      </FormGroup>
    );
  }
}

SelectField.defaultProps = {
  type: "text",
  disabled: false,
  required: false,
  blank: false,
};

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,

  options: PropTypes.array,
  valueField: PropTypes.string,
  textField: PropTypes.string,
  blank: PropTypes.bool,
};

export default SelectField;
