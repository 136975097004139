import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  CardFooter,
  CardTitle,
  Table,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import ReactTable from "react-table";
import NotificationAlert from "react-notification-alert";
import { Form, Field } from "react-final-form";
import TextField from "components/Fields/TextField";
import FieldValidation from "components/Fields/Validation/FieldValidation";
import { getReportFinancial, unload } from "../../reducers/FinancialReport";
import LoadingOverlay from "react-loading-overlay";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import MaskDate from "Utils/MaskDate";
import moment from "moment";
import fileDownload from "js-file-download";
import SelectField from "components/Fields/SelectField";
import MaskDecimal from "Utils/MaskDecimal";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";

const months = [
  { label: "Janeiro", value: "01" },
  { label: "Fevereiro", value: "02" },
  { label: "Março", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Maio", value: "05" },
  { label: "Junho", value: "06" },
  { label: "Julho", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Setembro", value: "09" },
  { label: "Outubro", value: "10" },
  { label: "Novembro", value: "11" },
  { label: "Dezembro", value: "12" },
];

const generateYears = () => {
  const currentDate = new Date();
  let yearsArr = [];

  for (let i = 0; i <= 3; i++) {
    if (i === 0) {
      yearsArr.push({ label: currentDate.getFullYear().toString(), value: currentDate.getFullYear().toString(), id: i + 1 });
    }

    if (i >= 1) {
      yearsArr.push({ label: (currentDate.getFullYear() - i).toString(), value: (currentDate.getFullYear() - i).toString(), id: i + 1 });
    }
  }

  return yearsArr
    .filter((value) => {
      // Retorna apenas o ano de 2023 para cima
      return Number(value.label) >= 2023 ? value : null;
    })
    .sort((a, b) => {
      // Ordena os anos em ordem decrescente
      return a.id - b.id;
    });
};

const years = generateYears();

class FinancialReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lista: [] };
  }

  componentWillUnmount() {
    this.props.unload();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errorReportFin !== this.props.errorReportFin) {
      if (nextProps.errorReportFin === true) {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>{nextProps.errorMessageFin}</div>
            </div>
          ),
          type: "danger",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
      }
    }
  }

  handleSearch = async (values, form) => {
    if (!values.mes || !values.mes.value || !values.ano || !values.ano.value) {
      form.submit();
    } else {
      let params = {
        companyId: this.props.company.companyId,
        mesAno: values.mes.value + values.ano.value,
      };
      await this.props.getReportFinancial(params);
      if (this.props.all && this.props.all.billings?.length == 0) {
        let options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>Nenhum registro encontrado.</div>
            </div>
          ),
          type: "warning",
          icon: "fa fa-exclamation-triangle",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
        return;
      }
    }
  };

  exportToCSV = (type) => {
    var head = [["Produto", "Data Cobrança", "Valor", "Remetente", "Data Registro Documento", "Nome Documento", "Situação Documento", "CPF", "Transaction ID"]];

    var ref = ["productName", "registrationDateBilling", "price", "senderName", "registrationDateDoc", "name", "status", "cpf", "transactionId"];

    var fileName = "RelatorioFaturamento";

    var data = this.props.all.billings.map((row) => {
      return {
        productName: row.productName,
        registrationDateBilling: MaskDate.TO_BR(row.registrationDateBilling),
        price: MaskDecimal.TO_BRL(row.price),
        senderName: row.docInfo ? row.docInfo.senderName : "",
        registrationDateDoc: row.docInfo ? MaskDate.TO_BR(row.docInfo.registrationDateDoc) : "",
        name: row.docInfo ? row.docInfo.name : "",
        status: row.docInfo ? row.docInfo.status : "",
        cpf: row.cpf,
        transactionId: row.docInfo ? row.docInfo.transactionId : "",
      };
    });

    data.push({ productName: "" });
    data.push({ productName: "Produtos:" });
    this.props.all.products.map((prod) => {
      return data.push({ productName: `Nome: ${prod.name}, Quantidade: ${prod.qtd}, Valor Total: ${MaskDecimal.TO_BRL(prod.price)}` });
    });
    data.push({ productName: `Valor Total: ${MaskDecimal.TO_BRL(this.props.all.priceTotal)}` });

    var ws = XLSX.utils.aoa_to_sheet(head);
    XLSX.utils.sheet_add_json(ws, data, {
      header: ref,
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${fileName}.xlsx`);
  };

  render() {
    return (
      <>
        <div className="content">
          <NotificationAlert ref="notificationAlert" />
          <LoadingOverlay active={this.props.loadingPage} spinner text="Processando...">
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div style={{ top: "0", zIndex: "100" }}>
                      <h6 className="title text-center" style={{ lineHeight: "44px", fontSize: ".85em" }}>
                        Relatório Financeiro
                      </h6>
                    </div>

                    <Row>
                      <Col className="text-center" md="12">
                        <Card className="card-tasks">
                          <CardHeader className="text-center">
                            <CardTitle tag="h6">Selecione o período a ser consultado</CardTitle>
                          </CardHeader>

                          <CardBody>
                            <Row>
                              <Col md="2">
                                <Field
                                  name="mes"
                                  label="Mês"
                                  validate={FieldValidation.required}
                                  component={SelectField}
                                  options={months}
                                  textField="label"
                                  valueField="value"
                                  placeholder="Selecione"
                                />
                              </Col>

                              <Col md="2">
                                <Field
                                  name="ano"
                                  label="Ano"
                                  validate={FieldValidation.required}
                                  component={SelectField}
                                  options={years}
                                  textField="label"
                                  valueField="value"
                                  placeholder="Selecione"
                                />
                              </Col>

                              <Col md="3">
                                <div className="fileinput text-center">
                                  <Button className="btn btn-round" style={{ margin: "25px 1px" }} onClick={() => this.handleSearch(values, form)}>
                                    Pesquisar
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col className="text-center" md="12">
                      <Card className="card-tasks">
                        <CardBody>
                          {this.props.all && this.props.all.billings && this.props.all.billings.length > 0 && (
                            <Row>
                              <Col m="4">
                                <div className="panel-heading">
                                  <br />
                                  <h6>Produtos:</h6>
                                  {this.props.all.products.map((prod) => (
                                    <cite>
                                      <h6>{"Nome: " + prod.name + ", Quantidade: " + prod.qtd + ", Valor Total: " + MaskDecimal.TO_BRL(prod.price)}</h6>
                                    </cite>
                                  ))}
                                  <h6>Valor Total: {MaskDecimal.TO_BRL(this.props.all.priceTotal)}</h6>
                                </div>
                              </Col>
                              <Col m="4" className="text-right">
                                <Button className="btn btn-round" onClick={() => this.exportToCSV()}>
                                  <i className="fa fa-file-excel-o" style={{ color: "#51bcda" }} />
                                  &nbsp; Gerar csv
                                </Button>
                              </Col>
                            </Row>
                          )}

                          <ReactTable
                            data={this.props.all && this.props.all.billings ? this.props.all.billings : []}
                            columns={[
                              {
                                Header: "Produto",
                                style: { whiteSpace: "unset" },
                                accessor: "productName",
                              },
                              {
                                Header: "Data Cobrança",
                                style: { whiteSpace: "unset" },
                                accessor: "rowCob",
                                className: "text-center",
                                Cell: (rowCob) => <div>{MaskDate.TO_BR(rowCob.original.registrationDateBilling)}</div>,
                              },
                              {
                                Header: "Valor",
                                style: { whiteSpace: "unset" },
                                className: "text-right",
                                accessor: "rowPrice",
                                width: 100,
                                Cell: (rowPrice) => <div>{MaskDecimal.TO_BRL(rowPrice?.original?.price || 0)}</div>,
                              },
                              {
                                Header: "Remetente",
                                style: { whiteSpace: "unset" },
                                accessor: "rowSenderName",
                                Cell: (rowSenderName) => <div>{rowSenderName?.original?.docInfo ? rowSenderName.original.docInfo.senderName : ""}</div>,
                              },
                              {
                                Header: "Data Registro Doc",
                                style: { whiteSpace: "unset" },
                                className: "text-center",
                                accessor: "rowRegDoc",
                                Cell: (rowRegDoc) => <div>{rowRegDoc.original.docInfo ? MaskDate.TO_BR(rowRegDoc.original.docInfo.registrationDateDoc) : ""}</div>,
                              },
                              {
                                Header: "Nome Documento",
                                style: { whiteSpace: "unset" },
                                accessor: "rowDocName",
                                Cell: (rowDocName) => <div>{rowDocName.original.docInfo ? rowDocName.original.docInfo.name : ""}</div>,
                              },
                              {
                                Header: "Situação Documento",
                                style: { whiteSpace: "unset" },
                                accessor: "rowDocStatus",
                                Cell: (rowDocStatus) => <div>{rowDocStatus.original.docInfo ? rowDocStatus.original.docInfo.status : ""}</div>,
                              },
                              {
                                Header: "CPF",
                                style: { whiteSpace: "unset" },
                                accessor: "cpf",
                                width: 140,
                              },
                              {
                                Header: "Transaction ID",
                                style: { whiteSpace: "unset" },
                                accessor: "rowTransactionId",
                                width: 140,
                                Cell: (rowTransactionId) => <div>{rowTransactionId.original.docInfo ? rowTransactionId.original.docInfo.transactionId : ""}</div>,
                              },
                              {
                                Header: "",
                                sortable: false,
                                filterable: false,
                                width: 5,
                              },
                            ]}
                            defaultPageSize={10}
                            showPaginationTop
                            showPaginationBottom={false}
                            showPageSizeOptions={false}
                            showPageJump={false}
                            previousText="ANTERIOR"
                            nextText="PRÓXIMO"
                            loadingText="Carregando..."
                            noDataText="Nenhum registro encontrado"
                            pageText="Página"
                            ofText="de"
                            rowsText="linhas"
                            className="-striped -highlight default-pagination"
                          />
                        </CardBody>
                        <CardFooter>
                          <hr />
                          <div className="stats">{this.props.all ? this.props.all.length : 0} Cobranças</div>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                </form>
              )}
            />
          </LoadingOverlay>
        </div>
      </>
    );
  }
}

const onSubmit = async (values) => {};

const mapStateToProps = (state) => ({
  ...state.FinancialReport,
  ...state.Auth,
});

function mapDispatchToProps(dispatch) {
  return {
    getReportFinancial: (balances) => dispatch(getReportFinancial(balances)),
    unload: () => dispatch(unload()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReport);
