import { combineReducers } from "redux";

import Auth from "./Auth";
import Register from "./Register";
import Profile from "./Profile";
import NewDocument from "./NewDocument";
import Dashboard from "./Dashboard";
import Documents from "./Documents";
import Authentications from "./Authentications";
import Users from "./Users";
import NewUser from "./NewUser";
import Notification from "./Notification";
import PaymentMethods from "./PaymentMethods";
import ChangePlan from "./ChangePlan";
import NewNotification from "./NewNotification";
import ListNotifications from "./ListNotifications";
import NewPassword from "./NewPassword";
import SinglePurchase from "./SinglePurchase";
import FinancialReport from "./FinancialReport";

// main reducers
export const reducers = combineReducers({
  Auth,
  Register,
  Profile,
  NewDocument,
  Dashboard,
  Documents,
  Users,
  NewUser,
  Notification,
  PaymentMethods,
  ChangePlan,
  NewNotification,
  ListNotifications,
  NewPassword,
  SinglePurchase,
  FinancialReport,
  Authentications,
});
