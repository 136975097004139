import React from "react";
import { OnChange } from "react-final-form-listeners";
// reactstrap components
import { Row, Col, Label } from "reactstrap";
import { Field } from "react-final-form";
import NotificationAlert from "react-notification-alert";

import TextField from "components/Fields/TextField";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import FieldValidation from "components/Fields/Validation/FieldValidation";

// core components

class PersonalData extends React.Component {
  isValidated = () => {
    console.log("VALUES=>", this.props.values);
    var ret = true;

    if (!this.props.values.name) {
      ret = false;
    }
    if (!this.props.values.cpf) {
      ret = false;
    }
    if (!this.props.values.email) {
      ret = false;
    }
    if (!this.props.values.phone) {
      ret = false;
    }

    if (ret) {
      if (!this.props.values.term || this.props.values.term.length === 0) {
        //this.props.validationError('É necessário ler e aceitar o termo de uso.');
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>É necessário ler e aceitar o termo de uso.</div>
            </div>
          ),
          type: "danger",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
        return false;
      }
    }

    if (!ret) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>Campos obrigatórios não preenchidos.</div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
    }

    return ret;
  };

  render() {
    console.log("TYPE", this.props.errors);
    return (
      <>
        <h5 className="info-text">SEUS DADOS</h5>

        <NotificationAlert ref="notificationAlert" />
        <Row className="justify-content-center">
          <Col className="mx-auto " md="12">
            <Field name="cpf" placeholder="* CPF" component={TextField} parse={FieldNormalize.CPF} validate={FieldValidation.composeValidators(FieldValidation.required, FieldValidation.cpf)} />
          </Col>

          <>
            <Col className="mx-auto " md="12">
              <Field name="name" placeholder="* Nome" component={TextField} validate={FieldValidation.required} />
            </Col>
            <Col className="mx-auto " md="12">
              <Field
                name="email"
                placeholder="* E-mail"
                component={TextField} //validate={FieldValidation.required}
                validate={FieldValidation.composeValidators(FieldValidation.required, FieldValidation.email)}
              />
            </Col>
            <Col className="mx-auto " md="12">
              <Field name="phone" placeholder="* Celular" component={TextField} parse={FieldNormalize.CELULAR} validate={FieldValidation.required} />
            </Col>
          </>
          <Col className="mx-auto text-center" md="12">
            <Label check>
              <Field name="term" component="input" type="checkbox" value="ok" /> <span className="form-check-sign" />
              Ao marcar este campo, tenho ciência que os dados pessoais acima inseridos <br />
              serão utilizados para execução e viabilização dos{" "}
              <a href="https://www.confia.net.br/privacidade" target="_blank" className="btn-link text-muted m-0 p-0 btn btn-default">
                serviços da Confia.
              </a>
              .
            </Label>
          </Col>
        </Row>
      </>
    );
  }
}

export default PersonalData;
