import React from "react";
import {Link} from 'react-router-dom';
import { Form } from 'react-final-form'
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  CardFooter,
  CardTitle,
  Table,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle

} from "reactstrap";

import TextField from 'components/Fields/TextField';
import SelectField from 'components/Fields/SelectField';
import FieldNormalize from 'components/Fields/Normalize/FieldNormalize';
import FieldValidation from 'components/Fields/Validation/FieldValidation';

class RegistrarDocumentos extends React.Component {



  render() {
    return (
      <>
        <div className="content">
          <Form
          onSubmit={onSubmit}
          initialValues={{ stooge: 'larry', employed: true }}
          render={({ handleSubmit, form, submitting, pristine, values, validating, valid }) => (
            <form onSubmit={handleSubmit}>



              <div style={{position:'sticky',top:'0','z-index':'100'}}>
                <Row>
                  <Col m="4" className="text-left">
                    <Button size="sm">Cancelar</Button>
                  </Col>
                  <Col m="4" className="text-center">
                      <h6 className="title" style={{lineHeight: '44px',fontSize: '.85em'}}>Registrar</h6>
                  </Col>
                  <Col m="4" className="text-right">
                      <Button color="primary" size="sm">Registrar</Button>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col className="text-center" md="12">
                  <Card className="card-tasks">
                    <CardHeader>
                      <CardTitle className="text-center" tag="h4">Documentos</CardTitle>
                      <Row>
                        <Col className="text-left" md="12">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              caret
                              className="btn-round"
                              color="info"
                              data-toggle="dropdown"
                              type="button"
                            >
                              <i className="nc-icon nc-settings-gear-65" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Marcar Todos
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Desmarcar Todos
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Registrar Todos
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div className="table-full-width table-responsive">
                        <Table>
                          <tbody>
                            <tr>
                              <td>
                                <FormGroup check>
                                  <Label check>
                                    <Input defaultChecked type="checkbox" />
                                    <span className="form-check-sign" />
                                  </Label>
                                </FormGroup>
                              </td>
                              <td className="text-left">
                                Banco Safra
                              </td>
                              <td className="text-left">
                                #RV0000000
                              </td>
                              <td className="td-actions text-right">
                                <Link to={'/cartorio/detalhe-documento'}>
                                <Button
                                  className="btn-round btn-icon btn-icon-mini btn-neutral"
                                  color="info"
                                  id="tooltip42906017"
                                  title=""
                                  type="button"
                                >
                                  <i className="nc-icon nc-zoom-split" />
                                </Button>
                                </Link>
                                <UncontrolledTooltip
                                  delay={0}
                                  target="tooltip42906017"
                                >
                                  Ver Detalhes
                                </UncontrolledTooltip>
                                <Button
                                  className="btn-round btn-icon btn-icon-mini btn-neutral"
                                  color="success"
                                  id="tooltip570363224"
                                  title=""
                                  type="button"
                                >
                                  <i className="nc-icon nc-check-2" />
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target="tooltip570363224"
                                >
                                  Registrar
                                </UncontrolledTooltip>
                              </td>
                            </tr>

                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <hr />
                      <div className="stats">
                        150 Documentos
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </form>
          )}
          />
        </div>
      </>
    );
  }
}

const onSubmit = async values => {
//  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2))
}

export default RegistrarDocumentos;
