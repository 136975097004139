import React from "react";
import {Link} from 'react-router-dom';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import { Line, Bar, Pie } from "react-chartjs-2";

import {
  chartExample1,
  chartExample4,
  chartExample9,
  chartExample10,
  chartExample11,
  chartExample12
} from "variables/charts";

class DetalhesEmolumentos extends React.Component {

  render() {
    return (
      <>
        <div className="content">

          <div style={{position:'sticky',top:'0','z-index':'100'}}>
            <Row>
              <Col m="4" className="text-left">
                <Link onClick={e => {e.preventDefault();this.props.history.goBack()}} className="btn btn-default btn-sm">Voltar</Link>
              </Col>

            </Row>
          </div>

          <Row>
            <Col lg="6" md="6" sm="6">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle>Emolumentos recebidos</CardTitle>
                  <p className="card-category">Total recebido no ano</p>
                </CardHeader>
                <CardBody>
                  <Line
                    data={chartExample1.data}
                    options={chartExample1.options}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-money" />
                    Total recebido: R$ 23.302,19
                  </div>
                </CardFooter>
              </Card>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle>Emolumentos por dia (R$)</CardTitle>
                  <p className="card-category">Mês: Janeiro</p>
                </CardHeader>
                <CardBody>
                  <Bar
                    data={chartExample10.data}
                    options={chartExample10.options}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" md="6" sm="6">
              <Card>
                <CardHeader>
                  <CardTitle>Quantidade por tipos</CardTitle>
                  <p className="card-category">Mês: Janeiro</p>
                </CardHeader>
                <CardBody>
                  <Pie
                    data={chartExample11.data}
                    options={chartExample11.options}
                    width={456}
                    height={300}
                  />
                </CardBody>
                <CardFooter>
                  <div className="legend">
                    <i className="fa fa-circle text-muted" />
                    Duplicatas
                  </div>
                  <div className="legend">
                    <i className="fa fa-circle text-info" />
                    Documentos
                  </div>
                  <div className="legend">
                    <i className="fa fa-circle text-warning" />
                    Notificações
                  </div>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-warning" />
                    Quantidade de operações que geraram emolumentos
                  </div>
                </CardFooter>
              </Card>
            </Col>

          </Row>
        </div>
      </>
    );
  }
}

export default DetalhesEmolumentos;
