import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormGroup, Input } from "reactstrap";

class TextField extends Component {
  render() {
    return (
      <FormGroup className={`has-label ${this.props.meta.touched && !this.props.meta.valid ? "has-danger" : ""}`}>
        {this.props.label && (
          <label>
            {this.props.label}
            {this.props.required ? " *" : ""}
          </label>
        )}
        <Input
          {...this.props.input}
          defaultValue={this.props.defaultValue}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          invalid={this.props.meta.error && this.props.meta.touched}
          maxLength={this.props.maxLength}
        />
        {this.props.meta.error && this.props.meta.touched && (
          <div className="text-right">
            <label className="text-right error">{this.props.meta.error}</label>
          </div>
        )}
      </FormGroup>
    );
  }
}

TextField.defaultProps = {
  disabled: false,
  required: false,
};

TextField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default TextField;
