import moment from 'moment';

const CEP = (value) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 5) return onlyNums;
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
}

const CNPJ = (value) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 2) return onlyNums;
  if (onlyNums.length <= 5)
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2)}`;
  if (onlyNums.length <= 8)
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}`;
  if (onlyNums.length <= 12)
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`;

  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`
}

const CPF = (value) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) return onlyNums;
  if (onlyNums.length <= 6)
      return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}`;
  if (onlyNums.length <= 9)
      return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}`;

  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(9, 11)}`;
}

const CPF_CNPJ = (value, previousValue) => {
  if (!isNaN(Number(value.replace(/[^\d]/g, "")))) {
    if (!value) {
      return value;
    }

    const onlyNums = value.replace(/[^\d]/g, "");
    if (!previousValue || value.length > previousValue.length) {
      // typing forward
      if (onlyNums.length === 3) {
        return onlyNums + ".";
      }
      if (onlyNums.length === 6) {
        return onlyNums.slice(0, 3) + "." + onlyNums.slice(3) + ".";
      }
      if (onlyNums.length === 9) {
        return (
          onlyNums.slice(0, 3) +
          "." +
          onlyNums.slice(3, 6) +
          "." +
          onlyNums.slice(6) +
          "-"
        );
      }
      if (onlyNums.length > 11) {
        return CNPJ(value, previousValue);
      }
    }
    if (onlyNums.length <= 3) {
      return onlyNums;
    }
    if (onlyNums.length <= 6) {
      return onlyNums.slice(0, 3) + "." + onlyNums.slice(3);
    }
    if (onlyNums.length <= 9) {
      return (
        onlyNums.slice(0, 3) +
        "." +
        onlyNums.slice(3, 6) +
        "." +
        onlyNums.slice(6)
      );
    }
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}-${onlyNums.slice(9, 11)}`;
  } else {
    return value;
  }
};


const CNS = (value) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 2) return onlyNums;
  if (onlyNums.length <= 5)
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2)}`;

  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}-${onlyNums.slice(5, 6)}`
}

const CELULAR = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`;
  }

  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(
    7,
    11
  )}`;
};

const NUMBER = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  return onlyNums;
};

function NUMBER_MONEY(value){
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");

  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  const lastNumber = onlyNums.length - 1;

  if (onlyNums.length === 3) {
    return `${onlyNums.slice(0, lastNumber)}.${onlyNums.slice(lastNumber)}`;
  }

  return `${onlyNums.slice(0, lastNumber - 1)}.${onlyNums.slice(lastNumber - 1, onlyNums.length)}`
}

function PERCENT_INPUT_FORMAT(value){

  if(value == '' || value == '0,0'){
      return null
   }
  const v = ((value.replace(/\D/g, '') / 100).toFixed(2) + '').split('.');

  const m = v[0].split('').reverse().join('').match(/.{1,3}/g);

  for (let i = 0; i < m.length; i++)
      m[i] = m[i].split('').reverse().join('') + '.';

  const r = m.reverse().join('');

  return r.substring(0, r.lastIndexOf('.')) + ',' + v[1];
}

const DATE = (value,previousValue) => {
  if (!value) {
    return value
  }

  const c = value.replace(/[^0-9]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    return `${c.slice(0, 2)}/${c.slice(2, 4)}/${c.slice(4, 8)}`
  } else {
    if (c.length <= 2) {
      return `${c.slice(0, 2)}`;
    }
    if (c.length <= 4) {
      return `${c.slice(0, 2)}/${c.slice(2, 4)}`;
    }
    return `${c.slice(0, 2)}/${c.slice(2, 4)}/${c.slice(4, 8)}`
  }

}

export default {
  CEP,
  CNPJ,
  CPF_CNPJ,
  CNS,
  CPF,
  CELULAR,
  NUMBER,
  NUMBER_MONEY,
  PERCENT_INPUT_FORMAT,
  DATE
};
