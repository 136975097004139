import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
// reactstrap components
import { Input, Row, Col, Label, UncontrolledAlert, Modal, Button } from "reactstrap";
import { Field } from "react-final-form";
import NotificationAlert from "react-notification-alert";

import TextField from "components/Fields/TextField";
import FieldNormalize from "components/Fields/Normalize/FieldNormalize";
import FieldValidation from "components/Fields/Validation/FieldValidation";

// core components

class CompanyData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTerm: false,
    };
  }

  toggleModalTerm = () => {
    this.setState({
      modalTerm: !this.state.modalTerm,
    });
  };

  isValidated = () => {
    console.log("VALUES=>", this.props.values);
    var ret = true;

    if (!this.props.values.companyName) {
      ret = false;
    }
    if (!this.props.values.cnpj) {
      ret = false;
    }
    if (!this.props.values.companyPhone) {
      ret = false;
    }

    if (ret) {
      if (FieldValidation.cnpj(this.props.values.cnpj) === "CNPJ inválido") {
        var options = {};
        options = {
          place: "tc",
          message: (
            <div>
              <div>CNPJ inválido.</div>
            </div>
          ),
          type: "danger",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        this.refs.notificationAlert.notificationAlert(options);
        return false;
      }
    }

    if (!ret) {
      var options = {};
      options = {
        place: "tc",
        message: (
          <div>
            <div>Campos obrigatórios não preenchidos.</div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
    }

    return ret;
  };

  render() {
    return (
      <>
        <h5 className="info-text">DADOS DA EMPRESA</h5>
        <NotificationAlert ref="notificationAlert" />

        <Modal isOpen={this.state.modalTerm} toggle={this.toggleModalTerm} scrollable={true}>
          <div className="modal-header">
            <button aria-hidden={true} className="close" data-dismiss="modal" type="button" onClick={this.toggleModalTerm}>
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h6 className="modal-title" id="myModalLabel">
              TERMO DE USO E POLÍTICA DE PRIVACIDADE CONFIA
            </h6>
          </div>
          <div className="modal-body">
            <div className="instruction">
              <Row>
                <Col md="12">
                  <p className="description">
                    O USO DO APLICATIVO “CONFIA” É REGIDO PELOS TERMOS E CONDIÇÕES ABAIXO DISCRIMINADOS, DE ACORDO COM AS LEIS BRASILEIRAS. AO USAR O APLICATIVO, O USUÁRIO CONCORDA INTEIRAMENTE COM
                    TODAS AS CONDIÇÕES AQUI DESCRITAS.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <strong>1. DO APLICATIVO CONFIA</strong>
                  <p className="description">
                    1.1. O aplicativo “CONFIA” (daqui por diante simplesmente “CONFIA”) foi desenvolvido pela empresa CONFIA TECNOLOGIA DA INFORMAÇÃO LTDA. (daqui por diante simplesmente CONFIA),
                    pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o n. 34.119.697/0001-36, com sede na Quadra CRS Setor de Comércio Residencial Sul, CR Comercio Residencial, Quadra 502
                    bloco C, Loja 37 PARTE 1016, Asa Sul, BRASÍLIA/DF, CEP 70330-530, com o intuito de prover serviços a seus clientes e aos usuários finais para conferir segurança em operações
                    econômicas e negócios jurídicos, mediante a identificação das partes, a garantia de segurança das informações prestadas, o estabelecimento de um canal de negociações e assinatura
                    de documentos e a interface com Central Nacional de Registro de Títulos e Documentos e de Registro Civil de Pessoas Jurídicas para registro de atos de interesse dos usuários e
                    clientes.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <strong>2. DEFINIÇÕES:</strong>
                  <p className="description">
                    2.1. CONFIA: empresa desenvolvedora e proprietária do CONFIA e do Sistema web CONFIA, responsável pela plataforma de serviços ofertada a clientes e usuários, que ocupa a condição
                    de controladora e operadora do processo de tratamento de dados pessoais de clientes e usuários, nos termos do art. 5º, incisos VII e VIII, c/c o art. 9º, inc. I, ambos da Lei n.
                    13.709, de 14 de agosto de 2018. 2.2. CONFIA: software desenvolvido para ser instalado em dispositivo eletrônico móvel (smartphone ou tablet) por meio de acesso a lojas on-line de
                    aplicativos, tais como Google Play e App Store; 2.3. Android: sistema operacional para dispositivos móveis desenvolvido pelo “Open Handset Alliance”, liderada pelo Google, sobre o
                    qual opera o CONFIA. 2.4. iOS: sistema operacional móvel da Apple Inc. desenvolvido originalmente para o iPhone e o iPad, sobre o qual opera o CONFIA; 2.5. Sistema web CONFIA:
                    página do CONFIA na internet por meio da qual os clientes e usuários executarão as seguintes atividades ou farão uso dos seguintes serviços: Cadastro inicial; Certificado Digital;
                    “Identidade Confia”; Assinatura Digital; Download de documentos; Biblioteca de documentos; Biblioteca de contratos; Receber documentos; Guardar documentos. 2.6. Clientes: pessoas
                    físicas ou jurídicas que firmaram contrato com a CONFIA para utilização do CONFIA e do Sistema web CONFIA e para ofertar seus produtos e serviços aos usuários. Os clientes terão
                    acesso as funcionalidades diversas do aplicativo, tais como identificação de clientes (know your client ou KYC), assinatura eletrônica de documentos digitais, trânsito de contratos
                    e documentos, histórico de contratos, avisos por mensagens, registros de processos, aquisição e venda de produtos, entre outras necessárias à oferta de seus produtos e serviços,
                    englobando, também, as demais que vierem a ser criadas posteriormente para a mesma finalidade. 2.7. Usuários: pessoas físicas e/ou jurídicas, sem restrição, que usam o CONFIA e o
                    Sistema web CONFIA e que terão acesso às funcionalidades para interação com os clientes e para aquisição de seus produtos e serviços, entre as quais assinatura eletrônica de
                    documentos digitais, histórico de assinaturas, notificações, entre outras necessárias à obtenção de produtos e serviços ofertados pelos clientes, englobando, também, as demais que
                    vierem a ser criadas posteriormente para a mesma finalidade. 2.8. Central Nacional de Registro de Títulos e Documentos e de Registro Civil de Pessoas Jurídicas: sistema mediador
                    que liga os clientes que desejam solicitar atos de registro aos Cartórios de Registro de Títulos e Documentos e de Registro Civil de Pessoas Jurídicas (RTDPJ). 2.9. Marca CONFIA: A
                    marca “CONFIA” está sob protocolo nº 919267432 e 919267572 de registros junto ao INPI - Instituto Nacional de Propriedade Industrial. 2.10. KYC – “Know Your Client” ou
                    IDENTIFICAÇÃO DE CLIENTES: procedimento utilizado para conhecer e coletar dados dos usuários da CONFIA e do Sistema web CONFIA. 2.11. CPF: Cadastro de Pessoa Física que o usuário
                    tem junto à Receita Federal do Brasil. 2.12. Documento com foto: os documentos com fotos que serão aceitos pela CONFIA são Carteira de Identidade e Carteira Nacional de
                    Habilitação. 2.13. Upload: ato de transferir dados de um computador ou smartphone local para um servidor. 2.14. Quiz: perguntas sobre dados pessoais do usuário. 2.15. Login: é o
                    nome que se dá para entrar no CONFIA e no Sistema web CONFIA. 2.16. FaceMatch: recurso baseado na tecnologia de reconhecimento facial.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <strong>3. DO ACESSO AO CONFIA E DO TRATAMENTO DE DADOS PESSOAIS</strong>
                  <p className="description">
                    3.1. Para que os clientes e usuários possam fazer o download e usar o CONFIA em seu dispositivo (smartphone ou tablet), deverão se certificar se possuem o Android ou o iOS como
                    sistemas operacionais, já que não haverá outra forma de acessar o CONFIA. 3.2. No tutorial de apresentação do CONFIA e do Sistema web CONFIA, será apresentado o aceite do “Termo de
                    Uso e Política de Privacidade do CONFIA” ao cliente e/ou usuário. O aceite do termo é obrigatório para uso do CONFIA e do Sistema web CONFIA, não sendo possível prosseguir sem o
                    aceitar. 3.3. Pela aceitação deste termo, fica expressamente declarado que o usuário e/ou o cliente aceitam o tratamento de seus dados pessoais para as seguintes finalidades:
                    identificação segura do usuário; compartilhamento dos dados pessoais com o cliente com quem o usuário estabelecer relação jurídica, assim como com os cartórios de RTDPJ e
                    instituições terceiras que possam vir a ofertar produtos e serviços; pesquisa de dados; estatística; proposição de produtos e serviços e armazenamento. 3.4. Os usuários e/ou
                    clientes ficam cientes que os seus dados pessoais serão tratados e mantidos armazenados pela CONFIA enquanto durar o uso do CONFIA e do Sistema web CONFIA pelo usuário e/ou
                    cliente, ou até que seja entregue à CONFIA a notificação especificada nos itens 5.1 e 5.2 deste termo. 3.5. Após o término do tratamento dos dados dos usuários e/ou clientes, os
                    dados armazenados serão eliminados, salvo aqueles que serão mantidos para fins estatísticos e de dimensionamento de produtos e serviços, além daqueles que devam ser mantidos para o
                    cumprimento de obrigações legais e dos dados anonimizados de uso exclusivo e intransferível da CONFIA, em conformidade com o item 3.8 deste termo. 3.6. Será necessário o envio de
                    dados pessoais sensíveis de usuários e/ou clientes para cadastro e acesso ao site, uma vez que será realizado o reconhecimento facial e biométrico, assim como serão coletados dados
                    dos documentos pessoais especificados no item 2.10 deste termo. 3.7. A CONFIA declara que poderá fazer o uso de dados anonimizados para fins de melhoria dos seus serviços. 3.8. São
                    direitos do cliente e/ou do usuário os seguintes: 3.8.1. confirmação da existência dos dados entregues quando do cadastro no CONFIA ou no Sistema web CONFIA ou em outras operações
                    na plataforma, além da realização das atividades de tratamento previstas no item 3.5 deste termo; 3.8.2. acesso aos dados entregues quando do cadastro no CONFIA ou no Sistema web
                    CONFIA ou em outras operações na plataforma; 3.8.3. correção de dados incompletos, inexatos ou desatualizados; 3.8.4. anonimização, bloqueio ou eliminação de dados desnecessários,
                    excessivos ou tratados em desconformidade com o disposto em lei e neste termo; 3.8.5. portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição
                    expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial; 3.8.6. eliminação dos dados pessoais tratados com o consentimento do
                    cliente e/ou usuário, exceto nas hipóteses previstas no item 3.5 deste termo; 3.8.7. informação das entidades públicas e privadas com as quais a CONFIA realize o uso compartilhado
                    de dados, caso seja necessário; 3.8.8. informação sobre a possibilidade de não fornecer consentimento, o que implicará o impedimento de acesso ao CONFIA e ao Sistema web CONFIA;
                    3.8.9. obtenção de cópia eletrônica integral dos dados pessoais mantidos pela CONFIA, observados os segredos comercial e industrial, nos termos de regulamentação da autoridade
                    nacional, em formato que permita a sua utilização subsequente, inclusive em outras operações de tratamento. 3.9. A CONFIA adotará as medidas necessárias para segurança e sigilo dos
                    dados que forem fornecidos ao CONFIA e ao Sistema web CONFIA, além dos meios necessários para a adoção das melhores práticas em sua gestão.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <strong>4. DO CADASTRO NO CONFIA</strong>
                  <p className="description">
                    4.1. Após o aceite mencionado nos itens 3.2 e 3.3 deste termo. O cliente e/ou o usuário deverão preencher um formulário de “Cadastro” sobre suas informações pessoais, assumindo o
                    compromisso da veracidade dos dados e a responsabilidade pelo correto preenchimento e manutenção das referidas informações. 4.2. Para validação do cadastro no CONFIA, o cliente
                    e/ou o usuário deverá remeter, via Sistema web CONFIA, documentos de comprovação de sua identidade por meio Upload de seu documento de identificação, a fim de que a CONFIA valide
                    as informações e permita o acesso à plataforma. 4.3. Os dados exigidos serão os seguintes: Pessoas físicas: Nome; CPF; data de nascimento; nome da mãe; número do documento, que
                    poderá ser Carteira de Identidade ou Carteira Nacional de Habilitação; endereço (CEP, Estado, Cidade, Bairro e endereço completo), número de telefone celular, e-mail e respostas ao
                    Quiz. Pessoas jurídicas: Atos constitutivos (contrato social, estatuto com ata de reunião ou assembleia, e procuração, se for o caso), razão social, CNPJ, nome do representante
                    legal, endereço (CEP, Estado, Cidade, Bairro e endereço completo), número de telefone celular, e-mail e respostas ao Quiz. 4.4. Após a validação do cadastro, que será comunicada ao
                    cliente e/ou usuário via CONFIA ou Sistema web CONFIA, será requerida a criação de perfil de acesso, mediante a definição de login de usuário e senha. 4.5. O login do usuário será
                    o número do CPF (no caso de pessoas jurídicas, o número do CPF do representante legal informado no cadastramento). 4.6. A senha será numérica, com bloqueio após a terceira
                    tentativa errada de digitação no acesso. 4.7. A assinatura dos documentos intermediados pelo CONFIA ou pelo Sistema web CONFIA, mediante senha de cada usuário e FaceMatch, é o
                    método escolhido, de comum acordo pelas partes, para validade dos documentos e comprovação da autoria, da integridade e, se necessário, da confidencialidade de documentos
                    digitalizados, nos termos do art. 6º do Decreto 10.278, de 18 de março de 2020. 4.8. O CLIENTE E/OU O USUÁRIO DECLARAM EXPRESSAMENTE, SOB RESPONSABILIDADE CIVIL E CRIMINAL, QUE AS
                    INFORMAÇÕES PRESTADAS SÃO VERDADEIRAS, NÃO SENDO A CONFIA, SEUS SÓCIOS, ADMINISTRADORES, EMPREGADOS, COLABORADORES E PREPOSTOS RESPONSÁVEIS PELA VERACIDADE DE TAIS DADOS.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <strong>5. DO CANCELAMENTO</strong>
                  <p className="description">
                    5.1. Caso o cliente não queira mais ofertar produtos e serviços por intermédio do CONFIA e do Sistema web CONFIA, deverá entrar em contato com a CONFIA por meio do CONFIA ou do
                    Sistema web CONFIA para rescindir o contrato firmado, quando, então, será removido da plataforma, garantindo-se a interrupção do tratamento de seus dados. 5.2. Caso o usuário não
                    queira mais utilizar o CONFIA e o Sistema web CONFIA, poderá requerer a sua exclusão da plataforma por meio do CONFIA ou do Sistema web CONFIA, quando, então, será removido da
                    plataforma, garantindo-se a interrupção do tratamento de seus dados. 5.3. As exclusões referidas nos subitens anteriores não serão realizadas, no entanto, se cliente e/ou usuário
                    tiverem contrato(s) pendente(s) de cumprimento, hipótese em que a exclusão ficará condicionada ao cumprimento dos efeitos dos atos jurídicos. 5.4. Sem prejuízo das demais sanções
                    legais e daquelas aqui previstas, a CONFIA poderá, a seu critério, notificar, suspender e/ou cancelar o cadastro do usuário e/ou cliente, revogar a licença do software, impedir o
                    acesso ao CONFIA ou ao Sistema web CONFIA (ou qualquer parte deles) a qualquer tempo, definitiva ou temporariamente, nos seguintes casos, exemplificando, mas não se limitando: a)
                    Descumprimento de quaisquer disposições deste Termo de Uso; b) Verificação de cadastro duplicado; c) Verificação de novo cadastro realizado por Usuário que teve seu cadastro
                    cancelado e/ou suspenso; d) Constatação de fraude ou tentativa de fraude; e/ ou, e) Fornecimento de informações solicitadas incorretas e/ou inverídicas; f) Recusa à prestação de
                    eventuais informações adicionais solicitadas pelo CONFIA ou pelo Sistema web CONFIA. 5.5. A CONFIA reserva-se o direito de alterar, suspender ou interromper o CONFIA ou o Sistema
                    web CONFIA (ou qualquer parte ou conteúdo) a qualquer tempo, sem aviso prévio, sem qualquer tipo de responsabilidade ou dever de indenizar em tal caso. 5.6. Fica ressalvado que o
                    cancelamento do cadastro de qualquer usuário no Confia não afeta os atos registrais praticados pelo Registro de Títulos e Documentos competente, que está sujeito a regramento legal
                    próprio
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <strong>6. CENTRAL DE ATENDIMENTO</strong>
                  <p className="description">Caso seja necessário contato com a Central de Atendimento da CONFIA, o cliente e/ou o usuário deverão enviar e-mail para suporte@confia.net.br</p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <strong>7. RENÚNCIA A GARANTIAS E LIMITAÇÃO DE RESPONSABILIDADE</strong>
                  <p className="description">
                    7.1. A CONFIA responde pelos seus serviços efetivamente prestados e pelo gerenciamento do CONFIA e do Sistema web CONFIA em relação aos clientes e/ou usuários; porém, os produtos e
                    serviços oferecidos pelos clientes aos usuários, assim como os dados dos fornecidos pelo usuário ao CONFIA e ao Sistema web CONFIA não são, sob qualquer hipótese, de
                    responsabilidade da CONFIA, que não interfere de qualquer forma na relação firmada entre clientes e usuários. 7.2. A CONFIA não assegura, declara ou garante que o uso do CONFIA ou
                    do Sistema web CONFIA será ininterrupto e livre de erros, e o cliente e/ou usuário concordam que a CONFIA pode remover o CONFIA ou o Sistema web CONFIA ou cancelar a sua
                    disponibilidade a qualquer momento, sem aviso prévio, independentemente de qualquer ou pagamento de indenização. 7.3. A CONFIA, seus sócios, administradores, empregados,
                    colaboradores, prepostos ou clientes não são responsáveis por quaisquer danos indiretos ou diretos decorrentes do uso pelos usuários do CONFIA ou do Sistema web CONFIA ou por
                    qualquer outra reclamação relacionada de qualquer forma ao seu uso pessoal do CONFIA ou do Sistema web CONFIA. 7.4. O cliente e/ou usuário são responsáveis por manterem o CONFIA
                    sempre atualizado em seus dispositivos para acessar as mais recentes funções da plataforma. O usuário e/ou cliente declaram expressamente que, se não o fizerem, qualquer problema
                    na utilização do CONFIA o do Sistema web CONFIA não será de responsabilidade da CONFIA. 7.5. A CONFIA não garante que o CONFIA ou o Sistema web CONFIA estará sempre isento de
                    perdas de desempenho, defeitos, ataques, interferências, atividades de hackers ou outra intrusão de segurança, limitando-se a apontar que adotará as melhores práticas para que
                    prevenir ou minimizar os impactos daí decorrentes. Em qualquer caso, a CONFIA não assume qualquer responsabilidade com relação a atos de terceiros como esses. 7.6. Ao usarem o
                    CONFIA ou o Sistema web CONFIA, o usuário e/ou cliente concordam, na máxima extensão permitida por lei, em indenizar e manter a CONFIA, seus sócios, administradores, empregados,
                    colaboradores ou prepostos livres de danos com relação a quaisquer reclamações resultantes da violação deste termo.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <strong>8. DA PROPRIEDADE INTELECTUAL</strong>
                  <p className="description">
                    8.1. O usuário e/ou cliente concordam que os serviços prestados pela CONFIA, incluindo, sem limitação, o CONFIA, o Sistema web CONFIA, sua marca, seus materiais de divulgação, sua
                    concepção, sua arquitetura, conteúdo editorial e scripts e software utilizados para implementá-lo contêm informações exclusivas e material que são propriedade da CONFIA e de seus
                    sócios e são protegidos pelas leis de propriedade intelectual e outras leis aplicáveis, incluindo, sem limitação, direitos autorais. 8.2. O usuário e/ou cliente concordam que não
                    utilizarão informações ou materiais exclusivos da CONFIA de nenhuma forma, exceto para uso do CONFIA e do Sistema web CONFIA em conformidade com este termo. Nenhuma parte do CONFIA
                    ou do Sistema web CONFIA poderá ser reproduzida de qualquer forma ou por qualquer meio, exceto conforme expressamente permitido por este termo. O usuário e/ou cliente concordam em
                    não modificar, alugar, arrendar, emprestar, vender, distribuir ou criar obras derivadas baseadas no CONFIA ou no Sistema web CONFIA de qualquer forma, e o usuário e/ou cliente não
                    poderão explorar o CONFIA ou o Sistema web CONFIA de qualquer forma não, incluindo, sem limitação, por invasão ou sobrecarga da capacidade da rede. 8.3. Sem prejuízo de qualquer
                    disposição deste termo, a CONFIA e seus sócios e representantes se reservam o direito de alterar, suspender, remover ou desabilitar o acesso ao CONFIA ou ao Sistema web CONFIA,
                    conteúdos ou outros materiais de sua propriedade, a qualquer momento, sem aviso ou ônus, exceto nos casos especificados em sentido contrário neste termo. Em hipótese alguma, a
                    CONFIA será responsabilizada por ter realizado tais alterações. A CONFIA poderá ainda impor limites sobre o uso ou de acesso a determinadas funcionalidades ou partes do CONFIA ou
                    do Sistema web CONFIA em qualquer caso e sem aviso ou responsabilidade, salvo nas hipóteses especificadas em sentido contrário neste termo. 8.4. Todos os direitos autorais
                    relacionados ao CONFIA, ao Sistema web CONFIA, sua marca e softwares a ele relacionados são de propriedade da CONFIA, que se reserva todos os direitos previstos em lei. A
                    UTILIZAÇÃO DA MARCA CONFIA, DO SOFTWARE OU DO SISTEMA WEB CONFIA, NO TODO OU EM PARTE, FORA DOS LIMITES ESPECIFICADOS NESTE TERMO, É ESTRITAMENTE PROIBIDA E VIOLA OS DIREITOS DE
                    PROPRIEDADE INDUSTRIAL E INTELECTUAL DA CONFIA, PODENDO SUJEITAR O INFRATOR ÀS PENALIDADES CIVIS E CRIMINAIS PREVISTAS EM LEI, INCLUINDO POSSÍVEIS DANOS PATRIMONIAIS POR VIOLAÇÃO
                    DE DIREITOS AUTORAIS. 8.5. A CONFIA, o CONFIA, o Sistema web CONFIA, a marca CONFIA e outras marcas, marcas de serviço, gráficos e logos da CONFIA usados em relação ao CONFIA e ao
                    Sistema web CONFIA são marcas ou marcas registradas no Brasil. Outras marcas, marcas de serviço, gráficos e logos usados em relação ao CONFIA ou ao Sistema web CONFIA podem ser
                    marcas de seus respectivos proprietários. O usuário e/o cliente não recebem qualquer direito ou licença com relação a quaisquer das marcas mencionadas e ao seu uso.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <strong>9. POLÍTICA DE PRIVACIDADE</strong>
                  <p className="description">
                    9.1. Informações pessoais: Os dados pessoais que tenham inseridos no CONFIA ou no Sistema web CONFIA no momento do cadastro ou para qualquer outra operação posterior serão
                    armazenados em ambiente seguro, garantindo o seu sigilo e a sua confidencialidade. 9.2. Cuidados ao acessar a área segura: o cliente e/ou usuário declaram são os únicos
                    responsáveis pelo seu login e senha de acessos, e ainda que têm ciência da necessidade de adotar, além de outras aplicáveis, ao menos as seguintes precauções de acesso: a) Senhas
                    sequenciais ou que contenham dados pessoais, data de aniversário ou de seus familiares, devem ser evitadas; b) A troca de senha deve ser realizada de maneira periódica; c)
                    Recomenda-se manter software de antivírus sempre atualizado no dispositivo; d) Deve-se evitar o acesso em dispositivos de terceiros.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <strong>10. COMPARTILHAMENTO DE INFORMAÇÃO</strong>
                  <p className="description">
                    10.1. O usuário e/ou cliente, por meio deste termo, concordam e aceitam que a CONFIA poderá compartilhar as suas informações com terceiros (como instituições bancárias, cartórios,
                    entre outros) sempre isso for necessário para efetivação dos serviços demandados, além das hipóteses de imposição legal ou determinação judicial. 10.2. A CONFIA não vende ou aluga
                    as informações pessoais dos usuários e/ou clientes para terceiros.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <strong>11. DISPOSIÇÕES GERAIS</strong>
                  <p className="description">
                    11.1. A CONFIA reserva o direito de, a qualquer tempo, modificar o presente Termo de Uso e Política de Privacidade e impor termos ou condições novos ou adicionais sobre o uso do
                    CONFIA e do Sistema web CONFIA. Tais modificações e termos e condições adicionais terão eficácia imediata e serão incorporados a este Termo. 11.2. No caso acima, a continuação do
                    uso do CONFIA e do Sistema web CONFIA será considerada como aceitação das modificações processadas. 11.3. Se qualquer cláusula ou condição deste Termo for considerado(a)
                    inválido(a), ilegal ou inaplicável, os clientes e os usuários concordam que tal cláusula ou condição poderá ser excluído(a) e o restante do Termo continuará em vigor por prazo
                    indeterminado. 11.4. Este Termo de Uso e Política de Privacidade serão regidos e interpretados de acordo com as leis vigentes na República Federativa do Brasil. 11.5. Os clientes e
                    os usuários reconhecem que todos os temas relativos ao uso do CONFIA e do Sistema web CONFIA submetem-se à jurisdição exclusiva do Poder Judiciário do Brasil. 11.6. Para dirimir
                    eventuais dúvidas acerca do presente instrumento, bem como qualquer evento relacionado à utilização dos serviços CONFIA e Sistema web CONFIA, fica desde logo eleito o Foro de
                    Brasília/DF, excluindo-se qualquer outro, por mais privilegiado que outro Foro seja.
                  </p>
                </Col>
              </Row>
            </div>
            <p></p>
          </div>
          <div className="modal-footer justify-content-center">
            <Button className="btn-round" color="default" data-dismiss="modal" type="button" onClick={this.toggleModalTerm}>
              Fechar
            </Button>
          </div>
        </Modal>

        <Row className="justify-content-center">
          <Col className="mx-auto " md="12">
            <Field name="companyName" placeholder="* Razão social" component={TextField} validate={FieldValidation.required} />
          </Col>
          <Col className="mx-auto " md="12">
            <Field name="cnpj" placeholder="* CNPJ" component={TextField} parse={FieldNormalize.CNPJ} validate={FieldValidation.cnpj} />
          </Col>
          <Col className="mx-auto " md="12">
            <Label check>
              <Field name="ieExempt" component="input" type="checkbox" value="true" /> <span className="form-check-sign" /> Isento de inscrição estadual
            </Label>
          </Col>
          {this.props.values.ieExempt != "true" && (
            <Col className="mx-auto " md="12">
              <Field name="ie" placeholder="* Inscrição Estadual" parse={FieldNormalize.NUMBER} component={TextField} validate={FieldValidation.required} />
            </Col>
          )}
          <Col className="mx-auto " md="12">
            <Field name="companyPhone" placeholder="* Telefone comercial" component={TextField} parse={FieldNormalize.CELULAR} validate={FieldValidation.required} />
          </Col>
        </Row>
      </>
    );
  }
}

CompanyData.propTypes = {
  values: PropTypes.object.isRequired,
};

export default CompanyData;
